import type { ChangeEvent } from 'react';
import React from 'react';
import styled from 'styled-components';
import { InputTitle } from 'ui/atoms/input-title';

interface Params {
  title?: string;
  onTextChange: (value: string) => void;
  isError?: boolean;
}

const TRANSIENT_PROPS: (keyof Params | string)[] = ['onTextChange', 'options'];

type Props = Omit<React.ComponentPropsWithoutRef<'textarea'>, 'onTextChange'> &
  Params;

export const TextArea = styled.label
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<Props>(
    ({ title, isError, onTextChange, ref, className, ...props }) => {
      const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        onTextChange(value);
      };
      return {
        ref,
        children: (
          <>
            {title && <InputTitle>{title}</InputTitle>}
            <TextAreaStyled onChange={onChange} isError={isError} {...props} />
          </>
        ),
      };
    },
  )<Props>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

`;

const TextAreaStyled = styled.textarea<{ isError?: boolean }>`
  padding: 16px;
  border-radius: 0.3rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) =>
    p.isError ? 'var(--error-color)' : 'var(--border-color)'};

  :focus,
  :focus-within {
    border-color: var(--secondary-color-v3);
    outline: var(--secondary-color-v3);
  }
`;
