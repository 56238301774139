import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

export const openExternalLink = (url: string, nativeUrl = '') => {
  if (Capacitor.isNativePlatform()) {
    Browser.open({ url: nativeUrl || url });
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.open(url, '_blank').focus();
  }
};
