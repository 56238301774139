import { presentIonModal } from 'modals/present-ion-modal';

import type { User } from 'models';
import type { ComponentProps } from 'react';
import BusinessProfile from '../components/BusinessProfile/BusinessProfile';

export const updateBusinessProfileModal = async (): Promise<User | undefined> =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (user: User) => {
      res(user);
      onClose();
    };

    const modalProps: ComponentProps<typeof BusinessProfile> = {
      onClose,
      onDone,
      isModal: true,
    };
    const [promptModal, dismissModal] = presentIonModal(
      BusinessProfile,
      modalProps,
      'businessInfoModal',
    );

    promptModal();
  });
