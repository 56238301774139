import { forward } from 'effector';
import { navigatorDetector } from 'typesafe-i18n/detectors';
import { detectLocale } from 'i18n/i18n-util';
import {
  $locale,
  $hideLangBanner,
  LOCAL_STORAGE_KEY,
  HIDE_LANG_BANNER_STORAGE_KEY,
} from './index';
import { loadLocalStorageFx, saveLocalStorageFx } from '../localStorage';

export const navigatorLocale = detectLocale(navigatorDetector);
// export const navigatorLocale = 'en';

$locale.on(
  loadLocalStorageFx.doneData,
  (_, { [LOCAL_STORAGE_KEY]: value }) => value ?? navigatorLocale,
  // (_, { [LOCAL_STORAGE_KEY]: value }) => navigatorLocale,
);

forward({
  from: $locale,
  to: saveLocalStorageFx.prepend((value) => ({
    value,
    localStorageKey: LOCAL_STORAGE_KEY,
  })),
});

$hideLangBanner.on(
  loadLocalStorageFx.doneData,
  (_, { [HIDE_LANG_BANNER_STORAGE_KEY]: value }) => value ?? false,
  // (_, { [HIDE_LANG_BANNER_STORAGE_KEY]: value }) => false,
);

forward({
  from: $hideLangBanner,
  to: saveLocalStorageFx.prepend((value) => ({
    value,
    localStorageKey: HIDE_LANG_BANNER_STORAGE_KEY,
  })),
});
