import { combine, createEvent, restore } from 'effector';

export const LOCAL_STORAGE_KEY = 'auth';

export const resetAuthToken = createEvent();

export const setAuthToken = createEvent<string>();
export const $authToken = restore(setAuthToken, '').reset(resetAuthToken);

export const resetRefreshToken = createEvent();

export const setRefreshToken = createEvent<string>();
export const $refreshToken = restore(setRefreshToken, '').reset(
  resetRefreshToken,
);

export const $auth = combine({
  authToken: $authToken,
  refreshToken: $refreshToken,
});
