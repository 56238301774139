import { ReactNode } from 'react';
import styles from './Expandable.module.css';

interface Props {
  children: ReactNode;
  collapsedStateContent: ReactNode;
  expanded: boolean;
  setExpanded: () => void;
}

export const Expandable: React.VFC<Props> = ({
  children,
  collapsedStateContent,
  expanded,
  setExpanded,
}) => (
  <div
    className={`${styles.expandable} ${expanded ? styles.expanded : ''}`}
    onClick={expanded ? undefined : setExpanded}
  >
    {expanded ? children : collapsedStateContent}
  </div>
);
