import React from 'react';
import styles from './Notifications.module.css';
import Checkbox from '../../assets/icon/checkbox.svg';
import Cross from '../../assets/icon/cross.svg';
import Email from '../../assets/icon/email-active.svg';

interface AlertOptions {
  variant?: 'warning' | 'error' | 'success' | 'default';
  title?: string;
  message?: string | HTMLElement;
  backdropDismiss?: boolean;
  big?: boolean;
  onDidDismiss?: () => void;
}

interface ActionOptions {
  text: string;
  value: any;
  cssClass?: string;
}

interface NotifyI {
  options: AlertOptions | string;
  actions?: ActionOptions[];
  actionFn: (options: any) => any;
  cssClass: string;
  icon?: React.ReactNode;
}

export default class Notifications {
  static async alert<T>(
    options: AlertOptions | string,
    actions?: ActionOptions[],
  ): Promise<T | undefined> {
    const actionFn = presentAlert;
    const cssClass = styles.alert;
    const icon = Cross;
    return this._notify({
      options,
      actions,
      actionFn,
      cssClass,
      icon,
    });
  }

  static async toast<T>(
    options: AlertOptions | string,
    actions?: ActionOptions[],
  ): Promise<T | undefined> {
    const actionFn = presentToast;
    const cssClass = styles.toast;
    const icon = Checkbox;
    return this._notify({
      options,
      actions,
      actionFn,
      cssClass,
      icon,
    });
  }

  static async info<T>(
    options: AlertOptions | string,
    actions?: ActionOptions[],
  ): Promise<T | undefined> {
    const actionFn = presentAlert;
    const cssClass = styles.info;
    const icon = Email;
    return this._notify({
      options,
      actions,
      actionFn,
      cssClass,
      icon,
    });
  }

  static async dialog<T>(
    options: AlertOptions | string,
    actions?: ActionOptions[],
  ): Promise<T | undefined> {
    const actionFn = presentAlert;
    const cssClass = styles.dialog;
    return this._notify({
      options,
      actions,
      actionFn,
      cssClass,
    });
  }

  private static async _notify<T>({
    options,
    actions,
    actionFn,
    cssClass,
    icon,
  }: NotifyI): Promise<T | undefined> {
    return new Promise((res, rej) => {
      const aOptions =
        typeof options === 'string' ? { message: options } : options;
      const buttons = (actions || []).map((action) => ({
        text: action.text,
        cssClass: `notification-alert-btn ${action.cssClass || ''}`,
        handler: () => res(action.value),
      }));

      const big = Boolean(aOptions.big);

      const alertOptions: { [key: string]: any } = {
        header: aOptions.title,
        message: aOptions.message,
        buttons,
        backdropDismiss: aOptions.backdropDismiss,
        onDidDismiss: aOptions.onDidDismiss,
      };

      if (icon && aOptions.message) {
        alertOptions.message = `
          <img src="${icon}" class="${styles.icon}"/>
          <div>${aOptions.message}</div>
        `;
      }

      alertOptions.cssClass = `${
        styles.notificationPopup
      } ${cssClass} notification-alert ${big ? 'size-big' : ''}`;

      actionFn(alertOptions).then((alert: any) => {
        alert.onDidDismiss().then(() => res(undefined));
      });
    });
  }
}

async function presentAlert(options: any) {
  const { onDidDismiss, ...opt } = options;
  const alert = document.createElement('ion-alert') as any;
  Object.assign(alert, opt);
  document.body.appendChild(alert);
  await alert.present();
  await alert.onDidDismiss();
  if (onDidDismiss) {
    onDidDismiss();
  }
  return alert;
}

async function presentToast(options: any) {
  const toast = document.createElement('ion-toast') as any;

  const buttons =
    options.buttons?.length > 0
      ? options.buttons
      : [{ text: 'OK', handler: () => toast.dismiss() }];

  Object.assign(toast, { ...options, buttons });

  toast.duration = 2000;
  toast.cssClass = 'notification-toast';
  toast.position = 'top';

  document.body.appendChild(toast);
  await toast.present();
  return toast;
}
