import { $config } from 'stores/config';

declare global {
  interface Window {
    IPQS_REQUEST_ID?: string;
  }
}

export class IPQS {
  static async pushData(data: { [k: string]: string }) {
    const requestId = window?.IPQS_REQUEST_ID;
    if (requestId) {
      const queryData = Object.fromEntries(
        Object.entries(data).map(([k, v]) => [`update[${k}]`, v]),
      );
      const queryParams = new URLSearchParams({
        ...queryData,
        request_id: requestId,
      });
      const { ipqsApiKey } = $config.getState();
      const url = `https://www.ipqualityscore.com/api/json/postback/${ipqsApiKey}?${queryParams}`;
      const response = await fetch(url);
      const result = await response.json();
    }
  }
}
