import {
  useCancelPaymentRequest,
  useRetrievePaymentRequest,
} from 'api/queries';
import { PaymentLinkActionButton } from 'components/PaymentLinkActionButton';
import { TitlePortal } from 'components/TitlePortal';
import { DashboardPage } from 'pages/Dashboard';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'ui/atoms/icon';
import { LinkButton } from 'ui/atoms/link-button';
import {
  DataLine,
  DataLineHighlight,
  DataLineItem,
  DataMultiline,
} from 'ui/organisms/summary';
import { formatDueDate, getPaymentRequestStatusMapping } from 'utils';
import { copyShare } from 'utils/copy-share';
import { formatAmount } from 'utils/format-amount';
import { getHost } from 'utils/get-host';
import { useI18nObject } from 'utils/use-i18n-object';

export interface Props {}

export const PaymentRequestDetails: React.VFC<Props> = () => {
  const LL = useI18nObject();

  const { paymentRequestId } = useParams<{ paymentRequestId: string }>();

  const { data: paymentRequest } = useRetrievePaymentRequest(paymentRequestId);

  const cancelPaymentRequestMutation = useCancelPaymentRequest();
  const cancelPaymentRequest = useCallback(() => {
    cancelPaymentRequestMutation.mutate(paymentRequestId);
  }, [paymentRequestId]);

  const makePaymentLink = `${getHost()}/pay/${paymentRequestId}`;

  const copyLink = useCallback(() => {
    copyShare({
      url: makePaymentLink,
      text: `Payment request`,
      title: `Payment request`,
      dialogTitle: 'Share the payment request',
    });
  }, [makePaymentLink]);

  if (!paymentRequest) {
    return null;
  }

  const statusRepr = getPaymentRequestStatusMapping(paymentRequest.status);

  const {
    id,
    amount,
    currency,
    payer_name: payerName,
    due_date: dueDate,
    description,
  } = paymentRequest;
  return (
    <>
      <TitlePortal>
        <Link to="/dashboard/payment-requests">Payment Requests</Link> /{' '}
        {paymentRequestId}
      </TitlePortal>
      <Content>
        <Information>
          <DataLine>
            <DataLineItem isTitle>{LL.REQUEST_ID()}</DataLineItem>
            <span>{id}</span>
          </DataLine>
          <DataLine>
            <DataLineItem isTitle>{LL.PAYMENT_LINK()}</DataLineItem>
            <span>
              <LinkButton href={makePaymentLink} target="_blank">
                {makePaymentLink}
              </LinkButton>
            </span>
          </DataLine>
          <DataLine>
            <DataLineItem isTitle>{LL.STATUS()}</DataLineItem>
            <span>{statusRepr}</span>
          </DataLine>
          {payerName && (
            <DataLine>
              <DataLineItem isTitle>{LL.SENDER_NAME()}</DataLineItem>
              <span>{payerName}</span>
            </DataLine>
          )}
          <DataMultiline>
            <DataLineItem isTitle>{LL.DESCRIPTION()}</DataLineItem>
            <span>{description}</span>
          </DataMultiline>
          <DataLine>
            <DataLineItem isTitle>{LL.DUE_DATE()}</DataLineItem>
            <span>{dueDate && formatDueDate(dueDate)}</span>
          </DataLine>
          <DataLine>
            <DataLineItem isTitle>{LL.INVOICE_AMOUNT()}</DataLineItem>
            <span>
              {formatAmount({
                amount,
                currency,
              })}
            </span>
          </DataLine>
          <DataLineHighlight>
            <DataLineItem isTitle>{LL.TOTAL()}</DataLineItem>
            <span>
              {formatAmount({
                amount,
                currency,
              })}
            </span>
          </DataLineHighlight>
        </Information>
        <PaymentLinkActionButton paymentLink={makePaymentLink} />
      </Content>
    </>
  );
};

const Content = styled(DashboardPage)`
  gap: 16px;
`;

const Information = styled.div`
  padding: 24px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 1;
`;
