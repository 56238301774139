import styled from 'styled-components';

export const LegalPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  article {
    color: #4d4d4d;
    flex: 1;
    max-width: 700px;
    margin: 0 auto;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: 'Roboto', serif;
    font-style: normal;
    line-height: 2;
  }

  p {
    margin-top: 1rem;
    line-height: 1.8;
  }

  ul {
    list-style: disc;
    margin-left: 2rem;
    line-height: 1.5;
  }

  li {
  }

  h3 {
    margin-left: 2rem;
    font-size: 1.2rem;
  }
`;
