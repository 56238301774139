import React from 'react';

import { IonPage } from '@ionic/react';
import { Link } from 'react-router-dom';
import { useI18nObject } from 'utils/use-i18n-object';

import styles from './NotFound.module.css';
import { ReactComponent as Logo } from '../../assets/icon/logo-colorless.svg';

import SiteFooter from '../../components/SiteFooter/SiteFooter';

const NotFound = () => {
  const LL = useI18nObject();
  return (
    <IonPage className={styles.page}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <div className={styles.notFound}>
          <h1 className={styles.header}>404</h1>
          <h2 className={styles.subheader}>{LL.PAGE_NOT_FOUND()}</h2>
          <p>{LL.THE_PAGE_YOU_ARE_LOOKING_FOR()}</p>
          <Link className={styles.button} to="/">
            {LL.HOME()}
          </Link>
        </div>
      </div>
      <SiteFooter />
    </IonPage>
  );
};

export default NotFound;
