import { useHistory } from 'react-router-dom';
import React, { useCallback } from 'react';

import styled from 'styled-components';

import { forgotPasswordModal } from 'modals/forgotPasswordModal';

import { Page } from 'ui/templates/page';
import { Logo } from 'ui/atoms/logo';

import { useI18nObject } from 'utils/use-i18n-object';

import { Input } from 'ui/atoms/input';
import { RevealableInput } from 'ui/organisms/revealable-input';
import { Button } from 'ui/atoms/button';
import { LinkButton } from 'ui/atoms/link-button';
import { useLogin } from 'api/queries';
import { useForm } from 'utils/use-form';
import {
  resetConfig,
  setDevConfig,
  setProdConfig,
  setStagingConfig,
} from 'stores/config';
import SiteFooter from 'components/SiteFooter/SiteFooter';

interface FormI {
  email: string;
  emailInput: string;
  password: string;
  rememberMe: boolean;
}

export const SignIn = () => {
  const history = useHistory();
  const LL = useI18nObject();

  const onDone = useCallback(async () => {
    const redirect = new URLSearchParams(window.location.pathname).get(
      'redirect',
    );
    if (redirect) {
      history.push(redirect);
    } else {
      history.push('/dashboard');
    }
  }, []);

  const onSignUpClick = useCallback(async () => {
    history.push('/signup');
  }, []);

  const onForgotClick = useCallback(async () => {
    await forgotPasswordModal();
  }, []);

  const loginMutation = useLogin();

  const form = useForm<FormI>({
    initialValues: {
      email: '',
      emailInput: '',
      password: '',
      rememberMe: false,
    },
    onSubmit: async (values) => {
      const { email, password } = values;

      await loginMutation.mutateAsync({ email, password });

      onDone();
    },
  });

  const { emailInput, password } = form.values;

  const onEmailChange = useCallback((value: string) => {
    const [email, environment_prefix] = value.split('#');
    form.setFieldValue('email', email);
    if (environment_prefix === 'production') {
      setProdConfig();
    } else if (environment_prefix === 'staging') {
      setStagingConfig();
    } else if (environment_prefix === 'dev') {
      setDevConfig();
    } else if (environment_prefix === 'default') {
      resetConfig();
    }
    form.setFieldValue('emailInput', value);
  }, []);

  return (
    <Page>
      <Logo />
      <ContentContainer>
        <SectionTitle>{LL.SIGN_IN()}</SectionTitle>
        <Form className="form1" onSubmit={form.onSubmitAttempt}>
          <InputStyled
            name="emailInput"
            type="text"
            value={emailInput}
            pattern=".+@.+\..+#?"
            onInputChange={onEmailChange}
            className="input"
            placeholder={LL.EMAIL_ADDRESS()}
            required
          />
          <PasswordInputContainer>
            <RevealableInput
              required
              name="password"
              type="password"
              value={password}
              onInputChange={(v: string) => {
                form.setFieldValue('password', v);
              }}
              className="input"
              placeholder={LL.PASSWORD_CAP()}
            />
            <ForgotPasswordLinkButton onClick={onForgotClick}>
              {LL.FORGOT_PASSWORD()}
            </ForgotPasswordLinkButton>
          </PasswordInputContainer>
          <div className="input-grid-group">
            <Button type="submit">{LL.SIGN_IN()}</Button>
          </div>
          <SecondaryActions>
            {LL.DONT_HAVE_AN_ACCOUNT()}{' '}
            <LinkButton className="cursor" onClick={onSignUpClick}>
              Create one
            </LinkButton>
          </SecondaryActions>
        </Form>
      </ContentContainer>
      <SiteFooter />
    </Page>
  );
};

const SectionTitle = styled.div`
  text-align: center;
  margin: 0.5rem 0 2rem 0;
  font-size: 1.75rem;
  color: var(--text-color-v2);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  padding: 8px;
  max-width: 540px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 0.5rem;
  background: white;

  ${Input} {
    padding-left: 16px;
  }
`;

const InputStyled = styled(Input)``;

const PasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`;

const ForgotPasswordLinkButton = styled(LinkButton)`
  margin-left: 16px;
`;

const SecondaryActions = styled.div`
  text-align: center;
  padding: 8px 0 16px;
`;
