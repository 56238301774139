import React, { useCallback, useMemo, useState } from 'react';

import { FinancialAccount, TransferData } from 'models';
import { FinancialAccountListItem } from 'components/FinancialAccountListItem/FinancialAccountListItem';

import { useCapabilities, usePullFinancialAccounts } from 'api/queries';

import { addFinancialAccountModal } from 'modals/addFinancialAccountModal';
import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';

import { useI18nObject } from 'utils/use-i18n-object';

import { getAvailableAccounts } from 'data';
import styled from 'styled-components';
import { usePrefundAccounts } from 'api/queries/queries';
import PrefundAccountListItem from 'components/PrefundAccountListItem/PrefundAccountListItem';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { useModal } from 'utils/use-modal';
import { LinkButton } from 'ui/atoms/link-button/link-button';
import { AllowedToCreatePrefundAccountsGuard } from 'pages/PrefundAccounts/AllowedToCreatePrefundAccountsGuard';

interface Props {
  data: TransferData;
  onSubmit: (v: FinancialAccount) => void;
  onGoNext: () => void;
  showPrefundAccounts: boolean;
}

type TabOption = 'financialAccounts' | 'prefundAccounts';
export const SelectPaymentMethod: React.VFC<Props> = ({
  data: { senderAccount },
  showPrefundAccounts,
  onSubmit,
  onGoNext,
}) => {
  const LL = useI18nObject();
  const [selectedTab, setSelectedTab] =
    useState<TabOption>('financialAccounts');
  const isValid = Boolean(senderAccount);

  const onSelect = useCallback(
    (account: FinancialAccount) => () => {
      onSubmit(account);
    },
    [onSubmit],
  );

  const { data: financialAccounts, refetch } = usePullFinancialAccounts();
  const { data: capabilities } = useCapabilities();
  const { data: prefundAccounts } = usePrefundAccounts();
  const { modalPush } = useModal();

  const availableFinancialAccounts = useMemo(() => {
    if (financialAccounts && capabilities) {
      return getAvailableAccounts({
        direction: 'pull',
        financialAccounts,
        capabilities,
      });
    }
    return [];
  }, [financialAccounts, capabilities]);

  const onAddAccountClick = useCallback(async () => {
    await addFinancialAccountModal('pull');
    await refetch();
  }, []);

  const onNextClick = () => {
    if (isValid) {
      onGoNext();
    }
  };
  const promptNewPrefundAccount = useCallback(async () => {
    modalPush('/dashboard/prefund-accounts/add');
  }, []);

  const onTabChange = useCallback((event) => {
    setSelectedTab(event.detail.value);
  }, []);
  return (
    <PrevNextStepper nextStepActive={isValid} onNextClick={onNextClick}>
      <div className="transfer-stage">
        <div className="transfer-stage-header">
          {LL.HOW_WOULD_YOU_LIKE_TO_PAY()}
        </div>

        <IonSegment
          onIonChange={onTabChange}
          value={selectedTab}
          className="settings-tab-bar"
          scrollable
        >
          <IonSegmentButtonStyled value="financialAccounts">
            <IonLabel>{LL.FINANCIAL_ACCOUNTS()}</IonLabel>
          </IonSegmentButtonStyled>
          {showPrefundAccounts && (
            <AllowedToCreatePrefundAccountsGuard hideWarning>
              <IonSegmentButtonStyled value="prefundAccounts">
                <IonLabel>{LL.PREFUND_ACCOUNTS()}</IonLabel>
              </IonSegmentButtonStyled>
            </AllowedToCreatePrefundAccountsGuard>
          )}
        </IonSegment>
        <FinancialAccounts>
          {selectedTab === 'prefundAccounts' && (
            <>
              {prefundAccounts?.length === 0 ? (
                <LinkButtonStyled onClick={promptNewPrefundAccount}>
                  + {LL.CREATE_FIRST_PREFUND_ACCOUNT()}
                </LinkButtonStyled>
              ) : (
                <>
                  <LinkButtonStyled onClick={promptNewPrefundAccount}>
                    + {LL.NEW_PREFUND_BALANCE()}
                  </LinkButtonStyled>
                  {prefundAccounts?.map((prefundAccount) => (
                    <PrefundAccountListItem
                      account={prefundAccount}
                      onClick={onSelect(prefundAccount.financial_account)}
                      key={prefundAccount.id}
                      isSelected={
                        prefundAccount.financial_account.id ===
                        senderAccount?.id
                      }
                    />
                  ))}
                </>
              )}
            </>
          )}
          {selectedTab === 'financialAccounts' && (
            <>
              <LinkButtonStyled onClick={onAddAccountClick}>
                + {LL.ADD_A_FINANCIAL_ACCOUNT()}
              </LinkButtonStyled>
              {availableFinancialAccounts?.map((account) => (
                <FinancialAccountListItem
                  key={account.id}
                  onClick={onSelect(account)}
                  account={account}
                  disabled={!account.available}
                  isSelected={account.id === senderAccount?.id}
                />
              ))}
            </>
          )}
        </FinancialAccounts>
      </div>
    </PrevNextStepper>
  );
};

const FinancialAccounts = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

const LinkButtonStyled = styled(LinkButton)`
  margin: 20px;
`;

const IonSegmentButtonStyled = styled(IonSegmentButton)`
  max-width: 250px;
`;
