import { OnBoardingState } from 'models';
import type { User } from 'models';

export const getOnboardingState = (user?: User): OnBoardingState => {
  if (!user) {
    return OnBoardingState.signup;
  }

  if (!user.email_confirmed) {
    return OnBoardingState.verifyEmail;
  }

  if (!user.temp_phone_number && !user.phone_number) {
    return OnBoardingState.addPhoneNumber;
  }

  if (!user.phone_number_confirmed) {
    return OnBoardingState.verifyPhoneNumberCode;
  }

  if (!user.profile?.date_of_birth || !user.profile?.address) {
    return OnBoardingState.verifyIdentity;
  }

  if (!user.id_document && !user.onboarding_data?.skip_id_document) {
    return OnBoardingState.verifyIdDocument;
  }

  if (
    !(
      user.business_profile.country &&
      user.business_profile.address &&
      user.business_profile.business_name &&
      user.business_profile.tin
    ) &&
    !user.onboarding_data?.skip_business_profile
  ) {
    return OnBoardingState.verifyBusiness;
  }
  if (
    (!(user.recovery_email || user.recovery_phone_number) &&
      !user.onboarding_data?.skip_recovery_options) ||
    !user.security_question
  ) {
    return OnBoardingState.addRecoveryOptions;
  }

  return OnBoardingState.complete;
};
