import React from 'react';

import { IonPage, IonSplitPane } from '@ionic/react';
import { BulkPRDetails } from 'pages/BulkPRDetails';
import { Go } from 'pages/Go';
import { Legal } from 'pages/Legal';
import { MyNetwork } from 'pages/MyNetwork';
import { NetworkItemDetails } from 'pages/NetworkItemDetails';
import { PaymentRequestDetails } from 'pages/PaymentRequestDetails';
import { Request } from 'pages/Request';
import { TransactionDetails } from 'pages/TransactionDetails';
import { Transactions } from 'pages/TransactionHistory';
import { VerifyAccountDeletion } from 'pages/VerifyAccountDeletion';
import type { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';

import { OtherPrefund } from 'pages/OtherPrefund';
import { PrefundAccounts, PrefundOptions } from 'pages/PrefundAccounts';
import { Invoice } from 'pages/Invoice';
import { AfiPrefund } from 'pages/AfiPrefund/AfiPrefund';
import { Menu } from './Menu';

import { DashboardHome } from '../DashboardHome/DashboardHome';
import { Help } from '../Help/Help';
import { Settings } from '../Settings/Settings';
import { Transfer } from '../Transfer/Transfer';

import { AuthGuard, DashboardGuard, GuardComponent } from '../../guards';
import { Header } from './Header';

const Dashboard: React.VFC<RouteComponentProps> = ({ match, ...props }) => (
  <IonPageStyled>
    <IonSplitPane contentId="dashboard-main">
      <Menu />
      <DashboardMain id="dashboard-main">
        <Header />
        <Content>
          <Switch>
            <Route path={`${match.url}`} exact component={DashboardHome} />
            <Route path={`${match.url}/go`} component={Go} />
            <Route path={`${match.url}/transfer`} component={Transfer} />
            <Route path={`${match.url}/request`} component={Request} />
            <Route
              path={`${match.url}/network/:recipientId`}
              component={NetworkItemDetails}
            />
            <Route
              path={`${match.url}/transactions/:transactionId`}
              component={TransactionDetails}
            />
            <Route
              path={`${match.url}/payment-requests/:paymentRequestId`}
              component={PaymentRequestDetails}
            />
            <Route
              path={`${match.url}/bulk-pr/:bulkPrId`}
              component={BulkPRDetails}
            />
            <Route path={`${match.url}/network`} component={MyNetwork} />
            <Route path={`${match.url}/settings`} component={Settings} />
            <Route
              path={`${match.url}/transactions`}
              component={Transactions}
            />
            <Route
              path={`${match.url}/payment-requests`}
              component={Transactions}
            />
            <Route path={`${match.url}/legal`} component={Legal} />
            <Route path={`${match.url}/help`} component={Help} />
            <Route
              path={`${match.url}/verify-account-deletion-request`}
              component={VerifyAccountDeletion}
            />
            {/* <Route
              path={`${match.url}/prefund-accounts/:prefundAccountId/increase/other`}
              component={OtherPrefund}
            /> */}
            <Route
              path={`${match.url}/prefund-accounts/:prefundAccountId/increase/afi`}
              component={AfiPrefund}
            />
            {/* <Route
              path={`${match.url}/prefund-accounts/:prefundAccountId/increase`}
              component={PrefundOptions}
            /> */}
            <Route path="/invoice/:id" component={Invoice} />
            <Route
              path={`${match.url}/prefund-accounts`}
              component={PrefundAccounts}
            />
          </Switch>
        </Content>
      </DashboardMain>
    </IonSplitPane>
  </IonPageStyled>
);

const DashboardMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--secondary-color-v2);
`;

const Content = styled.main`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0 auto;

  > * {
    flex: 1;
  }
`;

const IonPageStyled = styled(IonPage)`
  max-width: 1920px;
  margin: 0 auto;
`;

export default GuardComponent(Dashboard, [AuthGuard, DashboardGuard]);
