import React, { useState } from 'react';
import styled from 'styled-components';
import { CheckboxChangeEventDetail } from '@ionic/core';
import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import { Button } from 'ui/atoms/button';
import { useRequestAccountDeletion } from 'api/queries';
import Notifications from 'components/Notifications';
import { useI18nObject } from 'utils/use-i18n-object';
import { Link } from 'react-router-dom';
import CloseIcon from '../../assets/icon/close.svg';

interface Props {
  onDone: () => void;
  onClose: () => void;
}
export const AccountDeletionPolicy: React.FC<Props> = ({ onDone, onClose }) => {
  const [isPolicyAccepted, setPolicyAccepted] = useState(false);
  const requestAccountDeletion = useRequestAccountDeletion();
  const LL = useI18nObject();

  const submitAccountDeletionRequest = async () => {
    if (!isPolicyAccepted) {
      Notifications.alert(LL.ACCEPT_POLICY_FIRST());
      return;
    }
    const response = await requestAccountDeletion.mutateAsync();
    onDone();
  };

  return (
    <div>
      <div className="title formModalTitle">
        {LL.ACCOUNT_DELETION_CONFIRMATION()}
        {onClose && (
          <img src={CloseIcon} className="title-close-icon" onClick={onClose} />
        )}
      </div>
      <ModalBody>
        {LL.ACCOUNT_DELETION_NOTE()}
        <Link to="/privacy" target="_blank">
          {LL.PRIVACY_POLICY()}
        </Link>
        .
        <div>
          <div className="input-field">
            <IonItem className="input-item-checkbox" lines="none">
              <IonCheckbox
                checked={isPolicyAccepted}
                onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                  setPolicyAccepted(e.detail.checked);
                }}
                name="confirmedDetails"
                className="input"
              />
              <IonLabel className="input-label">{LL.I_AGREE()}</IonLabel>
            </IonItem>
          </div>
        </div>
        <DeleteButtonContainer>
          <DeleteButton onClick={submitAccountDeletionRequest}>
            {LL.DELETE_ACCOUNT()}
          </DeleteButton>
        </DeleteButtonContainer>
      </ModalBody>
    </div>
  );
};

const ModalBody = styled.div`
  padding: 20px;
  line-height: 1.6rem;
  white-space: pre-wrap;
`;

const DeleteButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
const DeleteButton = styled(Button)`
  background-color: var(--error-color);
  color: var(--light-background-color);
  max-width: 300px;
`;
