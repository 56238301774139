import React, { useCallback } from 'react';
import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import { IonItem, IonInput, IonGrid, IonRow, IonCol } from '@ionic/react';
import type { InputChangeEventDetail } from '@ionic/core';
import { useI18nObject } from 'utils/use-i18n-object';

import {
  useDestroyBusinessDocument,
  useListBusinessDocuments,
  useUpdateProfile,
} from 'api/queries';
import {
  DocumentsField,
  ghanaBusinessDocChoices,
} from 'ui/organisms/documents-field';

import { uploadDocModal } from 'modals/uploadDocModal';
import { useFormik } from 'formik';
import styled from 'styled-components';
import type { User } from 'models';
import CloseIcon from '../../assets/icon/close.svg';

import CountrySelect from '../CountrySelect/CountrySelect';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';

interface Props {
  onDone: (userProfile: User) => void;
  isModal?: any;
  onClose?: any;
  subTitle?: any;
}

interface BusinessProfileForm {
  country: string;
  businessName: string;
  tin: string;
  addressCountry: string;
  addressCity: string;
  addressState: string;
  addressAddressLine1: string;
  addressAddressLine2: string;
  addressZip: string;
  addressDigitalAddress: string;
  telephone: string;
  website: string;
  socialMediaLink: string;
}

const BusinessProfile: React.FC<Props> = ({
  onDone,
  isModal,
  onClose,
  subTitle,
}) => {
  const LL = useI18nObject();

  const user = useStore($user);

  const { data: businessDocuments } = useListBusinessDocuments();

  const onDocumentAdd = useCallback(() => {
    return uploadDocModal(ghanaBusinessDocChoices());
  }, [ghanaBusinessDocChoices()]);

  const destroyDocumentMutation = useDestroyBusinessDocument();

  const onDocumentDelete = useCallback((id: number) => {
    destroyDocumentMutation.mutate(id);
  }, []);

  const updateProfileMutation = useUpdateProfile();

  const formik = useFormik<BusinessProfileForm>({
    initialValues: {
      country: user?.business_profile?.country,
      businessName: user?.business_profile?.business_name,
      tin: user?.business_profile?.tin,
      addressCountry: user?.business_profile?.address?.country,
      addressCity: user?.business_profile?.address?.city,
      addressState: user?.business_profile?.address?.state,
      addressAddressLine1: user?.business_profile?.address?.address_line_1,
      addressAddressLine2: user?.business_profile?.address?.address_line_2,
      addressZip: user?.business_profile?.address?.zip,
      addressDigitalAddress: user?.business_profile?.address?.digital_address,
      telephone: user?.business_profile?.telephone,
      website: user?.business_profile?.website,
      socialMediaLink: user?.business_profile?.social_media_link,
    },
    validate: ({
      businessName,
      addressCountry,
      addressCity,
      addressAddressLine1,
    }) => {
      const errors: { [key: string]: string } = {};
      if (!businessName) {
        errors.businessName = LL.REQUIRED();
      }
      if (!addressCountry) {
        errors.addressCountry = LL.REQUIRED();
      }
      if (!addressCity) {
        errors.addressCity = LL.REQUIRED();
      }
      if (!addressAddressLine1) {
        errors.addressAddressLine1 = LL.REQUIRED();
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (!user) {
        return;
      }
      const data = {
        country: values.country,
        business_name: values.businessName,
        tin: values.tin,
        address: {
          country: values.addressCountry,
          city: values.addressCity,
          state: values.addressState,
          address_line_1: values.addressAddressLine1,
          address_line_2: values.addressAddressLine2,
          zip: values.addressZip,
          digital_address: values.addressDigitalAddress,
        },
        telephone: values.telephone,
        website: values.website,
        social_media_link: values.socialMediaLink,
      };
      const response = await updateProfileMutation.mutateAsync({
        business_profile: data,
        onboarding_data: {
          ...user.onboarding_data,
          skip_business_profile: true,
        },
      });
      onDone(response);
    },
  });

  const {
    country,
    businessName,
    tin,
    addressCountry,
    addressCity,
    addressState,
    addressAddressLine1,
    addressAddressLine2,
    addressZip,
    addressDigitalAddress,
    telephone,
    website,
    socialMediaLink,
  } = formik.values;

  const isDigitalAddressInputVisible = addressCountry === 'GH';

  const createIonChangeHandler = useCallback(
    (fieldName: keyof BusinessProfileForm) =>
      (e: CustomEvent<InputChangeEventDetail>) => {
        formik.setFieldValue(fieldName, e.detail.value);
      },
    [],
  );

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {LL.VERIFY_YOU_BUSINESS()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <Form onSubmit={formik.handleSubmit}>
        {subTitle && <div className="title">{subTitle}</div>}
        <div className="form-container">
          <div className="input-group">
            <div className="input-group-title">{LL.OVERVIEW()}</div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <CountrySelect
                  value={country}
                  onIonChange={createIonChangeHandler('country')}
                  name="country"
                  interface="popover"
                  className="input"
                  placeholder={LL.COUNTRY()}
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={businessName}
                  onIonChange={createIonChangeHandler('businessName')}
                  name="businessName"
                  className="input"
                  placeholder={LL.BUSINESS_NAME()}
                  required
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={tin}
                  onIonChange={createIonChangeHandler('tin')}
                  name="tin"
                  className="input"
                  placeholder="TIN / EIN"
                />
              </IonItem>
            </div>
          </div>
          <div className="input-group">
            <div className="input-group-title">{LL.ADDRESS()}</div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <CountrySelect
                  value={addressCountry}
                  onIonChange={createIonChangeHandler('addressCountry')}
                  name="addressCountry"
                  className="input"
                  interface="popover"
                  placeholder={LL.COUNTRY()}
                  required
                />
              </IonItem>
            </div>
            <IonGrid className="input-grid-group">
              <IonRow>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInput
                      value={addressCity}
                      onIonChange={createIonChangeHandler('addressCity')}
                      name="addressCity"
                      className="input"
                      placeholder={LL.CITY()}
                      required
                    />
                  </IonItem>
                </IonCol>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInput
                      value={addressState}
                      onIonChange={createIonChangeHandler('addressState')}
                      name="addressState"
                      className="input"
                      placeholder={LL.STATE_REGION()}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={addressAddressLine1}
                  onIonChange={createIonChangeHandler('addressAddressLine1')}
                  name="addressAddressLine1"
                  className="input"
                  placeholder={LL.ADDRESS_LINE_1()}
                  required
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={addressAddressLine2}
                  onIonChange={createIonChangeHandler('addressAddressLine2')}
                  name="addressAddressLine2"
                  className="input"
                  placeholder={LL.ADDRESS_LINE_2()}
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={addressZip}
                  onIonChange={createIonChangeHandler('addressZip')}
                  name="addressZip"
                  className="input"
                  placeholder={LL.ZIP_CODE()}
                />
              </IonItem>
            </div>
            {isDigitalAddressInputVisible && (
              <div className="input-field">
                <IonItem className="input-item" lines="none">
                  <IonInput
                    value={addressDigitalAddress}
                    onIonChange={createIonChangeHandler(
                      'addressDigitalAddress',
                    )}
                    name="addressDigitalAddress"
                    className="input"
                    placeholder={LL.DIGITAL_ADDRESS()}
                  />
                </IonItem>
              </div>
            )}
          </div>
          <div className="input-group">
            <div className="input-group-title">{LL.DOCUMENTS}</div>
            <div className="input-field">
              {!!businessDocuments && (
                <DocumentsField
                  value={businessDocuments}
                  onDocumentAdd={onDocumentAdd}
                  onDocumentDelete={onDocumentDelete}
                />
              )}
            </div>
          </div>
          <div className="input-group">
            <div className="input-group-title">{LL.CONTACT_INFO()}</div>
            <div className="input-field">
              <IonItem className="input-item phone-number-item" lines="none">
                <PhoneNumberInput
                  value={telephone}
                  onChange={(value: string) => {
                    formik.setFieldValue('telephone', value);
                  }}
                  name="telephone"
                  className="input"
                  placeholder={LL.PHONE_NUMBER()}
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={website}
                  onIonChange={createIonChangeHandler('website')}
                  name="website"
                  className="input"
                  placeholder={LL.WEBSITE()}
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={socialMediaLink}
                  onIonChange={createIonChangeHandler('socialMediaLink')}
                  name="socialMediaLink"
                  className="input"
                  placeholder={LL.SOCIAL_MEDIA_LINK()}
                />
              </IonItem>
            </div>
          </div>
        </div>
        <button type="submit" className="btn submit-btn">
          {LL.SUBMIT()}
        </button>
      </Form>
    </div>
  );
};

const Form = styled.form`
  padding: 16px;
  max-width: 700px;
`;

export default BusinessProfile;
