import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'ui/atoms/icon';
import { SyntheticEvent, useCallback } from 'react';

interface Props {
  to: string;
  highlightFor?: string[];
  highlightForStrict?: boolean;
  icon: string;
  iconActive?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const MenuItem: React.VFC<Props> = ({
  to,
  highlightFor,
  highlightForStrict = false,
  icon,
  iconActive,
  children,
  onClick,
}) => {
  const location = useLocation();

  const onLinkClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();
      event.nativeEvent.stopImmediatePropagation();
      if (onClick) {
        onClick();
      }
    },
    [onClick],
  );
  const highlight = highlightFor ?? [to];

  const active = highlightForStrict
    ? highlight.includes(location.pathname)
    : highlight.some((v) => location.pathname.startsWith(v));
  return (
    <MenuItemStyled
      $active={active}
      to={to}
      onClick={onClick ? onLinkClick : undefined}
    >
      <Icon name={(active ? iconActive : icon) ?? icon} size={24} />
      <span>{children}</span>
    </MenuItemStyled>
  );
};

const MenuItemStyled = styled(Link)<{ $active: boolean }>`
  display: flex;
  align-items: center;
  gap: 24px;
  font-weight: 500;
  color: ${(p) => (p.$active ? 'var(--primary-color-main)' : 'black')};
`;
