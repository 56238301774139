import React from 'react';
import type { ChangeEvent } from 'react';
import styled from 'styled-components';

interface Props {
  value: boolean;
  onCheck: (v: boolean) => void;
  children?: React.ReactNode;
}

const TRANSIENT_PROPS = ['onCheck'];

export const Checkbox = styled.label
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<Props>(({ value, onCheck, children }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.checked;
      onCheck(value);
    };

    return {
      children: (
        <>
          <CheckboxInput
            type="checkbox"
            checked={value}
            onChange={handleChange}
          />
          {children}
        </>
      ),
    };
  })<Props>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 18px;
`;

const CheckboxInput = styled.input`
  appearance: none;
  background-color: #fff;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid currentColor;
  border-radius: 0.15em;

  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--secondary-color-v3);
  }

  &:checked::before {
    transform: scale(1);
  }
`;
