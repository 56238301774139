import { useConfig } from 'api/queries';

import type { AccountTypeI } from 'models';

interface Params {
  accountType?: AccountTypeI;
  country?: string;
}

export const useLimits = ({ accountType, country }: Params) => {
  const { data: config } = useConfig();

  let minLimit = 0;
  let maxLimit = 0;

  if (accountType === 'mobile_money') {
    minLimit = config?.mobile_money_min_limit || 0;
    maxLimit = config?.mobile_money_max_limit || 0;
    if (country === 'TG') {
      minLimit = config?.tg_mobile_money_min_limit || 0;
      maxLimit = config?.tg_mobile_money_max_limit || 0;
    }
  } else {
    minLimit = config?.bank_account_min_limit || 0;
    maxLimit = config?.bank_account_max_limit || 0;
  }

  return {
    minLimit,
    maxLimit,
  };
};
