import type {
  BulkPaymentRequest,
  BulkPaymentRequestDetailed,
  BusinessSettings,
  Config,
  Invoice,
  PaymentRequest,
  PrefundAccount,
  Recipient,
} from './index';

export const defaultRecipient: Recipient = {
  id: 0,
  first_name: '',
  middle_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  user: 0,
  financial_accounts: [],
};
export const defaultPaymentRequest: PaymentRequest = {
  type: 'payment_request',
  id: '',
  created: '',
  requester_name: '',
  payer_name: null,
  amount: '0.00',
  currency: 'GHS',
  description: '',
  due_date: '2099-12-31T23:59:00Z',
  payer: 0,
  payer_covers_fees: false,
  requester_account: 0,
  status: 'issued',
  logo: null,
  title: '',
  bulk_payment_request: null,
};

export const defaultBulkPaymentRequest: BulkPaymentRequest = {
  type: 'bulk_pr',
  id: 0,
  created: '',
  total_requested_amount: '0.00',
  currency: 'GHS',
  description: '',
  payment_requests: [],
  invoice: null,
  status: 'pending',
};

export const defaultBulkPR: BulkPaymentRequestDetailed = {
  ...defaultBulkPaymentRequest,
  payment_requests: [],
};

export const defaultInvoice: Invoice = {
  id: 0,
  user: 0,
  afi_fee: 0,
  amount: '0.00',
  currency: 'GHS',
  country: 'GH',
  status: 'draft',
  transaction: null,
  payer_account_used: null,
  payer: null,
  bulk_payment_request: defaultBulkPaymentRequest,
  type: 'bulk_payment_request',
};

export const defaultBusinessSettings: BusinessSettings = {
  logo: null,
  payment_request_title: '',
};

export const configPlaceholderData: Config = {
  mobile_money_min_limit: 0,
  mobile_money_max_limit: 0,
  bank_account_min_limit: 0,
  bank_account_max_limit: 0,
  tg_mobile_money_min_limit: 0,
  tg_mobile_money_max_limit: 0,
  client_app_dwolla_enabled: false,
  client_app_plaid_enabled: false,
};
