import { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useExportTransactions, useTransactionsList } from 'api/queries';
import { TitlePortal } from 'components/TitlePortal';
import { useI18nObject } from 'utils/use-i18n-object';

import { Header } from './Header';
import TransactionListItem from '../../components/TransactionListItem/TransactionListItem';

import '../Dashboard.css';

import { EmptyBox } from '../Dashboard/EmptyBox';
import EmptyResults from '../Dashboard/EmptyResults/EmptyResults';

export const TransactionHistory: React.FC = () => {
  const LL = useI18nObject();

  const history = useHistory();
  const [params, setParams] = useState<
    { q?: string; start_date?: string; end_date?: string } | undefined
  >({});

  const { data: transactionList, isLoading } = useTransactionsList(params);
  const qParams =
    params?.q || params?.start_date || params?.end_date ? params : undefined;
  const isParamsSet = !!qParams;

  const onSearch = useCallback((params) => {
    setParams(params);
  }, []);

  const promptNewTransaction = useCallback(() => {
    history.push('/dashboard/go');
  }, []);

  const onXlsxExportClick = useExportTransactions('xlsx', qParams);
  const onCsvExportClick = useExportTransactions('csv', qParams);
  const onPdfExportClick = useExportTransactions('pdf', qParams);
  return (
    <>
      <TitlePortal>{LL.TRANSACTIONS()}</TitlePortal>
      <HeaderContainer>
        <Header
          onSearch={onSearch}
          onCsvExportClick={onCsvExportClick}
          onPdfExportClick={onPdfExportClick}
          onXlsxExportClick={onXlsxExportClick}
        />
      </HeaderContainer>
      <Transactions>
        {transactionList?.map((transaction) => (
          <TransactionListItem key={transaction.id} transaction={transaction} />
        ))}
        {!isLoading && !isParamsSet && transactionList?.length === 0 && (
          <EmptyBox onClick={promptNewTransaction}>
            {LL.SEND_OR_REQUEST_YOUR_FIRST_PAYMENT()}
          </EmptyBox>
        )}
        {!isLoading && isParamsSet && transactionList?.length === 0 && (
          <EmptyResults text={LL.NO_TRANSACTIONS_FOUND()} />
        )}
      </Transactions>
    </>
  );
};

const Transactions = styled.div`
  background: white;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 1rem 2rem 4rem;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  flex-basis: calc(100% - 120px);
`;

const HeaderContainer = styled.div`
  padding: 1rem 2rem;
`;
