import { forwardRef, PureComponent } from 'react';
import { IonItem } from '@ionic/react';

import { VGSLoadedGuard } from 'guards/VGSLoadedGuard';

import { useStore } from 'effector-react';
import { $config } from 'stores/config';

import { spinnerDecrease, spinnerIncrease } from 'stores/spinner';
import { getErrorMessage } from 'api/interceptor';

import type { FinancialAccount } from 'models';
import { $authToken } from 'stores/auth';
import { $locale } from 'stores/locale';
import Notifications from 'components/Notifications';

import '../vgs.css';
import { i18nObject } from 'i18n/i18n-util';

interface State {
  vgsForm: any;
  formState: any;
  loader: any;
}

interface SubmitArgs {
  endpoint: string;
  body: { [key: string]: any };
}

interface CreditCardFormProps {
  onValid: (v: boolean) => void;
  vgsEnvironment: string;
  vgsVaultId: string;
  authToken: string;
  locale: string;
}

export class CreditCardFormInner extends PureComponent<
  CreditCardFormProps,
  State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      vgsForm: null,
      formState: null,
      loader: null,
    };
  }

  onVgsInit(inputContainer: HTMLElement) {
    const { VGSCollect } = window as any;

    const { vgsEnvironment, vgsVaultId } = this.props;

    const vgsForm = VGSCollect.create(
      vgsVaultId,
      vgsEnvironment,
      (formState: any) => {
        const { onValid } = this.props;
        this.setState({ formState });
        const isFormValid =
          formState.customer_name?.isValid &&
          formState['bank_card.card_number']?.isValid &&
          formState.bank_card?.isValid;
        onValid(isFormValid);
      },
    );

    const nameFieldElement = inputContainer.querySelector('.vgs--cc-name');
    const cardNameFieldElement =
      inputContainer.querySelector('.vgs--cc-number');
    const expirationFieldElement =
      inputContainer.querySelector('.vgs--cc-expiry');

    const css = {
      fontSize: '1rem',
      lineHeight: 'normal',
    };

    const LL = i18nObject($locale.getState());

    // Create VGS Collect field for credit card name
    vgsForm.field(nameFieldElement, {
      type: 'text',
      name: 'customer_name',
      placeholder: LL.NAME_ON_CARD(),
      validations: ['required'],
      css,
    });

    // Create VGS Collect field for credit card number
    vgsForm.field(cardNameFieldElement, {
      type: 'card-number',
      name: 'bank_card.card_number',
      successColor: '#4F8A10',
      errorColor: '#D8000C',
      placeholder: LL.CARD_NUMBER(),
      validations: ['required', 'validCardNumber'],
      css,
    });

    // Create VGS Collect field for credit card expiration date
    vgsForm.field(expirationFieldElement, {
      type: 'card-expiration-date',
      name: 'bank_card',
      serializers: [
        vgsForm.SERIALIZERS.separate({
          monthName: 'expiration_month',
          yearName: 'expiration_year',
        }),
      ],
      yearLength: '2',
      placeholder: LL.EXPIRATION_DATE_MMYY(),
      validations: ['required', 'validCardExpirationDate'],
      css,
    });

    this.setState({ vgsForm });
  }

  setInputRef = (inputContainer: any) => {
    if (inputContainer) this.onVgsInit(inputContainer);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  async submit({ endpoint, body }: SubmitArgs): Promise<FinancialAccount> {
    return new Promise((res, rej) => {
      const { vgsForm } = this.state;
      const { authToken, locale } = this.props;

      const headers = {
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
        ...(locale && { 'Accept-Language': locale }),
      };
      const data = {
        ...body,
        bank_card: {
          ...(body.bank_card || {}),
          brand:
            this.state.formState[
              'bank_card.card_number'
            ].cardType.toLowerCase(),
          last_four_digits: this.state.formState['bank_card.card_number'].last4,
        },
      };

      console.log(data);

      spinnerIncrease();

      vgsForm.submit(
        endpoint,
        {
          mapDotToObject: 'merge',
          data,
          headers,
          method: 'post',
        },
        (status: any, data: any) => {
          spinnerDecrease();

          console.log(status, data);

          if (status >= 200 && status <= 299) res(data);
          else {
            rej(data);
            Notifications.alert(getErrorMessage(data));
          }
        },
        (errors: any) => {
          spinnerDecrease();
          Notifications.alert(getErrorMessage(errors));
          console.log(errors);
          rej(errors);
        },
      );
    });
  }

  render() {
    return (
      <VGSLoadedGuard>
        <div ref={this.setInputRef}>
          <div className="input-field">
            <IonItem className="input-item" lines="none">
              <div className="vgs-input vgs--cc-name" />
            </IonItem>
          </div>

          <div className="input-field">
            <IonItem className="input-item" lines="none">
              <div className="vgs-input vgs--cc-number" />
            </IonItem>
          </div>
          <div className="input-field">
            <IonItem className="input-item" lines="none">
              <div className="vgs-input vgs--cc-expiry" />
            </IonItem>
          </div>
        </div>
      </VGSLoadedGuard>
    );
  }
}

interface Props {
  onValid: (v: boolean) => void;
}

export const CreditCardForm = forwardRef<CreditCardFormInner, Props>(
  ({ onValid }: Props, forwardedRef) => {
    const { vgsEnvironment, vgsVaultId } = useStore($config);
    const locale = useStore($locale);
    const authToken = useStore($authToken);
    return (
      <CreditCardFormInner
        ref={forwardedRef}
        vgsEnvironment={vgsEnvironment}
        vgsVaultId={vgsVaultId}
        onValid={onValid}
        authToken={authToken}
        locale={locale}
      />
    );
  },
);
