import React from 'react';
import styled from 'styled-components';
import { DataLine, DataLineItem } from 'ui/organisms/summary';
import { useI18nObject } from 'utils/use-i18n-object';
import { ExternalLink } from 'ui/atoms/external-link';

export const HelpOptions: React.VFC = () => {
  const LL = useI18nObject();

  return (
    <HelpOptionsStyled>
      <h4>{LL.WE_ARE_AVAILABLE_TO_HELP_YOU()}</h4>
      <DataLine>
        <DataLineItem isTitle>Ghana</DataLineItem>
        <DataLineItem>
          <ExternalLink to="tel:+233543320217">+233 54 332 0217</ExternalLink>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>Togo</DataLineItem>
        <DataLineItem>
          <ExternalLink to="tel:+22870140037">+228 701 40037</ExternalLink>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>USA</DataLineItem>
        <DataLineItem>
          <ExternalLink to="tel:+16502000954">+1 650 200 0954</ExternalLink>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>{LL.EMAIL()}</DataLineItem>
        <DataLineItem>
          <ExternalLink to="mailto:support@afipayments.com">
            support@afipayments.com
          </ExternalLink>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>Whatsapp</DataLineItem>
        <DataLineItem>
          <ExternalLink to="https://wa.me/233543320217">
            +233 54 332 0217
          </ExternalLink>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>Facebook</DataLineItem>
        <DataLineItem>
          <ExternalLink to="https://fb.com/AFiPayments/">
            fb.com/AFiPayments
          </ExternalLink>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>Twitter</DataLineItem>
        <DataLineItem>
          <ExternalLink to="https://twitter.com/AFiPayments">
            @AFiPayments
          </ExternalLink>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>Instagram</DataLineItem>
        <DataLineItem>
          <ExternalLink to="https://www.instagram.com/afipayments/">
            @afipayments
          </ExternalLink>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>Status Page</DataLineItem>
        <DataLineItem>
          <ExternalLink to="https://status.afipayments.com">
            status.afipayments.com
          </ExternalLink>
        </DataLineItem>
      </DataLine>
    </HelpOptionsStyled>
  );
};

const HelpOptionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h4 {
    margin-bottom: 24px;
  }
`;
