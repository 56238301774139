import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { Modal } from 'components/Modal';
import { useModal } from 'utils/use-modal';

interface Props {
  children: React.ReactNode;
}

type Location = ReturnType<typeof useLocation>;

export const ModalSwitch: React.VFC<Props> = ({ children }) => {
  const { closeModal, backgroundLocation } = useModal();
  const location = useLocation<{ background?: Location }>();

  // This piece of state is set when one of the
  // gallery links is clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the gallery in the background, behind
  // the modal.

  const switchLocation = backgroundLocation || location;

  return (
    <>
      <Switch location={switchLocation}>{children}</Switch>

      {/* Show the modal when a background page is set */}
      {backgroundLocation && (
        <Modal onOverlayClick={closeModal}>
          <Switch>{children}</Switch>
          {/* {children} */}
        </Modal>
      )}
    </>
  );
};
