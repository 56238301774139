import { forward } from 'effector';
import { loadLocalStorageFx, saveLocalStorageFx } from 'stores/localStorage';
import { $auth, $authToken, $refreshToken, LOCAL_STORAGE_KEY } from './index';

$authToken.on(
  loadLocalStorageFx.doneData,
  (_, { [LOCAL_STORAGE_KEY]: value }) => value?.authToken,
);

$refreshToken.on(
  loadLocalStorageFx.doneData,
  (_, { [LOCAL_STORAGE_KEY]: value }) => value?.refreshToken,
);

forward({
  from: $auth,
  to: saveLocalStorageFx.prepend((value) => ({
    value,
    localStorageKey: LOCAL_STORAGE_KEY,
  })),
});
