import styled, { css } from 'styled-components';
import { IconButton } from 'ui/molecules/icon-button';
import { PrefundAccount } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  account: PrefundAccount;
  selected: boolean;
  onAccountSelect: (account: PrefundAccount) => void;
}
export const PrefundAccountCard = ({
  account,
  selected,
  onAccountSelect,
}: Props) => {
  const LL = useI18nObject();
  const history = useHistory();

  const increaseAccountBalance = useCallback(() => {
    history.push(`/dashboard/prefund-accounts/${account.id}/increase/afi`);
  }, []);

  return (
    <PrefundAccountItem
      selected={selected}
      as={selected ? 'div' : 'button'}
      onClick={() => onAccountSelect(account)}
    >
      <div>
        <Country>
          {LL.COUNTRY()}: {account.country}
        </Country>
      </div>
      <AmountRow>
        <Amount>{`${account.currency} ${account.balance}`}</Amount>
        {selected && (
          <IncreaseBalanceButton
            onButtonClick={increaseAccountBalance}
            iconProps={{ name: 'PlusIcon', size: 68, color: 'white' }}
          />
        )}
      </AmountRow>
      <AccountNameContainer>
        <AccountName>{account.account_name}</AccountName>
      </AccountNameContainer>
    </PrefundAccountItem>
  );
};

const PrefundAccountItem = styled.button<{
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  background-color: ${(p) =>
    p.selected ? 'var(--secondary-color-main)' : 'var(--text-color-v5)'};
  padding: 10px 20px;
  border-radius: 10px;
  width: 300px;
  flex: 0 0 290px;
  color: ${(p) => (p.selected ? 'white' : 'black')};
`;

const Country = styled.span`
  opacity: 0.7;
  font-size: 1rem;
`;

const AccountNameContainer = styled.div`
  text-align: left;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis'...';
  white-space: nowrap;
`;

const AccountName = styled.span`
  opacity: 0.7;
  text-transform: uppercase;
  font-size: 1rem;
`;

const Amount = styled.span`
  font-size: 1.5rem;
`;

const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const IncreaseBalanceButton = styled(IconButton)`
  background-color: transparent;
  height: 60px;
  width: 60px;
  border: 3px solid var(--secondary-color-v3);
  padding: 4px;
  border-radius: 50%;
`;
