import { presentIonModal } from 'modals/present-ion-modal';

import type { ComponentProps } from 'react';
import type { User } from 'models';
import { SecuritySettingsRecoveryOptionsForm } from 'components/SecuritySettingsRecoveryOptionsForm/SecuritySettingsRecoveryOptionsForm';
import { i18nObject } from 'i18n/i18n-util';
import { $locale } from 'stores/locale';

export const updateRecoveryOptionsModal = async (): Promise<User | undefined> =>
  new Promise((res, rej) => {
    const LL = i18nObject($locale.getState());

    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (user: User) => {
      res(user);
      onClose();
    };

    const modalProps: ComponentProps<
      typeof SecuritySettingsRecoveryOptionsForm
    > = {
      onClose,
      onDone,
      isModal: true,
      title: LL.RECOVERY_OPTIONS(),
    };
    const [promptModal, dismissModal] = presentIonModal(
      SecuritySettingsRecoveryOptionsForm,
      modalProps,
      'recoveryOptionsModal',
    );

    promptModal();
  });
