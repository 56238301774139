import React, { useCallback } from 'react';
import styled from 'styled-components';
import { usePullFinancialAccounts } from 'api/queries';
import { FinancialAccountListItem } from 'components/FinancialAccountListItem/FinancialAccountListItem';

import { DashboardPage } from 'pages/Dashboard';
import { addFinancialAccountModal } from 'modals/addFinancialAccountModal';

import { LinkButton } from 'ui/atoms/link-button';

import '../../Dashboard.css';

import { useI18nObject } from 'utils/use-i18n-object';

import { EmptyBox } from '../../Dashboard/EmptyBox';

const Accounts: React.FC = () => {
  const { data: accountList, isLoading, refetch } = usePullFinancialAccounts();
  const LL = useI18nObject();

  const promptNewAccount = useCallback(async () => {
    await addFinancialAccountModal('both');
    await refetch();
  }, []);

  return (
    <DashboardPageStyled>
      {!isLoading &&
        (accountList?.length === 0 ? (
          <EmptyBox onClick={promptNewAccount}>
            {LL.NO_ACCOUNTS_ADDED_YET()}
          </EmptyBox>
        ) : (
          <>
            <LinkButton onClick={promptNewAccount}>
              + {LL.ADD_A_FINANCIAL_ACCOUNT()}
            </LinkButton>
            <FinancialAccounts>
              {accountList?.map((account) => (
                <FinancialAccountListItem
                  key={account.id}
                  account={account}
                  detailed
                />
              ))}
            </FinancialAccounts>
          </>
        ))}
    </DashboardPageStyled>
  );
};

const FinancialAccounts = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
`;

const DashboardPageStyled = styled(DashboardPage)`
  gap: 16px;
`;

export default Accounts;
