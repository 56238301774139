import React from 'react';
import type { ChangeEvent } from 'react';
import styled from 'styled-components';

interface Params {
  onInputChange: (value: string) => void;
  left?: React.ReactNode;
  right?: React.ReactNode;
  isError?: boolean;
}

const TRANSIENT_PROPS: (keyof Params | string)[] = [
  'onInputChange',
  'left',
  'right',
];

type Props = Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> & Params;

export const Input = styled.div
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<Props>(({ onInputChange, left, right, ref, className, ...props }) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onInputChange(value);
    };
    return {
      ref,
      children: (
        <>
          {left}
          <InputStyled onChange={onChange} {...props} />
          {right}
        </>
      ),
    };
  })<Props>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
  gap: 8px;
  height: 48px;
  border-radius: 0.3rem;
  border-width: 1px;
  border-color: ${(p) => (p.isError ? 'var(--error-color)' : 'hsl(0,0%,87%)')};
  border-style: solid;

  :focus,
  :focus-within {
    border-color: var(--secondary-color-v3);
  }
`;

export const InputStyled = styled.input`
  border: none;
  outline: none;
  padding: 0;
  flex: 1;
`;
