import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import { Redirect } from 'react-router-dom';
import { getOnboardingState } from 'utils/get-onboarding-state';
import { DevModeEnabledGuard } from 'utils/dev-mode-enabled-guard';
import { $isLoggedIn } from 'stores/derivatives/is-logged-in';

interface Props {
  children: React.ReactNode;
}

export const OnboardingRedirect = ({ children }: Props) => {
  const isLoggedIn = useStore($isLoggedIn);
  const user = useStore($user);
  if (typeof isLoggedIn !== 'boolean') {
    return <DevModeEnabledGuard>OnboardingRedirect</DevModeEnabledGuard>;
  }
  if (user) {
    const onboardingStep = getOnboardingState(user);
    if (onboardingStep === 'complete') {
      console.log('OnboardingRedirect: /dashboard');
      return <Redirect to="/dashboard" />;
    }
  }
  return children;
};
