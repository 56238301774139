import styled from 'styled-components';
import { AfiLogo } from 'ui/atoms/afi-logo';

export const PoweredBy = styled.div.attrs(() => ({
  children: (
    <>
      <span>powered by</span>
      <AfiLogo />
    </>
  ),
}))`
  font-size: 16px;
  color: var(--primary-color-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  ${AfiLogo} {
    height: 32px;
    margin: 0;
  }
`;
