import { OnBoardingState } from 'models';
import type { User } from 'models';
import { getOnboardingState } from 'utils/get-onboarding-state';

const onboardingURLMap = {
  [OnBoardingState.signup]: '/signup',
  [OnBoardingState.verifyEmail]: '/email-verification-sent',
  [OnBoardingState.addPhoneNumber]: '/verify-phonenumber',
  [OnBoardingState.verifyPhoneNumberCode]: '/verify-code',
  [OnBoardingState.addRecoveryOptions]: '/recovery-options',
  [OnBoardingState.verifyIdDocument]: '/verify-id',
  [OnBoardingState.verifyBusiness]: '/business-profile',
  [OnBoardingState.verifyIdentity]: '/verify-identity',
};

export const getNextOnboardingStep = (user?: User): string => {
  const state = getOnboardingState(user);
  if (state === OnBoardingState.complete) {
    return '/dashboard';
  }
  return `/onboarding${onboardingURLMap[state]}`;
};
