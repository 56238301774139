import { createEvent, restore } from 'effector';
import { navigatorDetector } from 'typesafe-i18n/detectors';
import { Locales } from 'i18n/i18n-types';
import { detectLocale } from 'i18n/i18n-util';

export const LOCAL_STORAGE_KEY = 'locale';
export const HIDE_LANG_BANNER_STORAGE_KEY = 'hideLangBanner';

// export const navigatorLocale = detectLocale(navigatorDetector);
export const navigatorLocale = 'en';

export const setLocale = createEvent<Locales>();
export const $locale = restore(setLocale, navigatorLocale);

export const setHideLangBanner = createEvent<boolean>();
export const $hideLangBanner = restore(setHideLangBanner, false);
