import { useCallback, useMemo } from 'react';
import 'react-phone-number-input/style.css';

import PhoneInput from 'react-phone-number-input';
import styles from './PhoneNumberInput.module.css';

interface Props {
  [k: string]: any;
  onChange?: (v: string) => void;
}

const PhoneNumberInput: React.FC<Props> = ({ ...props }) => {
  const onChange = useCallback(
    (value) => {
      if (props.onChange) {
        props.onChange(value);
      }
    },
    [props.onChange],
  );

  const className = useMemo(() => {
    return `${props.className} ${styles.phoneInputContainer}`;
  }, [props.className]);

  return (
    <PhoneInput
      international
      {...props}
      onChange={onChange}
      className={className}
    />
  );
};

export default PhoneNumberInput;
