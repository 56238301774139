import { useCallback, useState } from 'react';

import { IonIcon, IonInput, IonItem } from '@ionic/react';
import { search } from 'ionicons/icons';
import styled from 'styled-components';

import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';
import { LinkButton } from 'ui/atoms/link-button';
import { useRecipients, useSelf } from 'api/queries';
import { addRecipientModal } from 'modals/addRecipientModal';

import { useI18nObject } from 'utils/use-i18n-object';

import { FinancialAccount, TransferData } from 'models';

// import AddRecipientForm from "../../../components/AddRecipientForm/AddRecipientForm"
import RecipientListItem from 'components/RecipientListItem/RecipientListItem';

interface Props {
  data: TransferData;
  onSubmit: (v: FinancialAccount) => void;
  onGoBack: () => void;
  onGoNext: () => void;
}

export const SelectRecipient: React.VFC<Props> = ({
  data: { recipientAccount, senderAccount },
  onSubmit,
  onGoBack,
  onGoNext,
}) => {
  const LL = useI18nObject();

  const { data: recipients } = useRecipients();
  const { data: user } = useSelf();

  const [recipientFilter, setRecipientFilter] = useState('');

  const onRecipientFilterChange = (e: CustomEvent) => {
    setRecipientFilter(e.detail.value);
  };

  const filteredRecipients = recipients?.filter((r) => {
    const searchQuery = recipientFilter.toLowerCase();
    const firstNameMatch = (r.first_name || '')
      .toLowerCase()
      .includes(searchQuery);
    const lastNameMatch = (r.last_name || '')
      .toLowerCase()
      .includes(searchQuery);
    const phoneNumberMatch = (r.phone_number || '')
      .toLowerCase()
      .includes(searchQuery);
    const emailMatch = (r.email || '').toLowerCase().includes(searchQuery);
    return [firstNameMatch, lastNameMatch, phoneNumberMatch, emailMatch].some(
      Boolean,
    );
  });

  const isCrossborderAllowed = Boolean(
    user?.allowed_to_create_crossborder_transactions,
  );

  const availableRecipients = filteredRecipients?.map((recipient) => {
    const accounts = recipient.financial_accounts.map((account) => {
      let available = true;
      if (senderAccount?.country === 'TG') {
        available = account.country === 'TG';
      }
      if (isCrossborderAllowed) {
        available = true;
      }
      return { ...account, available };
    });
    return {
      ...recipient,
      financial_accounts: accounts,
    };
  });

  const isValid = Boolean(recipientAccount);

  const onSelectAccount = useCallback(
    (financialAccount: FinancialAccount) => {
      onSubmit(financialAccount);
    },
    [onSubmit],
  );

  const onAddRecipientClick = useCallback(async () => {
    await addRecipientModal();
  }, []);

  const onNextClick = () => {
    if (isValid) {
      onGoNext();
    }
  };

  return (
    <PrevNextStepper
      nextStepActive={isValid}
      onPrevClick={onGoBack}
      onNextClick={onNextClick}
    >
      <div className="transfer-stage">
        <div>
          <div className="transfer-stage-header">
            {LL.WHO_WOULD_YOU_LIKE_TO_PAY()}
          </div>
          <div className="input-field">
            <IonItem className="input-item" lines="none">
              <SearchIcon icon={search} />
              <IonInput
                value={recipientFilter}
                onIonChange={onRecipientFilterChange}
                className="input"
                type="text"
                placeholder={LL.SEARCH_YOU_NETWORK()}
                required
              />
            </IonItem>
          </div>
          <LinkButton onClick={onAddRecipientClick}>
            + {LL.ADD_A_RECIPIENT()}
          </LinkButton>
          <div className="transfer-stage-content">
            {availableRecipients?.map((recipient) => (
              <RecipientListItem
                key={recipient.id}
                recipient={recipient}
                onAccountSelect={onSelectAccount}
                isExpanded={recipient.id === recipientAccount?.recipient?.id}
                selectedAccountId={recipientAccount?.id}
                selectAccount
              />
            ))}
          </div>
        </div>
      </div>
    </PrevNextStepper>
  );
};

const SearchIcon = styled(IonIcon)`
  margin-right: 16px;
`;
