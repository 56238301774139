import React, { useCallback } from 'react';
import styled from 'styled-components';
import type { RouteComponentProps } from 'react-router';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';

import { DashboardPage } from 'pages/Dashboard';
import { useI18nObject } from 'utils/use-i18n-object';

import { PaymentRequests } from './PaymentRequests';
import { TransactionHistory } from './TransactionHistory';

import '../Dashboard.css';

export const Transactions = ({ match }: RouteComponentProps) => {
  const LL = useI18nObject();

  const transactionsTabActive = useRouteMatch({
    path: '/dashboard/transactions',
    exact: true,
  });
  const paymentRequestsTabActive = useRouteMatch({
    path: '/dashboard/payment-requests',
  });

  const selectedTab = {
    true: '/dashboard/transactions',
    [String(!!transactionsTabActive)]: '/dashboard/transactions',
    [String(!!paymentRequestsTabActive)]: '/dashboard/payment-requests',
  }.true;

  const history = useHistory();

  const onTabChange = useCallback((event) => {
    history.push(event.detail.value);
  }, []);

  return (
    <TransactionsPage>
      <IonSegment
        onIonChange={onTabChange}
        value={selectedTab}
        className="settings-tab-bar"
      >
        <IonSegmentButton value="/dashboard/transactions">
          <IonLabel>{LL.TRANSACTIONS()}</IonLabel>
        </IonSegmentButton>

        <IonSegmentButton value="/dashboard/payment-requests">
          <IonLabel>{LL.PAYMENT_REQUESTS()}</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <Switch>
        <Route path="/dashboard/payment-requests" component={PaymentRequests} />
        <Route
          path="/dashboard/transactions"
          exact
          component={TransactionHistory}
        />
      </Switch>
    </TransactionsPage>
  );
};

const TransactionsPage = styled(DashboardPage)`
  padding: 0;
`;
