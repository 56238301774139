import React, { ErrorInfo } from 'react';
import { LinkButton } from 'ui/atoms/link-button';

interface Props {}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>AFi Payments</h1>
          <h2>Something went wrong</h2>
          <p>
            Try to{' '}
            <LinkButton
              onClick={() => {
                window.location.href = '/';
              }}
            >
              reload
            </LinkButton>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
