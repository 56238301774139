import React, { ComponentProps, useState } from 'react';
import { Input } from 'ui/atoms/input';
import styled from 'styled-components';
import { ReactComponent as EyeClose } from 'assets/icon/eye-close.svg';
import { ReactComponent as EyeOpen } from 'assets/icon/eye-open.svg';

type Props = ComponentProps<typeof Input>;

export const RevealableInput: React.FC<Props> = React.forwardRef(
  ({ children, passwordVisible, togglePasswordVisible, ...props }, ref) => {
    const [enabled, setEnabled] = useState(false);

    // controllable or uncontrollable
    const onButtonClick =
      togglePasswordVisible ||
      (() => {
        setEnabled((v) => !v);
      });
    const buttonState = togglePasswordVisible ? passwordVisible : enabled;

    return (
      <InputStyled
        ref={ref}
        {...props}
        type={buttonState ? 'text' : 'password'}
        right={
          <button onClick={onButtonClick} type="button">
            {buttonState ? <EyeClose /> : <EyeOpen />}
          </button>
        }
      />
    );
  },
);

const InputStyled = styled(Input)`
  :after {
    content: '${(p) => (p.required ? '*' : '')}';
    color: red;
  }
`;
