import React from 'react';

import styled from 'styled-components';
import { useSelf } from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

interface Props {
  children: React.ReactNode;
}

export const AllowedToPerformTransactionsGuard: React.VFC<Props> = ({
  children,
}) => {
  const LL = useI18nObject();

  const { data: user, isFetched } = useSelf();

  const allowedToPerformTransactions =
    user?.allowed_to_perform_transactions || false;
  const allowedToPerformTransactionsReason =
    user?.allowed_to_perform_transactions_reason || '';
  if (allowedToPerformTransactions) {
    return <>{children}</>;
  }
  return (
    <CannotPerformTransactionsMessageStyled>
      {isFetched &&
        (allowedToPerformTransactionsReason ||
          LL.CANNOT_INITIATE_TRANSACTION())}
    </CannotPerformTransactionsMessageStyled>
  );
};

const CannotPerformTransactionsMessageStyled = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 20px;
  background: white;
  border-radius: 0.5rem;
  margin: 0;
`;
