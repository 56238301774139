import { combine } from 'effector';
import { $user } from 'stores/user';
import { $authToken } from 'stores/auth';
import { $localStorageLoaded } from 'stores/localStorage';

export const $isLoggedIn = combine(
  $authToken,
  $localStorageLoaded,
  $user,
  (authToken, localStorageLoaded): boolean | null => {
    if (!localStorageLoaded) {
      return null;
    }
    return !!authToken;
  },
);
$isLoggedIn.watch((v) => console.log('isLoggedIn', v));
