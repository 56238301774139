import { useStore } from 'effector-react';
import { $configName } from 'stores/config';
import styled from 'styled-components';

export const ConfigIndicator = () => {
  const configName = useStore($configName);

  return (
    <ConfigIndicatorStyled configName={configName}>
      <span>{configName}</span>
    </ConfigIndicatorStyled>
  );
};

const ConfigIndicatorStyled = styled.div<{ configName: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 40px;
  z-index: 30000;
  opacity: 0.7;
  pointer-events: none;
  height: 40px;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  display: ${(p) => p.configName === 'production' && 'none'};
  color: white;
  font-size: 8px;

  background-image: linear-gradient(
    to top left,
    transparent 50%,
    ${(p) =>
        ({
          true: '#cccccc',
          [String(p.configName === 'production')]: '#fa5750',
          [String(p.configName === 'staging')]: '#2fa62f',
          [String(p.configName === 'dev')]: '#3880ff',
        }.true)}
      0
  );

  > * {
    display: block;
    white-space: nowrap;
    text-overflow: clip;
    transform: rotate(-45deg);
  }
`;
