import React, { ChangeEvent, useCallback } from 'react';
import { IonInput, IonItem, IonLabel } from '@ionic/react';

import { useCountryList } from 'data/countries';

import { useCapabilities } from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

import {
  getAccountTypeNameById,
  getAvailableAccountTypes,
  getAvailableBanks,
  getOperatorsList,
} from 'data';

import type { AccountTypeI, DirectionI, Operators } from 'models';
import { InputChangeEventDetail } from '@ionic/core';
import CountrySelect from 'components/CountrySelect/CountrySelect';
import PhoneNumberInput from 'components/PhoneNumberInput/PhoneNumberInput';

export interface AccountForm {
  accountType: AccountTypeI | '';
  country: string;
  bankCode: string;
  bankName: string;
  bankAccountNumber: string;
  mobileOperator: Operators | '';
  mobileNumber: string;
}

interface Props {
  directionType: DirectionI | 'both';
  accountData: AccountForm;
  onAccountDataChange: (v: AccountForm) => void;
}

export const FinancialAccountPicker = ({
  directionType,
  accountData,
  onAccountDataChange,
}: Props) => {
  const LL = useI18nObject();

  const {
    accountType,
    country,
    bankCode,
    bankName,
    bankAccountNumber,
    mobileOperator,
    mobileNumber,
  } = accountData;

  const onChange = useCallback(
    (key: keyof AccountForm, value: unknown) => {
      onAccountDataChange({
        ...accountData,
        [key]: value,
      });
    },
    [onAccountDataChange, accountData],
  );

  const countryList = useCountryList();

  const { data: capabilities } = useCapabilities();
  const processorCapabilities = capabilities || [];

  const accountTypeList =
    (country &&
      getAvailableAccountTypes({
        direction: directionType,
        selectedCountry: country,
        countryCapabilities: processorCapabilities,
      })) ||
    [];

  const banksList =
    (country &&
      getAvailableBanks({
        selectedCountry: country,
        direction: directionType,
        countryCapabilities: processorCapabilities,
      })) ||
    [];

  const onBankCodeChange = useCallback(
    (event) => {
      const { value: bankCode } = event.target;
      const bank = banksList.find((b: any) => b.bank_code === bankCode);
      if (bank) {
        const { bank_name: bankName } = bank;
        onAccountDataChange({
          ...accountData,
          bankCode,
          bankName,
        });
      }
    },
    [banksList, onAccountDataChange, accountData],
  );

  const isBankAccountFormVisible =
    country !== 'US' && accountType === 'bank_account';
  const isMobileMoneyFormVisible =
    country !== 'US' && accountType === 'mobile_money';

  return (
    <div>
      <div className="input-group">
        <div className="input-field">
          <IonItem className="input-item" lines="none">
            <CountrySelect
              value={country}
              onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                onChange('country', e.detail.value);
              }}
              name="country"
              countries={countryList}
              placeholder={LL.COUNTRY()}
              interface="popover"
              required
            />
          </IonItem>
        </div>
        <div className="input-field">
          <IonItem className="input-item" disabled={!country}>
            <IonLabel position="stacked" style={{ paddingBottom: '0.75rem' }}>
              {LL.ACCOUNT_TYPE()}
            </IonLabel>
            <select
              value={accountType}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                onChange('accountType', e.target.value);
              }}
              name="accountType"
              style={{ paddingBottom: '0.75rem' }}
              required
            >
              <option value="">{LL.SELECT_ACCOUNT()}</option>
              {accountTypeList.map(({ id, name }) => (
                <option key={id} value={id}>
                  {getAccountTypeNameById(id)}
                </option>
              ))}
            </select>
          </IonItem>
        </div>
        {isBankAccountFormVisible && (
          <>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <select
                  value={bankCode}
                  onChange={onBankCodeChange}
                  name="bankCode"
                  placeholder={LL.BANK_NAME()}
                  required
                >
                  <option value="">{LL.SELECT_BANK_ACCOUNT()}</option>
                  {banksList.map((bank: any) => (
                    <option key={bank.bank_code} value={bank.bank_code}>
                      {bank.bank_name}
                    </option>
                  ))}
                </select>
              </IonItem>
            </div>

            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={bankAccountNumber}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    onChange('bankAccountNumber', e.detail.value);
                  }}
                  name="bankAccountNumber"
                  placeholder={LL.ACCOUNT_NUMBER()}
                  required
                />
              </IonItem>
            </div>
          </>
        )}
        {isMobileMoneyFormVisible && (
          <>
            <div className="input-field">
              <IonItem className="input-item">
                <select
                  value={mobileOperator}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onChange('mobileOperator', e.target.value);
                  }}
                  name="mobileOperator"
                  placeholder={LL.OPERATOR()}
                >
                  <option value="">{LL.SELECT_MOBILE_OPERATOR()}</option>
                  {getOperatorsList({ direction: directionType, country }).map(
                    ({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ),
                  )}
                </select>
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <PhoneNumberInput
                  value={mobileNumber}
                  onChange={(value: string) => {
                    onChange('mobileNumber', value);
                  }}
                  name="mobileNumber"
                  defaultCountry={country}
                  placeholder={LL.SELECT_MOBILE_OPERATOR()}
                  required
                />
              </IonItem>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
