import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import { CheckboxChangeEventDetail } from '@ionic/core';

import { usePlaidLink } from 'react-plaid-link';
import {
  useConfig,
  useCreateFinancialAccount,
  usePlaidToken,
} from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

import styled from 'styled-components';
import { AccountNameResolver } from 'components/AccountNameResolver';
import { FinancialAccountPicker } from 'components/FinancialAccountPicker';
import { CreditCardForm } from 'components/vgs/CreditCardForm/CreditCardForm';
import { useForm } from 'utils/use-form';
import { FinancialAccountApi } from 'api/http';
import type {
  AccountTypeI,
  DirectionI,
  FinancialAccount,
  Operators,
} from 'models';
import { Slider } from 'ui/organisms/Slider';
import { Button } from 'ui/atoms/button';
import Notifications from '../Notifications';

import CloseIcon from '../../assets/icon/close.svg';

declare global {
  interface Window {
    dwolla: any;
  }
}

interface Props {
  directionType: DirectionI | 'both';
  recipientId?: number;
  onAccountCreated: (account: FinancialAccount) => void;
  onClose: () => void;
}

interface Form {
  customerName: string;
  confirmedDetails: boolean;
  accountType: AccountTypeI | '';
  country: string;
  bankCode: string;
  bankName: string;
  bankAccountNumber: string;
  mobileOperator: Operators | '';
  mobileNumber: string;
}

export const AddFinancialAccount: React.FC<Props> = ({
  directionType,
  recipientId,
  onAccountCreated,
  onClose,
}) => {
  const LL = useI18nObject();

  const [stageId, setStageId] = useState<0 | 1>(0);

  const creditCardFormRef = useRef<any>();
  const vgsForm = creditCardFormRef.current;
  const [isCreditFormValid, setIsCreditFormValid] = useState<boolean>(false);

  const createFinancialAccountMutation = useCreateFinancialAccount();

  const formik = useForm<Form>({
    initialValues: {
      customerName: '',
      confirmedDetails: false,
      accountType: '',
      country: '',
      bankCode: '',
      bankName: '',
      bankAccountNumber: '',
      mobileOperator: '',
      mobileNumber: '',
    },
    validate: (values) => {
      const errors: { [P in keyof Form]?: string } = {};
      if (
        ['bank_account', 'mobile_money'].includes(values.accountType) &&
        !values.customerName
      ) {
        errors.customerName = LL.REQUIRED();
      }
      return errors;
    },
    onSubmit: async (values) => {
      const payload = {
        ...(recipientId && { recipient: recipientId }),
        confirmed_details: values.confirmedDetails,
        account_type: values.accountType,
        country: values.country,
        ...(values.accountType === 'bank_account' && {
          bank_account: {
            bank_code: values.bankCode,
            bank_name: values.bankName,
            account_number: values.bankAccountNumber,
          },
        }),
        ...(values.accountType === 'mobile_money' && {
          mobile_money: {
            operator: values.mobileOperator,
            mobile_number: values.mobileNumber,
          },
        }),
        customer_name: values.customerName,
        financial_account: {
          country: values.country,
          account_type: values.accountType,
          ...(values.accountType === 'bank_account' && {
            bank_account: {
              bank_code: values.bankCode,
              bank_name: values.bankName,
              account_number: values.bankAccountNumber,
            },
          }),
          ...(values.accountType === 'mobile_money' && {
            mobile_money: {
              operator: values.mobileOperator,
              mobile_number: values.mobileNumber,
            },
          }),
          customer_name: values.customerName,
          confirmed_details: values.confirmedDetails,
        },
        // phone_numberCountry: 'GH',
      };

      console.log(accountData);

      let account: FinancialAccount;

      if (accountData.accountType === 'bank_card' && vgsForm) {
        if (!isCreditFormValid) {
          return Notifications.alert(LL.PLEASE_FILL_THE_FORM());
        }
        account = await vgsForm.submit({
          endpoint: '/api/financial-accounts',
          body: payload,
        });
      } else if (shouldLaunchPlaid) {
        account = await openPlaid();
      } else if (shouldLaunchDwolla) {
        await startDwolla();
        return;
      } else {
        account = await createFinancialAccountMutation.mutateAsync(payload);
      }

      onAccountCreated(account);
    },
  });

  const { customerName, confirmedDetails } = formik.values;

  const accountData = {
    accountType: formik.values.accountType,
    country: formik.values.country,
    bankCode: formik.values.bankCode,
    bankName: formik.values.bankName,
    bankAccountNumber: formik.values.bankAccountNumber,
    mobileOperator: formik.values.mobileOperator,
    mobileNumber: formik.values.mobileNumber,
  };

  const onAccountDataChange = useCallback(
    (data: ComponentProps<typeof FinancialAccountPicker>['accountData']) => {
      formik.setValues({
        ...formik.values,
        ...data,
      });
    },
    [formik.values, formik.setFieldValue],
  );

  const { data: config } = useConfig();

  const { client_app_dwolla_enabled, client_app_plaid_enabled } = config || {};

  const { data: linkTokenResponse } = usePlaidToken();
  const linkToken = linkTokenResponse?.link_token?.authentication_token || '';

  const onSuccess = useCallback(async (token: string, metadata) => {
    // send token to server
    try {
      const response = FinancialAccountApi.sendPlaidToken(token).then(
        (bank_account) => console.log(bank_account),
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const {
    open: openPlaid,
    ready,
    error,
  } = usePlaidLink({
    token: linkToken,
    onSuccess,
  });
  if (error) {
    console.log(error);
  }

  const startDwolla = useCallback(async () => {
    console.log('dwolla workflow start');
    const response =
      await FinancialAccountApi.getDwollaAccountVerificationToken();
    console.log('iavres', response);
    const iavToken = response.token;

    window.dwolla.configure('sandbox');
    window.dwolla.iav.start(
      iavToken,
      {
        container: 'iavContainer',
        stylesheets: [
          'https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext',
        ],
        microDeposits: 'true',
        fallbackToMicroDeposits: 'true',
      },
      async (err: any, res: any) => {
        console.log(
          `Error: ${JSON.stringify(err)} -- Response: ${JSON.stringify(res)}`,
        );
        const funding_source = res._links['funding-source'].href;
        console.log(`funding_source  ${funding_source}`);

        const response = await FinancialAccountApi.sendDwollaFundingSource(
          funding_source,
        );
        onAccountCreated(response);
      },
    );
  }, []);

  const shouldLaunchDwolla =
    accountData.country === 'US' &&
    accountData.accountType === 'mobile_money' &&
    client_app_dwolla_enabled;

  const shouldLaunchPlaid =
    accountData.country === 'US' &&
    accountData.accountType === 'bank_account' &&
    client_app_plaid_enabled;

  const isBankAccountFormVisible =
    accountData.country !== 'US' && accountData.accountType === 'bank_account';
  const isMobileMoneyFormVisible =
    accountData.country !== 'US' && accountData.accountType === 'mobile_money';

  const isCreditCardFormVisible = accountData.accountType === 'bank_card';

  const onSubmitAttempt = (e?: React.SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (stageId === 0) {
      if (accountData.accountType === 'bank_card') {
        formik.onSubmitAttempt();
        return;
      }
      setStageId(1);
    } else if (stageId === 1) {
      formik.onSubmitAttempt();
    }
  };

  return (
    <FormStyled onSubmit={onSubmitAttempt} style={{ height: '100%' }}>
      <div className="title formModalTitle">
        {LL.ADD_ACCOUNT()}
        {onClose && (
          <img src={CloseIcon} className="title-close-icon" onClick={onClose} />
        )}
      </div>
      <SliderContainer>
        <Slider slideId={stageId}>
          <Stage hidden={stageId !== 0}>
            <StageContent>
              <FinancialAccountPicker
                directionType={directionType}
                accountData={accountData}
                onAccountDataChange={onAccountDataChange}
              />
              <div hidden={!isCreditCardFormVisible}>
                <CreditCardForm
                  ref={creditCardFormRef}
                  onValid={setIsCreditFormValid}
                />
                <div className="input-field">
                  <IonItem className="input-item-checkbox" lines="none">
                    <IonCheckbox
                      checked={confirmedDetails}
                      onIonChange={(
                        e: CustomEvent<CheckboxChangeEventDetail>,
                      ) => {
                        formik.setFieldValue(
                          'confirmedDetails',
                          e.detail.checked,
                        );
                      }}
                      name="confirmedDetails"
                      className="input"
                    />
                    <IonLabel className="input-label">
                      {LL.CONFIRMED_DETAILS()}
                    </IonLabel>
                  </IonItem>
                </div>
              </div>
              <div id="iavContainer" />
            </StageContent>
            <Button>{LL.NEXT()}</Button>
          </Stage>
          <Stage hidden={stageId !== 1}>
            <StageContent>
              {stageId === 1 &&
                (isBankAccountFormVisible || isMobileMoneyFormVisible) && (
                  <AccountNameResolver
                    accountData={accountData}
                    customerName={customerName}
                    setCustomerName={(v: string) => {
                      formik.setFieldValue('customerName', v);
                    }}
                  />
                )}
              <div className="input-field">
                <IonItem className="input-item-checkbox" lines="none">
                  <IonCheckbox
                    checked={confirmedDetails}
                    onIonChange={(
                      e: CustomEvent<CheckboxChangeEventDetail>,
                    ) => {
                      formik.setFieldValue(
                        'confirmedDetails',
                        e.detail.checked,
                      );
                    }}
                    name="confirmedDetails"
                    className="input"
                  />
                  <IonLabel className="input-label">
                    {LL.CONFIRMED_DETAILS()}
                  </IonLabel>
                </IonItem>
              </div>
            </StageContent>
            <Button
              type="button"
              ghost
              onClick={() => {
                setStageId(0);
              }}
            >
              {LL.BACK()}
            </Button>
            <Button>{LL.SUBMIT()}</Button>
          </Stage>
        </Slider>
      </SliderContainer>
    </FormStyled>
  );
};

const FormStyled = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SliderContainer = styled.div`
  flex: 1;

  background: white;
  border-radius: 0;
`;

const Stage = styled.div`
  padding: 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StageContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 0;
  min-height: calc(100% - 70px);
  align-items: center;

  > * {
    width: 100%;
  }
`;
