import styled from 'styled-components';

export const TransactionLimit = styled.div<{
  isError: boolean;
}>`
  color: ${(p) =>
    p.isError ? 'var(--text-color-v0)' : 'var(--text-color-v0)'};
  display: block;
  opacity: 0.6;
  font-size: 0.8rem;
`;
