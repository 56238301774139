import { ComponentProps, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from 'utils/use-form';
import { useModal } from 'utils/use-modal';

import { getCurrencyByCountry } from 'data/currencies';
import type { PaymentRequestData } from 'models';

import { useCreatePaymentRequest } from 'api/queries';
import { useCreateBulkPaymentRequest } from 'api/queries/mutations';
import { TitlePortal } from 'components/TitlePortal';
import { DashboardPage } from 'pages/Dashboard';
import { AllowedToPerformTransactionsGuard } from 'pages/Transfer/AllowedToPerformTransactionsGuard';
import styled from 'styled-components';
import { Slider } from 'ui/organisms/Slider';
import { MakePayment } from './MakePayment/MakePayment';
import { PaymentRequestPreview } from './MakePayment/PaymentRequestPreview';
import { SelectAmount } from './SelectAmount/SelectAmount';
import { SelectPayer } from './SelectPayer/SelectPayer';
import { SelectPaymentMethod } from './SelectPaymentMethod/SelectPaymentMethod';

import '../Dashboard.css';
// eslint-disable-next-line import/order
import { useI18nObject } from 'utils/use-i18n-object';

const DISPLAY_PAYMENT_REQUEST_PREVIEW = true;

enum Stages {
  PAYMENT_METHOD = 0,
  RECIPIENT = 1,
  AMOUNT = 2,
  MAKE_PAYMENT = 3,
}

export const Request: React.VFC = () => {
  const { modalPush } = useModal();
  const history = useHistory();
  const [movingBack, setMovingBack] = useState(false);
  const [stageId, setStageId] = useState<Stages>(Stages.PAYMENT_METHOD);

  const LL = useI18nObject();

  // PaymentMethod Stage
  const onPaymentMethodStageGoNext = () => {
    setMovingBack(false);
    setStageId(Stages.RECIPIENT);
  };

  // Recipient Stage
  const onRecipientStageGoBack = () => {
    setMovingBack(true);
    setStageId(Stages.PAYMENT_METHOD);
  };
  const onRecipientStageGoNext = () => {
    setMovingBack(false);
    setStageId(Stages.AMOUNT);
  };

  // Amount Stage
  const onAmountStageGoBack = () => {
    setMovingBack(true);
    setStageId(Stages.RECIPIENT);
  };
  const onAmountStageGoNext = () => {
    setMovingBack(false);
    setStageId(Stages.MAKE_PAYMENT);
  };

  // MakePayment Stage
  const onMakePaymentStageGoBack = () => {
    setMovingBack(true);
    setStageId(Stages.AMOUNT);
  };

  const paymentRequestMutation = useCreatePaymentRequest();
  const bulkPRMutation = useCreateBulkPaymentRequest();

  const formik = useForm<PaymentRequestData>({
    initialValues: {
      bulk: false,
      requesterAccount: null,
      payerIds: [],
      requestAmounts: {},
      dueDates: {},
      category: null,
      description: '',
      iCoverFees: true,
    },
    validate: (values) => {
      const errors: { [k in keyof typeof values]?: string } = {};
      if (!values.requesterAccount) {
        errors.requesterAccount = LL.REQUIRED();
      }
      return errors;
    },
    onSubmit: async (values) => {
      const { payerIds, requesterAccount, category } = values;
      const requesterAccountId = requesterAccount?.id;
      if (requesterAccount && category && requesterAccountId) {
        const paymentRequests = payerIds.map((payerId) => ({
          amount: values.requestAmounts[payerId],
          description: values.description,
          due_date: values.dueDates[payerId].toISOString(),
          category: category.id,
          currency,
          payer_covers_fees: values.iCoverFees,
          requester_account: requesterAccountId,
          payer: payerId,
        }));
        if (bulk) {
          const bulkPaymentRequest = await bulkPRMutation.mutateAsync({
            payment_requests: paymentRequests,
          });
          modalPush({
            background: `/dashboard/bulk-pr/${bulkPaymentRequest.id}`,
            to: `/invoice/${bulkPaymentRequest.invoice.id}`,
          });
        } else {
          const paymentRequest = await paymentRequestMutation.mutateAsync(
            paymentRequests[0],
          );
          if (paymentRequest) {
            const createdPaymentRequest = Array.isArray(paymentRequest)
              ? paymentRequest[0]
              : paymentRequest;

            history.push(
              `/dashboard/payment-requests/${createdPaymentRequest.id}`,
            );
          }
        }
        console.log({ values });
      }
    },
  });
  const paymentRequestData = formik.values;
  const { bulk } = paymentRequestData;
  const payerId = bulk ? undefined : paymentRequestData.payerIds[0];
  const setBulk = (v: boolean) => {
    formik.setFieldValue('bulk', v);
  };

  const currency = getCurrencyByCountry(
    paymentRequestData.requesterAccount?.country,
  );

  const fee = 0;
  const totalAmount = Object.entries(paymentRequestData.requestAmounts)
    .filter(([id, amount]) => paymentRequestData.payerIds.includes(+id))
    .map(([id, amount]) => amount)
    .reduce((acc, value) => Number(acc) + Number(value), 0);

  const onPayerSubmit = useCallback(
    (data: ComponentProps<typeof SelectPayer>['data']) => {
      formik.setFieldValue('payerIds', data.payerIds);
      formik.setFieldValue('requestAmounts', data.requestAmounts);
      formik.setFieldValue('dueDates', data.dueDates);
    },
    [],
  );

  const onAmountSubmit = useCallback(
    (data: Parameters<ComponentProps<typeof SelectAmount>['onSubmit']>[0]) => {
      console.log(data.requestAmounts, data);
      formik.setFieldValue('category', data.category);
      formik.setFieldValue('description', data.description);
      formik.setFieldValue('iCoverFees', data.iCoverFees);
      formik.setFieldValue('requestAmounts', data.requestAmounts);
      formik.setFieldValue('dueDates', data.dueDates);
    },
    [],
  );

  return (
    <>
      <TitlePortal>{LL.REQUEST()}</TitlePortal>
      <RequestStyled>
        <Slider slideId={stageId} movingBack={movingBack}>
          <AllowedToPerformTransactionsGuard>
            <>
              {stageId === Stages.PAYMENT_METHOD && (
                <SelectPaymentMethod
                  data={paymentRequestData}
                  onSubmit={(v) => formik.setFieldValue('requesterAccount', v)}
                  onGoNext={onPaymentMethodStageGoNext}
                />
              )}
              {stageId === Stages.RECIPIENT && (
                <SelectPayer
                  data={paymentRequestData}
                  onSubmit={onPayerSubmit}
                  onGoBack={onRecipientStageGoBack}
                  onGoNext={onRecipientStageGoNext}
                  bulk={bulk}
                  setBulk={setBulk}
                  currency={currency}
                />
              )}
              {stageId === Stages.AMOUNT && (
                <SelectAmount
                  data={paymentRequestData}
                  onSubmit={onAmountSubmit}
                  onGoBack={onAmountStageGoBack}
                  onGoNext={onAmountStageGoNext}
                  payerId={payerId}
                  currency={currency}
                  totalAmount={totalAmount}
                />
              )}
              {stageId === Stages.MAKE_PAYMENT && (
                <MakePayment
                  paymentRequestData={paymentRequestData}
                  currency={currency}
                  totalAmount={totalAmount}
                  onConfirm={formik.onSubmitAttempt}
                  onGoBack={onMakePaymentStageGoBack}
                >
                  {DISPLAY_PAYMENT_REQUEST_PREVIEW && (
                    <PaymentRequestPreview
                      data={paymentRequestData}
                      currency={currency}
                      totalAmount={totalAmount}
                    />
                  )}
                </MakePayment>
              )}
            </>
          </AllowedToPerformTransactionsGuard>
        </Slider>
      </RequestStyled>
    </>
  );
};

const RequestStyled = styled(DashboardPage)`
  padding: 0;
`;
