export const PrivacyPolicyFrench = () => (
  <>
    <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
    <p>Dernière mise à jour : 6 novembre 2023</p>
    <p>
      Chez TecHustle, Inc. et ses filiales, nous prenons votre vie privée très
      au sérieux et savons que vous vous souciez de la manière dont vos
      informations personnelles sont utilisées et partagées. Notre objectif
      principal est de vous fournir un service exceptionnel, et nous comprenons
      que vous puissiez avoir des questions et/ou des préoccupations concernant
      vos informations personnelles et la manière dont elles seront utilisées.
      Vos informations personnelles sont toutes les informations qui peuvent
      être associées à une personne spécifique et peuvent être utilisées pour
      identifier cette personne.
    </p>
    <p>
      Lorsque vous utilisez les services AFi (tels que définis dans les
      Conditions d'utilisation), nous collectons des informations vous
      concernant, notamment votre nom, votre date de naissance, votre adresse,
      votre profession, vos documents d'identité, votre adresse IP, vos
      informations de géolocalisation, vos informations de compte, vos
      informations d'identification, des informations sur l'appareil et des
      informations financières. Nous pouvons également obtenir des informations
      vous concernant auprès de tiers tels que la vérification d'identité, la
      prévention de la fraude et des services similaires. Lorsque vous utilisez
      les services AFi, nous collecterons des informations sur les transactions
      de votre compte et pouvons collecter des informations sur votre dispositif
      d'accès à des fins de prévention de la fraude et de réglementation. Nous
      pouvons collecter des informations supplémentaires auprès de vous ou à
      votre sujet par d'autres moyens non spécifiquement décrits ici.
    </p>
    <p>
      Notre objectif principal dans la collecte d'informations personnelles est
      de vous fournir une expérience sûre, efficace et rentable conformément aux
      lois, réglementations et directives financières locales et
      internationales.
    </p>
    <p>
      Nous ne partageons pas vos informations personnelles avec des tiers à des
      fins promotionnelles ou marketing. Nous pouvons partager vos informations
      personnelles avec les forces de l'ordre, des représentants du gouvernement
      ou d'autres tiers si nous y sommes contraints ; avec des fournisseurs de
      services tiers qui nous aident à vous servire ou qui fournissent des
      services de détection de fraude ou des services similaires ; ou avec
      d'autres tiers avec votre consentement ou selon vos instructions.
    </p>
    <p>
      Nous pouvons conserver certaines données même après la suppression de
      votre compte afin de nous conformer aux réglementations financières
      applicables. Cette conservation des données est essentielle pour respecter
      les obligations légales et assurer la sécurité et l'intégrité des services
      AFi. Ces données peuvent inclure des enregistrements de transactions, des
      détails de compte et d'autres informations pertinentes nécessaires à la
      conformité réglementaire et à la gestion des risques. Soyez assuré(e) que
      toutes les données conservées continueront d'être protégées conformément à
      notre politique de confidentialité et aux lois de protection des données
      pertinentes. Veuillez noter que ces données ne seront consultées ou
      divulguées qu'en vertu de la loi ou en réponse à des demandes valides
      émanant d'autorités gouvernementales autorisées.
    </p>
    <p>
      Nous stockons et traitons vos informations personnelles en utilisant des
      serveurs tiers situés dans des centres de données. Ces informations sont
      protégées par des garanties physiques, électroniques et procédurales
      conformes aux normes de l'industrie.
    </p>
    <p>
      Les services AFi évoluent et s'améliorent constamment pour mieux vous
      servir. Nous pouvons occasionnellement mettre à jour cette politique de
      confidentialité.
    </p>
  </>
);
