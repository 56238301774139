import React from 'react';
import { createPortal } from 'react-dom';
import { ToastContainer as Container, toast } from 'react-toastify';

import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

interface Props {}

export const ToastContainer: React.VFC<Props> = () => {
  const portalDomNode = document.getElementById('toast');
  if (portalDomNode) {
    return createPortal(<ToastContainerStyled />, portalDomNode);
  }
  return null;
};

export const ToastContainerStyled = styled(Container).attrs({
  position: toast.POSITION.TOP_CENTER,
  autoClose: false,
  closeOnClick: false,
})`
  --toastify-color-progress-light: var(--primary-color-main);
  --toastify-toast-width: 560px;
  &&&.Toastify__toast-container {
    z-index: 21000;
  }
  &&&.Toastify__toast-container--top-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 16px;
    --toastify-toast-width: 400px;

    .Toastify__toast {
      padding: 48px 36px;
      background: var(--toastify-color-light);
      color: var(--text-color-v0);
    }
  }
  .Toastify__toast {
    color: var(--primary-color-v1);
    background: #323232;
  }
  .Toastify__toast-body {
    > * {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;
    }

    button {
      text-transform: uppercase;
      color: var(--primary-color-main);
      font-weight: 700;
    }
  }
  .Toastify__progress-bar {
  }
`;
