import type { ChangeEvent } from 'react';
import React, { useCallback } from 'react';

import addDays from 'date-fns/addDays';
import endOfDay from 'date-fns/endOfDay';
import isValid from 'date-fns/isValid';

import { useFormik } from 'formik';

import { IonItem, IonTextarea } from '@ionic/react';

import type { Currency, PaymentRequestData, TransactionCategory } from 'models';
import { useCategories } from 'api/queries';
import 'components/forms.css';

import styled from 'styled-components';
import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';
import Notifications from 'components/Notifications';
import { DatePicker } from 'ui/molecules/date-picker';
import { getDefaultDueDate } from 'pages/Request/utils';
import { CurrencyInput } from 'ui/molecules/currency-input';
import { useI18nObject } from 'utils/use-i18n-object';
import { useLimits } from 'utils/use-limits';

import { formatAmount } from 'utils/format-amount';
import styles from './SelectAmount.module.css';

const FEES_ENABLED = false;

const DUE_DATE_MAXIMUM_DAYS = 90;

interface FormI {
  requestAmount: number;
  category: TransactionCategory | null;
  description: string;
  iCoverFees: boolean;
  dueDate: Date | null;
}

interface Props {
  data: PaymentRequestData;
  payerId?: number;
  onSubmit: (v: {
    category: PaymentRequestData['category'];
    description: PaymentRequestData['description'];
    iCoverFees: PaymentRequestData['iCoverFees'];
    requestAmounts?: PaymentRequestData['requestAmounts'];
    dueDates?: PaymentRequestData['dueDates'];
  }) => void;
  onGoBack: () => void;
  onGoNext: () => void;
  currency: Currency;
  totalAmount: number;
}

export const SelectAmount = ({
  data,
  payerId,
  onSubmit,
  onGoBack,
  onGoNext,
  currency,
  totalAmount,
}: Props) => {
  const LL = useI18nObject();

  const { data: categoryOptions } = useCategories();
  const { requesterAccount, bulk } = data;

  const { minLimit, maxLimit } = useLimits({
    accountType: requesterAccount?.account_type,
    country: requesterAccount?.country,
  });

  const formik = useFormik<FormI>({
    initialValues: {
      category: data.category,
      description: data.description,
      iCoverFees: data.iCoverFees,
      requestAmount: payerId ? data?.requestAmounts[payerId] : 0,
      dueDate: payerId ? data?.dueDates[payerId] : getDefaultDueDate(),
    },
    // initialErrors: {
    //   ...(!data.requestAmounts[payerId] && { requestAmount: '' }),
    // },
    validate: (values) => {
      const { dueDate, requestAmount, category, description } = values;
      const errors: { [k in keyof typeof values]?: string } = {};
      if (!bulk) {
        if (!requestAmount || requestAmount <= 0) {
          errors.requestAmount = LL.ENTER_AMOUNT();
        }
        if (maxLimit && requestAmount > maxLimit) {
          errors.requestAmount = LL.MAX_AMOUNT_PER_TRANSACTION({
            currencyAmount: formatAmount({
              amount: maxLimit,
              currency,
            }),
          });
        }
        if (minLimit && requestAmount < minLimit) {
          errors.requestAmount = LL.MIN_AMOUNT_PER_TRANSACTION({
            currencyAmount: formatAmount({
              amount: minLimit,
              currency,
            }),
          });
        }
        if (!dueDate || !isValid(dueDate)) {
          errors.dueDate = LL.DUE_DATE_MISSING_ERROR();
        }
      }
      if (!category) {
        errors.category = LL.CATEGORY_MISSING_ERROR();
      }
      if (!description) {
        errors.description = LL.DESCRIPTION_MISSING_ERROR();
      }
      return errors;
    },
    onSubmit: (values) => {
      const payload: Pick<
        typeof data,
        | 'category'
        | 'description'
        | 'iCoverFees'
        | 'requestAmounts'
        | 'dueDates'
      > = {
        category: values.category,
        description: values.description,
        iCoverFees: values.iCoverFees,
        requestAmounts: data.requestAmounts,
        dueDates: data.dueDates,
      };
      if (!bulk && payerId && values.dueDate) {
        payload.requestAmounts = { [payerId]: values.requestAmount };
        payload.dueDates = { [payerId]: values.dueDate };
      }
      onSubmit(payload);
      onGoNext();
    },
  });

  const { requestAmount, category, description, iCoverFees, dueDate } =
    formik.values;

  const fee = 0;
  const total = bulk ? totalAmount : requestAmount + fee;

  const onCategoryChange = useCallback(
    (event) => {
      const id = Number(event.target.value);
      const newCategory = categoryOptions?.find((p) => p.id === id);
      formik.setFieldValue('category', newCategory);
    },
    [categoryOptions, formik.setFieldValue],
  );

  const onRequestAmountChange = useCallback((value: number) => {
    formik.setFieldValue('requestAmount', value);
  }, []);

  const onSubmitAttempt = () => {
    if (formik.isValid) {
      formik.submitForm();
    } else {
      Notifications.alert(Object.values(formik.errors).join('\n'));
    }
  };

  const onDueDateChange = useCallback(
    (value) => {
      formik.setFieldValue('dueDate', endOfDay(value));
    },
    [formik],
  );

  return (
    <PrevNextStepper
      nextStepActive={formik.isValid}
      onPrevClick={onGoBack}
      onNextClick={onSubmitAttempt}
    >
      <Form onSubmit={onSubmitAttempt}>
        <Section>
          <div className="transfer-stage-header">{LL.PAYMENT_DETAILS()}</div>
          {!bulk && (
            <div>
              <Row>
                <span>{LL.AMOUNT()}</span>
                <CurrencyInput
                  name="amount"
                  defaultValue={requestAmount}
                  onValueChange={onRequestAmountChange}
                  currency={currency}
                />
              </Row>
              <TransactionHint>
                {LL.MIN_AMOUNT_PER_TRANSACTION({
                  currencyAmount: formatAmount({
                    amount: minLimit,
                    currency,
                  }),
                })}
              </TransactionHint>
              <TransactionHint>
                {LL.MAX_AMOUNT_PER_TRANSACTION({
                  currencyAmount: formatAmount({
                    amount: maxLimit,
                    currency,
                  }),
                })}
              </TransactionHint>
            </div>
          )}
          <Row>
            <div className={styles.label}>{LL.FEES()}</div>
            <div className={styles.value}>
              {/* {currency} {fee} */}
              {LL.PROMOTIONAL()}
            </div>
          </Row>
          {FEES_ENABLED && (
            <Row className={styles.feeRow}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                <input
                  type="checkbox"
                  name="iCoverFees"
                  checked={iCoverFees}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('iCoverFees', e.target.checked);
                  }}
                />
                {LL.I_WILL_COVER_FEES()}
              </label>
            </Row>
          )}
          {!bulk && (
            <DueDateSection>
              <DueDateFieldContainer>
                <div className={`input-group-title ${styles.amountTitle}`}>
                  {LL.DUE_DATE()}
                </div>
                <DatePicker
                  value={dueDate || undefined}
                  onDayChange={onDueDateChange}
                  disabled={{
                    before: new Date(),
                    after: addDays(new Date(), DUE_DATE_MAXIMUM_DAYS),
                  }}
                />
              </DueDateFieldContainer>
            </DueDateSection>
          )}
          <div className="input-field">
            <IonItem className="input-item" lines="none">
              <select
                value={category?.id}
                onChange={onCategoryChange}
                className="input"
                placeholder={LL.PURPOSE_OF_PAYMENT()}
                required
              >
                <option value="">{LL.SELECT_PURPOSE_OF_TRANSACTION()}</option>
                {categoryOptions?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </IonItem>
          </div>
          <div className="input-field">
            <IonItem className="input-item" lines="none">
              <IonTextarea
                value={description}
                onIonChange={(e) =>
                  formik.setFieldValue('description', e.detail.value)
                }
                rows={10}
                className="input"
                placeholder={LL.DESCRIPTION()}
              />
            </IonItem>
          </div>
          <Row className={styles.totalsRow}>
            <div className={`${styles.totalsLabel} ${styles.label}`}>Total</div>
            <div className={`${styles.totalsValue} ${styles.value}`}>
              {formatAmount({
                amount: total,
                currency,
              })}
            </div>
          </Row>
        </Section>
      </Form>
    </PrevNextStepper>
  );
};

const DueDateSection = styled.label`
  margin: 32px 0;
`;

const DueDateFieldContainer = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 0;

  cursor: pointer;

  :hover {
    background-color: hsla(0, 0%, 70%, 0.1);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 1 1 auto;
  max-width: 480px;
  gap: 24px;
`;

const TransactionHint = styled.span`
  display: block;
  opacity: 0.6;
  font-size: 0.8rem;
`;
