import React from 'react';

import styled from 'styled-components';
import { useSelf } from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

interface Props {
  children: React.ReactNode;
  hideWarning?: boolean;
}

export const AllowedToCreatePrefundAccountsGuard: React.VFC<Props> = ({
  hideWarning = false,
  children,
}) => {
  const LL = useI18nObject();

  const { data: user, isFetched } = useSelf();

  const allowedToCreatePrefundAccount =
    user?.allowed_to_create_prefund_balance || false;

  if (allowedToCreatePrefundAccount) {
    return <>{children}</>;
  }
  return hideWarning ? null : (
    <AllowedToCreatePrefundAccountsGuardMessage>
      {isFetched && LL.CANNOT_CREATE_PREFUND_ACCOUNTS()}
    </AllowedToCreatePrefundAccountsGuardMessage>
  );
};

const AllowedToCreatePrefundAccountsGuardMessage = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 20px;
  background: white;
  border-radius: 0.5rem;
  margin: 0;
`;
