import React from 'react';
import { createPortal } from 'react-dom';

import styled from 'styled-components';

interface Props {
  onOverlayClick?: () => void;
  children: React.ReactNode;
}

export const Modal = ({ children, onOverlayClick }: Props) => {
  const Component = (
    <>
      <Overlay onClick={onOverlayClick} />
      <ModalStyled>{children}</ModalStyled>
    </>
  );
  const portalDomNode = document.getElementById('modal');
  if (portalDomNode) {
    return createPortal(Component, portalDomNode);
  }
  return null;
};

const Overlay = styled.div<{ onClick?: () => void }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  content: '';
  background: rgba(0, 0, 0, 0.5);
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
`;

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;
  z-index: 2000;

  > * {
    pointer-events: auto;
  }
`;
