import 'stores/init';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { setupConfig } from '@ionic/react';
import { REACT_APP_VERSION } from 'utils/react-app-version';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { App as CapacitorApp } from '@capacitor/app';
import { appStarted, appStateChangedFx } from 'stores/app';
import { queryClient } from 'api/fetcher';
import { ErrorBoundary } from 'components/ErrorBoundary';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

console.log('APP VERSION: ', REACT_APP_VERSION);

setupConfig({
  mode: 'md',
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
      <ReactQueryDevtools
        position="top-right"
        toggleButtonProps={{
          style: {
            transform: 'scale(0.5) translate(40px, -40px)',
            filter: 'brightness(3) saturate(0)',
            opacity: 0.5,
          },
        }}
        initialIsOpen={false}
      />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

appStarted();

CapacitorUpdater.notifyAppReady();

CapacitorApp.addListener('appStateChange', appStateChangedFx);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
