import React from 'react';

import { useStore } from 'effector-react';
import { $locale } from 'stores/locale';
import { LegalPage } from 'ui/templates/legal-page';
import { Logo } from 'ui/atoms/logo';
import SiteFooter from 'components/SiteFooter/SiteFooter';

import { UserAgreementFrench } from './UserAgreementFrench';
import { UserAgreementEnglish } from './UserAgreementEnglish';

export const UserAgreement = () => {
  const locale = useStore($locale);
  const UserAgreementContent =
    locale === 'fr' ? UserAgreementFrench : UserAgreementEnglish;

  return (
    <LegalPage>
      <Logo />
      <article>
        <UserAgreementContent />
      </article>
      <SiteFooter />
    </LegalPage>
  );
};
