import React, { useCallback, useEffect, useState } from 'react';
import { DataLine, DataLineItem } from 'ui/organisms/summary';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import type { BundleInfo } from '@capgo/capacitor-updater';
import { IconButton } from 'ui/molecules/icon-button';
import { Section } from './Section';

export const Updater = () => {
  const [updaterDeviceId, setUpdaterDeviceId] = useState<string | null>(null);
  const [updaterPluginVersion, setUpdaterPluginVersion] = useState<
    string | null
  >(null);
  const [currentBundle, setCurrentBundle] = useState<{
    bundle: BundleInfo;
    native: string;
  } | null>(null);

  const updateInfo = useCallback(async () => {
    const { deviceId: updaterDeviceId } = await CapacitorUpdater.getDeviceId();
    setUpdaterDeviceId(updaterDeviceId);
    const { version: updaterPluginVersion } =
      await CapacitorUpdater.getPluginVersion();
    setUpdaterPluginVersion(updaterPluginVersion);
    const currentBundle = await CapacitorUpdater.current();
    setCurrentBundle(currentBundle);
  }, []);

  useEffect(() => {
    updateInfo();
  }, []);

  const onResetClick = useCallback(async () => {
    CapacitorUpdater.reset();
  }, []);
  return (
    <Section
      name="Updater"
      right={
        <IconButton
          iconProps={{
            name: 'Refresh',
            size: 16,
          }}
          onButtonClick={updateInfo}
        />
      }
    >
      <DataLine>
        <DataLineItem isTitle>current bundle</DataLineItem>
        <DataLineItem>{currentBundle?.bundle.version}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>updater device ID</DataLineItem>
        <DataLineItem>{updaterDeviceId}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>plugin version</DataLineItem>
        <DataLineItem>{updaterPluginVersion}</DataLineItem>
      </DataLine>
      <DataLine>
        <button onClick={onResetClick}>Reset to builtin bundle</button>
      </DataLine>
    </Section>
  );
};
