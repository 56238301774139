import { presentIonModal } from 'modals/present-ion-modal';

import type { ComponentProps } from 'react';
import { i18nObject } from 'i18n/i18n-util';
import { $locale } from 'stores/locale';
import ResetPasswordForm from '../components/ResetPasswordForm/ResetPasswordForm';

export const resetPasswordModal = async (
  token: string,
): Promise<boolean | undefined> =>
  new Promise((res, rej) => {
    const LL = i18nObject($locale.getState());

    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (success: boolean) => {
      res(success);
      onClose();
    };

    const modalProps: ComponentProps<typeof ResetPasswordForm> = {
      onClose,
      onDone,
      isModal: true,
      title: LL.RESET_PASSWORD(),
      token,
    };
    const [promptModal, dismissModal] = presentIonModal(
      ResetPasswordForm,
      modalProps,
      'updatePasswordModal',
    );

    promptModal();
  });
