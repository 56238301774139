import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import moment from 'moment';

import { DataLine, DataLineItem } from 'ui/organisms/summary';
import { useI18nObject } from 'utils/use-i18n-object';

import type { PaymentRequest } from 'models';

import { getPaymentRequestStatusMapping } from 'utils';
import { formatAmount } from 'utils/format-amount';

interface Props {
  data: PaymentRequest;
  hideDescription?: boolean;
}

export const PaymentRequestListItem: React.FC<Props> = ({
  data,
  hideDescription,
}) => {
  const { status } = data;
  const LL = useI18nObject();

  const createdDate = moment(data.created).format('DD/MM/YYYY');

  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(`/dashboard/payment-requests/${data.id}`);
  }, []);

  const statusRepr = getPaymentRequestStatusMapping(data.status);

  const isSucceeded = status === 'paid';

  const disabled = status === 'draft';

  return (
    <PaymentRequestListItemStyled
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <DataLine>
        <DataLineItem>
          <Amount
            isFailed={['expired', 'cancelled'].includes(status)}
            isSucceeded={isSucceeded}
          >
            {formatAmount({
              amount: data.amount,
              currency: data.currency,
            })}
          </Amount>
        </DataLineItem>
        <DataLineItem secondary>{createdDate}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem>#{data.id}</DataLineItem>
        <DataLineItem secondary>
          <Status>{statusRepr}</Status>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem>{data.payer_name}</DataLineItem>
        <DataLineItem>{!hideDescription && data.description}</DataLineItem>
      </DataLine>
    </PaymentRequestListItemStyled>
  );
};

const PaymentRequestListItemStyled = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px solid var(--text-color-v5);
  gap: 8px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  opacity: ${(p) => p.disabled && 0.5};
`;

const Amount = styled.span<{
  isFailed: boolean;
  isSucceeded: boolean;
}>`
  color: ${(p) =>
    ({
      true: 'gray',
      [String(p.isSucceeded)]: 'var(--income-color)',
    }.true)};
  text-decoration: ${(p) => p.isFailed && 'line-through'};
`;

const Status = styled.div`
  text-transform: capitalize;
`;
