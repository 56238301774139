import { Icon } from 'ui/atoms/icon';
import React from 'react';
import styled from 'styled-components';

import { UploadButton } from 'ui/atoms/upload-button';

interface Props {
  value: File | null;
  onChange: (original: File) => void;
  onDelete: () => void;
  label: string;
  required?: boolean;
  accept?: string;
}

export const FileUploadInput = ({
  value,
  onChange,
  onDelete,
  label,
  required = false,
  accept,
}: Props) => {
  const extension = value?.name.split('.').pop();

  return (
    <Document>
      <Buttons>
        {value ? (
          <ExtensionContainer>
            <Extension>{extension}</Extension>
          </ExtensionContainer>
        ) : (
          <UploadButtonStyled
            accept={accept || '*'}
            onFilePick={(v: File) => {
              onChange(v);
            }}
          >
            <Icon name="FileDocumentPlus" size={48} />
          </UploadButtonStyled>
        )}
      </Buttons>
      <InformationSection>
        <Label required={required}>{label}</Label>
      </InformationSection>
      {!!value && (
        <DeleteButton onClick={onDelete}>
          <Icon name="Delete" color="hsl(0, 0%, 67%)" />
        </DeleteButton>
      )}
    </Document>
  );
};

const Document = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px 0;
  gap: 24px;
`;

const Label = styled.p<{ required: boolean }>`
  font-weight: ${({ required }) => (required ? 600 : 'normal')};
`;

const Buttons = styled.div`
  flex: 0 1 160px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const UploadButtonStyled = styled(UploadButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #efefef;
  border-radius: 4px;
`;

const ExtensionContainer = styled.div`
  background-color: #efefef;
  border-radius: 4px;
  width: 80px;
  height: 80px;
`;

const Extension = styled.div`
  width: 48px;
  height: 48px;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--text-color-v0);
  background-color: var(--light-background-color);
`;

const InformationSection = styled.div`
  flex: 1;
`;

const DeleteButton = styled.button`
  background: none;
  padding: 0;
`;
