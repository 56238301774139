export const PrivacyPolicyEnglish = () => (
  <>
    <h1>PRIVACY POLICY</h1>
    <p>Last updated: November 6, 2023</p>
    <p>
      We at TecHustle, Inc. and its subsidiaries take your privacy very
      seriously and know that you care about how your personal information is
      used and shared. Our primary goal is to provide you with exceptional
      service, and we understand that you may have questions and/or concerns
      regarding your personal information and how it will be used. Your personal
      information is any information that can be associated with a specific
      person and can be used to identify that person.
    </p>
    <p>
      When you use the AFi services (as defined in the User Agreement), we
      collect the information from you, including your name, date of birth,
      address, occupation, identity documentation, IP address, geo-location
      information, account information, identification information, device
      information, and financial information. We may also obtain information
      about you from third parties such as identity verification, fraud
      prevention and similar services. When you are using the AFi services, we
      collect information about your account transactions and may collect
      information about your access device for fraud prevention and regulatory
      purposes. We may collect additional information from or about you in other
      ways not specifically described here.
    </p>
    <p>
      Our primary purpose in collecting personal information is to provide you
      with a safe, efficient and cost-effective experience in compliance with
      local and international financial laws, regulations and guidelines.
    </p>
    <p>
      We do not share your personal information with third parties for their
      promotional or marketing purposes. We may share your personal information
      with law enforcement, government officials, or other third parties if we
      are compelled to; with third party service providers who assist us in
      providing services to you or who provide fraud detection or similar
      services; or with other third parties with your consent or at your
      direction to do so.
    </p>
    <p>
      We may retain certain data even after you delete your account to comply
      with applicable financial regulations. This data retention is essential
      for meeting legal obligations and ensuring the security and integrity of
      AFi Services. Such data may include transaction records, account details,
      and other relevant information necessary for regulatory compliance and
      risk management purposes. Rest assured, any retained data will continue to
      be protected in accordance with our privacy policy and relevant data
      protection laws. Please note that this data will only be accessed or
      disclosed as required by law or in response to valid requests by
      authorised government authorities.
    </p>
    <p>
      We store and process your personal information using third party servers
      located in data centres. This information is protected by physical,
      electronic and procedural safeguards in compliance with industry
      standards.
    </p>
    <p>
      AFi Services are always evolving and improving to serve you better. We may
      occasionally update this privacy policy.
    </p>
  </>
);
