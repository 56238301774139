import React, { useCallback, useState } from 'react';
import { DayPicker, DayPickerDefaultProps, useInput } from 'react-day-picker';

import { usePopper } from 'react-popper';

import styled from 'styled-components';

import { enUS, fr } from 'date-fns/locale';

import 'react-day-picker/dist/style.css';
import { $locale } from 'stores/locale';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';

import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import { IconButton } from 'ui/molecules/icon-button';
import { Input } from 'ui/atoms/input';

interface Props extends DayPickerDefaultProps {
  value?: Date;
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  onDayChange?: (day?: Date) => void;
  isError?: boolean;
  placeholder?: string;
  // [key: string]: any;
}

export const DatePicker = ({
  value,
  className,
  inputRef,
  isError,
  onDayChange,
  placeholder,
  ...props
}: Props) => {
  const locale = useStore($locale);

  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'flip', options: { fallbackPlacements: ['top', 'bottom'] } },
    ],
  });

  const { inputProps, dayPickerProps, setSelected } = useInput({
    defaultSelected: value,
    // fromYear: 2021,
    // toYear: 2023,
    format: 'yyyy-MM-dd',
    required: true,
    locale: { en: enUS, fr }[locale],
  });

  const openPopper = useCallback(() => {
    setIsPopperOpen(true);
  }, [setIsPopperOpen]);

  const closePopper = useCallback(() => {
    setIsPopperOpen(false);
  }, [setIsPopperOpen]);

  const [inputValue, setInputValue] = useState<string>('');

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date?: Date) => {
    setSelected(date);
    if (date) {
      setInputValue(format(date, 'yyyy-MM-dd'));
      if (onDayChange) {
        onDayChange(date);
      }
      closePopper();
    } else {
      setInputValue('');
    }
  };

  const onInputChange = useCallback(
    (value) => {
      setInputValue(value);
      const date = parse(value, 'yyyy-MM-dd', new Date());
      if (isValid(date)) {
        setSelected(date);
        if (onDayChange) {
          onDayChange(date);
        }
      } else {
        setSelected(undefined);
      }
    },
    [setInputValue, setSelected, onDayChange],
  );

  return (
    <DatePickerStyled ref={setReferenceElement}>
      <Input
        ref={inputRef}
        {...inputProps}
        value={inputValue}
        onClick={openPopper}
        onInputChange={onInputChange}
        isError={isError}
        placeholder={placeholder}
        right={
          <IconButton
            iconProps={{ name: 'Calendar' }}
            type="button"
            onButtonClick={handleButtonClick}
          />
        }
      />
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            // fallbackFocus: buttonRef.current,
          }}
        >
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 1000 }}
            {...attributes.popper}
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              {...props}
              {...dayPickerProps}
              selected={value}
              onSelect={handleDaySelect}
            />
          </div>
        </FocusTrap>
      )}
    </DatePickerStyled>
  );
};

export const DatePickerStyled = styled.div`
  .rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: #4a90e2;
    --rdp-background-color: #e7edff;
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #4a90e2;
    --rdp-background-color-dark: #e7edff;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);

    background: white;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;
