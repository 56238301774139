import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';

interface Params {
  blob: Blob;
  filename: string;
}

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const { result } = reader;
      if (typeof result === 'string') {
        resolve(result);
      }
    };
    reader.readAsDataURL(blob);
  });
};

export const downloadBlob = async ({ blob, filename }: Params) => {
  if (Capacitor.isNativePlatform()) {
    const base64Encoded = await blobToBase64(blob);
    const { uri } = await Filesystem.writeFile({
      path: filename,
      data: base64Encoded,
      directory: Directory.Cache,
    });
    await Share.share({
      url: uri,
    });
  } else {
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    // window.open(url, '_blank');
  }
};
