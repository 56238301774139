import { useState, useCallback } from 'react';
import { IonItem, IonInput } from '@ionic/react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import { useUpdateProfile } from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

import styles from './SecuritySettingsRecoveryOptionsForm.module.css';
import CloseIcon from '../../assets/icon/close.svg';

import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import { Expandable } from '../Expandable/Expandable';
import Notifications from '../Notifications';

export const SecuritySettingsRecoveryOptionsForm: React.FC<any> = ({
  isModal,
  title,
  onClose,
}) => {
  const LL = useI18nObject();

  const [expandedForm, setExpandedForm] = useState<'email' | 'phone' | 'none'>(
    'none',
  );
  const expandEmailForm = () => {
    setExpandedForm('email');
  };
  const expandPhoneForm = () => {
    setExpandedForm('phone');
  };

  const [newRecoveryPhoneNumber, setNewRecoveryPhoneNumber] = useState('');
  const [newRecoveryEmailAddress, setNewRecoveryEmailAddress] = useState('');
  const [
    newRecoveryEmailAddressConfirmation,
    setNewRecoveryEmailAddressConfirmation,
  ] = useState('');

  const onRecoveryEmailChange = (e: any) => {
    setNewRecoveryEmailAddress(e.detail.value);
  };
  const onRecoveryEmailConfirmationChange = (e: any) => {
    setNewRecoveryEmailAddressConfirmation(e.detail.value);
  };

  const user = useStore($user);

  const updateProfileMutation = useUpdateProfile();

  const onPhoneNumberSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      const profileData = {
        recovery_email: user?.recovery_email,
        recovery_phone_number: newRecoveryPhoneNumber,
      } as any;

      await updateProfileMutation.mutateAsync(profileData);
    },
    [newRecoveryPhoneNumber, user?.recovery_email],
  );

  const onEmailSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (!newRecoveryEmailAddress) {
        await Notifications.alert('Please enter email');
      }

      if (newRecoveryEmailAddress !== newRecoveryEmailAddressConfirmation) {
        await Notifications.alert(LL.EMAILS_DOES_NOT_MATCH());
        return;
      }

      const profileData = {
        recovery_email: newRecoveryEmailAddress,
        recovery_phone_number: user?.recovery_phone_number,
      } as any;

      await updateProfileMutation.mutateAsync(profileData);
    },
    [
      newRecoveryEmailAddress,
      newRecoveryEmailAddressConfirmation,
      user?.recovery_phone_number,
    ],
  );

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {title || LL.RECOVERY_OPTIONS()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <div className={styles.content}>
        <Expandable
          expanded={expandedForm === 'phone'}
          setExpanded={expandPhoneForm}
          collapsedStateContent={
            <div>
              <h2 className={styles.sectionHeader}>
                {LL.RECOVERY_MOBILE_NUMBER()}
              </h2>
              <p className={styles.hint}>
                {LL.YOUR_CURRENT_RECOVERY_IS_SET_TO({
                  recovery: user?.recovery_phone_number,
                })}
              </p>
            </div>
          }
        >
          <form className={styles.form1} onSubmit={onPhoneNumberSubmit}>
            <div>
              <h2 className={styles.sectionHeader}>
                {LL.RECOVERY_MOBILE_NUMBER()}
              </h2>
              <p className={styles.hint}>
                {LL.YOUR_CURRENT_RECOVERY_IS_SET_TO({
                  recovery: user?.recovery_phone_number,
                })}
              </p>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <PhoneNumberInput
                  value={newRecoveryPhoneNumber}
                  name="recovery_phone_number"
                  className="input"
                  onChange={setNewRecoveryPhoneNumber}
                />
              </IonItem>
            </div>
            <button
              type="submit"
              className={`btn submit-btn ${styles.submitButton}`}
            >
              {LL.SAVE()}
            </button>
          </form>
        </Expandable>
        <Expandable
          expanded={expandedForm === 'email'}
          setExpanded={expandEmailForm}
          collapsedStateContent={
            <div>
              <h2 className={styles.sectionHeader}>
                {LL.RECOVERY_EMAIL_ADDRESS()}
              </h2>
              <p className={styles.hint}>
                {LL.YOUR_CURRENT_RECOVERY_IS_SET_TO({
                  recovery: user?.recovery_email,
                })}
              </p>
            </div>
          }
        >
          <form className={styles.form1} onSubmit={onEmailSubmit}>
            <div>
              <h2 className={styles.sectionHeader}>
                {LL.RECOVERY_EMAIL_ADDRESS()}
              </h2>
              <p className={styles.hint}>
                {LL.YOUR_CURRENT_RECOVERY_IS_SET_TO({
                  recovery: user?.recovery_email,
                })}
              </p>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={newRecoveryEmailAddress}
                  name="recovery_email"
                  type="email"
                  className="input"
                  placeholder={LL.NEW_RECOVERY_EMAIL_ADDRESS()}
                  onIonChange={onRecoveryEmailChange}
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={newRecoveryEmailAddressConfirmation}
                  name="recovery_email_confirmation"
                  type="email"
                  className="input"
                  placeholder={LL.CONFIRM_NEW_RECOVERY_EMAIL_ADDRESS()}
                  onIonChange={onRecoveryEmailConfirmationChange}
                />
              </IonItem>
            </div>
            <button
              type="submit"
              className={`btn submit-btn ${styles.submitButton}`}
            >
              {LL.SAVE()}
            </button>
          </form>
        </Expandable>
      </div>
    </div>
  );
};
