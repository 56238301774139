import { presentIonModal } from 'modals/present-ion-modal';

import type { ComponentProps } from 'react';
import type { User } from 'models';
import { VerifyIdDocumentForm } from '../components/VerifyIdDocumentForm/VerifyIdDocumentForm';

export const verifyIdModal = async (): Promise<User | undefined> =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismiss();
      res(undefined);
    };

    const onDone = async (user: User) => {
      res(user);
      onClose();
    };

    const modalProps: ComponentProps<typeof VerifyIdDocumentForm> = {
      onClose,
      onDone,
      isModal: true,
    };
    const [promptModal, dismiss] = presentIonModal(
      VerifyIdDocumentForm,
      modalProps,
      'verifyIdModal',
    );

    promptModal();
  });
