import { Capacitor } from '@capacitor/core';
import { inform } from 'utils/inform';
import { Share } from '@capacitor/share';
import type { ShareOptions } from '@capacitor/share';

interface Options extends ShareOptions {
  url: string;
}

export const copyShare = async ({ url, ...shareOptions }: Options) => {
  if (Capacitor.isNativePlatform()) {
    await Share.share({
      url,
      ...shareOptions,
    });
  } else {
    navigator.clipboard.writeText(url);
    inform({
      message: 'Copied',
    });
  }
};
