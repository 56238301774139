import React, { useCallback, useRef, useState } from 'react';

import styled from 'styled-components';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import isDate from 'date-fns/isDate';

import { useI18nObject } from 'utils/use-i18n-object';

import { DatePicker } from './date-picker';

const parseDate = (str: string, format: string, locale?: string) => {
  const parsed = dateFnsParse(str, format, new Date());
  if (isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

const formatDate = (date: number | Date, format: string, locale?: string) =>
  dateFnsFormat(date, format);

interface Props {
  onDateFromSet: (from?: Date) => void;
  onDateToSet: (to?: Date) => void;
  className?: string;
}

export const DateRangePickerComponent: React.VFC<Props> = ({
  onDateFromSet,
  onDateToSet,
  className,
}) => {
  const LL = useI18nObject();

  const [value, setValue] = useState<{ to?: Date; from?: Date }>({
    to: undefined,
    from: undefined,
  });
  const now = new Date();
  const { to, from } = value;
  const modifiers = { start: from, end: to };

  const handleFromChange = useCallback(
    (fromValue?: Date) => {
      // Change the from date and focus the "to" input field
      setValue((prev) => ({ ...prev, from: fromValue }));
      onDateFromSet(fromValue);
    },
    [setValue],
  );

  const handleToChange = useCallback(
    (toValue?: Date) => {
      setValue((prev) => ({ ...prev, to: toValue }));
      onDateToSet(toValue);
    },
    [setValue, to, from],
  );

  return (
    <div className={`InputFromTo ${className}`}>
      <DatePicker
        value={from}
        placeholder={LL.FROM_DATE()}
        onDayChange={handleFromChange}
        disabled={[...(to ? [{ after: to }] : []), { after: now }]}
        // formatDate={formatDate}
        // parseDate={parseDate}
        // dayPickerProps={{
        //   selected: [from, { from, to }],
        //   toMonth: to,
        //   modifiers,
        // }}
      />
      <DatePicker
        value={to}
        placeholder={LL.TO()}
        onDayChange={handleToChange}
        disabled={[...(from ? [{ before: from }] : []), { after: now }]}
        // formatDate={formatDate}
        // parseDate={parseDate}
        // dayPickerProps={{
        //   selected: [from, { from, to }],
        //   modifiers,
        //   month: from,
        //   fromMonth: from,
        // }}
      />
    </div>
  );
};

export const DateRangePicker = styled(DateRangePickerComponent)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
