import { useStore } from 'effector-react';
import TypesafeI18n from 'i18n/i18n-react';
import { $locale } from '../stores/locale';

type Props = {
  children?: React.ReactNode;
};

export const ModalWrapper: React.VFC<Props> = ({ children }) => {
  const locale = useStore($locale);

  return <TypesafeI18n locale={locale}>{children}</TypesafeI18n>;
};

export default ModalWrapper;
