import React, { useState, useMemo, useCallback, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { useDestroyFinancialAccount } from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

import { undoable } from 'utils/undoable';
import type { FinancialAccount } from 'models';
import { Icon } from 'ui/atoms/icon';
import { IconButton } from 'ui/molecules/icon-button';

import { AccountTypeIcon } from '../../data';

const AccountTypeIcons = AccountTypeIcon as any;

interface Props {
  account: FinancialAccount;
  isSelected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  detailed?: boolean;
}

export const FinancialAccountListItem: React.FC<Props> = ({
  account,
  disabled = false,
  onClick,
  isSelected = false,
  detailed = false,
}) => {
  const LL = useI18nObject();

  const destroyFinancialAccountMutation = useDestroyFinancialAccount();

  const onRemoveClick = useCallback(async () => {
    const networkAccountId = account.id;
    if (networkAccountId) {
      undoable({
        message: LL.YOU_ARE_ABOUT_TO_DELETE({
          subject: account.account_title,
        }),
        onTimeout: async () => {
          await destroyFinancialAccountMutation.mutateAsync(networkAccountId);
        },
      });
    }
  }, [account]);

  const expiryDate = useMemo(() => {
    if (
      account?.bank_card?.expiration_month &&
      account?.bank_card?.expiration_year
    )
      return `${account.bank_card.expiration_month}/${account.bank_card.expiration_year}`;
  }, [
    account?.bank_card?.expiration_month,
    account?.bank_card?.expiration_year,
  ]);

  return (
    <FinancialAccountListItemStyled selected={isSelected}>
      <AccountButton onClick={onClick} disabled={disabled}>
        <IconContainer>
          <img src={AccountTypeIcons[account.account_type]} />
        </IconContainer>

        <Content>
          <HeaderTitle>{account.account_title}</HeaderTitle>
          <SubTitle>
            {LL.ENDING_WITH()}
            {account.last_four_digits}
            {detailed && expiryDate && (
              <>
                {' '}
                <SubTitle>{LL.EXPIRES()}</SubTitle>{' '}
                <ValueText>{expiryDate || 'N/A'}</ValueText>
              </>
            )}
          </SubTitle>
          {detailed && (
            <VerificationStatus>
              <Icon
                name={account.verified ? 'Verified' : 'Unverified'}
                size={16}
              />
              <ValueText>{LL.VERIFIED()}</ValueText>
            </VerificationStatus>
          )}
        </Content>
        {detailed && (
          <IconButton
            iconProps={{
              name: 'Delete',
              size: 24,
              color: 'var(--primary-color-main)',
            }}
            type="button"
            onButtonClick={onRemoveClick}
          />
        )}
      </AccountButton>
    </FinancialAccountListItemStyled>
  );
};

const FinancialAccountListItemStyled = styled.div<{
  selected: boolean;
}>`
  padding: 16px 8px;

  gap: 8px;

  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  border-radius: 0.5rem;
  border-color: ${(p) => p.selected && 'var(--secondary-color-v3)'};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const AccountButton = styled.button`
  pointer-events: ${(p) => p.disabled && 'none'};
  opacity: ${(p) => p.disabled && '0.5'};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'initial')};

  font-size: 1.1rem;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 24px;
`;

const Content = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HeaderTitle = styled.p`
  color: var(--text-color-v0);
  line-height: 1.5rem;
`;

const SubTitle = styled.span`
  color: var(--text-color-v4);
  line-height: 1.5rem;
`;

const ValueText = styled.span``;

const VerificationStatus = styled.span`
  line-height: 1.5rem;
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`;
