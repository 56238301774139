import { presentIonModal } from 'modals/present-ion-modal';
import { AddRecipientForm } from 'components/AddRecipientForm';
import type { ComponentProps } from 'react';

import type { Recipient } from 'models';

export const addRecipientModal = async (): Promise<Recipient | undefined> =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismiss();
      res(undefined);
    };

    const onDone = async (recipient: Recipient) => {
      res(recipient);
      onClose();
    };

    const modalProps: ComponentProps<typeof AddRecipientForm> = {
      onClose,
      onRecipientCreated: onDone,
    };
    const [promptModal, dismiss] = presentIonModal(
      AddRecipientForm,
      modalProps,
      'addRecipientModal',
    );

    promptModal();
  });
