import React from 'react';
import { toast } from 'react-toastify';
import type { ToastPosition } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

interface Params {
  message: string;
  onTimeout?: () => void;
  timeout?: number;
  onUndo?: () => void;
  buttonLabel?: string | null;
  position?: ToastPosition;
}

export const undoable = ({
  message,
  timeout,
  onTimeout,
  onUndo,
  buttonLabel,
  position,
}: Params) => {
  const toastId = uuidv4();

  const setDismissOnClose = () => {
    toast.update(toastId, {
      onClose: null,
    });
  };

  toast(
    ({ closeToast }) => {
      const handleUndoClick = () => {
        setDismissOnClose();
        if (onUndo) {
          onUndo();
        }
        if (closeToast) {
          closeToast();
        }
      };

      return (
        <>
          <span>{message}</span>
          {buttonLabel !== null && (
            <button onClick={handleUndoClick}>{buttonLabel || 'UNDO'}</button>
          )}
        </>
      );
    },
    {
      toastId,
      position: position || toast.POSITION.BOTTOM_CENTER,
      closeButton: false,
      closeOnClick: false,
      autoClose: timeout || 10 * 1000,
      onClose: onTimeout,
    },
  );

  const dismiss = () => {
    setDismissOnClose();
    toast.dismiss(toastId);
  };

  return { dismiss };
};
