import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import { getOnboardingState } from 'utils/get-onboarding-state';
import { getNextOnboardingStep } from 'utils/get-next-onboarding-step';
import { Redirect } from 'react-router-dom';

export const OnboardingStepRedirector = () => {
  const user = useStore($user);
  if (user) {
    const onboardingStep = getOnboardingState(user);
    if (onboardingStep === 'complete') {
      console.log('OnboardingRoute: /dashboard');
      return <Redirect to="/dashboard" />;
    }
    const url = getNextOnboardingStep(user);
    console.log(`OnboardingRoute: ${url}`);
    return <Redirect to={url} />;
  }
  return null;
};
