import React, { ChangeEventHandler } from 'react';
import type { ChangeEvent } from 'react';
import styled from 'styled-components';

interface Params {
  onValueChange: (value: string) => void;
  isError?: boolean;
}

const TRANSIENT_PROPS: (keyof Params | string)[] = ['onValueChange', 'options'];

type Props = Omit<React.ComponentPropsWithoutRef<'select'>, 'onValueChange'> &
  Params;

export const SelectInput = styled.div
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<Props>(({ onValueChange, ref, className, ...props }) => {
    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      onValueChange(value);
    };
    return {
      ref,
      children: (
        <>
          <SelectStyled onChange={onChange} {...props}>
            {props.children}
          </SelectStyled>
        </>
      ),
    };
  })<Props>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
  gap: 8px;
  height: 48px;
  border-radius: 0.3rem;
  border-width: 1px;
  border-color: ${(p) => (p.isError ? 'var(--error-color)' : 'hsl(0,0%,87%)')};
  border-style: solid;

  :focus,
  :focus-within {
    border-color: var(--secondary-color-v3);
  }
`;

export const SelectStyled = styled.select`
  border: none;
  outline: none;
  flex: 1;
  background-color: transparent;
`;
