import { CapacitorUpdater } from '@capgo/capacitor-updater';
import type {
  LatestVersion as LatestVersionInfo,
  BundleInfo,
} from '@capgo/capacitor-updater';
import { SplashScreen } from '@capacitor/splash-screen';
import { createEffect, createStore } from 'effector';

export const checkForUpdatesFx = createEffect(async () => {
  console.log('checkForUpdatesFx');
  return CapacitorUpdater.getLatest();
});

export const $latestVersion = createStore<LatestVersionInfo | null>(null).on(
  checkForUpdatesFx.doneData,
  (_, data) => data,
);

$latestVersion.watch((v) => {
  console.log('$latestVersion', v);
});

export const downloadUpdateFx = createEffect(
  async (latestVersion: LatestVersionInfo) => {
    console.log('downloadUpdateFx');
    const { url, version } = latestVersion;
    // Do the download during user active app time to prevent failed download
    if (url) {
      return CapacitorUpdater.download({
        url,
        version,
      });
    }
  },
);

export const $bundleInfo = createStore<BundleInfo | null>(null).on(
  downloadUpdateFx.doneData,
  (_, data) => data,
);

$bundleInfo.watch((v) => {
  console.log('$bundleInfo', v);
});

export const applyUpdateFx = createEffect(async (bundleInfo: BundleInfo) => {
  // Do the switch when user leave app or when you want
  console.log('applyUpdateFx');
  SplashScreen.show();
  try {
    console.log('applyUpdateFx: set');
    await CapacitorUpdater.set({ id: bundleInfo.id });
  } catch (err) {
    console.log(err);
    SplashScreen.hide(); // in case the set fail, otherwise the new app will have to hide it
  }
});
