import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui/atoms/icon';
import { useHistory } from 'react-router';

import type { Recipient } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';

interface Props {
  recipient: Recipient;
}

export const MyNetworkItem: React.VFC<Props> = ({ recipient }) => {
  const LL = useI18nObject();

  const history = useHistory();
  const onClick = useCallback(() => {
    history.push(`/dashboard/network/${recipient.id}`);
  }, [recipient]);

  const nameAbbr = (
    recipient
      ? `${recipient.first_name && recipient.first_name[0]}${
          recipient.last_name && recipient.last_name[0]
        }`
      : ''
  ).toUpperCase();

  return (
    <RecipientListItemStyled onClick={onClick}>
      <RecipientBrief>
        <AvatarIcon>{nameAbbr}</AvatarIcon>
        <Name>
          {recipient.first_name} {recipient.last_name}
        </Name>
      </RecipientBrief>
      <RecipientDetails>
        <RecipientInformation>
          {recipient.email && (
            <li>
              <DetailIcon size={28} name="Email" />
              <span>{recipient.email}</span>
            </li>
          )}
          {recipient.phone_number && (
            <li>
              <DetailIcon size={28} name="PhoneNumber" />
              <span>{recipient.phone_number}</span>
            </li>
          )}
          <li>
            <DetailIcon size={28} name="BankCard" />
            <span>
              {recipient.financial_accounts.length} {LL.FINANCIAL_ACCOUNTS()}
            </span>
          </li>
        </RecipientInformation>
      </RecipientDetails>
    </RecipientListItemStyled>
  );
};

const RecipientListItemStyled = styled.button`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  :not(:last-of-type) {
    border-bottom: 1px solid var(--text-color-v5);
  }
`;

const AvatarIcon = styled.div`
  font-weight: 500;
  color: var(--text-color-v1);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--secondary-color-v2);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RecipientBrief = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  width: 100%;

  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color-v1);
`;

const Name = styled.div`
  flex: 1;
  text-align: left;
`;

const RecipientDetails = styled.div``;

const RecipientInformation = styled.ul`
  padding: 0 1rem;
  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }
`;

const DetailIcon = styled(Icon)`
  margin-right: 0.75rem;
`;
