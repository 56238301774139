import axios from 'axios';
import {
  requestInterceptor,
  responseErrorInterceptor,
  responseInterceptor,
} from 'api/interceptor';
import { createEffect } from 'effector';

export const instance = axios.create();

export const updateAxiosInstanceBaseUrlFx = createEffect((value: string) => {
  instance.defaults.baseURL = `${value}/api`;
});

instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor,
);
