import { forward, sample } from 'effector';
import { loadLocalStorageFx, saveLocalStorageFx } from 'stores/localStorage';
import { updateAxiosInstanceBaseUrlFx } from 'api/axios';
import { $apiEndpoint, $config, $configName, LOCAL_STORAGE_KEY } from './index';

$configName.on(
  loadLocalStorageFx.doneData,
  (_, { [LOCAL_STORAGE_KEY]: value }) => value?.configName,
);

forward({
  from: $config,
  to: saveLocalStorageFx.prepend((value) => ({
    value,
    localStorageKey: LOCAL_STORAGE_KEY,
  })),
});

forward({
  from: $apiEndpoint,
  to: updateAxiosInstanceBaseUrlFx,
});

sample({
  source: $apiEndpoint,
  clock: loadLocalStorageFx.done,
  target: updateAxiosInstanceBaseUrlFx,
});
