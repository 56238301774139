import { PrefundAccount } from 'models';
import {
  useExportPrefundAccountTransactions,
  usePrefundAccountTransactions,
} from 'api/queries';
import TransactionListItem from 'components/TransactionListItem/TransactionListItem';
import { useI18nObject } from 'utils/use-i18n-object';
import styled from 'styled-components';
import { EmptyBox } from 'pages/Dashboard/EmptyBox';
import { useCallback, useState } from 'react';
import { Header } from 'pages/TransactionHistory/Header';

interface Props {
  prefundAccount: PrefundAccount;
}
export const TransactionHistory = ({ prefundAccount }: Props) => {
  const LL = useI18nObject();
  const [params, setParams] = useState<
    { start_date?: string; end_date?: string } | undefined
  >({});
  const { data: transactions } = usePrefundAccountTransactions(
    prefundAccount.id.toString(),
    params,
  );
  const qParams = params?.start_date || params?.end_date ? params : undefined;
  const isParamsSet = !!qParams;

  const onSearch = useCallback((params) => {
    setParams(params);
  }, []);
  const onXlsxExportClick = useExportPrefundAccountTransactions({
    format: 'xlsx',
    accountId: prefundAccount.id.toString(),
    params: qParams,
  });
  const onCsvExportClick = useExportPrefundAccountTransactions({
    format: 'csv',
    accountId: prefundAccount.id.toString(),
    params: qParams,
  });
  const onPdfExportClick = useExportPrefundAccountTransactions({
    format: 'pdf',
    accountId: prefundAccount.id.toString(),
    params: qParams,
  });
  return (
    <>
      <HeaderContainer>
        <Header
          showSearchBar={false}
          onSearch={onSearch}
          onCsvExportClick={onCsvExportClick}
          onXlsxExportClick={onXlsxExportClick}
          onPdfExportClick={onPdfExportClick}
        />
      </HeaderContainer>
      {!transactions || transactions.length === 0 ? (
        <EmptyBoxStyled>
          {LL.PREFUND_ACCOUNT_NO_TRANSACTION_HISTORY()}
        </EmptyBoxStyled>
      ) : (
        transactions.map((i) => (
          <TransactionListItem
            key={i.id}
            transaction={i.transaction}
            currentBalance={i.current_balance}
          />
        ))
      )}
    </>
  );
};

const EmptyBoxStyled = styled(EmptyBox)`
  height: 60px;
`;

const HeaderContainer = styled.div`
  padding: 1rem 0;
`;
