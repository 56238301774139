import type { Country } from 'models';

import { useStore } from 'effector-react';

import { $locale } from 'stores/locale';
import { useDevModeEnabled } from 'utils/use-dev-mode-enabled';

export const countries: Country[] = [
  {
    id: 4,
    name: 'Afghanistan',
    name_fr: "l'Afghanistan",
    alpha2: 'af',
    alpha3: 'afg',
  },
  {
    id: 8,
    name: 'Albania',
    name_fr: "l'Albanie",
    alpha2: 'al',
    alpha3: 'alb',
  },
  {
    id: 12,
    name: 'Algeria',
    name_fr: "l'Algérie",
    alpha2: 'dz',
    alpha3: 'dza',
  },
  {
    id: 20,
    name: 'Andorra',
    name_fr: "l'Andorre",
    alpha2: 'ad',
    alpha3: 'and',
  },
  {
    id: 24,
    name: 'Angola',
    name_fr: "l'Angola",
    alpha2: 'ao',
    alpha3: 'ago',
  },
  {
    id: 28,
    name: 'Antigua and Barbuda',
    name_fr: "l'Antigua-et-Barbuda",
    alpha2: 'ag',
    alpha3: 'atg',
  },
  {
    id: 32,
    name: 'Argentina',
    name_fr: "l'Argentine",
    alpha2: 'ar',
    alpha3: 'arg',
  },
  {
    id: 51,
    name: 'Armenia',
    name_fr: "l'Arménie",
    alpha2: 'am',
    alpha3: 'arm',
  },
  {
    id: 36,
    name: 'Australia',
    name_fr: "l'Australie",
    alpha2: 'au',
    alpha3: 'aus',
  },
  {
    id: 40,
    name: 'Austria',
    name_fr: "l'Autriche",
    alpha2: 'at',
    alpha3: 'aut',
  },
  {
    id: 31,
    name: 'Azerbaijan',
    name_fr: "l'Azerbaïdjan",
    alpha2: 'az',
    alpha3: 'aze',
  },
  {
    id: 44,
    name: 'Bahamas',
    name_fr: 'les Bahamas',
    alpha2: 'bs',
    alpha3: 'bhs',
  },
  {
    id: 48,
    name: 'Bahrain',
    name_fr: 'le Bahreïn',
    alpha2: 'bh',
    alpha3: 'bhr',
  },
  {
    id: 50,
    name: 'Bangladesh',
    name_fr: 'le Bangladesh',
    alpha2: 'bd',
    alpha3: 'bgd',
  },
  {
    id: 52,
    name: 'Barbados',
    name_fr: 'la Barbade',
    alpha2: 'bb',
    alpha3: 'brb',
  },
  {
    id: 112,
    name: 'Belarus',
    name_fr: 'la Biélorussie',
    alpha2: 'by',
    alpha3: 'blr',
  },
  {
    id: 56,
    name: 'Belgium',
    name_fr: 'la Belgique',
    alpha2: 'be',
    alpha3: 'bel',
  },
  {
    id: 84,
    name: 'Belize',
    name_fr: 'le Belize',
    alpha2: 'bz',
    alpha3: 'blz',
  },
  {
    id: 204,
    name: 'Benin',
    name_fr: 'le Bénin',
    alpha2: 'bj',
    alpha3: 'ben',
  },
  {
    id: 64,
    name: 'Bhutan',
    name_fr: 'le Bhoutan',
    alpha2: 'bt',
    alpha3: 'btn',
  },
  {
    id: 68,
    name: 'Bolivia (Plurinational State of)',
    name_fr: 'la Bolivie',
    alpha2: 'bo',
    alpha3: 'bol',
  },
  {
    id: 70,
    name: 'Bosnia and Herzegovina',
    name_fr: 'la Bosnie-Herzégovine',
    alpha2: 'ba',
    alpha3: 'bih',
  },
  {
    id: 72,
    name: 'Botswana',
    name_fr: 'le Botswana',
    alpha2: 'bw',
    alpha3: 'bwa',
  },
  {
    id: 76,
    name: 'Brazil',
    name_fr: 'le Brésil',
    alpha2: 'br',
    alpha3: 'bra',
  },
  {
    id: 96,
    name: 'Brunei Darussalam',
    name_fr: 'le Brunéi',
    alpha2: 'bn',
    alpha3: 'brn',
  },
  {
    id: 100,
    name: 'Bulgaria',
    name_fr: 'la Bulgarie',
    alpha2: 'bg',
    alpha3: 'bgr',
  },
  {
    id: 854,
    name: 'Burkina Faso',
    name_fr: 'le Burkina',
    alpha2: 'bf',
    alpha3: 'bfa',
  },
  {
    id: 108,
    name: 'Burundi',
    name_fr: 'le Burundi',
    alpha2: 'bi',
    alpha3: 'bdi',
  },
  {
    id: 132,
    name: 'Cabo Verde',
    name_fr: 'le Cap-Vert',
    alpha2: 'cv',
    alpha3: 'cpv',
  },
  {
    id: 116,
    name: 'Cambodia',
    name_fr: 'le Cambodge',
    alpha2: 'kh',
    alpha3: 'khm',
  },
  {
    id: 120,
    name: 'Cameroon',
    name_fr: 'le Cameroun',
    alpha2: 'cm',
    alpha3: 'cmr',
  },
  {
    id: 124,
    name: 'Canada',
    name_fr: 'le Canada',
    alpha2: 'ca',
    alpha3: 'can',
  },
  {
    id: 140,
    name: 'Central African Republic',
    name_fr: 'la République centrafricaine',
    alpha2: 'cf',
    alpha3: 'caf',
  },
  {
    id: 148,
    name: 'Chad',
    name_fr: 'le Tchad',
    alpha2: 'td',
    alpha3: 'tcd',
  },
  {
    id: 152,
    name: 'Chile',
    name_fr: 'le Chili',
    alpha2: 'cl',
    alpha3: 'chl',
  },
  {
    id: 156,
    name: 'China',
    name_fr: 'la Chine',
    alpha2: 'cn',
    alpha3: 'chn',
  },
  {
    id: 170,
    name: 'Colombia',
    name_fr: 'la Colombie',
    alpha2: 'co',
    alpha3: 'col',
  },
  {
    id: 174,
    name: 'Comoros',
    name_fr: 'les Comores',
    alpha2: 'km',
    alpha3: 'com',
  },
  {
    id: 178,
    name: 'Congo',
    name_fr: 'le Congo',
    alpha2: 'cg',
    alpha3: 'cog',
  },
  {
    id: 180,
    name: 'Congo, Democratic Republic of the',
    name_fr: 'la république démocratique du Congo',
    alpha2: 'cd',
    alpha3: 'cod',
  },
  {
    id: 188,
    name: 'Costa Rica',
    name_fr: 'le Costa Rica',
    alpha2: 'cr',
    alpha3: 'cri',
  },
  {
    id: 384,
    name: "Côte d'Ivoire",
    name_fr: "la Côte d'Ivoire",
    alpha2: 'ci',
    alpha3: 'civ',
  },
  {
    id: 191,
    name: 'Croatia',
    name_fr: 'la Croatie',
    alpha2: 'hr',
    alpha3: 'hrv',
  },
  {
    id: 192,
    name: 'Cuba',
    name_fr: 'Cuba',
    alpha2: 'cu',
    alpha3: 'cub',
  },
  {
    id: 196,
    name: 'Cyprus',
    name_fr: 'Chypre',
    alpha2: 'cy',
    alpha3: 'cyp',
  },
  {
    id: 203,
    name: 'Czechia',
    name_fr: 'la République tchèque',
    alpha2: 'cz',
    alpha3: 'cze',
  },
  {
    id: 208,
    name: 'Denmark',
    name_fr: 'le Danemark',
    alpha2: 'dk',
    alpha3: 'dnk',
  },
  {
    id: 262,
    name: 'Djibouti',
    name_fr: 'le Djibouti',
    alpha2: 'dj',
    alpha3: 'dji',
  },
  {
    id: 212,
    name: 'Dominica',
    name_fr: 'la Dominique',
    alpha2: 'dm',
    alpha3: 'dma',
  },
  {
    id: 214,
    name: 'Dominican Republic',
    name_fr: 'la République dominicaine',
    alpha2: 'do',
    alpha3: 'dom',
  },
  {
    id: 218,
    name: 'Ecuador',
    name_fr: "l'Équateur",
    alpha2: 'ec',
    alpha3: 'ecu',
  },
  {
    id: 818,
    name: 'Egypt',
    name_fr: "l'Égypte",
    alpha2: 'eg',
    alpha3: 'egy',
  },
  {
    id: 222,
    name: 'El Salvador',
    name_fr: 'le Salvador',
    alpha2: 'sv',
    alpha3: 'slv',
  },
  {
    id: 226,
    name: 'Equatorial Guinea',
    name_fr: 'la Guinée équatoriale',
    alpha2: 'gq',
    alpha3: 'gnq',
  },
  {
    id: 232,
    name: 'Eritrea',
    name_fr: "l'Érythrée",
    alpha2: 'er',
    alpha3: 'eri',
  },
  {
    id: 233,
    name: 'Estonia',
    name_fr: "l'Estonie",
    alpha2: 'ee',
    alpha3: 'est',
  },
  {
    id: 748,
    name: 'Eswatini',
    name_fr: 'l’Eswatini',
    alpha2: 'sz',
    alpha3: 'swz',
  },
  {
    id: 231,
    name: 'Ethiopia',
    name_fr: "l'Éthiopie",
    alpha2: 'et',
    alpha3: 'eth',
  },
  {
    id: 242,
    name: 'Fiji',
    name_fr: 'les Fidji',
    alpha2: 'fj',
    alpha3: 'fji',
  },
  {
    id: 246,
    name: 'Finland',
    name_fr: 'la Finlande',
    alpha2: 'fi',
    alpha3: 'fin',
  },
  {
    id: 250,
    name: 'France',
    name_fr: 'la France',
    alpha2: 'fr',
    alpha3: 'fra',
  },
  {
    id: 266,
    name: 'Gabon',
    name_fr: 'le Gabon',
    alpha2: 'ga',
    alpha3: 'gab',
  },
  {
    id: 270,
    name: 'Gambia',
    name_fr: 'la Gambie',
    alpha2: 'gm',
    alpha3: 'gmb',
  },
  {
    id: 268,
    name: 'Georgia',
    name_fr: 'la Géorgie',
    alpha2: 'ge',
    alpha3: 'geo',
  },
  {
    id: 276,
    name: 'Germany',
    name_fr: "l'Allemagne",
    alpha2: 'de',
    alpha3: 'deu',
  },
  {
    id: 288,
    name: 'Ghana',
    name_fr: 'le Ghana',
    alpha2: 'gh',
    alpha3: 'gha',
  },
  {
    id: 300,
    name: 'Greece',
    name_fr: 'la Grèce',
    alpha2: 'gr',
    alpha3: 'grc',
  },
  {
    id: 308,
    name: 'Grenada',
    name_fr: 'la Grenade',
    alpha2: 'gd',
    alpha3: 'grd',
  },
  {
    id: 320,
    name: 'Guatemala',
    name_fr: 'le Guatemala',
    alpha2: 'gt',
    alpha3: 'gtm',
  },
  {
    id: 324,
    name: 'Guinea',
    name_fr: 'la Guinée',
    alpha2: 'gn',
    alpha3: 'gin',
  },
  {
    id: 624,
    name: 'Guinea-Bissau',
    name_fr: 'la Guinée-Bissao',
    alpha2: 'gw',
    alpha3: 'gnb',
  },
  {
    id: 328,
    name: 'Guyana',
    name_fr: 'la Guyana',
    alpha2: 'gy',
    alpha3: 'guy',
  },
  {
    id: 332,
    name: 'Haiti',
    name_fr: 'Haïti',
    alpha2: 'ht',
    alpha3: 'hti',
  },
  {
    id: 340,
    name: 'Honduras',
    name_fr: 'le Honduras',
    alpha2: 'hn',
    alpha3: 'hnd',
  },
  {
    id: 348,
    name: 'Hungary',
    name_fr: 'la Hongrie',
    alpha2: 'hu',
    alpha3: 'hun',
  },
  {
    id: 352,
    name: 'Iceland',
    name_fr: "l'Islande",
    alpha2: 'is',
    alpha3: 'isl',
  },
  {
    id: 356,
    name: 'India',
    name_fr: "l'Inde",
    alpha2: 'in',
    alpha3: 'ind',
  },
  {
    id: 360,
    name: 'Indonesia',
    name_fr: "l'Indonésie",
    alpha2: 'id',
    alpha3: 'idn',
  },
  {
    id: 364,
    name: 'Iran (Islamic Republic of)',
    name_fr: "l'Iran",
    alpha2: 'ir',
    alpha3: 'irn',
  },
  {
    id: 368,
    name: 'Iraq',
    name_fr: "l'Irak",
    alpha2: 'iq',
    alpha3: 'irq',
  },
  {
    id: 372,
    name: 'Ireland',
    name_fr: "l'Irlande",
    alpha2: 'ie',
    alpha3: 'irl',
  },
  {
    id: 376,
    name: 'Israel',
    name_fr: 'Israël',
    alpha2: 'il',
    alpha3: 'isr',
  },
  {
    id: 380,
    name: 'Italy',
    name_fr: "l'Italie",
    alpha2: 'it',
    alpha3: 'ita',
  },
  {
    id: 388,
    name: 'Jamaica',
    name_fr: 'la Jamaïque',
    alpha2: 'jm',
    alpha3: 'jam',
  },
  {
    id: 392,
    name: 'Japan',
    name_fr: 'le Japon',
    alpha2: 'jp',
    alpha3: 'jpn',
  },
  {
    id: 400,
    name: 'Jordan',
    name_fr: 'la Jordanie',
    alpha2: 'jo',
    alpha3: 'jor',
  },
  {
    id: 398,
    name: 'Kazakhstan',
    name_fr: 'le Kazakhstan',
    alpha2: 'kz',
    alpha3: 'kaz',
  },
  {
    id: 404,
    name: 'Kenya',
    name_fr: 'le Kenya',
    alpha2: 'ke',
    alpha3: 'ken',
  },
  {
    id: 296,
    name: 'Kiribati',
    name_fr: 'Kiribati',
    alpha2: 'ki',
    alpha3: 'kir',
  },
  {
    id: 408,
    name: "Korea (Democratic People's Republic of)",
    name_fr: 'la Corée du Nord',
    alpha2: 'kp',
    alpha3: 'prk',
  },
  {
    id: 410,
    name: 'Korea, Republic of',
    name_fr: 'la Corée du Sud',
    alpha2: 'kr',
    alpha3: 'kor',
  },
  {
    id: 414,
    name: 'Kuwait',
    name_fr: 'le Koweït',
    alpha2: 'kw',
    alpha3: 'kwt',
  },
  {
    id: 417,
    name: 'Kyrgyzstan',
    name_fr: 'le Kirghizstan',
    alpha2: 'kg',
    alpha3: 'kgz',
  },
  {
    id: 418,
    name: "Lao People's Democratic Republic",
    name_fr: 'le Laos',
    alpha2: 'la',
    alpha3: 'lao',
  },
  {
    id: 428,
    name: 'Latvia',
    name_fr: 'la Lettonie',
    alpha2: 'lv',
    alpha3: 'lva',
  },
  {
    id: 422,
    name: 'Lebanon',
    name_fr: 'le Liban',
    alpha2: 'lb',
    alpha3: 'lbn',
  },
  {
    id: 426,
    name: 'Lesotho',
    name_fr: 'le Lesotho',
    alpha2: 'ls',
    alpha3: 'lso',
  },
  {
    id: 430,
    name: 'Liberia',
    name_fr: 'le Libéria',
    alpha2: 'lr',
    alpha3: 'lbr',
  },
  {
    id: 434,
    name: 'Libya',
    name_fr: 'la Libye',
    alpha2: 'ly',
    alpha3: 'lby',
  },
  {
    id: 438,
    name: 'Liechtenstein',
    name_fr: 'le Liechtenstein',
    alpha2: 'li',
    alpha3: 'lie',
  },
  {
    id: 440,
    name: 'Lithuania',
    name_fr: 'la Lituanie',
    alpha2: 'lt',
    alpha3: 'ltu',
  },
  {
    id: 442,
    name: 'Luxembourg',
    name_fr: 'le Luxembourg',
    alpha2: 'lu',
    alpha3: 'lux',
  },
  {
    id: 450,
    name: 'Madagascar',
    name_fr: 'Madagascar',
    alpha2: 'mg',
    alpha3: 'mdg',
  },
  {
    id: 454,
    name: 'Malawi',
    name_fr: 'le Malawi',
    alpha2: 'mw',
    alpha3: 'mwi',
  },
  {
    id: 458,
    name: 'Malaysia',
    name_fr: 'la Malaisie',
    alpha2: 'my',
    alpha3: 'mys',
  },
  {
    id: 462,
    name: 'Maldives',
    name_fr: 'les Maldives',
    alpha2: 'mv',
    alpha3: 'mdv',
  },
  {
    id: 466,
    name: 'Mali',
    name_fr: 'le Mali',
    alpha2: 'ml',
    alpha3: 'mli',
  },
  {
    id: 470,
    name: 'Malta',
    name_fr: 'Malte',
    alpha2: 'mt',
    alpha3: 'mlt',
  },
  {
    id: 584,
    name: 'Marshall Islands',
    name_fr: 'les Îles Marshall',
    alpha2: 'mh',
    alpha3: 'mhl',
  },
  {
    id: 478,
    name: 'Mauritania',
    name_fr: 'la Mauritanie',
    alpha2: 'mr',
    alpha3: 'mrt',
  },
  {
    id: 480,
    name: 'Mauritius',
    name_fr: 'Île Maurice',
    alpha2: 'mu',
    alpha3: 'mus',
  },
  {
    id: 484,
    name: 'Mexico',
    name_fr: 'le Mexique',
    alpha2: 'mx',
    alpha3: 'mex',
  },
  {
    id: 583,
    name: 'Micronesia (Federated States of)',
    name_fr: 'la Micronésie',
    alpha2: 'fm',
    alpha3: 'fsm',
  },
  {
    id: 498,
    name: 'Moldova, Republic of',
    name_fr: 'la Moldavie',
    alpha2: 'md',
    alpha3: 'mda',
  },
  {
    id: 492,
    name: 'Monaco',
    name_fr: 'Monaco',
    alpha2: 'mc',
    alpha3: 'mco',
  },
  {
    id: 496,
    name: 'Mongolia',
    name_fr: 'la Mongolie',
    alpha2: 'mn',
    alpha3: 'mng',
  },
  {
    id: 499,
    name: 'Montenegro',
    name_fr: 'le Monténégro',
    alpha2: 'me',
    alpha3: 'mne',
  },
  {
    id: 504,
    name: 'Morocco',
    name_fr: 'le Maroc',
    alpha2: 'ma',
    alpha3: 'mar',
  },
  {
    id: 508,
    name: 'Mozambique',
    name_fr: 'le Mozambique',
    alpha2: 'mz',
    alpha3: 'moz',
  },
  {
    id: 104,
    name: 'Myanmar',
    name_fr: 'le Myanmar',
    alpha2: 'mm',
    alpha3: 'mmr',
  },
  {
    id: 516,
    name: 'Namibia',
    name_fr: 'la Namibie',
    alpha2: 'na',
    alpha3: 'nam',
  },
  {
    id: 520,
    name: 'Nauru',
    name_fr: 'la Nauru',
    alpha2: 'nr',
    alpha3: 'nru',
  },
  {
    id: 524,
    name: 'Nepal',
    name_fr: 'le Népal',
    alpha2: 'np',
    alpha3: 'npl',
  },
  {
    id: 528,
    name: 'Netherlands',
    name_fr: 'les Pays-Bas',
    alpha2: 'nl',
    alpha3: 'nld',
  },
  {
    id: 554,
    name: 'New Zealand',
    name_fr: 'New Zealand',
    alpha2: 'nz',
    alpha3: 'nzl',
  },
  {
    id: 558,
    name: 'Nicaragua',
    name_fr: 'Nicaragua',
    alpha2: 'ni',
    alpha3: 'nic',
  },
  {
    id: 562,
    name: 'Niger',
    name_fr: 'Niger',
    alpha2: 'ne',
    alpha3: 'ner',
  },
  {
    id: 566,
    name: 'Nigeria',
    name_fr: 'Nigeria',
    alpha2: 'ng',
    alpha3: 'nga',
  },
  {
    id: 807,
    name: 'North Macedonia',
    name_fr: 'la Macédoine',
    alpha2: 'mk',
    alpha3: 'mkd',
  },
  {
    id: 578,
    name: 'Norway',
    name_fr: 'la Norvège',
    alpha2: 'no',
    alpha3: 'nor',
  },
  {
    id: 512,
    name: 'Oman',
    name_fr: "l'Oman",
    alpha2: 'om',
    alpha3: 'omn',
  },
  {
    id: 586,
    name: 'Pakistan',
    name_fr: 'le Pakistan',
    alpha2: 'pk',
    alpha3: 'pak',
  },
  {
    id: 585,
    name: 'Palau',
    name_fr: 'les Palaos',
    alpha2: 'pw',
    alpha3: 'plw',
  },
  {
    id: 591,
    name: 'Panama',
    name_fr: 'le Panama',
    alpha2: 'pa',
    alpha3: 'pan',
  },
  {
    id: 598,
    name: 'Papua New Guinea',
    name_fr: 'la Papouasie-Nouvelle-Guinée',
    alpha2: 'pg',
    alpha3: 'png',
  },
  {
    id: 600,
    name: 'Paraguay',
    name_fr: 'le Paraguay',
    alpha2: 'py',
    alpha3: 'pry',
  },
  {
    id: 604,
    name: 'Peru',
    name_fr: 'le Pérou',
    alpha2: 'pe',
    alpha3: 'per',
  },
  {
    id: 608,
    name: 'Philippines',
    name_fr: 'les Philippines',
    alpha2: 'ph',
    alpha3: 'phl',
  },
  {
    id: 616,
    name: 'Poland',
    name_fr: 'la Pologne',
    alpha2: 'pl',
    alpha3: 'pol',
  },
  {
    id: 620,
    name: 'Portugal',
    name_fr: 'le Portugal',
    alpha2: 'pt',
    alpha3: 'prt',
  },
  {
    id: 634,
    name: 'Qatar',
    name_fr: 'le Qatar',
    alpha2: 'qa',
    alpha3: 'qat',
  },
  {
    id: 642,
    name: 'Romania',
    name_fr: 'la Roumanie',
    alpha2: 'ro',
    alpha3: 'rou',
  },
  {
    id: 643,
    name: 'Russian Federation',
    name_fr: 'la Russie',
    alpha2: 'ru',
    alpha3: 'rus',
  },
  {
    id: 646,
    name: 'Rwanda',
    name_fr: 'le Rwanda',
    alpha2: 'rw',
    alpha3: 'rwa',
  },
  {
    id: 659,
    name: 'Saint Kitts and Nevis',
    name_fr: 'Saint-Christophe-et-Niévès',
    alpha2: 'kn',
    alpha3: 'kna',
  },
  {
    id: 662,
    name: 'Saint Lucia',
    name_fr: 'Sainte-Lucie',
    alpha2: 'lc',
    alpha3: 'lca',
  },
  {
    id: 670,
    name: 'Saint Vincent and the Grenadines',
    name_fr: 'Saint-Vincent-et-les-Grenadines',
    alpha2: 'vc',
    alpha3: 'vct',
  },
  {
    id: 882,
    name: 'Samoa',
    name_fr: 'les Samoa occidentales',
    alpha2: 'ws',
    alpha3: 'wsm',
  },
  {
    id: 674,
    name: 'San Marino',
    name_fr: 'Saint-Marin',
    alpha2: 'sm',
    alpha3: 'smr',
  },
  {
    id: 678,
    name: 'Sao Tome and Principe',
    name_fr: 'Sao Tomé et Principe',
    alpha2: 'st',
    alpha3: 'stp',
  },
  {
    id: 682,
    name: 'Saudi Arabia',
    name_fr: "l'Arabie saoudite",
    alpha2: 'sa',
    alpha3: 'sau',
  },
  {
    id: 686,
    name: 'Senegal',
    name_fr: 'le Sénégal',
    alpha2: 'sn',
    alpha3: 'sen',
  },
  {
    id: 688,
    name: 'Serbia',
    name_fr: 'la Serbie',
    alpha2: 'rs',
    alpha3: 'srb',
  },
  {
    id: 690,
    name: 'Seychelles',
    name_fr: 'les Seychelles',
    alpha2: 'sc',
    alpha3: 'syc',
  },
  {
    id: 694,
    name: 'Sierra Leone',
    name_fr: 'la Sierra Leone',
    alpha2: 'sl',
    alpha3: 'sle',
  },
  {
    id: 702,
    name: 'Singapore',
    name_fr: 'Singapour',
    alpha2: 'sg',
    alpha3: 'sgp',
  },
  {
    id: 703,
    name: 'Slovakia',
    name_fr: 'la Slovaquie',
    alpha2: 'sk',
    alpha3: 'svk',
  },
  {
    id: 705,
    name: 'Slovenia',
    name_fr: 'la Slovénie',
    alpha2: 'si',
    alpha3: 'svn',
  },
  {
    id: 90,
    name: 'Solomon Islands',
    name_fr: 'les Îles Salomon',
    alpha2: 'sb',
    alpha3: 'slb',
  },
  {
    id: 706,
    name: 'Somalia',
    name_fr: 'la Somalie',
    alpha2: 'so',
    alpha3: 'som',
  },
  {
    id: 710,
    name: 'South Africa',
    name_fr: "l'Afrique du Sud",
    alpha2: 'za',
    alpha3: 'zaf',
  },
  {
    id: 728,
    name: 'South Sudan',
    name_fr: 'le Soudan du Sud',
    alpha2: 'ss',
    alpha3: 'ssd',
  },
  {
    id: 724,
    name: 'Spain',
    name_fr: "l'Espagne",
    alpha2: 'es',
    alpha3: 'esp',
  },
  {
    id: 144,
    name: 'Sri Lanka',
    name_fr: 'le Sri Lanka',
    alpha2: 'lk',
    alpha3: 'lka',
  },
  {
    id: 729,
    name: 'Sudan',
    name_fr: 'le Soudan',
    alpha2: 'sd',
    alpha3: 'sdn',
  },
  {
    id: 740,
    name: 'Suriname',
    name_fr: 'le Surinam',
    alpha2: 'sr',
    alpha3: 'sur',
  },
  {
    id: 752,
    name: 'Sweden',
    name_fr: 'la Suède',
    alpha2: 'se',
    alpha3: 'swe',
  },
  {
    id: 756,
    name: 'Switzerland',
    name_fr: 'la Suisse',
    alpha2: 'ch',
    alpha3: 'che',
  },
  {
    id: 760,
    name: 'Syrian Arab Republic',
    name_fr: 'la Syrie',
    alpha2: 'sy',
    alpha3: 'syr',
  },
  {
    id: 762,
    name: 'Tajikistan',
    name_fr: 'le Tadjikistan',
    alpha2: 'tj',
    alpha3: 'tjk',
  },
  {
    id: 834,
    name: 'Tanzania, United Republic of',
    name_fr: 'la Tanzanie',
    alpha2: 'tz',
    alpha3: 'tza',
  },
  {
    id: 764,
    name: 'Thailand',
    name_fr: 'la Thaïlande',
    alpha2: 'th',
    alpha3: 'tha',
  },
  {
    id: 626,
    name: 'Timor-Leste',
    name_fr: 'le Timor oriental',
    alpha2: 'tl',
    alpha3: 'tls',
  },
  {
    id: 768,
    name: 'Togo',
    name_fr: 'le Togo',
    alpha2: 'tg',
    alpha3: 'tgo',
  },
  {
    id: 776,
    name: 'Tonga',
    name_fr: 'les Tonga',
    alpha2: 'to',
    alpha3: 'ton',
  },
  {
    id: 780,
    name: 'Trinidad and Tobago',
    name_fr: 'la Trinité-et-Tobago',
    alpha2: 'tt',
    alpha3: 'tto',
  },
  {
    id: 788,
    name: 'Tunisia',
    name_fr: 'la Tunisie',
    alpha2: 'tn',
    alpha3: 'tun',
  },
  {
    id: 792,
    name: 'Turkey',
    name_fr: 'la Turquie',
    alpha2: 'tr',
    alpha3: 'tur',
  },
  {
    id: 795,
    name: 'Turkmenistan',
    name_fr: 'le Turkménistan',
    alpha2: 'tm',
    alpha3: 'tkm',
  },
  {
    id: 798,
    name: 'Tuvalu',
    name_fr: 'le Tuvalu',
    alpha2: 'tv',
    alpha3: 'tuv',
  },
  {
    id: 800,
    name: 'Uganda',
    name_fr: "l'Ouganda",
    alpha2: 'ug',
    alpha3: 'uga',
  },
  {
    id: 804,
    name: 'Ukraine',
    name_fr: "l'Ukraine",
    alpha2: 'ua',
    alpha3: 'ukr',
  },
  {
    id: 784,
    name: 'United Arab Emirates',
    name_fr: 'les Émirats arabes unis',
    alpha2: 'ae',
    alpha3: 'are',
  },
  {
    id: 826,
    name: 'United Kingdom of Great Britain and Northern Ireland',
    name_fr: "le Royaume-Uni & l'Irelande du Nord",
    alpha2: 'gb',
    alpha3: 'gbr',
  },
  {
    id: 840,
    name: 'United States of America',
    name_fr: 'les États-Unis',
    alpha2: 'us',
    alpha3: 'usa',
  },
  {
    id: 858,
    name: 'Uruguay',
    name_fr: "l'Uruguay",
    alpha2: 'uy',
    alpha3: 'ury',
  },
  {
    id: 860,
    name: 'Uzbekistan',
    name_fr: "l'Ouzbékistan",
    alpha2: 'uz',
    alpha3: 'uzb',
  },
  {
    id: 548,
    name: 'Vanuatu',
    name_fr: 'le Vanuatu',
    alpha2: 'vu',
    alpha3: 'vut',
  },
  {
    id: 862,
    name: 'Venezuela (Bolivarian Republic of)',
    name_fr: 'le Venezuela',
    alpha2: 've',
    alpha3: 'ven',
  },
  {
    id: 704,
    name: 'Viet Nam',
    name_fr: 'le Viêt-Nam',
    alpha2: 'vn',
    alpha3: 'vnm',
  },
  {
    id: 887,
    name: 'Yemen',
    name_fr: 'le Yémen',
    alpha2: 'ye',
    alpha3: 'yem',
  },
  {
    id: 894,
    name: 'Zambia',
    name_fr: 'la Zambie',
    alpha2: 'zm',
    alpha3: 'zmb',
  },
  {
    id: 716,
    name: 'Zimbabwe',
    name_fr: 'le Zimbabwe',
    alpha2: 'zw',
    alpha3: 'zwe',
  },
];

export const useCountryList = () => {
  const locale = useStore($locale);
  const devModeEnabled = useDevModeEnabled();

  const withDevland = [
    ...countries,
    ...(devModeEnabled
      ? [
          {
            id: 1337,
            name: 'Devland',
            name_fr: 'Devland',
            alpha2: 'dv',
            alpha3: 'dev',
          },
        ]
      : []),
  ];

  if (locale === 'fr') {
    return withDevland.map((country) => ({
      ...country,
      name: country.name_fr,
    }));
  }

  return withDevland;
};
