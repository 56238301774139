import React, { useState } from 'react';
import styled from 'styled-components';
import { TitlePortal } from 'components/TitlePortal';
import { Slider } from 'ui/organisms/Slider';
import { DashboardPage } from 'pages/Dashboard';
import { useI18nObject } from 'utils/use-i18n-object';
import { SelectPaymentMethod } from 'pages/Transfer/SelectPaymentMethod';
import { useCreateTransaction } from 'api/queries/mutations';
import { useForm } from 'utils/use-form';
import { AccountTypes, Transaction, TransferData } from 'models';
import { enterCVVModal } from 'modals/enterCVVModal';
import Notifications from 'components/Notifications';
import { IPQS } from 'api/ipqs';
import { getCurrencyByCountry } from 'data/currencies';
import { useHistory } from 'react-router-dom';
import { SelectAmount } from 'pages/Transfer/SelectAmount';
import { MakePayment } from 'pages/Transfer/MakePayment';
import { usePrefundAccount } from 'api/queries/queries';
import { useParams } from 'react-router';
import { AllowedToCreatePrefundAccountsGuard } from 'pages/PrefundAccounts/AllowedToCreatePrefundAccountsGuard';

enum Stages {
  PAYMENT_METHOD = 0,
  SELECT_AMOUNT = 2,
  MAKE_PAYMENT = 3,
}
export const AfiPrefund = () => {
  const LL = useI18nObject();
  const [movingBack, setMovingBack] = useState(false);
  const [stageId, setStageId] = useState<Stages>(Stages.PAYMENT_METHOD);
  const history = useHistory();
  const { prefundAccountId } = useParams<{ prefundAccountId: string }>();
  const { data: prefundAccount } = usePrefundAccount(prefundAccountId);
  const onPaymentOptionStageGoNext = () => {
    setMovingBack(false);
    setStageId(Stages.SELECT_AMOUNT);
  };

  const onAmountStageGoNext = () => {
    setMovingBack(true);
    setStageId(Stages.MAKE_PAYMENT);
  };

  const onAmountStageGoBack = () => {
    setMovingBack(true);
    setStageId(Stages.PAYMENT_METHOD);
  };

  const onMakePaymentStageGoBack = () => {
    setMovingBack(true);
    setStageId(Stages.SELECT_AMOUNT);
  };

  const createTransactionMutation = useCreateTransaction();

  const [MTNWarningVisible, setMTNWarningVisible] = useState(false);
  const showMTNWarning = () => {
    setMTNWarningVisible(true);
  };
  const hideMTNWarning = () => {
    setMTNWarningVisible(false);
  };

  const formik = useForm<TransferData>({
    initialValues: {
      senderAccount: null,
      recipientAccount: prefundAccount?.financial_account || null,
      amount: {
        sendAmount: 0,
        category: null,
        description: '',
      },
      attachments: [],
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: { [P in keyof TransferData]?: string } = {};
      if (!values.senderAccount) {
        errors.senderAccount = LL.REQUIRED();
      }
      if (!prefundAccount) {
        errors.recipientAccount = LL.REQUIRED();
      }
      return errors;
    },
    onSubmit: async (values) => {
      const { senderAccount, amount } = values;
      if (!senderAccount || !createTransactionBody) {
        return;
      }

      let transaction!: Transaction;
      if (
        transferData?.senderAccount?.account_type === AccountTypes.bank_card
      ) {
        const endpointUrl = '/api/transactions';
        const response = await enterCVVModal({
          endpointUrl,
          payload: createTransactionBody,
          financialAccount: transferData.senderAccount,
        });
        transaction = response as Transaction;
      } else {
        transaction = await createTransactionMutation.mutateAsync(
          createTransactionBody,
        );
      }

      if (transaction) {
        history.push(`/dashboard/transactions/${transaction.id}`);

        if (transferData?.senderAccount?.account_type === 'mobile_money') {
          if (transferData?.senderAccount?.mobile_money?.operator === 'MTN') {
            showMTNWarning();
          } else {
            Notifications.info(
              {
                message: LL.PLEASE_ENSURE(),
                big: true,
              },
              [{ text: 'OK', value: true }],
            );
          }
        }
        IPQS.pushData({
          billing_first_name:
            transaction?.sender_account?.user?.first_name || '',
          billing_last_name: transaction?.sender_account?.user?.last_name || '',
          billing_email: transaction?.sender_account?.user?.email || '',
          billing_phone: transaction?.sender_account?.user?.phone_number || '',
          transactionId: String(transaction?.id) || '',
          userId: String(transaction?.sender) || '',
        });
      } else {
        Notifications.alert(LL.SOMETHING_WENT_WRONG());
      }
    },
  });

  const transferData = {
    ...formik.values,
    recipientAccount: prefundAccount?.financial_account || null,
  };

  const country = prefundAccount?.financial_account.country;

  const currency = country ? getCurrencyByCountry(country) : undefined;

  const createTransactionBody =
    prefundAccount?.financial_account && transferData.senderAccount && currency
      ? {
          send_amount: transferData.amount.sendAmount,
          category: transferData.amount.category?.id,
          description: transferData.amount.description,
          recipient_account: prefundAccount.financial_account.id,
          sender_account: transferData.senderAccount.id,
          currency,
        }
      : undefined;
  return (
    <>
      <TitlePortal>{LL.PREFUND_USING_AFI()}</TitlePortal>
      <AllowedToCreatePrefundAccountsGuard>
        <TransferStyled>
          <Slider slideId={stageId} movingBack={movingBack}>
            <>
              {stageId === Stages.PAYMENT_METHOD && (
                <SelectPaymentMethod
                  data={transferData}
                  onSubmit={(v) => formik.setFieldValue('senderAccount', v)}
                  onGoNext={onPaymentOptionStageGoNext}
                  showPrefundAccounts={false}
                />
              )}
              {stageId === Stages.SELECT_AMOUNT && createTransactionBody && (
                <SelectAmount
                  data={transferData}
                  onSubmit={(v) => formik.setFieldValue('amount', v)}
                  onGoBack={onAmountStageGoBack}
                  onGoNext={onAmountStageGoNext}
                  createTransactionBody={createTransactionBody}
                />
              )}
              {stageId === Stages.MAKE_PAYMENT && createTransactionBody && (
                <MakePayment
                  onConfirm={formik.onSubmitAttempt}
                  onGoBack={onMakePaymentStageGoBack}
                  data={createTransactionBody}
                />
              )}
            </>
          </Slider>
        </TransferStyled>
      </AllowedToCreatePrefundAccountsGuard>
    </>
  );
};

const TransferStyled = styled(DashboardPage)`
  padding: 0;
`;
