import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { $isLoggedIn } from 'stores/derivatives/is-logged-in';

import { useIdleTimer } from 'react-idle-timer';
import { useLogout } from 'api/queries';
import { undoable } from 'utils/undoable';

export const IdlePopup = () => {
  const { logout } = useLogout();
  const [dismissToast, setDismissToast] = useState<(() => void) | null>(null);
  const onIdle = () => {
    const { dismiss } = undoable({
      onTimeout: logout,
      message: 'Are you still present?',
      timeout: 60 * 1000,
      buttonLabel: null,
      position: 'top-center',
    });
    setDismissToast(() => dismiss);
  };

  const onActive = () => {
    if (dismissToast) {
      dismissToast();
      setDismissToast(null);
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 5 * 1000 * 60,
    crossTab: true,
    onActive,
  });

  return null;
};

export const IdleHelper = () => {
  const isLoggedIn = useStore($isLoggedIn);
  return isLoggedIn ? <IdlePopup /> : null;
};
