import React, { useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useDownloadReceipt } from 'api/queries';
import { TransactionStatusId } from 'data';

import { DataLine, DataLineItem } from 'ui/organisms/summary';
import { LinkButton } from 'ui/atoms/link-button';
import { Icon } from 'ui/atoms/icon';
import { useI18nObject } from 'utils/use-i18n-object';
import { formatAmount } from 'utils/format-amount';
import { useHistory } from 'react-router-dom';

interface Props {
  transaction: any;
  currentBalance?: number;
}

const TransactionListItem: React.VFC<Props> = ({
  transaction,
  currentBalance,
}) => {
  const { status } = transaction;
  const LL = useI18nObject();

  const history = useHistory();

  const createdDate = moment(transaction.created).format('DD/MM/YYYY');

  const onDownloadReceipt = useDownloadReceipt(transaction.id);

  const isSucceeded = status === TransactionStatusId.succeeded;
  const isPending = status === TransactionStatusId.pending;
  const isFailed = status === TransactionStatusId.failed;

  const statusRepr = {
    true: LL.PENDING(),
    [String(isSucceeded)]: '',
    [String(status === TransactionStatusId.failed)]: LL.FAILED(),
  }.true;

  const isIncome =
    !!transaction.payment_request ||
    transaction.recipient_account.account_type === 'prefund_account';
  const isReversed = transaction.is_reversed;

  const amountRepr = isIncome
    ? formatAmount({
        amount: transaction.recipient_amount,
        currency: transaction.recipient_currency,
      })
    : formatAmount({
        amount: transaction.send_amount,
        currency: transaction.currency,
      });

  const onClick = useCallback(() => {
    history.push(`/dashboard/transactions/${transaction.id}`);
  }, []);

  return (
    <TransactionListItemStyled onClick={onClick}>
      <AmountDataLine>
        <DataLineItem>
          <Amount
            isFailed={isFailed}
            isIncome={isIncome}
            isSucceeded={isSucceeded}
            isReversal={isReversed}
          >
            <span>
              {
                {
                  true: '',
                  [String(isIncome)]: (
                    <Icon
                      name="PlusCircle"
                      size={12}
                      color="var(--income-color)"
                    />
                  ),
                  [String(!isIncome)]: (
                    <Icon
                      name="MinusCircle"
                      size={12}
                      color="var(--expense-color)"
                    />
                  ),
                  [String(isPending)]: (
                    <Icon
                      name="ClockTimeFourOutline"
                      size={12}
                      color="rgb(128, 128, 128)"
                    />
                  ),
                  [String(isFailed)]: (
                    <Icon
                      name="AlertCircle"
                      size={12}
                      color="rgb(128, 128, 128)"
                    />
                  ),
                  [String(isReversed)]: (
                    <Icon
                      name="ReversalIcon"
                      size={12}
                      color="rgb(128, 128, 128)"
                    />
                  ),
                }.true
              }{' '}
              {amountRepr}
            </span>
            {Boolean(parseFloat(transaction.fee)) && (
              <Fee>(fee {transaction.fee})</Fee>
            )}
            {currentBalance && <Fee>(Current Balance {currentBalance} )</Fee>}
            {isReversed && <Fee>({LL.REVERSED()})</Fee>}
          </Amount>
        </DataLineItem>
        <DataLineItem secondary>
          {createdDate} #{transaction.id}
        </DataLineItem>
      </AmountDataLine>
      <DataLine>
        <DataLineItem>
          {transaction?.recipient?.first_name}{' '}
          {transaction?.recipient?.last_name}
        </DataLineItem>
        <DataLineItem secondary>
          {isSucceeded ? (
            <LinkButton onClick={onDownloadReceipt}>
              {LL.DOWNLOAD_RECEIPT()}
            </LinkButton>
          ) : (
            <Status>{statusRepr}</Status>
          )}
        </DataLineItem>
      </DataLine>

      {/* {expanded && ( */}
      {/*  <IonGrid className={`${styles.expandedGrid} no-padding`}> */}
      {/*    <IonRow className={styles.expandedRow}> */}
      {/*      <IonCol className={styles.purposeCol}> */}
      {/*        <span className={styles.subTitle}>Purpose</span> */}
      {/*        <span className={styles.valueText}> */}
      {/*          {transaction.category?.name} */}
      {/*        </span> */}
      {/*      </IonCol> */}
      {/*    </IonRow> */}

      {/*    <IonRow> */}
      {/*      <IonCol> */}
      {/*        <span className={styles.subTitle}> */}
      {/*          Sending account ending in XX */}
      {/*          {transaction.sender_account.last_four_digits} */}
      {/*        </span> */}
      {/*      </IonCol> */}
      {/*    </IonRow> */}
      {/*    <IonRow className={styles.expandedRow}> */}
      {/*      <IonCol> */}
      {/*        <span className={styles.subTitle}> */}
      {/*          Receiving account ending in XX */}
      {/*          {transaction.recipient_account.last_four_digits} */}
      {/*        </span> */}
      {/*      </IonCol> */}
      {/*    </IonRow> */}
      {/*  </IonGrid> */}
      {/* )} */}
    </TransactionListItemStyled>
  );
};

const TransactionListItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px solid var(--text-color-v5);
  gap: 8px;
`;

const AmountDataLine = styled(DataLine)`
  align-items: flex-start;
`;

const Amount = styled.span<{
  isFailed: boolean;
  isSucceeded: boolean;
  isIncome: boolean;
  isReversal: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: ${(p) =>
    ({
      true: 'gray',
      [String(p.isSucceeded && p.isIncome)]: 'var(--income-color)',
      [String(p.isSucceeded && !p.isIncome)]: 'var(--expense-color)',
      [String(p.isReversal)]: 'gray',
    }.true)};
  };
`;

const Fee = styled.span`
  color: gray;
  font-size: 0.7rem;
`;

const Status = styled.span``;

export default TransactionListItem;
