import styled from 'styled-components';

export const EmptyBox = styled.button`
  background: var(--secondary-color-v3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;

  border-radius: 4px;
  color: white;
  font-size: 1.2rem;
`;
