import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { Page } from 'ui/templates/page';
import { Logo } from 'ui/atoms/logo';

import { useI18nObject } from 'utils/use-i18n-object';

import SiteFooter from 'components/SiteFooter/SiteFooter';
import { RevealableInput } from 'ui/organisms/revealable-input';
import { LanguageSwitcher } from 'components/LanguageSwitcher';
import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import { PasswordValidator } from 'components/PasswordValidator';
import { LinkButton } from 'ui/atoms/link-button';
import { openExternalLink } from 'utils/open-external-link';
import { Button } from 'ui/atoms/button';
import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { checkPassword } from 'utils/validatePassword';
import { useCreateAccount } from 'api/queries/mutations';
import type { InputChangeEventDetail } from '@ionic/core';
import Notifications from 'components/Notifications';

interface SignUpFormI {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  acceptedTermsAndConditions: boolean;
}

export const SignUp = () => {
  const LL = useI18nObject();
  const history = useHistory();

  const createAccountMutation = useCreateAccount();

  const formik = useFormik<SignUpFormI>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      acceptedTermsAndConditions: false,
    },
    validate: ({
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      acceptedTermsAndConditions,
    }) => {
      const errors: { [k: string]: string } = {};

      if (!firstName) {
        errors.firstName = LL.FIRST_NAME_REQUIRED();
      }
      if (!lastName) {
        errors.lastName = LL.LAST_NAME_REQUIRED();
      }
      if (!email) {
        errors.email = LL.LAST_NAME_REQUIRED();
      }
      if (!checkPassword(password)) {
        errors.password = LL.PASSWORD_SHOULD_MEET_MINIMUM();
      }
      if (password !== passwordConfirm) {
        errors.password = LL.PASSWORDS_DO_NOT_MATCH();
      }
      if (!acceptedTermsAndConditions) {
        errors.acceptedTermsAndConditions =
          LL.PLEASE_ACCEPT_THE_TERMS_AND_CONDITOINS();
      }
      return errors;
    },
    onSubmit: async ({
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      acceptedTermsAndConditions,
    }) => {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        temp_email: email,
        password,
        password_confirm: passwordConfirm,
        accepted_terms_and_conditions: acceptedTermsAndConditions,
        onboarding_data: {},
      };
      const user = await createAccountMutation.mutateAsync(data);
      if (user) {
        history.push('/onboarding');
      }
    },
  });

  const {
    firstName,
    lastName,
    email,
    password,
    passwordConfirm,
    acceptedTermsAndConditions,
  } = formik.values;

  const createIonChangeHandler = useCallback(
    (fieldName: keyof SignUpFormI) =>
      (e: CustomEvent<InputChangeEventDetail>) => {
        formik.setFieldValue(fieldName, e.detail.value);
      },
    [],
  );

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisible = () => {
    setPasswordVisible((v) => !v);
  };

  const onSubmitAttempt = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (createAccountMutation.isLoading) {
      return;
    }
    if (formik.isValid) {
      formik.submitForm();
    } else {
      Notifications.alert({
        title: Object.values(formik.errors).join('\n'),
        variant: 'warning',
      });
    }
  };

  return (
    <Page>
      <Logo />
      <ContentContainer>
        <SectionTitle>{LL.CREATE_YOUR_AFI_ACCOUNT()}</SectionTitle>
        <Form onSubmit={onSubmitAttempt}>
          <div className="input-field">
            <IonItem className="input-item">
              <IonLabel position="stacked" style={{ paddingBottom: '0.75rem' }}>
                {LL.PREFERED_LANGUAGE()}
              </IonLabel>
              <LanguageSwitcherStyled reload={false} />
            </IonItem>
          </div>
          <div>
            <IonGrid className="input-grid-group">
              <IonRow>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInput
                      value={firstName}
                      onIonChange={createIonChangeHandler('firstName')}
                      name="firstName"
                      className="input"
                      placeholder={LL.FIRST_NAME()}
                      required
                    />
                  </IonItem>
                </IonCol>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInput
                      value={lastName}
                      onIonChange={createIonChangeHandler('lastName')}
                      name="lastName"
                      className="input"
                      placeholder={LL.LAST_NAME()}
                      required
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  name="email"
                  type="email"
                  pattern=".+@.+\..+"
                  title="Please provide a valid e-mail address"
                  className="input"
                  placeholder={LL.EMAIL_ADDRESS()}
                  value={email}
                  onIonChange={createIonChangeHandler('email')}
                  autocomplete="off"
                  required
                />
              </IonItem>
            </div>
            <div className="input-field">
              <PasswordValidator password={password}>
                <RevealableInputStyled
                  value={password}
                  onInputChange={(v: string) => {
                    formik.setFieldValue('password', v);
                  }}
                  name="password"
                  className="input"
                  placeholder={LL.CHOOSE_PASSWORD()}
                  passwordVisible={passwordVisible}
                  togglePasswordVisible={togglePasswordVisible}
                  autocomplete="off"
                  required
                />
              </PasswordValidator>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={passwordConfirm}
                  onIonChange={createIonChangeHandler('passwordConfirm')}
                  name="passwordConfirm"
                  className="input"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder={LL.CONFIRM_PASSWORD()}
                  required
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item-checkbox" lines="none">
                <IonCheckbox
                  checked={acceptedTermsAndConditions}
                  onIonChange={(e) => {
                    formik.setFieldValue(
                      'acceptedTermsAndConditions',
                      e.detail.checked,
                    );
                  }}
                  name="acceptedTermsAndConditions"
                  className="input"
                />
                <IonLabel className="input-label">
                  {LL.PLEASE_CERTIFY_THAT_YOU_ARE_18_YEARS()}{' '}
                  <LinkButton
                    onClick={() => {
                      openExternalLink(
                        '/user-agreement',
                        'https://afipayments.com/user-agreement',
                      );
                    }}
                  >
                    {LL.USER_AGREEMENT()}
                  </LinkButton>{' '}
                  {LL.AND()}{' '}
                  <LinkButton
                    onClick={() => {
                      openExternalLink(
                        '/privacy',
                        'https://afipayments.com/privacy',
                      );
                    }}
                  >
                    {LL.PRIVACY_POLICY()}
                  </LinkButton>
                </IonLabel>
              </IonItem>
            </div>
            <Button>{LL.CREATE_ACCOUNT()}</Button>
          </div>
          <SecondaryActions>
            <Link to="/signin">{LL.SIGN_IN()}</Link>{' '}
            {LL.IF_YOU_ALREADY_HAVE_AN_ACCOUNT()}
          </SecondaryActions>
        </Form>
      </ContentContainer>

      <SiteFooter />
    </Page>
  );
};

const SectionTitle = styled.div`
  text-align: center;
  margin: 0.5rem 0 2rem 0;
  font-size: 1.75rem;
  color: var(--text-color-v2);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  padding: 8px;
  max-width: 540px;
  width: 100%;
`;

const Form = styled.form`
  padding: 16px;
  border-radius: 0.5rem;
  background: white;
`;

const RevealableInputStyled = styled(RevealableInput)`
  margin-bottom: 1rem;
  padding-left: 16px;
`;

const LanguageSwitcherStyled = styled(LanguageSwitcher)`
  padding-bottom: 0.75rem;
`;

const SecondaryActions = styled.div`
  text-align: center;
  background: white;
  padding: 32px 0 16px;
`;
