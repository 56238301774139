import moment from 'moment';
import { i18nObject } from 'i18n/i18n-util';
import { PaymentRequestStatus } from 'models';
import { $locale } from 'stores/locale';

export const throttle = (throttleFunction: Function, time: number) => {
  let tid: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(tid);
    tid = setTimeout(() => {
      throttleFunction.call(this, ...args);
    }, time);
  };
};

const splitAndMerge = (inputData: any) => {
  const cleanData = {} as any;

  Object.keys(inputData).forEach((key) => {
    if (key.search('__') > 0) {
      const [parent, child, ...children] = key.split('__');
      cleanData[parent] = cleanData[parent] || {};
      if (children.length > 0) {
        cleanData[parent][child] = {
          ...cleanData[parent][child],
          ...splitAndMerge({ [children.join('__')]: inputData[key] }),
        };
      } else cleanData[parent][child] = inputData[key];
    } else {
      cleanData[key] = inputData[key];
    }
  });

  return cleanData;
};

export const getFormData = (form: HTMLFormElement): any => {
  const fd = new FormData(form);
  const data = {} as { [key: string]: string };
  // eslint-disable-next-line no-restricted-syntax
  for (const [name, value] of fd.entries() as any) {
    data[name] = value;
  }
  return splitAndMerge(data);
};

export const formatDueDate = (date: string) => {
  try {
    const momentDate = moment(date);
    const year = momentDate.year();
    if (year >= 2099) {
      return 'no date';
    }
    return momentDate.format('DD/MM/YYYY');
  } catch (e) {
    return '';
  }
};

export const getPaymentRequestStatusMapping = (
  status: PaymentRequestStatus,
): string => {
  const LL = i18nObject($locale.getState());

  const mapping: { [status in PaymentRequestStatus]: string } = {
    draft: LL.PAYMENT_DRAFT_STATUS(),
    issued: LL.PAYMENT_ISSUED_STATUS(),
    paid: LL.PAYMENT_PAID_STATUS(),
    expired: LL.PAYMENT_EXPIRED_STATUS(),
    cancelled: LL.PAYMENT_CANCELLED_STATUS(),
    processing_payment: LL.PAYMENT_PROCESSING_PAYMENT_STATUS(),
  };
  return mapping[status];
};
