import { useQuery } from 'react-query';
import { useStore } from 'effector-react';

import { $authToken } from 'stores/auth';
import { $user, setUser } from 'stores/user';
import { $config } from 'stores/config';
import { $localStorageLoaded } from 'stores/localStorage';

import {
  configPlaceholderData,
  defaultBulkPR,
  defaultBusinessSettings,
  defaultInvoice,
  defaultPaymentRequest,
  defaultRecipient,
} from 'models/defaults';

import { downloadBlob } from 'utils/download-file';

import { createFetcher, useFetcher } from 'api/fetcher';

import type {
  BulkPaymentRequest,
  BulkPaymentRequestDetailed,
  BusinessSettings,
  Config,
  CountryCapability,
  CreatePaymentRequestValidationResponse,
  CreateTransactionRequestBody,
  FinancialAccount,
  Invoice,
  PaymentRequest,
  PaymentRequestCreationValidationBody,
  PayPaymentRequestRequestBody,
  PlaidTokenResponse,
  PrefundAccount,
  PrefundAccountTransactionHistory,
  Recipient,
  RecoveryOptionsResponse,
  SecurityQuestion,
  SmileIdentityTokenResponse,
  Transaction,
  TransactionCategory,
  UploadDocumentResponse,
  User,
} from 'models';

export const useConfig = () => {
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: 'config',
  });
  const config = { placeholderData: configPlaceholderData };

  const query = useQuery<Config>(key, fetcher, config);
  return { ...query };
};

export const usePullFinancialAccounts = () => {
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: 'financial-accounts',
    queryParams: { type: 'pull' },
  });
  const config = {
    placeholderData: [],
    staleTime: 10 * 1000,
  };

  const query = useQuery<FinancialAccount[]>(key, fetcher, config);
  return { ...query };
};

export const useRecipients = (queryParams?: { q?: string }) => {
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: 'recipients',
    queryParams,
  });
  const config = {
    placeholderData: [],
    select: (data: unknown) => {
      if (Array.isArray(data)) {
        return data.sort((a, b) => {
          const aFullName = `${a.first_name} ${a.last_name}`;
          const bFullName = `${b.first_name} ${b.last_name}`;
          if (aFullName < bFullName) {
            return -1;
          }
          if (aFullName > bFullName) {
            return 1;
          }
          return 0;
        });
      }
      return [];
    },
  };

  const query = useQuery<Recipient[]>(key, fetcher, config);
  return { ...query };
};

export const useRecipient = (recipientId: number) => {
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: `recipients/${recipientId}`,
  });
  const config = { placeholderData: defaultRecipient };

  const query = useQuery<Recipient>(key, fetcher, config);
  return { ...query };
};

export const useCategories = () => {
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: 'transactions/categories',
  });
  const config = { placeholderData: [] };

  const query = useQuery<TransactionCategory[]>(key, fetcher, config);
  return { ...query };
};

export const useCapabilities = () => {
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: 'transactions/capabilities',
  });
  const config = { placeholderData: [] };

  const query = useQuery<CountryCapability[]>(key, fetcher, config);
  return { ...query };
};

export const usePaymentRequests = (params?: {
  q?: string;
  start_date?: string;
  end_date?: string;
  includeBulk?: boolean;
}) => {
  const { includeBulk = false, ...queryParams } = params ?? {};
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: 'payment_requests',
    queryParams,
  });
  const config = {
    placeholderData: [],
    select: (data: unknown) => {
      let result = [];
      if (Array.isArray(data)) {
        result = data.map((pr) => ({ ...pr, type: 'payment_request' }));
      }
      if (!includeBulk) {
        result = result.filter((pr) => pr.bulk_payment_request === null);
      }
      return result;
    },
  };

  const query = useQuery<PaymentRequest[]>(key, fetcher, config);
  return { ...query };
};

export const useRetrievePaymentRequest = (id: string) => {
  const { fetcher, key } = useFetcher({
    endpoint: `payment_requests/${id}`,
    method: 'GET',
  });
  const localStorageLoaded = useStore($localStorageLoaded);
  const config = {
    placeholderData: defaultPaymentRequest,
    enabled: localStorageLoaded,
  };

  const query = useQuery<PaymentRequest>(key, fetcher, config);
  return { ...query };
};

export const useValidateCreatePaymentRequest = (
  body: PaymentRequestCreationValidationBody,
) => {
  const { fetcherParams, key } = useFetcher({
    endpoint: `payment_requests/validate_creation`,
    method: 'POST',
  });

  const fetcher = () =>
    createFetcher({
      ...fetcherParams,
      jsonBody: body,
    });

  const localStorageLoaded = useStore($localStorageLoaded);
  const config = {
    cacheTime: 0,
    retry: false,
    enabled: localStorageLoaded,
  };

  const query = useQuery<CreatePaymentRequestValidationResponse>(
    [key, body],
    fetcher,
    config,
  );
  return { ...query };
};
export const useValidatePayPaymentRequest = ({
  id,
  payerAccountData,
}: {
  id: string;
  payerAccountData?: PayPaymentRequestRequestBody;
}) => {
  const { fetcherParams, key } = useFetcher({
    endpoint: `payment_requests/${id}/validate`,
    method: 'POST',
  });

  const jsonBody = {
    payer_account_data: payerAccountData,
  };

  const fetcher = () =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });

  const localStorageLoaded = useStore($localStorageLoaded);
  const config = {
    cacheTime: 0,
    retry: false,
    enabled: !!(localStorageLoaded && payerAccountData),
  };

  const query = useQuery<Transaction>([...key, jsonBody], fetcher, config);
  return { ...query };
};

export const useBulkPaymentRequests = (params?: {
  q?: string;
  start_date?: string;
  end_date?: string;
}) => {
  const { fetcher, key } = useFetcher({
    endpoint: 'bulk-pr',
    method: 'GET',
    queryParams: {
      ...params,
    },
  });
  const config = {
    placeholderData: [],
    select: (data: unknown) => {
      if (Array.isArray(data)) {
        return data.map((pr) => ({ ...pr, type: 'bulk_pr' }));
      }
      return [];
    },
  };

  const query = useQuery<BulkPaymentRequest[]>(key, fetcher, config);
  return { ...query };
};

export const useRetrieveBulkPaymentRequest = (id: string) => {
  const { fetcher, key } = useFetcher({
    endpoint: `bulk-pr/${id}`,
    method: 'GET',
  });
  const config = {
    placeholderData: defaultBulkPR,
  };

  const query = useQuery<BulkPaymentRequestDetailed>(key, fetcher, config);
  return { ...query };
};

export const useRetrieveInvoice = (id: string) => {
  const { fetcher, key } = useFetcher({
    endpoint: `invoices/${id}`,
    method: 'GET',
  });
  const config = {
    placeholderData: defaultInvoice,
  };

  const query = useQuery<Invoice>(key, fetcher, config);
  return { ...query };
};

export const useListBusinessDocuments = () => {
  const { fetcher, key } = useFetcher({
    endpoint: 'auth/business-document',
    method: 'GET',
  });
  const config = { placeholderData: [] };

  const query = useQuery<UploadDocumentResponse[]>(key, fetcher, config);
  return { ...query };
};

export const useExportTransactions = (
  format: 'xlsx' | 'csv' | 'pdf',
  params?: { q?: string; start_date?: string; end_date?: string },
) => {
  const { fetcherParams } = useFetcher({
    endpoint: 'transactions-export',
    method: 'GET',
    queryParams: {
      format,
      ...params,
    },
    responseBodyType: 'blob',
  });
  const fetcher = async () => {
    const blob = await createFetcher(fetcherParams);
    downloadBlob({
      blob,
      filename: `export.${format}`,
    });
  };
  return fetcher;
};

export const useExportNetwork = (format: 'xlsx' | 'csv') => {
  const { fetcherParams } = useFetcher({
    endpoint: 'recipients-export',
    method: 'GET',
    queryParams: {
      format,
    },
    responseBodyType: 'blob',
  });
  const fetcher = async () => {
    const blob = await createFetcher(fetcherParams);
    downloadBlob({
      blob,
      filename: `network.${format}`,
    });
  };
  return fetcher;
};

export const useDownloadReceipt = (transactionId?: number) => {
  const { fetcherParams } = useFetcher({
    endpoint: `transactions/${transactionId}/receipt`,
    method: 'GET',
    responseBodyType: 'blob',
  });
  if (!transactionId) {
    return;
  }
  const fetcher = async () => {
    const blob = await createFetcher(fetcherParams);
    downloadBlob({
      blob,
      filename: 'receipt.pdf',
    });
  };

  return fetcher;
};

export const useRecoveryOptions = (email: string) => {
  const { fetcher, key } = useFetcher({
    endpoint: 'auth/account/recovery_options',
    method: 'GET',
    queryParams: {
      email,
    },
  });
  const config = {
    placeholderData: { secret_question: null },
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  };

  const query = useQuery<RecoveryOptionsResponse>(key, fetcher, config);
  return { ...query };
};

export const useSelf = () => {
  const authToken = useStore($authToken);
  const { fetcher, key } = useFetcher({
    endpoint: 'auth/profile/self',
    method: 'GET',
  });
  const localStorageLoaded = useStore($localStorageLoaded);
  const config = {
    placeholderData: null,
    enabled: localStorageLoaded && !!authToken,
    onSuccess: (user: User | null) => {
      if (user) {
        setUser(user);
      }
    },
  };

  const query = useQuery<User | null>(key, fetcher, config);
  return { ...query };
};

export const useSecurityQuestionChoices = () => {
  const { fetcher, key } = useFetcher({
    endpoint: 'auth/security-question',
    method: 'GET',
  });
  const config = { placeholderData: [] };

  const query = useQuery<SecurityQuestion[]>(key, fetcher, config);
  return { ...query };
};

export const useTransactionsList = (params?: {
  q?: string;
  start_date?: string;
  end_date?: string;
}) => {
  const user = useStore($user);
  const { fetcher, key } = useFetcher({
    endpoint: 'transactions',
    method: 'GET',
    queryParams: {
      ...params,
    },
  });
  const config = {
    placeholderData: [],
    enabled: !!user?.allowed_to_perform_transactions,
  };

  const query = useQuery<Transaction[]>(key, fetcher, config);
  return { ...query };
};

export const useRetrieveTransaction = (id: string) => {
  const { fetcher, key } = useFetcher({
    endpoint: `transactions/${id}`,
    method: 'GET',
  });
  const localStorageLoaded = useStore($localStorageLoaded);
  const config = {
    enabled: localStorageLoaded,
  };

  const query = useQuery<Transaction>(key, fetcher, config);
  return { ...query };
};

export const useValidateTransaction = (
  jsonBody?: CreateTransactionRequestBody,
) => {
  const { fetcherParams, key } = useFetcher({
    endpoint: 'transactions/validate',
    method: 'POST',
  });

  const fetcher = () =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });

  const localStorageLoaded = useStore($localStorageLoaded);
  const canProceed = Boolean(
    jsonBody?.currency &&
      jsonBody?.sender_account &&
      jsonBody?.recipient_account,
  );
  const config = {
    cacheTime: 0,
    retry: false,
    enabled: localStorageLoaded && canProceed,
  };

  const query = useQuery<Transaction>([...key, jsonBody], fetcher, config);
  return { ...query };
};

export const usePlaidToken = () => {
  const { fetcher, key } = useFetcher({
    endpoint: 'financial-accounts/link_token',
    method: 'GET',
  });
  const config = {
    placeholderData: {
      success: true,
      link_token: {
        authentication_token: '',
      },
    },
  };

  const query = useQuery<PlaidTokenResponse>(key, fetcher, config);
  return { ...query };
};

export const useSmileIdentityToken = () => {
  const { fetcher, key } = useFetcher({
    endpoint: 'auth/smile-identity/token',
    method: 'GET',
  });
  const config = {
    placeholderData: {
      success: true,
      token: '',
    },
  };

  const query = useQuery<SmileIdentityTokenResponse>(key, fetcher, config);
  return { ...query };
};

export const useDwollaAccountVerificationToken = () => {
  const { fetcher, key } = useFetcher({
    endpoint: 'financial-accounts/iav_token',
    method: 'GET',
  });
  const config = { placeholderData: [] };

  const query = useQuery<unknown[]>(key, fetcher, config);
  return { ...query };
};

export const useBusinessSettings = () => {
  const { fetcher, key } = useFetcher({
    endpoint: 'business-settings',
    method: 'GET',
  });
  const config = { placeholderData: defaultBusinessSettings };

  const query = useQuery<BusinessSettings>(key, fetcher, config);
  return { ...query };
};

export const useRefresh = () => {
  const { fetcherParams, key, authToken } = useFetcher({
    endpoint: 'auth/account/refresh',
    method: 'GET',
  });
  const REFRESH_TIMER_INTERVAL_MINUTES = 10;

  const config = {
    refetchInterval: REFRESH_TIMER_INTERVAL_MINUTES * 60 * 1000,
    enabled: !!authToken,
  };

  const fetcher = () =>
    createFetcher({
      ...fetcherParams,
    });

  const query = useQuery(key, fetcher, config);
  return { ...query };
};

export const useStatus = () => {
  const { statusApiEndpoint } = useStore($config);
  const fetcher = async () => {
    try {
      const response = await fetch(statusApiEndpoint);
      return response.json();
    } catch (e) {
      return null;
    }
  };
  const config = {
    refetchInterval: 3 * 60 * 1000,
    retry: false,
  };
  const query = useQuery('status', fetcher, config);
  return { ...query };
};

interface BankAccountNameRequest {
  type: 'bank_account';
  country: string;
  bankCode: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
}

interface MobileMoneyNameRequest {
  type: 'mobile_money';
  country: string;
  mobileMoneyNetwork: string;
  mobileNumber: string;
}

type NameRequest = BankAccountNameRequest | MobileMoneyNameRequest;

interface AccountNameResponse {
  found: boolean;
  message: string;
  registeredName: string;
}

export const useAccountName = (params?: NameRequest) => {
  const { fetcher, key } = useFetcher({
    endpoint: 'transactions/account_name',
    queryParams: { ...params },
    method: 'GET',
    // {
    //   type: 'bank_account',
    //   country: 'GH',
    //   bankCode: '',
    //   bankName: 'STANBIC BANK',
    //   bankAccountName: 'Kafui Akpalu',
    //   bankAccountNumber: '9040004611572',
    // },
    // queryParams: {
    //   type: 'mobile_money',
    //   country: 'GH',
    //   mobileMoneyNetwork: 'MTN',
    //   mobileNumber: '233541262480',
    // },
  });
  const config = {
    placeholderData: { found: false, message: '', registeredName: '' },
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  };

  const query = useQuery<AccountNameResponse>(key, fetcher, config);
  return { ...query };
};

export const usePrefundAccounts = () => {
  const { fetcher, key } = useFetcher({
    endpoint: 'prefund-accounts',
    method: 'GET',
  });
  const query = useQuery<PrefundAccount[]>(key, fetcher);
  return { ...query };
};

export const usePrefundAccount = (accountId: string) => {
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: `prefund-accounts/${accountId}`,
  });
  const config = {};

  const query = useQuery<PrefundAccount>(key, fetcher, config);
  return { ...query };
};

export const usePrefundAccountTransactions = (
  accountId: string,
  params?: {
    start_date?: string;
    end_date?: string;
  },
) => {
  const { fetcher, key } = useFetcher({
    method: 'GET',
    endpoint: `prefund-accounts/${accountId}/transactions`,
    queryParams: { ...params },
  });
  const config = {};

  const query = useQuery<PrefundAccountTransactionHistory[]>(
    key,
    fetcher,
    config,
  );
  return { ...query };
};

export const useExportPrefundAccountTransactions = ({
  format,
  accountId,
  params,
}: {
  format: 'xlsx' | 'csv' | 'pdf';
  accountId: string;
  params?: { q?: string; start_date?: string; end_date?: string };
}) => {
  const { fetcherParams } = useFetcher({
    endpoint: `prefund-transactions-export`,
    method: 'GET',
    queryParams: {
      format,
      prefund_account: accountId,
      ...params,
    },
    responseBodyType: 'blob',
  });
  const fetcher = async () => {
    const blob = await createFetcher(fetcherParams);
    downloadBlob({
      blob,
      filename: `export.${format}`,
    });
  };
  return fetcher;
};
