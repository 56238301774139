import React, { useMemo, useCallback } from 'react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { addFinancialAccountModal } from 'modals/addFinancialAccountModal';
import { verifyIdModal } from 'modals/verifyIdModal';

import { EmptyBox } from 'pages/Dashboard/EmptyBox';
import { useI18nObject } from 'utils/use-i18n-object';

import VerifiedIcon from '../../assets/icon/verified.svg';
import UnVerifiedIcon from '../../assets/icon/unverified.svg';

import '../Dashboard.css';
import styles from './Header.module.css';

interface Props {}

export const Header: React.VFC<Props> = () => {
  const history = useHistory();
  const user = useStore($user);
  const LL = useI18nObject();

  const identityVerified =
    user?.email_confirmed && user?.phone_number_confirmed;

  const subtitle =
    !identityVerified ||
    !user?.has_financial_account ||
    !user?.has_made_transaction
      ? LL.READY_TO_MAKE_YOUR_FIRST_TRANSACTION()
      : LL.MAKE_ANOTHER_TRANSACTION();

  const requiresAction =
    !identityVerified ||
    !user?.has_financial_account ||
    !user?.has_made_transaction;

  const promptAction = useMemo(() => {
    if (!identityVerified) {
      return LL.VERIFY_YOUR_IDENTITY();
    }
    if (!user?.has_financial_account) {
      return LL.ADD_A_FINANCIAL_ACCOUNT();
    }
    if (!user?.has_made_transaction) {
      return LL.SEND_REQUEST();
      // return 'Make your first transaction';
    }
    return LL.SEND_REQUEST();
    // return 'Make another transaction';
  }, [
    identityVerified,
    user?.has_financial_account,
    user?.has_made_transaction,
  ]);

  const onActionClick = useCallback(() => {
    if (!identityVerified) {
      verifyIdModal();
    } else if (!user?.has_financial_account) {
      addFinancialAccountModal('pull');
    } else {
      history.push('/dashboard/go');
    }
  }, [identityVerified, user?.has_financial_account]);

  return (
    <HeaderStyled>
      <Welcome>
        <div className={styles.title}>
          {LL.WELCOME()} {user?.first_name}!
        </div>
        {requiresAction && subtitle && (
          <div className={styles.subTitle}>{subtitle}</div>
        )}
      </Welcome>

      {requiresAction && (
        <ActionsList>
          <Action
            className={`${styles.actionContainer} ${
              !identityVerified && styles.actionContainerDisabled
            }`}
          >
            <img
              className={styles.actionImgStatus}
              src={identityVerified ? VerifiedIcon : UnVerifiedIcon}
            />
            <div className={styles.actionTitle}>
              {identityVerified
                ? LL.IDENTITY_VERIFIED()
                : LL.CONFIRM_YOUR_IDENTITY()}
            </div>
            <div className={styles.actionSubTitle}>
              {LL.VERIFY_YOUR_EMAIL_ADDRESS_AND_TELEPHONE_NUMBER()}
            </div>
          </Action>
          <Action
            className={`${styles.actionContainer} ${
              !user?.has_financial_account && styles.actionContainerDisabled
            }`}
          >
            <img
              className={styles.actionImgStatus}
              src={user?.has_financial_account ? VerifiedIcon : UnVerifiedIcon}
            />
            <div className={styles.actionTitle}>
              {LL.ADD_A_FINANCIAL_ACCOUNT()}
            </div>
            <div className={styles.actionSubTitle}>
              {LL.USE_YOUR_BANK_ACCOUNT_CARD_AND_MOBILE_MONEY_ACCOUNT()}
            </div>
          </Action>
          <Action
            className={`${styles.actionContainer} ${
              !user?.has_made_transaction && styles.actionContainerDisabled
            }`}
          >
            <img
              className={styles.actionImgStatus}
              src={user?.has_made_transaction ? VerifiedIcon : UnVerifiedIcon}
            />
            <div className={styles.actionTitle}>{LL.PAY_AND_GET_PAID()}</div>
            <div className={styles.actionSubTitle}>
              {LL.SEND_REQUEST_AND_RECEIVE_PAYMENTS()}
            </div>
          </Action>
        </ActionsList>
      )}
      <ActionHeader>
        <EmptyBox onClick={onActionClick}>{promptAction}</EmptyBox>
      </ActionHeader>
    </HeaderStyled>
  );
};

const HeaderStyled = styled.div`
  background: white;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const Welcome = styled.div``;
const ActionHeader = styled.div``;

const ActionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Action = styled.div`
  display: grid;
  gap: 8px 16px;
  grid-template: auto / auto 1fr;

  > div:last-of-type {
    grid-column: span 1 / -1;
  }
`;
