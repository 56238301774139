import { Capacitor } from '@capacitor/core';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'ui/atoms/button';
import { Icon } from 'ui/atoms/icon';
import { copyShare } from 'utils/copy-share';
import { useI18nObject } from 'utils/use-i18n-object';

interface Props {
  paymentLink: string;
}
export const PaymentLinkActionButton: React.FC<Props> = ({
  paymentLink,
}: Props) => {
  const LL = useI18nObject();

  const copyLink = useCallback(() => {
    copyShare({
      url: paymentLink,
      text: `Payment request`,
      title: `Payment request`,
      dialogTitle: 'Share the payment request',
    });
  }, [paymentLink]);

  return (
    <Button shrink onClick={copyLink}>
      <Icon
        name={LabelIcons[Capacitor.getPlatform()]}
        size={20}
        color="var(--light-background-color)"
      />

      <CopyShareLabel>
        {Capacitor.isNativePlatform() ? LL.SHARE_LINK() : LL.COPY_LINK()}
      </CopyShareLabel>
    </Button>
  );
};

const LabelIcons: { [key: string]: string } = {
  ios: 'ShareIOS',
  android: 'ShareAndroid',
  web: 'ClipboardCopy',
};

const CopyShareLabel = styled.span`
  padding-left: 10px;
`;
