import { createGlobalStyle } from 'styled-components';

export const COLORS = {
  primaryColorMain: '#fc0057' /* pink */,
  primaryColorV1: '#fce3e7',
  secondaryColorMain: '#0029fc' /* blue */,
  secondaryColorV3: '#6b9ef2' /* lightblue */,
  secondaryColorV2: '#deebfc' /* lighterblue */,
  tertiaryColorV1: '#00a495' /* green */,
  incomeColor: '#158915',
  expenseColor: 'hsl(339,100%,49%)',
  errorColor: '#ff3737',
  borderColor: '#d3d3d3',
  textColorV0: '#000000',
  textColorV1: '#202020',
  textColorV2: '#4d4d4d',
  textColorV3: '#7a7a7a',
  textColorV4: '#b2b2b2',
  textColorV5: '#e5e5e5',
  textColorV6: '#333333',
  textColorV7: '#9ca3af',
  textColorV8: '#6b7280',
  lightBackgroundColor: '#fafbfc',
  darkBackgroundColorV0: '#374151',
  successColorMain: '#00a495',
  successColorV1: '#d8eeee',
};

export const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color-main: ${COLORS.primaryColorMain}; 
    --primary-color-v1: ${COLORS.primaryColorV1};
  
  
    --secondary-color-main: ${COLORS.secondaryColorMain}; 
    --secondary-color-v3: ${COLORS.secondaryColorV3}; 
    --secondary-color-v2: ${COLORS.secondaryColorV2}; 
  
    --tertiary-color-v1: ${COLORS.tertiaryColorV1};
    
    --income-color: ${COLORS.incomeColor};
    --expense-color: ${COLORS.expenseColor};
      
    --error-color: ${COLORS.errorColor};
    --border-color: ${COLORS.borderColor};
  
    --text-color-v0: ${COLORS.textColorV0};
    --text-color-v1: ${COLORS.textColorV1};
    --text-color-v2: ${COLORS.textColorV2};
    --text-color-v3: ${COLORS.textColorV3};
    --text-color-v4: ${COLORS.textColorV4};
    --text-color-v5: ${COLORS.textColorV5};
  
    --text-color-v6: ${COLORS.textColorV6};
    --text-color-v7: ${COLORS.textColorV7};
    --text-color-v8: ${COLORS.textColorV8};
  
  
    --light-background-color: ${COLORS.lightBackgroundColor};
    --dark-background-color-v0: ${COLORS.darkBackgroundColorV0};
  
    --success-color-main: ${COLORS.successColorMain};
    --success-color-v1: ${COLORS.successColorV1};
    
    --primary-font-main: "Roboto", sans-serif;
    font-family: var(--primary-font-main);
    --ion-font-family: var(--primary-font-main);
    --ion-text-color: var(--text-color-v1);
    
    //--success-gradient: linear-gradient(
    //  90deg,
    //  #f00058,
    //  #f32063,
    //  #f6326e,
    //  #f93f79,
    //  #fb4b83,
    //  #fc568d,
    //  #fe6197,
    //  #ff6ba1
    //);
    
    --success-gradient: linear-gradient(
      90deg,
      hsl(118deg 46% 51%) 0%,
      hsl(117deg 47% 50%) 11%,
      hsl(116deg 49% 48%) 22%,
      hsl(116deg 52% 46%) 33%,
      hsl(115deg 56% 44%) 44%,
      hsl(114deg 60% 42%) 56%,
      hsl(113deg 64% 41%) 67%,
      hsl(112deg 70% 39%) 78%,
      hsl(110deg 78% 36%) 89%,
      hsl(108deg 91% 33%) 100%
    );
      
    --failed-gradient: linear-gradient(
      90deg,
      hsl(0deg 84% 34%) 0%,
      hsl(0deg 79% 34%) 11%,
      hsl(0deg 75% 35%) 22%,
      hsl(0deg 71% 35%) 33%,
      hsl(0deg 67% 35%) 44%,
      hsl(0deg 64% 35%) 56%,
      hsl(0deg 61% 35%) 67%,
      hsl(0deg 57% 35%) 78%,
      hsl(0deg 54% 35%) 89%,
      hsl(0deg 51% 35%) 100%
    );
    

    --pending-gradient: linear-gradient(
      90deg,
      hsl(52deg 55% 47%) 0%,
      hsl(53deg 56% 46%) 11%,
      hsl(53deg 58% 44%) 22%,
      hsl(54deg 60% 43%) 33%,
      hsl(55deg 62% 42%) 44%,
      hsl(56deg 65% 41%) 56%,
      hsl(56deg 68% 39%) 67%,
      hsl(57deg 71% 38%) 78%,
      hsl(58deg 75% 36%) 89%,
      hsl(58deg 80% 35%) 100%
    );
  }
  
  #root {
    height: 100%;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html,
  body {
    font-family: var(--primary-font-main);
    background: white !important;
    background-color: white !important;
  }
  
  button {
    outline: none;
    background: none;
    padding: 0;
  }
  
  a {
    color: var(--secondary-color-v3);
    text-decoration: none;
  }
  
  body {
    font-family: var(--primary-font-v2);
  }
  
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  p {
    margin: 0;
  }
  
  .modal-wrapper {
    border-radius: 0.6rem !important;
  }
  
  .modal-wrapper > .ion-page {
    overflow: auto;
  }
  
  .cursor {
    cursor: pointer;
  }
  
  .popover-content {
    min-width: 320px;
  }
  
  ion-split-pane {
    z-index: 200;
  }
  
  .verifyIdModal .modal-wrapper {
    max-height: 600px;
    max-width: 500px;
  }
  
  .addAccountModal .modal-wrapper {
    max-height: 100vh;
    max-width: 440px;
  }
  
  .addRecipientModal .modal-wrapper {
    max-height: 100vh;
    max-width: 440px;
  }
  
  .paymentRequestDetailsModal .modal-wrapper {
  
  }
  
  .personalInfoModal .modal-wrapper {

  }
  
  .businessInfoModal .modal-wrapper {

  }
  
  .addPhoneNumberModal .modal-wrapper {
    max-height: 500px;
    max-width: 440px;
  }
  
  .verifyCodeModal .modal-wrapper {
    max-height: 420px;
    max-width: 500px;
  }
  
  .changeEmailModal .modal-wrapper {
    max-height: 420px;
    max-width: 480px;
  }
  
  .cvvFormMdal .modal-wrapper {
    max-height: 380px;
    max-width: 480px;
  }
  
  .emailVerifiedMessage .modal-wrapper {
    max-height: 420px;
    max-width: 500px;
  }
  
  .waitlist-modal .modal-wrapper {
    min-width: 1040px;
    min-height: 725px;
  }
  
  .recoveryOptionsModal .modal-wrapper > .ion-page {
    position: static;
    contain: initial;
  }
  
  .recoveryOptionsModal .modal-wrapper {
    min-height: 200px;
    height: auto;
    max-width: 450px;
  }
  
  .updatePasswordModal .modal-wrapper {
    max-height: 550px;
    max-width: 475px;
  }
  
  .forgotPasswordModal .modal-wrapper {
    max-height: 500px;
    max-width: 475px;
  }
  
  
  #waitlist-signup {
    display: none;
  }
  
  #waitlist-signup > iframe {
    width: 100%;
    height: 720px;
    border: none;
  }
  
  .modal-wrapper #waitlist-signup {
    display: block;
  }
  
  ion-select-popover ion-label {
    white-space: pre-wrap;
  }
  
  ion-item > select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
  
    border: none;
    background: inherit;
    width: 100%;
    cursor: pointer;
    outline: none;
  }
  
  ion-item > select.empty,
  ion-item > select.empty > option[value=""] {
    color: gray;
  }
  
  ion-item > select.empty > option {
    color: initial;
  }
  
  .ion-page {
    overflow: auto;
  }
`;
