import addDays from 'date-fns/addDays';

const DUE_DATE_DEFAULT_EXPIRATION_DAYS = 30;
const DUE_DATE_MAXIMUM_DAYS = 90;

export const getDefaultDueDate = () =>
  addDays(new Date(), DUE_DATE_DEFAULT_EXPIRATION_DAYS);

export const getDueDateMaximum = () =>
  addDays(new Date(), DUE_DATE_MAXIMUM_DAYS);
