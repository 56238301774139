function _GuardComponent(Component: any, Guard: any) {
  return (props: any) => (
    <Guard {...props}>
      <Component {...props} />
    </Guard>
  );
}

export function GuardComponent(Component: any, Guards: any[]) {
  let c = Component;

  const rGuards = [...Guards].reverse();

  // eslint-disable-next-line no-restricted-syntax
  for (const guard of rGuards) {
    c = _GuardComponent(c, guard);
  }

  return c;
}
