import { createEffect, createEvent, createStore } from 'effector';
import type { AppState } from '@capacitor/app';

import { SplashScreen } from '@capacitor/splash-screen';

export const appStarted = createEvent();

export const appStateChangedFx = createEffect(
  async (state: AppState) => state.isActive,
);

export const $appActive = createStore<boolean>(true).on(
  appStateChangedFx.doneData,
  (_, active) => active,
);

export const hideSplashScreenFx = createEffect(async () => {
  await SplashScreen.hide();
});
