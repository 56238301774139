import React, { useCallback } from 'react';
import { IonItem } from '@ionic/react';
import styled from 'styled-components';
import { getCurrencyByCountry } from 'data/currencies';
import { useI18nObject } from 'utils/use-i18n-object';
import { Button } from 'ui/atoms/button';
import { useForm } from 'utils/use-form';
import { Country, CreatePreFundAccountRequest, Currency } from 'models';
import { InputChangeEventDetail } from '@ionic/core';
import { useCreatePrefundAccount } from 'api/queries/mutations';
import CountrySelect from 'components/CountrySelect/CountrySelect';
import { Input } from 'ui/atoms/input';
import { BaseModalTemplate } from 'ui/templates/modal-template';
import { Icon } from 'ui/atoms/icon';
import { useModal } from 'utils/use-modal';

interface Form {
  country: string;
  currency: Currency;
  accountName: string;
  description: string;
}

const supportedCountries: Country[] = [
  {
    id: 288,
    name: 'Ghana',
    name_fr: 'le Ghana',
    alpha2: 'gh',
    alpha3: 'gha',
  },
  {
    id: 768,
    name: 'Togo',
    name_fr: 'le Togo',
    alpha2: 'tg',
    alpha3: 'tgo',
  },
];
export const AddPrefundAccount = () => {
  const LL = useI18nObject();
  const createPrefundAccountMutation = useCreatePrefundAccount();

  const { closeModal } = useModal();

  const form = useForm<Form>({
    initialValues: {
      country: 'GH',
      currency: 'GHS',
      accountName: '',
      description: '',
    },

    validate: (values) => {
      const errors: { [P in keyof Form]?: string } = {};
    },
    onSubmit: async (values) => {
      const payload: CreatePreFundAccountRequest = {
        account_name: values.accountName,
        description: values.description,
        currency: getCurrencyByCountry(values.country),
        country: values.country,
      };
      const response = await createPrefundAccountMutation.mutateAsync(payload);
      closeModal();
    },
  });

  const createIonChangeHandler = useCallback(
    (fieldName: keyof Form) => (e: CustomEvent<InputChangeEventDetail>) => {
      form.setFieldValue(fieldName, e.detail.value);
    },
    [],
  );

  const { country, accountName, currency, description } = form.values;

  return (
    <>
      <BaseModalTemplate>
        <Header>
          <h2>{LL.NEW_PREFUND_BALANCE()}</h2>
          <CrossIcon name="Cross" onClick={closeModal} />
        </Header>

        <FormStyled onSubmit={form.onSubmitAttempt}>
          <FormBody>
            <div className="input-group">
              <InputItem>
                <div className="input-field">
                  <div className="input-group-title">{LL.NAME()}</div>
                  <>
                    <Input
                      name=""
                      className="input"
                      required
                      value={accountName}
                      onInputChange={(value) => {
                        form.setFieldValue('accountName', value);
                      }}
                    />
                  </>
                </div>
              </InputItem>

              <div className="input-field">
                <div className="input-group-title">{LL.COUNTRY()}</div>
                <IonItem className="input-item" lines="none">
                  <CountrySelect
                    value={country}
                    countries={supportedCountries}
                    onIonChange={createIonChangeHandler('country')}
                    required
                  />
                </IonItem>
              </div>
              <InputItem>
                <div className="input-field">
                  <div className="input-group-title">{LL.DESCRIPTION()}</div>

                  <Input
                    required
                    value={description}
                    onInputChange={(value) => {
                      form.setFieldValue('description', value);
                    }}
                  />
                </div>
              </InputItem>
            </div>
          </FormBody>
          <Button>{LL.SUBMIT()}</Button>
        </FormStyled>
      </BaseModalTemplate>
    </>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CrossIcon = styled(Icon)`
  width: 20px;
  margin: 8px 0 0 16px;
`;

const FormStyled = styled.form`
  padding: 0;
`;

const FormBody = styled.div`
  padding-top: 20px;
`;

const InputItem = styled.div`
  margin: 10px 0;
`;
