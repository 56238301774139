import React, { useState } from 'react';
import { Collapsible } from 'ui/atoms/collapsible';
import styled from 'styled-components';
import { DataLine, DataLineItem } from 'ui/organisms/summary';

export const Section = ({
  children,
  name,
  right,
}: {
  children: React.ReactNode;
  name?: React.ReactNode;
  right?: React.ReactNode;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => {
    setExpanded((v) => !v);
  };
  return (
    <SectionStyled>
      <SectionHeader>
        <DataLineItem isTitle>
          <button onClick={toggleExpanded}>{name}</button>
        </DataLineItem>
        <DataLineItem>{right}</DataLineItem>
      </SectionHeader>
      <Collapsible isExpanded={expanded}>{children}</Collapsible>
    </SectionStyled>
  );
};

const SectionHeader = styled(DataLine)`
  background-color: #bdbdbd;
`;

const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${Collapsible} {
    gap: 16px;
  }
`;
