import React from 'react';
import styled from 'styled-components';

export const LinkButton = styled.a<{ disabled?: boolean }>`
  display: inline-flex;
  gap: 8px;
  align-items: flex-end;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  pointer-events: ${(p) => p.disabled && 'none'};
  color: ${(p) =>
    p.disabled ? 'var(--text-color-v3)' : 'var(--secondary-color-v3)'};
  border-color: ${(p) =>
    p.disabled ? 'var(--text-color-v3)' : 'var(--secondary-color-v3)'};
`;
