import styled from 'styled-components';

export const Page = styled.div`
  background-color: var(--secondary-color-v2);
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
