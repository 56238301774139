import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'api/fetcher';
import { ModalWrapper } from './ModalWrapper';

export const presentIonModal = (
  Component: any,
  props: any,
  cssClass: string,
): [prompt: Function, dismiss: Function] => {
  const container = document.createElement('div');
  ReactDOM.render(
    <ModalWrapper>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Component {...props} />
        </Router>
      </QueryClientProvider>
    </ModalWrapper>,
    container,
  );

  const modalElement = document.createElement('ion-modal') as any;
  modalElement.component = container;
  modalElement.cssClass = cssClass;
  const present = (...args: any) => {
    const root = document.getElementById('legacy-modal')!;
    root.appendChild(modalElement);
    return modalElement.present(...args);
  };
  const dismiss = (...args: any) => {
    return modalElement.dismiss(...args);
  };
  return [present, dismiss];
};
