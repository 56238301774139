import React, { ComponentProps, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'utils/use-form';

import styled from 'styled-components';
import { IPQS } from 'api/ipqs';
import { useCreateTransaction } from 'api/queries/mutations';
import { useValidateTransaction } from 'api/queries/queries';

import { AccountTypes, Transaction, TransferData } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';
import { getCurrencyByCountry } from 'data/currencies';

import { Icon } from 'ui/atoms/icon';
import { NotificationPopup } from 'components/NotificationPopup';
import { Slider } from 'ui/organisms/Slider';
import { MTNWarning } from 'pages/Transfer/MTNWarning';
import { AllowedToPerformTransactionsGuard } from 'pages/Transfer/AllowedToPerformTransactionsGuard';
import { DashboardPage } from 'pages/Dashboard';
import { enterCVVModal } from 'modals/enterCVVModal';
import { TitlePortal } from 'components/TitlePortal';

import { SelectPaymentMethod } from 'pages/Transfer/SelectPaymentMethod';
import { SelectRecipient } from 'pages/Transfer/SelectRecipient';
import { SelectAmount } from 'pages/Transfer/SelectAmount';
import { MakePayment } from 'pages/Transfer/MakePayment';
import { UploadAttachments } from 'pages/Transfer/UploadAttachments';

import Notifications from '../../components/Notifications';
import '../Dashboard.css';

const Stages = {
  paymentMethod: 0,
  recipient: 1,
  amount: 2,
  uploadAttachments: 3,
  makePayment: 4,
} as const;

type StageKey = keyof typeof Stages;

interface AttachmentField {
  file: File;
  fileId: string;
}

interface AttachmentFields {
  [k: string]: AttachmentField;
}

export const Transfer: React.VFC = () => {
  const LL = useI18nObject();
  const history = useHistory();

  const [movingBack, setMovingBack] = useState(false);
  const [stageId, setStageId] = useState<(typeof Stages)[StageKey]>(
    Stages.paymentMethod,
  );

  // PaymentMethod Stage
  const onPaymentMethodStageGoNext = () => {
    setMovingBack(false);
    setStageId(Stages.recipient);
  };

  // Recipient Stage
  const onRecipientStageGoBack = () => {
    setMovingBack(true);
    setStageId(Stages.paymentMethod);
  };
  const onRecipientStageGoNext = () => {
    setMovingBack(false);
    setStageId(Stages.amount);
  };

  // Amount Stage
  const onAmountStageGoBack = () => {
    setMovingBack(true);
    setStageId(Stages.recipient);
  };
  const onAmountStageGoNext = () => {
    setMovingBack(false);
    if (shouldUploadAttachments) {
      setStageId(Stages.uploadAttachments);
    } else {
      setStageId(Stages.makePayment);
    }
  };

  // Upload attachments Stage
  const onUploadAttachmentsStageGoBack = () => {
    setMovingBack(true);
    setStageId(Stages.amount);
  };
  const onUploadAttachmentsStageGoNext = () => {
    setMovingBack(false);
    setStageId(Stages.makePayment);
  };

  // MakePayment Stage
  const onMakePaymentStageGoBack = () => {
    setMovingBack(true);
    if (shouldUploadAttachments) {
      setStageId(Stages.uploadAttachments);
    } else {
      setStageId(Stages.amount);
    }
  };

  const createTransactionMutation = useCreateTransaction();

  const [MTNWarningVisible, setMTNWarningVisible] = useState(false);
  const showMTNWarning = () => {
    setMTNWarningVisible(true);
  };
  const hideMTNWarning = () => {
    setMTNWarningVisible(false);
  };

  const [attachmentFields, setAttachmentFields] = useState<AttachmentFields>(
    {},
  );

  const formik = useForm<TransferData>({
    initialValues: {
      senderAccount: null,
      recipientAccount: null,
      amount: {
        sendAmount: 0,
        category: null,
        description: '',
      },
      attachments: [],
    },
    validate: (values) => {
      const errors: { [P in keyof TransferData]?: string } = {};
      if (!values.senderAccount) {
        errors.senderAccount = LL.REQUIRED();
      }
      if (!values.recipientAccount) {
        errors.recipientAccount = LL.REQUIRED();
      }
      return errors;
    },
    onSubmit: async (values) => {
      const { senderAccount, recipientAccount, amount } = values;
      if (!senderAccount || !recipientAccount || !createTransactionBody) {
        return;
      }

      let transaction!: Transaction;
      if (
        transferData?.senderAccount?.account_type === AccountTypes.bank_card
      ) {
        const endpointUrl = '/api/transactions';
        const response = await enterCVVModal({
          endpointUrl,
          payload: createTransactionBody,
          financialAccount: transferData.senderAccount,
        });
        transaction = response as Transaction;
      } else {
        transaction = await createTransactionMutation.mutateAsync(
          createTransactionBody,
        );
      }

      if (transaction) {
        history.push(`/dashboard/transactions/${transaction.id}`);

        if (transferData?.senderAccount?.account_type === 'mobile_money') {
          if (transferData?.senderAccount?.mobile_money?.operator === 'MTN') {
            showMTNWarning();
          } else {
            Notifications.info(
              {
                message: LL.PLEASE_ENSURE(),
                big: true,
              },
              [{ text: 'OK', value: true }],
            );
          }
        }
        IPQS.pushData({
          billing_first_name:
            transaction?.sender_account?.user?.first_name || '',
          billing_last_name: transaction?.sender_account?.user?.last_name || '',
          billing_email: transaction?.sender_account?.user?.email || '',
          billing_phone: transaction?.sender_account?.user?.phone_number || '',
          transactionId: String(transaction?.id) || '',
          userId: String(transaction?.sender) || '',
        });
      } else {
        Notifications.alert(LL.SOMETHING_WENT_WRONG());
      }
    },
  });
  const transferData = formik.values;

  const country = transferData.recipientAccount?.country;

  const currency = country ? getCurrencyByCountry(country) : undefined;

  const createTransactionBody =
    transferData.recipientAccount && transferData.senderAccount && currency
      ? {
          send_amount: transferData.amount.sendAmount,
          category: transferData.amount.category?.id,
          description: transferData.amount.description,
          recipient_account: transferData.recipientAccount.id,
          sender_account: transferData.senderAccount.id,
          attachments: transferData.attachments,
          currency,
        }
      : undefined;

  const { data: validationResult } = useValidateTransaction(
    createTransactionBody,
  );

  const updateAttachmentFields = (
    v: ComponentProps<typeof UploadAttachments>['initialValue'],
  ) => {
    setAttachmentFields(v);
    formik.setFieldValue(
      'attachments',
      Object.values(v).map((a) => a.fileId),
    );
  };

  const attachmentRequirements = validationResult?.attachment_requirements;

  const shouldUploadAttachments = !!attachmentRequirements?.fields.some(
    (a) => a.stage === 'before',
  );

  return (
    <>
      <TitlePortal>{LL.SEND()}</TitlePortal>
      <TransferStyled>
        {MTNWarningVisible && (
          <NotificationPopup
            iconComponent={
              <Icon
                name="AlertOctogram"
                size={72}
                color="var(--primary-color-main)"
              />
            }
            onClose={hideMTNWarning}
          >
            <MTNWarning />
          </NotificationPopup>
        )}
        <Slider slideId={stageId} movingBack={movingBack}>
          <AllowedToPerformTransactionsGuard>
            <>
              {stageId === Stages.paymentMethod && (
                <SelectPaymentMethod
                  data={transferData}
                  showPrefundAccounts
                  onSubmit={(v) => formik.setFieldValue('senderAccount', v)}
                  onGoNext={onPaymentMethodStageGoNext}
                />
              )}
              {stageId === Stages.recipient && (
                <SelectRecipient
                  data={transferData}
                  onSubmit={(v) => formik.setFieldValue('recipientAccount', v)}
                  onGoBack={onRecipientStageGoBack}
                  onGoNext={onRecipientStageGoNext}
                />
              )}
              {stageId === Stages.amount && createTransactionBody && (
                <SelectAmount
                  data={transferData}
                  onSubmit={(v) => formik.setFieldValue('amount', v)}
                  onGoBack={onAmountStageGoBack}
                  onGoNext={onAmountStageGoNext}
                  createTransactionBody={createTransactionBody}
                />
              )}
              {stageId === Stages.uploadAttachments &&
                attachmentRequirements && (
                  <UploadAttachments
                    initialValue={attachmentFields}
                    onSubmit={updateAttachmentFields}
                    onGoBack={onUploadAttachmentsStageGoBack}
                    onGoNext={onUploadAttachmentsStageGoNext}
                    attachmentRequirements={attachmentRequirements}
                  />
                )}
              {stageId === Stages.makePayment && createTransactionBody && (
                <MakePayment
                  onConfirm={formik.onSubmitAttempt}
                  onGoBack={onMakePaymentStageGoBack}
                  data={createTransactionBody}
                />
              )}
            </>
          </AllowedToPerformTransactionsGuard>
        </Slider>
      </TransferStyled>
    </>
  );
};

const TransferStyled = styled(DashboardPage)`
  padding: 0;
`;
