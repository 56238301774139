import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { usePaymentRequests, useBulkPaymentRequests } from 'api/queries';
import { PaymentRequestListItem } from 'components/PaymentRequestListItem';
import { PaymentRequestsHeader } from 'pages/TransactionHistory/PaymentRequestsHeader';
import { BulkPRListItem } from 'components/BulkPRListItem';
import { TitlePortal } from 'components/TitlePortal';
import { EmptyBox } from 'pages/Dashboard/EmptyBox';
import { useHistory } from 'react-router-dom';
import EmptyResults from 'pages/Dashboard/EmptyResults/EmptyResults';
import { useI18nObject } from 'utils/use-i18n-object';

import '../Dashboard.css';

export const PaymentRequests: React.FC = () => {
  const LL = useI18nObject();
  const history = useHistory();
  const [params, setParams] = useState<
    { q?: string; start_date?: string; end_date?: string } | undefined
  >({});

  const { data: paymentRequests, isLoading } = usePaymentRequests(params);
  const { data: bulkPaymentRequests } = useBulkPaymentRequests(params);
  const qParams =
    params?.q || params?.start_date || params?.end_date ? params : undefined;
  const isParamsSet = !!qParams;

  const onSearch = useCallback((params) => {
    setParams(params);
  }, []);

  const aggregatedList = useMemo(
    () =>
      [...(paymentRequests || []), ...(bulkPaymentRequests || [])].sort(
        (a, b) => {
          const aDate = new Date(a.created);
          const bDate = new Date(b.created);
          return bDate.getTime() - aDate.getTime();
        },
      ),
    [paymentRequests, bulkPaymentRequests],
  );

  const promptNewTransaction = useCallback(() => {
    history.push('/dashboard/go');
  }, []);

  return (
    <>
      <TitlePortal>{LL.PAYMENT_REQUESTS()}</TitlePortal>
      <PaymentRequestsHeader onSearch={onSearch} />
      <PaymentRequestsContainer>
        {aggregatedList?.map((o) => {
          const { type, ...rest } = o;
          return type === 'bulk_pr' ? (
            <BulkPRListItem key={rest.id} data={o} />
          ) : (
            <PaymentRequestListItem key={rest.id} data={o} />
          );
        })}
        {!isLoading && !isParamsSet && aggregatedList?.length === 0 && (
          <EmptyBox onClick={promptNewTransaction}>
            {LL.REQUEST_YOUR_FIRST_PAYMENT()}
          </EmptyBox>
        )}
        {!isLoading && isParamsSet && aggregatedList?.length === 0 && (
          <EmptyResults text="No requests found for this search" />
        )}
      </PaymentRequestsContainer>
    </>
  );
};

const PaymentRequestsContainer = styled.div`
  background: white;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 1rem 2rem 4rem;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  flex-basis: calc(100% - 120px);
`;
