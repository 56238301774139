import React from 'react';
import styled from 'styled-components';

import { Icon } from 'ui/atoms/icon';
import { useI18nObject } from 'utils/use-i18n-object';

interface Props {
  children: React.ReactNode;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  nextButtonLabel?: string;
  nextStepActive?: boolean;
}

export const PrevNextStepper: React.VFC<Props> = ({
  children,
  onNextClick,
  nextButtonLabel,
  onPrevClick,
  nextStepActive,
}) => {
  const LL = useI18nObject();

  return (
    <Container>
      <StageContainer>{children}</StageContainer>
      <Buttons>
        {onPrevClick && (
          <BackButton onClick={onPrevClick}>
            <div />
            <Icon name="ArrowUp" rotate={270} size={16} color="#707070" />
            <span>{LL.BACK()}</span>
          </BackButton>
        )}
        {onNextClick && (
          <NextButton
            onClick={onNextClick}
            active={nextStepActive}
            disabled={!nextStepActive}
          >
            <div />
            <Icon
              name="ArrowUp"
              rotate={90}
              size={16}
              color={nextStepActive ? '#ffffff' : '#707070'}
            />
            <span>{nextButtonLabel ?? LL.NEXT()}</span>
          </NextButton>
        )}
      </Buttons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-height: 100%;
  width: 100%;
  height: 100%;
`;

const StageContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const StepButton = styled.button<{ active?: boolean }>`
  background: ${(p) =>
    p.active ? 'var(--secondary-color-v3)' : 'transparent'};
  color: ${(p) => (p.active ? 'white' : 'var(--text-color-v3)')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 8px 0;
  gap: 4px;

  &:not(:first-child) {
    border-left: 1px solid var(--text-color-v5);
  }
`;

const BackButton = styled(StepButton)`
  grid-column: 1;
  border-radius: 0 0 0 0.5rem;
`;

const NextButton = styled(StepButton)`
  grid-column: -2 / -1;
  border-radius: 0 0 0.5rem 0;
`;
