export const UserAgreementEnglish = () => (
  <>
    <h1>USER AGREEMENT</h1>
    <p>
      This user agreement will be effective for all users as of June 12, 2023.
    </p>
    <p>
      This User Agreement (“Agreement”) is a contract between you and TecHustle,
      Inc. and its subsidiaries (“Company”). It applies to your use of AFi
      products and services, technologies, and/or functionalities offered by
      Company on its website, in the AFi app or through any other means (“AFi
      Services”). The AFi Services are provided to you subject to this agreement
      as well as the Company’s Privacy Policy (“Privacy Policy”). By opening and
      using an AFi account, you agree to comply with all the terms and
      conditions in this Agreement.
    </p>
    <h2>1. AFi Account Creation</h2>
    <p>
      In order to use AFi Services, you must be at least 18 years old, and have
      created an AFi account. To create an AFi account, you must download the
      AFi application and provide information about yourself, including your
      name, address, date of birth, telephone number, email address and other
      information or documentation that is needed to verify your identity. You
      agree to provide complete and accurate information and/or documentation,
      as well as update any information or documentation provided to keep your
      information complete and accurate at all times.
    </p>
    <p>
      Access to AFi services depends on verification of your identity. You
      hereby authorise the Company, directly or through third parties, to make
      any inquiries considered necessary to validate and/or authenticate your
      identity and account information. This may include asking you for further
      information and/or documentation about yourself, your account usage or
      identity; requiring you to take steps to confirm ownership of your
      wireless/cellular telephone number or financial instruments; and verifying
      your information against third party databases or through other sources.
      This process is for internal verification purposes.
    </p>
    <h2>2. AFi Services</h2>
    <p>
      The AFi services allow you to create an AFi account; to link your
      financial account(s); and to send and receive payments. Once initiated,
      transfer of funds cannot be reversed and are not refundable. The Company
      may, at its discretion, impose limits on the amount of transactions you
      conduct through the AFi Services. These limits may change from time to
      time at the Company's sole discretion. You may close your AFi account and
      terminate your relationship with Company at any time without cost, but you
      will remain liable for all obligations related to your AFi account even
      after the AFi account is closed. In certain cases, you may not close your
      AFi account, including to evade an investigation.
    </p>
    <p>
      The Company may review account and transaction activity at various times,
      including when you initiate a transfer of funds. This review checks for,
      among other things, suspicious or illegal activity, and whether your
      account activity and the activity of AFi users with whom you have
      transacted comply with this Agreement. Reviews may result in delayed,
      blocked, or cancelled transactions; funds being held by AFi; account
      suspension or termination; and/or funds being seized to comply with a
      court order, warrant or other regulatory and/or legal process. In
      connection with the Company’s review process, you may be required to
      provide additional information and/or documentation. The Company may limit
      access to your AFi account until verification is completed.
    </p>
    <h3>a. Applicable to AFi use in Ghana</h3>
    <p>
      Users in Ghana are prohibited from using AFi to make and/or accept
      payments relating to the following:
    </p>
    <ul>
      <li>illegal products and/or services;</li>
      <li>counterfeit goods (e.g. knock-offs, imitations, bootlegs);</li>
      <li>
        gambling (e.g. lotteries, internet gambling, contests, sweepstakes);
      </li>
      <li>
        online, mail, or telephone order pharmacies or pharmacy referral
        services;
      </li>
      <li>securities brokerage;</li>
      <li>negative response marketing;</li>
      <li>deceptive marketing;</li>
      <li>bail bonds; and</li>
      <li>bidding fee auctions.</li>
    </ul>

    <h2>3. Liability</h2>
    <p>
      You acknowledge that you are responsible for the accuracy of all fund
      transfers made using the AFi Services, including but not limited to the
      accuracy of the amount of funds to be transferred and the third party on
      the other end of the transaction. The Company shall not be responsible or
      in any way held liable due to inaccurate payments, including but not
      limited to receiving an incorrect amount of funds or sending funds to an
      incorrect recipient.
    </p>
    <p>
      You agree you will not use the AFi Services to violate any law, statute,
      ordinance or regulation relating to illegal and/or fraudulent activity.
    </p>
    <p>
      If the Company have reason to believe that you have engaged in and/or are
      engaging in any restricted activities, made excessive or unexplainable
      transactions, violated any parts of this Agreement or provided any
      incorrect information, the Company may take various actions to protect the
      Company, another AFi user, a third party, or you from reversals,
      chargebacks, claims, fees, fines, penalties and any other liability.
    </p>
    <p>
      Unless otherwise prohibited by law, you assume all responsibility for your
      use of the AFi Services and use them at your own risk.
    </p>
    <h2>4. Indemnity</h2>
    <p>
      In consideration of this Agreement to provide you with AFi Services, you
      agree to indemnify and hold the Company harmless from and against any and
      all claims, suits, judgments, executions, liabilities, losses, damages,
      costs, and expenses (including reasonable legal fees), in connection with
      or arising out of (i) your use of AFi Services, and/or (ii) your breach of
      this Agreement. Without limiting the generality of the foregoing
      indemnification obligation, you indemnify the Company for any losses
      caused due to any inaccurate, non- current or incomplete information that
      you provided to the Company and for any losses due to your failure to
      maintain the secrecy and confidentiality of all your IDs, passwords, or
      due to any unauthorised use of your ID and/or password.
    </p>
    <h2>5. Arbitration of Disputes</h2>
    <p>
      Any dispute or claim arising from or relating to this Agreement, AFi
      services, including disputes involving this provision about the resolution
      of disputes, will be settled by binding arbitration under the rules for
      the applicable jurisdiction.
    </p>
    <h3>a. Arbitration in Ghana</h3>
    <p>
      All disputes for transactions initiated by users resident in Ghana will be
      settled by binding arbitration under the Alternative Dispute Resolutions
      Act 2012 (Act 798) of Ghana. Each party will be responsible for costs
      relating to attorneys, experts and witnesses.
    </p>
  </>
);
