import React from 'react';
import type { ChangeEvent } from 'react';

import styled from 'styled-components';
import imageCompression from 'browser-image-compression';

interface Props {
  onFilePick: (file: File) => void;
  children: React.ReactNode;
  className?: string;
  accept?: string;
  maxSizeMB?: number;
}

const TRANSIENT_PROPS = ['onFilePick'];

export const UploadButton = styled.label
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<Props>(({ children, onFilePick, accept, maxSizeMB }) => {
    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        if (maxSizeMB) {
          const options = {
            maxSizeMB,
          };
          try {
            const compressedBlob = await imageCompression(file, options);
            console.log(
              'compressedFile instanceof Blob',
              compressedBlob instanceof Blob,
            );
            console.log(
              `compressedFile size ${compressedBlob.size / 1024 / 1024} MB`,
            );
            const compressedFile = new File([compressedBlob], file.name);
            onFilePick(compressedFile);
          } catch (error) {
            console.log(error);
            onFilePick(file);
          }
        } else {
          onFilePick(file);
        }
      }
    };

    const inputAccepts = accept || 'image/*';

    const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
      const element = event.target as HTMLInputElement;
      element.value = '';
    };

    return {
      children: (
        <>
          {children}
          <Input
            type="file"
            accept={inputAccepts}
            onChange={handleChange}
            onClick={onClick}
          />
        </>
      ),
    };
  })<Props>`
  display: block;
`;

const Input = styled.input`
  display: none;
`;
