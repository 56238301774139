import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { addFinancialAccountModal } from 'modals/addFinancialAccountModal';
import { Icon } from 'ui/atoms/icon';
import { AccountTypeI, Recipient } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';

import ArrowDownIcon from '../../assets/icon/arrow-down.svg';
import ArrowUpIcon from '../../assets/icon/arrow-up.svg';

const AccountTypeIcons: { [key in AccountTypeI]: string } = {
  bank_card: 'BankCard',
  bank_account: 'BankAccount',
  mobile_money: 'MobileMoney',
  prefund_account: 'PrefundAccount',
};

type FinancialAccount = Recipient['financial_accounts'][number] & {
  available: boolean;
};

type RecipientI = Omit<Recipient, 'financial_accounts'> & {
  financial_accounts: FinancialAccount[];
};

interface Props {
  recipient: RecipientI;
  isExpanded?: any;
  selectAccount?: any;
  onAccountSelect?: any;
  selectedAccountId?: any;
}

const RecipientListItem: React.VFC<Props> = ({
  recipient,
  isExpanded,
  selectAccount = false,
  onAccountSelect,
  selectedAccountId,
}) => {
  const LL = useI18nObject();

  const [expanded, setExpanded] = useState<boolean>(isExpanded);
  const onExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onSelectAccount = useCallback(
    (account) => () => {
      if (onAccountSelect) {
        onAccountSelect(account);
      }
    },
    [onAccountSelect],
  );

  const nameAbbr = (
    recipient
      ? `${recipient.first_name && recipient.first_name[0]}${
          recipient.last_name && recipient.last_name[0]
        }`
      : ''
  ).toUpperCase();

  const onAddAccountClick = async (recipientId: number) => {
    await addFinancialAccountModal('push', recipientId);
  };

  return (
    <RecipientListItemStyled>
      <RecipientBrief onClick={onExpandClick}>
        <AvatarIcon>{nameAbbr}</AvatarIcon>
        <Name>
          {recipient.first_name} {recipient.last_name}
        </Name>
        <img src={expanded ? ArrowUpIcon : ArrowDownIcon} />
      </RecipientBrief>
      {expanded && (
        <RecipientDetails>
          {!selectAccount && (
            <>
              <RecipientInformation>
                <li>
                  <DetailIcon size={28} name="Email" />
                  <span>{recipient.email}</span>
                </li>
                <li>
                  <DetailIcon size={28} name="PhoneNumber" />
                  <span>{recipient.phone_number}</span>
                </li>
              </RecipientInformation>
              <RecipientFinancialAccountsLabel>
                {LL.FINANCIAL_ACCOUNTS()}
              </RecipientFinancialAccountsLabel>
            </>
          )}
          <RecipientFinancialAccounts>
            {recipient.financial_accounts.map((account) => (
              <RecipientFinancialAccountWrapper key={account.id}>
                <RecipientFinancialAccount
                  selected={account.id === selectedAccountId}
                  selectAccount={selectAccount}
                  onClick={onSelectAccount(account)}
                  disabled={!account.available}
                >
                  <DetailIcon
                    size={28}
                    name={AccountTypeIcons[account.account_type]}
                  />
                  <DetailText>{account.account_name}</DetailText>
                </RecipientFinancialAccount>
              </RecipientFinancialAccountWrapper>
            ))}
          </RecipientFinancialAccounts>
          <AddAccountButton onClick={() => onAddAccountClick(recipient.id)}>
            + {LL.ADD_FINANCIAL_ACCOUNT()}
          </AddAccountButton>
        </RecipientDetails>
      )}
    </RecipientListItemStyled>
  );
};

const RecipientListItemStyled = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  :not(:last-of-type) {
    border-bottom: 1px solid var(--text-color-v5);
  }
`;

const AddAccountButton = styled.button`
  color: var(--secondary-color-v3);
  background: white;
  font-size: 1rem;
  max-width: 250px;
  padding: 1.5rem;
  text-align: left;
`;

const AvatarIcon = styled.div`
  font-weight: 500;
  color: var(--text-color-v1);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--secondary-color-v2);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DetailText = styled.div``;

const RecipientBrief = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  width: 100%;

  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color-v1);
`;

const Name = styled.div`
  flex: 1;
  text-align: left;
`;

const RecipientDetails = styled.div``;

const RecipientInformation = styled.ul`
  padding: 0 1rem;
  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }
`;

const RecipientFinancialAccountsLabel = styled.p`
  margin: 40px 0 16px;
  font-size: 14px;
  padding: 0 1rem;
`;

const RecipientFinancialAccounts = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecipientFinancialAccountWrapper = styled.div`
  :not(:last-child) {
    border-bottom: 1px solid var(--text-color-v5);
    margin-bottom: 8px;
    margin-right: auto;
  }
`;

const RecipientFinancialAccount = styled.button<{
  selected: boolean;
  selectAccount: boolean;
}>`
  line-height: 1.5;
  border: none;
  border-radius: 0;
  outline: none !important;
  background-color: transparent;
  padding: 1rem;
  appearance: none;
  cursor: default;

  display: flex;
  margin-bottom: 8px;
  align-items: center;
  color: var(--text-color-v1);
  font-size: 1.1rem;
  opacity: ${(p) => p.disabled && 0.4};

  cursor: ${(p) => p.selectAccount && 'pointer'};

  border-radius: ${(p) => p.selected && '0.5rem'};
  border: ${(p) => p.selected && '1px solid var(--secondary-color-v3)'};
`;

const DetailIcon = styled(Icon)`
  margin-right: 0.75rem;
`;

export default RecipientListItem;
