import { forward } from 'effector';
import { loadLocalStorageFx, saveLocalStorageFx } from 'stores/localStorage';
import { $devMode, $devModeEnabled, LOCAL_STORAGE_KEY } from './index';

$devModeEnabled.on(
  loadLocalStorageFx.doneData,
  (_, { [LOCAL_STORAGE_KEY]: value }) => value?.devModeEnabled,
);

forward({
  from: $devMode,
  to: saveLocalStorageFx.prepend((value) => ({
    value,
    localStorageKey: LOCAL_STORAGE_KEY,
  })),
});
