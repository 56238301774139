import { useCallback } from 'react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import styled from 'styled-components';

import { DashboardPage } from 'pages/Dashboard';
import { updateRecoveryOptionsModal } from 'modals/updateRecoveryOptionsModal';
import { updatePasswordModal } from 'modals/updatePasswordModal';
import { updateSecurityQuestionModal } from 'modals/updateSecurityQuestionModal';
import { twoFactorPhoneChangeModal } from 'modals/twoFactorPhoneChangeModal';
import { useI18nObject } from 'utils/use-i18n-object';

const Base: React.FC<any> = ({ onAdd }) => {
  const user = useStore($user);

  const LL = useI18nObject();

  const onManage = useCallback(
    (modal, ...args) =>
      () => {
        console.log(...args);
        modal(...args);
      },
    [user],
  );

  return (
    <SecurityStyled>
      <Card onClick={onManage(twoFactorPhoneChangeModal, user?.phone_number)}>
        <Title>{LL.TWO_FACTOR_AUTHENTICATION()}</Title>
        <Description>{LL.ACCOUNT_SAFE_SMS_CONFIRM()}</Description>

        <ButtonContainer>
          <Button>{LL.MANAGE()}</Button>
        </ButtonContainer>
      </Card>
      <Card onClick={onManage(updatePasswordModal)}>
        <Title>{LL.PASSWORD_CAP()}</Title>
        <Description>{LL.CHANGE_YOU_PASSWORD_REGULARY()}</Description>

        <ButtonContainer>
          <Button>{LL.MANAGE()}</Button>
        </ButtonContainer>
      </Card>
      <Card onClick={onManage(updateSecurityQuestionModal)}>
        <Title>{LL.SECURITY_QUESTION()}</Title>
        <Description>{LL.SET_UNIQUE_SECURITY_QUESTION()}</Description>

        <ButtonContainer>
          <Button>{LL.MANAGE()}</Button>
        </ButtonContainer>
      </Card>
      <Card onClick={onManage(updateRecoveryOptionsModal)}>
        <Title>{LL.RECOVERY_OPTIONS()}</Title>
        <Description>{LL.BACKUP_OPTIONS()}</Description>
        <ButtonContainer>
          <Button>{LL.MANAGE()}</Button>
        </ButtonContainer>
      </Card>
    </SecurityStyled>
  );
};

const SecurityStyled = styled(DashboardPage)`
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Card = styled.button`
  flex: 1 1 100%;
  background-color: white;
  border: 1px solid var(--text-color-v4);
  border-radius: 10px;
  padding: 20px;

  text-align: left;

  display: flex;
  flex-direction: column;
  gap: 16px;

  :hover,
  :active,
  :focus {
    color: white;
    background: var(--secondary-color-v3);
    cursor: pointer;
    border-color: var(--secondary-color-v3);
  }

  :hover *,
  :active *,
  :focus * {
    color: white;
  }

  @media (min-width: 560px) {
    flex: 1 1 40%;
  }
`;

const Title = styled.text`
  font-family: Roboto, serif;
  color: #333333;
  font-size: 20px;
  font-weight: 500;
`;

const Description = styled.p`
  font-family: Roboto, serif;
  color: #767676;
`;

const ButtonContainer = styled.div`
  align-self: end;
`;

const Button = styled.button`
  background-color: transparent;
  font-weight: 500;
`;

export default Base;
