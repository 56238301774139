import {
  $authToken,
  resetAuthToken,
  setAuthToken,
  setRefreshToken,
} from 'stores/auth';
import { $locale } from 'stores/locale';
import { spinnerDecrease, spinnerIncrease } from 'stores/spinner';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import Notifications from '../components/Notifications';

export function requestInterceptor(config: AxiosRequestConfig) {
  spinnerIncrease();
  const newConfig = { ...config };
  const authToken = $authToken.getState();
  const locale = $locale.getState();

  const { headers } = newConfig;
  const newHeaders = {
    ...headers,
    ...(authToken && { Authorization: `Bearer ${authToken}` }),
    ...(locale && { 'Accept-Language': locale }),
  };
  newConfig.headers = newHeaders;
  return newConfig;
}

export function responseInterceptor(response: AxiosResponse) {
  spinnerDecrease();
  if (response.headers['set-auth-token']) {
    setAuthToken(response.headers['set-auth-token']);
    setRefreshToken(response.headers['set-refresh-token']);
    console.log(response.headers);
  }
  return response.data;
}

export function responseErrorInterceptor(error: any) {
  spinnerDecrease();
  const { response } = error;
  let errorMessage = '';
  if (response) {
    console.log(response);
    if (response.status === 401) {
      resetAuthToken();
      const currentURL = `${window.location.pathname}${window.location.search}`;
      if (currentURL !== '/signin')
        window.location.href = `/signin?redirect=${currentURL}`;
    } else if (typeof response.data === 'string') {
      errorMessage = `${response.status}: ${response.statusText}`;
    } else {
      errorMessage = getErrorMessage(response.data);
    }
  } else {
    errorMessage = error.message;
  }
  if (String(errorMessage) === 'Network Error') {
    Notifications.alert('Cannot connect to the AFI network');
  } else if (errorMessage) {
    Notifications.alert(errorMessage);
  }
  return Promise.reject(error);
}

export function getErrorMessage(error: any): string {
  if (
    typeof error === 'string' ||
    typeof error === 'boolean' ||
    typeof error === 'number'
  ) {
    return String(error);
  }
  if (typeof error === 'object' && Array.isArray(error)) {
    return error.map((e) => getErrorMessage(e)).join(',');
  }
  if (typeof error === 'object') {
    return Object.keys(error)
      .map((key) => `${getErrorMessage(error[key])}`)
      .join(',');
  }
  return String(error);
}
