import React from 'react';

import { IonMenuButton } from '@ionic/react';
import { Link } from 'react-router-dom';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';

import styled from 'styled-components';
import { StatusHelper } from 'utils/status-helper';
import AvatarIcon from '../../assets/icon/default-profile.svg';

interface Props {}

export const Header: React.VFC<Props> = () => {
  const user = useStore($user);
  return (
    <>
      <HeaderStyled>
        <IonMenuButton />
        <div id="title-portal" />
        <div className="account-right-menu">
          <Link className="account-profile-btn" to="/dashboard/settings">
            <img className="account-avatar-icon" src={AvatarIcon} />
            <div className="account-header-name">{user?.first_name}</div>
          </Link>
        </div>
      </HeaderStyled>
      <StatusHelper />
    </>
  );
};

const HeaderStyled = styled.header`
  padding: calc(1rem + env(safe-area-inset-top)) 2rem 1rem;
  display: flex;
  background: var(--light-background-color);
  align-items: center;

  > ion-menu-button {
    --color: var(--primary-color-main);
  }
`;
