import { useCallback, useRef, useState } from 'react';
import { IonItem } from '@ionic/react';
import { useI18nObject } from 'utils/use-i18n-object';

import type { FinancialAccount } from 'models';
import CloseIcon from '../../assets/icon/close.svg';

import { CVVForm } from '../vgs/CVVForm/CVVForm';

interface Props {
  onDone: (response: { [key: string]: any }) => void;
  onClose: () => void;
  isModal: boolean;
  endpointUrl: string;
  payload: { [key: string]: any };
  financialAccount: FinancialAccount;
}

const EnterCVV: React.FC<Props> = ({
  onDone,
  onClose,
  isModal,
  endpointUrl,
  payload,
  financialAccount,
}) => {
  const LL = useI18nObject();

  const cvvForm = useRef<any>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (isValid && !isSubmitting && cvvForm.current) {
        try {
          setIsSubmitting(true);
          const response = await cvvForm.current.submit({
            endpoint: endpointUrl,
            body: payload,
          });
          onDone(response);
        } finally {
          setIsSubmitting(false);
        }
      }
    },
    [onDone, cvvForm.current, isSubmitting],
  );

  const onValidChange = useCallback((valid: boolean) => {
    setIsValid(valid);
  }, []);

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {LL.ENTER_CVV()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <form className="form1" onSubmit={onSubmit}>
        <div className="title">
          {LL.ENTER_THE_3_DIGITS_AT_THE_BACK_OF_YOUR()} <br />
          {LL.CREDIT_DEBIT_CARD_ENDING_IN} {financialAccount?.last_four_digits}
        </div>
        <div className="form-container">
          <div className="input-group">
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <CVVForm ref={cvvForm} onValid={onValidChange} />
              </IonItem>
            </div>
          </div>
        </div>
        <button
          disabled={!isValid || isSubmitting}
          type="submit"
          className="btn submit-btn"
        >
          {LL.SUBMIT()}
        </button>
      </form>
    </div>
  );
};

export default EnterCVV;
