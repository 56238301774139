import React from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { usePrevious } from 'utils/use-previous';

const ANIMATION_DURATION = 300;

interface Props {
  children: React.ReactNode;
  slideId: number;
  movingBack?: boolean;
  className?: string;
}

export const Slider: React.VFC<Props> = ({
  className,
  children,
  slideId,
  movingBack,
}) => {
  const previousSlideId = usePrevious<number>(slideId, -1);
  const isMovingBack = movingBack ?? previousSlideId > slideId;
  return (
    <SliderContainer className={className}>
      <PreviewSlider $movingBack={isMovingBack}>
        <CSSTransition
          key={slideId}
          timeout={ANIMATION_DURATION}
          classNames="slide-animation"
        >
          <PreviewSliderItem>{children}</PreviewSliderItem>
        </CSSTransition>
      </PreviewSlider>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  position: relative;
  height: 100%;
`;

const PreviewSlider = styled(TransitionGroup)<{ $movingBack: boolean }>`
  display: block;
  position: relative;
  height: 100%;

  .slide-animation {
    &-enter {
      position: absolute;
      opacity: 0;
      ${(p) => (p.$movingBack ? 'left' : 'right')}: -250px;
      bottom: 0;
      transition: opacity 200ms ease-in-out 100ms, left 200ms ease-in-out 100ms,
        right 200ms ease-in-out 100ms;
      &-active {
        position: absolute;
        opacity: 1;
        ${(p) => (p.$movingBack ? 'left' : 'right')}: 0;
      }
    }
    &-exit {
      position: absolute;
      opacity: 1;
      ${(p) => (p.$movingBack ? 'right' : 'left')}: 0;
      bottom: 0;
      transition: opacity 100ms ease-in, left 100ms ease-in, right 100ms ease-in;
      &-active {
        opacity: 0;
        ${(p) => (p.$movingBack ? 'right' : 'left')}: -250px;
      }
    }
  }
`;

const PreviewSliderItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  //max-width: 100%;
  //max-height: 100%;
`;
