import React, { useState, useCallback } from 'react';
import { IonItem, IonInput } from '@ionic/react';

import { PasswordValidator } from 'components/PasswordValidator';
import { RevealableInput } from 'ui/organisms/revealable-input';
import { useFormik } from 'formik';
import { checkPassword } from 'utils/validatePassword';
import { InputChangeEventDetail } from '@ionic/core';
import styled from 'styled-components';
import { useI18nObject } from 'utils/use-i18n-object';

import CloseIcon from '../../assets/icon/close.svg';

import { AuthApi } from '../../api/http';
import Notifications from '../Notifications';

interface ResetPasswordFormI {
  password: string;
  passwordConfirm: string;
}

const ResetPasswordForm: React.FC<any> = ({
  onDone,
  isModal,
  title,
  onClose,
  token,
}) => {
  const LL = useI18nObject();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const togglePasswordVisible = () => {
    setPasswordVisible((v) => !v);
  };

  const formik = useFormik<ResetPasswordFormI>({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validate: ({ password, passwordConfirm }) => {
      const errors: { [k: string]: string } = {};

      if (!checkPassword(password)) {
        errors.password = LL.PASSWORD_SHOULD_MEET_MINIMUM();
      }
      if (password !== passwordConfirm) {
        errors.password = LL.PASSWORDS_DO_NOT_MATCH();
      }
      return errors;
    },
    onSubmit: async ({ password, passwordConfirm }) => {
      const data = {
        token,
        password,
      };

      try {
        setLoading(true);
        await AuthApi.resetPassword(data);
        await Notifications.alert(
          {
            title: LL.YOUR_PASSWORD_HAS_SUCCESSFULLY_BEEN_RESET(),
            backdropDismiss: true,
          },
          [{ text: 'OK', value: true }],
        );

        onDone(true);
      } catch (e) {
        setLoading(false);
      }
    },
  });

  const { password, passwordConfirm } = formik.values;

  const createIonChangeHandler = useCallback(
    (fieldName: keyof ResetPasswordFormI) =>
      (e: CustomEvent<InputChangeEventDetail>) => {
        formik.setFieldValue(fieldName, e.detail.value);
      },
    [],
  );

  const onSubmitAttempt = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formik.isValid) {
      formik.submitForm();
    } else {
      Notifications.alert({
        title: Object.values(formik.errors).join('\n'),
        variant: 'warning',
      });
    }
  };

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {title || LL.RESET_PASSWORD()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <form className="form1" onSubmit={onSubmitAttempt}>
        <div className="title">{LL.ENTER_YOUR_NEW_PASSWORD_BELOW()}</div>
        <div className="input-group">
          <div className="input-field">
            <PasswordValidator password={password}>
              <RevealableInputStyled
                value={password}
                onInputChange={(v: string) => {
                  formik.setFieldValue('password', v);
                }}
                name="password"
                required
                className="input"
                placeholder={LL.CHOOSE_PASSWORD()}
                passwordVisible={passwordVisible}
                togglePasswordVisible={togglePasswordVisible}
              />
            </PasswordValidator>
          </div>
          <div className="input-field">
            <IonItem className="input-item" lines="none">
              <IonInput
                value={passwordConfirm}
                onIonChange={createIonChangeHandler('passwordConfirm')}
                name="passwordConfirm"
                required
                className="input"
                type={passwordVisible ? 'text' : 'password'}
                placeholder={LL.CONFIRM_PASSWORD()}
              />
            </IonItem>
          </div>
        </div>
        <button type="submit" className="btn submit-btn" disabled={isLoading}>
          {LL.SUBMIT()}
        </button>
      </form>
    </div>
  );
};

const RevealableInputStyled = styled(RevealableInput)`
  margin-bottom: 1rem;
  padding-left: 16px;
`;

export default ResetPasswordForm;
