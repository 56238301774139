import styled from 'styled-components';
import React from 'react';

interface Props {
  children: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const HeaderLayout = styled.div.attrs<Props>(
  ({ children, left, right }) => ({
    children: (
      <>
        <Side>{left}</Side>
        <Main>{children}</Main>
        <Side>{right}</Side>
      </>
    ),
  }),
)<Props>`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  background-color: rgba(107, 242, 145, 0.45);
  justify-items: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
`;

const Main = styled.div``;

const Side = styled.div``;
