import { VerifySecurityCode } from 'components/VerifySecurityCode';
import { useStore } from 'effector-react';
import React, { useCallback } from 'react';
import { $user } from 'stores/user';
import styled from 'styled-components';
import { useI18nObject } from 'utils/use-i18n-object';
import { useLogout } from 'api/queries';

import { VerificationTypes } from '../../data';

export const VerifyAccountDeletion: React.FC = () => {
  const user = useStore($user);
  const { logout } = useLogout();

  const LL = useI18nObject();

  const onDone = useCallback(async () => {
    logout();
  }, []);
  return (
    <PageContainer>
      <div className="dashboard-page">
        <div className="form1">
          <div className="form-container">
            <div className="input-group">
              <div className="input-field">
                <VerifySecurityCode
                  onDone={onDone}
                  verificationType={VerificationTypes.accountDeletionEmail}
                  destination={user?.email ? user.email : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  margin: 4px;
`;
