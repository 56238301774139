import React, { useCallback } from 'react';
import { useModal } from 'utils/use-modal';
import styled from 'styled-components';
import { formatDueDate } from 'utils';
import { useI18nObject } from 'utils/use-i18n-object';
import {
  DataLine,
  DataLineHighlight,
  DataLineItem,
  DataMultiline,
} from 'ui/organisms/summary';
import { useParams } from 'react-router';
import { TitlePortal } from 'components/TitlePortal';
import { Link } from 'react-router-dom';
import { DashboardPage } from 'pages/Dashboard';
import { useRetrieveBulkPaymentRequest } from 'api/queries/queries';
import { Button } from 'ui/atoms/button';
import { PaymentRequestListItem } from 'components/PaymentRequestListItem';
import { InvoiceStatus } from 'models';
import { formatAmount } from 'utils/format-amount';

export interface Props {}

export const BulkPRDetails: React.VFC<Props> = () => {
  const LL = useI18nObject();

  const STATUS_MAPPING: { [key in InvoiceStatus]: string } = {
    draft: LL.INVOICE_STATUS_DRAFT(),
    paid: LL.INVOICE_STATUS_PAID(),
    unpaid: LL.INVOICE_STATUS_UNPAID(),
    pending: LL.INVOICE_STATUS_PENDING(),
  };

  const { bulkPrId } = useParams<{ bulkPrId: string }>();
  const { modalPush } = useModal();

  const { data: bulkPr } = useRetrieveBulkPaymentRequest(bulkPrId);

  const onPayClick = useCallback(() => {
    console.log({ invoice });
    if (invoice) {
      modalPush(`/invoice/${invoice.id}`);
    }
  }, [bulkPr?.invoice]);

  const status = bulkPr?.invoice?.status;

  const statusRepr = (status && STATUS_MAPPING[status]) ?? '';

  const displayPayButton = ['draft', 'unpaid'].includes(
    bulkPr?.invoice?.status ?? '',
  );

  if (!bulkPr) {
    return null;
  }

  const {
    id,
    total_requested_amount,
    description,
    created,
    invoice,
    payment_requests,
  } = bulkPr;

  const currency = invoice?.currency;

  return (
    <>
      <TitlePortal>
        <Link to="/dashboard/payment-requests">Bulk PR</Link> / {id}
      </TitlePortal>
      <Content>
        <Information>
          <DataLine>
            <DataLineItem isTitle>{LL.STATUS()}</DataLineItem>
            <span>{statusRepr}</span>
          </DataLine>
          <DataMultiline>
            <DataLineItem isTitle>{LL.DESCRIPTION()}</DataLineItem>
            <span>{description}</span>
          </DataMultiline>
          <DataLine>
            <DataLineItem isTitle>{LL.DUE_DATE()}</DataLineItem>
            <span>{created && formatDueDate(created)}</span>
          </DataLine>
          <DataLineHighlight>
            <DataLineItem isTitle>{LL.TOTAL_REQUESTED_AMOUNT()}</DataLineItem>
            <span>
              {formatAmount({ amount: total_requested_amount, currency })}
            </span>
          </DataLineHighlight>
        </Information>
        <PaymentRequestsList>
          {payment_requests.map((pr) => (
            <PaymentRequestListItem key={pr.id} data={pr} hideDescription />
          ))}
        </PaymentRequestsList>
        {displayPayButton && <Button onClick={onPayClick}>{LL.PAY()}</Button>}
      </Content>
    </>
  );
};

const Content = styled(DashboardPage)`
  gap: 16px;
`;

const Information = styled.div`
  padding: 24px;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const PaymentRequestsList = styled.div`
  padding: 24px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 1;
`;
