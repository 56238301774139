export const UserAgreementFrench = () => (
  <>
    <h1>ACCORD DE L'UTILISATEUR</h1>

    <p>
      Ce contrat d'utilisation entrera en vigueur pour tous les utilisateurs à
      compter du 12 juin 2023.
    </p>

    <p>
      Le présent Contrat d'utilisation ("Contrat") est un contrat entre vous et
      TecHustle, Inc. et ses filiales ("Société"). Elle s'applique à votre
      utilisation des produits et services AFi, des technologies et/ou des
      fonctionnalités proposées par la Société sur son site Web, dans
      l'application AFi ou par tout autre moyen (« Services AFi »). Les services
      AFi vous sont fournis sous réserve du présent accord ainsi que de la
      politique de confidentialité de la société ("Politique de
      confidentialité"). En ouvrant et en utilisant un compte AFi, vous acceptez
      de vous conformer à tous les termes et conditions du présent Accord.
    </p>

    <h2>1. Création de compte AFi</h2>
    <p>
      Pour utiliser les Services AFi, vous devez être âgé d'au moins 18 ans et
      avoir créé un compte AFi. Pour créer un compte AFi, vous devez télécharger
      l'application AFi et fournir des informations vous concernant, y compris
      votre nom, adresse, date de naissance, numéro de téléphone, adresse e-mail
      et autres informations ou documents nécessaires pour vérifier votre
      identité. Vous acceptez de fournir des informations et/ou de la
      documentation complètes et exactes, ainsi que de mettre à jour toute
      information ou documentation fournie pour que vos informations soient
      complètes et exactes à tout moment.
    </p>
    <p>
      L'accès aux services AFi dépend de la vérification de votre identité. Vous
      autorisez par la présente la Société, directement ou par l'intermédiaire
      de tiers, à effectuer toute enquête jugée nécessaire pour valider et/ou
      authentifier votre identité et les informations de votre compte. Cela peut
      inclure de vous demander des informations et/ou de la documentation
      supplémentaires sur vous-même, l'utilisation de votre compte ou votre
      identité ; vous obligeant à prendre des mesures pour confirmer la
      propriété de votre numéro de téléphone/cellulaire ou de vos instruments
      financiers ; et vérifier vos informations par rapport à des bases de
      données tierces ou via d'autres sources. Ce processus est à des fins de
      vérification interne.
    </p>
    <h2>2. Services AFI</h2>
    <p>
      Les services AFi vous permettent de créer un compte AFi ; pour lier votre
      (vos) compte(s) financier(s) ; et pour envoyer et recevoir des paiements.
      Une fois initié, le transfert de fonds ne peut être annulé et n'est pas
      remboursable.
    </p>
    <p>
      La Société peut, à sa discrétion, imposer des limites au montant des
      transactions que vous effectuez via les Services AFi. Ces limites peuvent
      changer de temps à autre à la seule discrétion de la Société.
    </p>
    <p>
      Vous pouvez fermer votre compte AFi et mettre fin à votre relation avec la
      Société à tout moment sans frais, mais vous resterez responsable de toutes
      les obligations liées à votre compte AFi même après la fermeture du compte
      AFi. Dans certains cas, vous ne pouvez pas clôturer votre compte AFi, y
      compris pour échapper à une enquête.
    </p>
    <p>
      La Société peut examiner l'activité du compte et des transactions à divers
      moments, y compris lorsque vous initiez un transfert de fonds. Cet examen
      vérifie, entre autres, les activités suspectes ou illégales, et si
      l'activité de votre compte et l'activité des utilisateurs AFi avec
      lesquels vous avez effectué des transactions sont conformes au présent
      Accord. Les avis peuvent entraîner des transactions retardées, bloquées ou
      annulées ; fonds détenus par AFi ; suspension ou résiliation du compte ;
      et/ou des fonds saisis pour se conformer à une ordonnance d'un tribunal,
      un mandat ou une autre procédure réglementaire et/ou légale. Dans le cadre
      du processus d'examen de la Société, il se peut que vous deviez fournir
      des informations et/ou des documents supplémentaires. La Société peut
      limiter l'accès à votre compte AFi jusqu'à ce que la vérification soit
      terminée.
    </p>
    <h3>a. Applicable à l'utilisation d’AFi au Togo</h3>
    <p>
      Il est interdit aux utilisateurs au Ghana d'utiliser AFi pour effectuer
      et/ou accepter des paiements relatifs aux éléments suivants :
    </p>
    <ul>
      <li>produits et/ou services illégaux ;</li>
      <li>
        produits de contrefaçon (par exemple contrefaçons, imitations,
        contrefaçons) ;
      </li>
      <li>
        jeux d'argent (par exemple, loteries, jeux d'argent sur Internet,
        concours, tirages au sort) ;
      </li>
      <li>
        les pharmacies en ligne, par courrier ou par téléphone ou les services
        d'orientation vers les pharmacies ;
      </li>
      <li>courtage en valeurs mobilières;</li>
      <li>marketing de réponse négative ;</li>
      <li>commercialisation trompeuse ;</li>
      <li>cautionnements; et</li>
      <li>enchères des frais d'enchères.</li>
    </ul>

    <h2>3. Responsabilité</h2>
    <p>
      Vous reconnaissez que vous êtes responsable de l'exactitude de tous les
      transferts de fonds effectués à l'aide des Services AFi, y compris, mais
      sans s'y limiter, de l'exactitude du montant des fonds à transférer et du
      tiers à l'autre bout de la transaction. La Société ne sera pas responsable
      ou tenue responsable de quelque manière que ce soit en raison de paiements
      inexacts, y compris, mais sans s'y limiter, la réception d'un montant de
      fonds incorrects ou l'envoi de fonds à un destinataire incorrect.
    </p>
    <p>
      Vous acceptez de ne pas utiliser les Services AFi pour enfreindre une loi,
      un statut, une ordonnance ou un règlement relatif à une activité illégale
      et/ou frauduleuse.
    </p>
    <p>
      Si la Société a des raisons de croire que vous vous êtes engagé dans et /
      ou que vous vous engagez dans des activités restreintes, avez effectué des
      transactions excessives ou inexplicables, violé des parties du présent
      Accord ou fourni des informations incorrectes, la Société peut prendre
      diverses mesures pour se protéger contre. , un autre utilisateur AFi, un
      tiers ou vous contre les annulations, rétrofacturations, réclamations,
      frais, amendes, pénalités et toute autre responsabilité.
    </p>
    <p>
      Sauf interdiction contraire de la loi, vous assumez l'entière
      responsabilité de votre utilisation des Services AFi et les utilisez à vos
      risques et périls.
    </p>
    <h2>4. Indemnité</h2>
    <p>
      En contrepartie de cet Accord pour vous fournir les Services AFi, vous
      acceptez d'indemniser et de dégager la Société de toute responsabilité en
      cas de réclamations, poursuites, jugements, exécutions, responsabilités,
      pertes, dommages, coûts et dépenses (y compris les frais juridiques
      raisonnables ), en relation avec ou découlant de (i) votre utilisation des
      Services AFi, et/ou (ii) votre violation du présent Contrat. Sans limiter
      la généralité de l'obligation d'indemnisation qui précède, vous indemnisez
      la Société pour toute perte causée en raison de toute information
      inexacte, non actuelle ou incomplète que vous avez fournie à la Société et
      pour toute perte due à votre incapacité à maintenir le secret et la
      confidentialité de tous vos identifiants, mots de passe, ou en raison de
      toute utilisation non autorisée de votre identifiant et/ou mot de passe.
    </p>
    <h2>5. Arbitrage des différends</h2>
    <p>
      Tout litige ou réclamation découlant de ou lié au présent Contrat, aux
      services AFi, y compris les litiges impliquant cette disposition relative
      à la résolution des litiges, sera réglé par arbitrage exécutoire
      conformément aux règles de la juridiction applicable.
    </p>

    <h3>a. Arbitrage au Ghana</h3>
    <p>
      Tous les litiges concernant des transactions initiées par des utilisateurs
      résidant au Ghana seront réglés par arbitrage exécutoire en vertu de la
      loi de 2012 sur le règlement alternatif des différends (loi 798) du Ghana.
      Chaque partie sera responsable des frais relatifs aux avocats, experts et
      témoins.
    </p>
  </>
);
