import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AllowedToPerformTransactionsGuard } from 'pages/Transfer/AllowedToPerformTransactionsGuard';
import { DashboardPage } from 'pages/Dashboard';
import { TitlePortal } from 'components/TitlePortal';
import { useI18nObject } from 'utils/use-i18n-object';

export const Go = () => {
  const LL = useI18nObject();

  return (
    <>
      <TitlePortal>{LL.SEND__REQUEST()}</TitlePortal>
      <DashboardPageStyled>
        <AllowedToPerformTransactionsGuard>
          <Content>
            <SendButton to="/dashboard/transfer">
              {LL.SEND_PAYMENT()}
            </SendButton>
            <Button to="/dashboard/request">{LL.REQUEST_A_PAYMENT()}</Button>
          </Content>
        </AllowedToPerformTransactionsGuard>
      </DashboardPageStyled>
    </>
  );
};

const DashboardPageStyled = styled(DashboardPage)`
  background-color: inherit;
  padding: 0;
`;

const Content = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Button = styled(Link)`
  padding: 0.75rem 1rem;
  color: var(--text-color-v1);
  border: 1px solid var(--text-color-v4);
  background: white;
  font-size: 0.95rem;
  border-radius: 0.3rem;
  text-align: center;

  min-width: 200px;
`;

const SendButton = styled(Button)`
  color: white;
  background-color: var(--primary-color-main);
  margin-top: 0;
`;
