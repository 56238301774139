import styled from 'styled-components';
import React from 'react';
import { InputTitle } from 'ui/atoms/input-title';

interface Params {
  title?: string;
  value?: string | number;
  onOptionSelect: (v: string) => void;
  options?: { id: string | number; name: string }[];
  emptyValueLabel?: string;
}

type Props = Omit<React.ComponentPropsWithoutRef<'select'>, 'onOptionSelect'> &
  Params;

const TRANSIENT_PROPS: (keyof Params | string)[] = [
  'onOptionSelect',
  'options',
];

export const Dropdown = styled.label
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<Props>(
    ({
      title,
      options,
      emptyValueLabel,
      onOptionSelect,
      ref,
      className,
      ...props
    }) => {
      const onValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onOptionSelect(e.target.value);
      };
      return {
        ref,
        children: (
          <>
            {title && <InputTitle>{title}</InputTitle>}
            <SelectWrapper>
              <select onChange={onValueChange} {...props}>
                <option value="">{emptyValueLabel || '---'}</option>
                {options?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </SelectWrapper>
          </>
        ),
      };
    },
  )<Props>`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const SelectWrapper = styled.div`
  position: relative;

  ::after {
    content: '▼';
    font-size: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    position: absolute;
  }

  > select {
    padding: 8px 20px;
    appearance: none;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-color);
    border-radius: 4px;

    :focus,
    :focus-within {
      border-color: var(--secondary-color-v3);
      outline: var(--secondary-color-v3);
    }
  }
`;
