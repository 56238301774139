import { AccountDeletionPolicy } from 'components/AccountDeletionPolicy';
import { presentIonModal } from 'modals/present-ion-modal';

export const accountDeletionPolicyModal = () =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismissModal();
    };

    const onDone = () => {
      res(undefined);
      dismissModal();
    };
    const modalProps = {
      onDone,
      onClose,
      isModal: true,
    };

    const [promptModal, dismissModal] = presentIonModal(
      AccountDeletionPolicy,
      modalProps,
      '',
    );

    promptModal();
  });
