import React from 'react';
import { checkmark } from 'ionicons/icons';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import styled from 'styled-components';
import {
  checkMinLengthValid,
  checkMinLowerCaseValid,
  checkMinNumberValid,
  checkMinUpperCaseValid,
} from 'utils/validatePassword';
import styles from 'components/PasswordValidator/PasswordValidator.module.css';
import { useI18nObject } from 'utils/use-i18n-object';

interface Props {
  password: string;
  children: React.ReactNode;
}

export const PasswordValidator: React.VFC<Props> = ({ children, password }) => {
  const LL = useI18nObject();

  return (
    <PasswordInputStyled>
      {children}
      <PasswordValidation>
        <IonGrid className={styles.strengthGrid}>
          <IonRow>
            <IonCol
              size="12"
              className={`${
                checkMinLengthValid(password)
                  ? styles.validSection
                  : styles.invalidSection
              }`}
            >
              <IonIcon icon={checkmark} />
              <span className={styles.criteriaHeader}>
                {LL.ATLEAST_CHARACTERS_CONTAINING()}
              </span>
            </IonCol>
            <IonCol
              size="4"
              className={`${
                checkMinLowerCaseValid(password)
                  ? styles.validSection
                  : styles.invalidSection
              }`}
            >
              <IonIcon icon={checkmark} />
              {LL.LOWER_CASE()}
            </IonCol>
            <IonCol
              size="4"
              className={`${
                checkMinUpperCaseValid(password)
                  ? styles.validSection
                  : styles.invalidSection
              }`}
            >
              <IonIcon icon={checkmark} />
              {LL.UPPER_CASE()}
            </IonCol>
            <IonCol
              size="4"
              className={`${
                checkMinNumberValid(password)
                  ? styles.validSection
                  : styles.invalidSection
              }`}
            >
              <IonIcon icon={checkmark} />
              {LL.NUMBER()}
            </IonCol>
          </IonRow>
        </IonGrid>
      </PasswordValidation>
    </PasswordInputStyled>
  );
};

const PasswordValidation = styled.div`
  display: none;
`;

const PasswordInputStyled = styled.div`
  width: 100%;

  :focus-within ${PasswordValidation} {
    display: block;
  }
`;
