import { registerPlugin } from '@capacitor/core';

interface PhotoCaptureResult {
  original_file_uri: string | null;
  cropped_file_uri: string | null;
}

export enum CameraType {
  Rear = 'rear',
  Front = 'front',
}
interface StartCameraParams {
  camera_type: CameraType;
}
export interface PhotoCapturePluginI {
  startCamera(params: StartCameraParams): Promise<PhotoCaptureResult>;
}

export const PhotoCapturePlugin =
  registerPlugin<PhotoCapturePluginI>('PhotoCapture');
