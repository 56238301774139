import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { addFinancialAccountModal } from 'modals/addFinancialAccountModal';
import { Icon } from 'ui/atoms/icon';
import {
  useDestroyFinancialAccount,
  useDestroyRecipient,
  useRecipient,
  useUpdateRecipient,
} from 'api/queries';
import type { AccountTypeI, FinancialAccount } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';

import { useHistory, useParams } from 'react-router';
import { TitlePortal } from 'components/TitlePortal';
import { DashboardPage } from 'pages/Dashboard';
import { InstantEditField } from 'ui/organisms/instant-edit-field';
import { IconButton } from 'ui/molecules/icon-button';
import { undoable } from 'utils/undoable';

const AccountTypeIcons: { [key in AccountTypeI]: string } = {
  bank_card: 'BankCard',
  bank_account: 'BankAccount',
  mobile_money: 'MobileMoney',
  prefund_account: 'PrefundAccount',
};

interface Props {}

const DISPLAY_DELETE_BUTTON = true;

export const NetworkItemDetails: React.VFC<Props> = () => {
  const history = useHistory();
  const LL = useI18nObject();

  const { recipientId: r } = useParams<{ recipientId: string }>();
  const recipientId = Number(r);
  const { data: recipient, refetch: refetchRecipients } =
    useRecipient(recipientId);

  const destroyAccountMutation = useDestroyFinancialAccount();
  const destroyNetworkItemMutation = useDestroyRecipient();

  const nameAbbr = (
    recipient
      ? `${recipient.first_name && recipient.first_name[0]}${
          recipient.last_name && recipient.last_name[0]
        }`
      : ''
  ).toUpperCase();

  const onAddAccountClick = async (recipientId: number) => {
    await addFinancialAccountModal('push', recipientId);
  };

  const updateRecipientMutation = useUpdateRecipient(recipientId);

  const onFirstNameSave = useCallback(
    async (newValue) => {
      await updateRecipientMutation.mutateAsync({
        first_name: newValue,
      });
    },
    [updateRecipientMutation],
  );
  const onLastNameSave = useCallback(
    async (newValue) => {
      await updateRecipientMutation.mutateAsync({
        last_name: newValue,
      });
    },
    [updateRecipientMutation],
  );
  const onEmailSave = useCallback(
    async (newValue) => {
      await updateRecipientMutation.mutateAsync({
        email: newValue,
      });
    },
    [updateRecipientMutation],
  );
  const onPhoneNumberSave = useCallback(
    async (newValue) => {
      await updateRecipientMutation.mutateAsync({
        phone_number: newValue,
      });
    },
    [updateRecipientMutation],
  );

  const handleFinancialAccountDeleteFactory = useCallback(
    (account: FinancialAccount) => async () => {
      const financialAccountId = account.id;
      if (financialAccountId) {
        undoable({
          message: LL.YOU_ARE_ABOUT_TO_DELETE({
            subject: account.account_name,
          }),
          onTimeout: async () => {
            await destroyAccountMutation.mutateAsync(financialAccountId, {
              onSuccess: () => {
                refetchRecipients();
              },
            });
          },
        });
      }
    },
    [destroyAccountMutation],
  );

  const handleNetworkItemDelete = useCallback(async () => {
    if (recipient) {
      const currentPathname = history.location.pathname;
      undoable({
        message: LL.YOU_ARE_ABOUT_TO_DELETE_FROM_NETWORK({
          fullname: `${recipient.first_name} ${recipient.last_name}`,
        }),
        onTimeout: async () => {
          await destroyNetworkItemMutation.mutateAsync(recipientId);
        },
        onUndo: () => {
          history.push(currentPathname);
        },
      });
      history.push('/dashboard/network');
    }
  }, [destroyNetworkItemMutation, recipient]);

  if (!recipient) {
    return null;
  }

  return (
    <>
      <TitlePortal>
        <Link to="/dashboard/network">{LL.MY_NETWORK()}</Link> /{' '}
        {recipient.first_name}
        &nbsp;
        {recipient.last_name}
      </TitlePortal>
      <NetworkItemListItemStyled>
        <NetworkItemBrief>
          <AvatarIcon>{nameAbbr}</AvatarIcon>
          <FullName>
            <InstantEditField
              value={recipient.first_name}
              onSave={onFirstNameSave}
            />
            <InstantEditField
              value={recipient.last_name}
              onSave={onLastNameSave}
            />
          </FullName>
          {DISPLAY_DELETE_BUTTON && (
            <IconButton
              iconProps={{
                name: 'Delete',
                size: 24,
                color: 'var(--primary-color-main)',
              }}
              type="button"
              onButtonClick={handleNetworkItemDelete}
            />
          )}
        </NetworkItemBrief>
        <Details>
          <NetworkItemInformation>
            <li>
              <DetailIcon size={28} name="Email" />
              <InstantEditField value={recipient.email} onSave={onEmailSave} />
            </li>
            <li>
              <DetailIcon size={28} name="PhoneNumber" />
              <InstantEditField
                value={recipient.phone_number}
                onSave={onPhoneNumberSave}
              />
            </li>
          </NetworkItemInformation>
          <NetworkItemFinancialAccountsLabel>
            Financial Accounts
          </NetworkItemFinancialAccountsLabel>
          <NetworkItemFinancialAccounts>
            {recipient.financial_accounts.map((account) => (
              <NetworkItemFinancialAccount key={account.id}>
                <DetailIcon
                  size={28}
                  name={AccountTypeIcons[account.account_type]}
                />
                <DetailText>{account.account_name}</DetailText>
                {DISPLAY_DELETE_BUTTON && (
                  <IconButton
                    iconProps={{
                      name: 'Delete',
                      size: 24,
                      color: 'var(--text-color-v3)',
                    }}
                    type="button"
                    onButtonClick={handleFinancialAccountDeleteFactory(account)}
                  />
                )}
              </NetworkItemFinancialAccount>
            ))}
          </NetworkItemFinancialAccounts>
          <AddAccountButton
            as="button"
            onClick={() => onAddAccountClick(recipient.id)}
          >
            <DetailIcon size={28} name="PlusCircle" />
            <DetailText>{LL.ADD_FINANCIAL_ACCOUNT()}</DetailText>
          </AddAccountButton>
        </Details>
      </NetworkItemListItemStyled>
    </>
  );
};

const NetworkItemListItemStyled = styled(DashboardPage)`
  gap: 16px;

  :not(:last-of-type) {
    border-bottom: 1px solid var(--text-color-v5);
  }
`;

const AvatarIcon = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color-v1);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--secondary-color-v2);

  font-size: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const DetailText = styled.div`
  flex: 1;
`;

const NetworkItemBrief = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-right: 1rem;
  margin-bottom: 16px;

  width: 100%;

  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color-v1);
`;

const FullName = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const Details = styled.div``;

const NetworkItemInformation = styled.ul`
  padding: 0 1rem;
  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }
`;

const NetworkItemFinancialAccountsLabel = styled.p`
  margin: 56px 1rem 16px;
  font-size: 16px;
  font-weight: 500;
`;

const NetworkItemFinancialAccounts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const NetworkItemFinancialAccount = styled.div`
  line-height: 1.5;
  border: none;
  border-radius: 0;
  outline: none !important;
  background-color: transparent;
  padding: 1rem;
  appearance: none;
  cursor: default;

  display: flex;
  margin-bottom: 8px;
  align-items: center;
  color: var(--text-color-v1);
  font-size: 1rem;

  @media (min-width: 560px) {
    font-size: 1.1rem;
  }
`;

const AddAccountButton = styled(NetworkItemFinancialAccount)`
  color: var(--secondary-color-v3);
`;

const DetailIcon = styled(Icon)`
  margin-right: 0.75rem;
`;
