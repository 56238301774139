import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { WEB_LINKS } from 'data';

import { DevModeButton } from 'components/DevModeButton';
import { REACT_APP_VERSION } from 'utils/react-app-version';
import { useI18nObject } from 'utils/use-i18n-object';
import { LanguageSwitcher } from 'components/LanguageSwitcher';

import FacebookIcon from 'ui/atoms/icon/assets/facebook.svg';
import TwitterIcon from 'ui/atoms/icon/assets/twitter.svg';
import InstagramIcon from 'ui/atoms/icon/assets/instagram.svg';

const Base: React.FC<any> = ({ onAdd }) => {
  const LL = useI18nObject();

  const currentYear = new Date().getFullYear();
  const [appVersionVisible, setAppVersionVisible] = useState(false);
  const onAppVersionButtonClick = useCallback(() => {
    if (appVersionVisible) {
      navigator.clipboard.writeText(REACT_APP_VERSION);
    } else {
      setAppVersionVisible(true);
    }
  }, [appVersionVisible, REACT_APP_VERSION]);

  return (
    <Footer>
      <Section>
        <Title>{LL.CONTACT_INFO()}</Title>
        <Row>
          <div>
            <Country>Ghana</Country>{' '}
            <a href="tel:+233559606777">+233 55 690 6777</a>
          </div>
        </Row>

        <Row>
          <div>
            <Country>Togo</Country>{' '}
            <a href="tel:+22870140037">+228 701 40037</a>
          </div>
        </Row>

        <Row>
          <div>
            <Country>USA</Country>{' '}
            <a href="tel:+16502000954">+1 650 200 0954</a>
          </div>
        </Row>

        <Row>
          <div className="col-lg-2">
            <Country>{LL.EMAIL()}</Country>{' '}
            <a href="mailto:general@afipayments.com">general@afipayments.com</a>
          </div>
        </Row>
      </Section>

      <WebLinks>
        <a
          href={WEB_LINKS.facebook}
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook"
        >
          <img src={FacebookIcon} style={{ width: '20px', height: '20px' }} />
        </a>
        <a href={WEB_LINKS.instagram} target="_blank" rel="noreferrer">
          <img
            src={InstagramIcon}
            style={{ width: '20px', height: '20px' }}
            aria-label="Instagram"
          />
        </a>
        <a href={WEB_LINKS.twitter} target="_blank" rel="noreferrer">
          <img
            src={TwitterIcon}
            style={{ width: '20px', height: '20px' }}
            aria-label="Twitter"
          />
        </a>
        <DevModeButton />
      </WebLinks>

      <Section>
        <div>
          <Fintitle>{LL.FINANCIALLY_CONNECTED()} ®</Fintitle>
          <br />
          <span>
            ©{' '}
            <AppVersionButton onClick={onAppVersionButtonClick}>
              {appVersionVisible ? REACT_APP_VERSION : `2017-${currentYear}`}
            </AppVersionButton>{' '}
            {LL.TECHUSTLE_AND_SUBSIDIARIES()}
          </span>
        </div>
      </Section>

      <Section>
        <Title>{LL.LANGUAGES()}</Title>
        <Row>
          <LanguageSwitcher />
        </Row>
      </Section>
    </Footer>
  );
};

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 16px;
  justify-content: space-between;
  padding: 50px 15% 20px;
  background-color: white;
  width: 100%;
`;

const Country = styled.span`
  margin: 8px 8px 8px 0;
  font-family: Roboto, serif;
  font-weight: 400;
  color: #7e7e7e;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 2rem;
`;

const Fintitle = styled.span`
  vertical-align: top;
  font-size: 14px;
  font-weight: 500;
`;

const Row = styled.div``;

const WebLinks = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 24px;
`;

const Section = styled.div`
  a {
    color: black;
  }
  margin: 16px 0;
`;

const AppVersion = styled.button``;

const AppVersionButton = styled.button`
  font-size: 16px;
`;

export default Base;
