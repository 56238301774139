import { createEvent, restore } from 'effector';

export const LOCAL_STORAGE_KEY = 'config';

export const configNames = ['production', 'staging', 'dev'];
type ConfigName = (typeof configNames)[number];

interface Config {
  configName: ConfigName;
  apiEndpoint: string;
  statusApiEndpoint: string;
  acuantGoUrl: string;
  ipqsApiKey: string;
  ipqsUrlPrefix: string; // This env value is used in index.html; consider refactoring
  vgsEnvironment: 'live' | 'sandbox' | string;
  vgsVaultId: string;
  smileIdentityPartnerId: string;
  smileIdentityEnvironment: 'live' | 'sandbox';
  smileIdentityCallbackUrl: string;
}

const prodConfig: Config = {
  configName: 'production',
  apiEndpoint: 'https://api.afipayments.com',
  statusApiEndpoint: 'https://status.afipayments.com/api',
  acuantGoUrl: 'https://go-stg.acuant.com/viewform/m3atq/',
  ipqsApiKey: 'vj4eFiH7SX7xDN6AIw3Gv4SlabTeFdTA',
  ipqsUrlPrefix:
    'https://www.ipqualityscore.com/api/afipayments.com/TNcHRZSrGckFgNFvcuO4xw5xObwSFUfpaKORxsvJcad7gkw59ssZKsEV7ZrMDgHSXiHGHkmqzLG3UAk22wxV1mxY9LDivSunlb55aB3sGRhhRdAgZjE7Xa6PW95oe44Pi9vZtNve1UHglbj5QGWURiHaW5UBoVl6RwtxrCB3ItRK5Sv4PYkh45wJ4h8ral7bopDVzqHYxpulvKtLqGb0hJuWBJyBGLFge1saxBXKytrdSo9X8w3mDnqrsmlX1nCI',
  vgsEnvironment: 'live',
  vgsVaultId: 'tntsbvaq7gm',
  smileIdentityPartnerId: '6341',
  smileIdentityEnvironment: 'live',
  smileIdentityCallbackUrl:
    'https://api.afipayments.com/api/auth/smile-identity/document_verification',
};

const stagingConfig: Config = {
  configName: 'staging',
  apiEndpoint: 'https://staging.api.afipayments.com',
  statusApiEndpoint: 'https://status.afipayments.com/api',
  acuantGoUrl: 'https://go-stg.acuant.com/viewform/m3atq/',
  ipqsApiKey: 'dXR3qSrZ5ZdK1VVoKjGlTbDNqxqRYkV8',
  ipqsUrlPrefix:
    'https://www.ipqualityscore.com/api/beta.afipayments.com/DPI4ZWoLyp2D9cQrAHUR4Ow65Hi0cWEZHE94wLkm6EgTKH8bZZB5RGaD2IiZMvS8j967DRCN5bcwZQykb8BlLurbrbHxEybYkwLo7BofoNcuSazVZAoZWLRpkOnOPgORrtItiWscggf7b9zv3pWH4anwFuYDnedloF5XVQso952EHdnI1070MYBThCicCtcVunpAva2JPIlxWjE9YgbAk7LgP1FrPqfQeWCbYGqD1eUce0VX3P4gKqOKRD2rnLTV',
  vgsEnvironment: 'sandbox',
  vgsVaultId: 'tntjtm1hcjo',
  smileIdentityPartnerId: '6341',
  smileIdentityEnvironment: 'sandbox',
  smileIdentityCallbackUrl:
    'https://staging.api.afipayments.com/api/auth/smile-identity/document_verification',
};

const devConfig: Config = {
  ...stagingConfig,
  configName: 'dev',
  apiEndpoint: 'http://localhost:8000',
  statusApiEndpoint: 'http://localhost:3016/api',
  smileIdentityPartnerId: '6341',
  smileIdentityEnvironment: 'sandbox',
  smileIdentityCallbackUrl:
    'https://staging.api.afipayments.com/api/auth/smile-identity/document_verification',
};

const configs: { [k: ConfigName]: Config } = {
  production: prodConfig,
  staging: stagingConfig,
  dev: devConfig,
};

const defaultConfigNameEnv =
  process.env.REACT_APP_DEFAULT_CONFIG_NAME || 'production';

const defaultConfigName: ConfigName = configNames.includes(defaultConfigNameEnv)
  ? defaultConfigNameEnv
  : 'production';

const defaultConfig: Config = configs[defaultConfigName];

export const resetConfig = createEvent();
export const setProdConfig = createEvent();
export const setStagingConfig = createEvent();
export const setDevConfig = createEvent();

const setName = createEvent<Config['configName']>();
export const $configName = restore(setName, defaultConfig.configName)
  .reset(resetConfig)
  .on(setProdConfig, () => prodConfig.configName)
  .on(setStagingConfig, () => stagingConfig.configName)
  .on(setDevConfig, () => devConfig.configName);

export const $config = $configName.map((configName) => configs[configName]);

export const $apiEndpoint = $config.map((config) => config.apiEndpoint);
