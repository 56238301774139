import React, { useState, useCallback } from 'react';

import styled from 'styled-components';

import { Icon } from 'ui/atoms/icon';
import { Input } from 'ui/atoms/input';
import { throttle } from 'utils';
import { LinkButton } from 'ui/atoms/link-button';
import { DashboardPage } from 'pages/Dashboard';
import { useRecipients } from 'api/queries';
import { addRecipientModal } from 'modals/addRecipientModal';
import { TitlePortal } from 'components/TitlePortal';
import { EmptyBox } from 'pages/Dashboard/EmptyBox';
import EmptyResults from 'pages/Dashboard/EmptyResults/EmptyResults';
import { useI18nObject } from 'utils/use-i18n-object';

import { MyNetworkItem } from './MyNetworkItem';

export const MyNetwork: React.FC<any> = () => {
  const LL = useI18nObject();

  const [params, setParams] = useState<{ q?: string }>({});

  const { data: recipientList, isLoading } = useRecipients(params);
  const qParams = params?.q ? params : undefined;
  const isParamsSet = !!qParams;

  const onSearch = useCallback((params) => {
    console.log(params);
    setParams(params);
  }, []);

  const onInput = useCallback(
    throttle((value: string) => {
      onSearch({ q: value });
    }, 1000),
    [onSearch],
  );

  const promptNewRecipient = useCallback(async () => {
    await addRecipientModal();
    onSearch(null);
  }, []);

  return (
    <>
      <TitlePortal>{LL.MY_NETWORK()}</TitlePortal>
      <RecipientsStyled>
        <Input
          left={<Icon name="Search" size={16} />}
          onInputChange={onInput}
          placeholder={LL.SEARCH_YOU_NETWORK()}
        />
        {!isLoading && (
          <>
            {recipientList?.length ? (
              <>
                <LinkButton onClick={promptNewRecipient}>
                  + {LL.ADD_A_PERSON_OR_BUSINESS()}
                </LinkButton>
                <RecipientsList>
                  {recipientList?.map((recipient) => (
                    <MyNetworkItem key={recipient.id} recipient={recipient} />
                  ))}
                </RecipientsList>
              </>
            ) : (
              <>
                {isParamsSet ? (
                  <EmptyResults
                    text={LL.NO_RECIPIENTS_FOUND_FOR_THIS_SEARCH()}
                  />
                ) : (
                  <EmptyBox onClick={promptNewRecipient}>
                    {LL.START_BUILDING_YOUR_NETWORK()}
                  </EmptyBox>
                )}
              </>
            )}
          </>
        )}
      </RecipientsStyled>
    </>
  );
};

const RecipientsStyled = styled(DashboardPage)`
  gap: 16px;
`;

const RecipientsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
