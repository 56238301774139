import type { FormikConfig, FormikValues } from 'formik';
import { useFormik } from 'formik';
import Notifications from 'components/Notifications';
import { useCallback } from 'react';
import type { InputChangeEventDetail } from '@ionic/core';

export const useForm = <Values extends FormikValues = FormikValues>({
  ...params
}: FormikConfig<Values>) => {
  const formik = useFormik<Values>({ ...params });

  const onSubmitAttempt = (e?: React.SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (formik.isSubmitting) {
      return;
    }
    if (formik.isValid) {
      formik.submitForm();
    } else {
      Notifications.alert({
        title: Object.values(formik.errors).join('\n'),
        variant: 'warning',
      });
    }
  };

  const createIonChangeHandler = useCallback(
    (fieldName: keyof Values) => (e: CustomEvent<InputChangeEventDetail>) => {
      formik.setFieldValue(String(fieldName), e.detail.value);
    },
    [],
  );

  return {
    ...formik,
    onSubmitAttempt,
    createIonChangeHandler,
  };
};
