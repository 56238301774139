import React from 'react';

import { useStore } from 'effector-react';
import { $locale } from 'stores/locale';
import { LegalPage } from 'ui/templates/legal-page';
import { Logo } from 'ui/atoms/logo';
import SiteFooter from 'components/SiteFooter/SiteFooter';

import { PrivacyPolicyFrench } from './PrivacyPolicyFrench';
import { PrivacyPolicyEnglish } from './PrivacyPolicyEnglish';

export const PrivacyPolicy = () => {
  const locale = useStore($locale);
  const PrivacyPolicyContent =
    locale === 'fr' ? PrivacyPolicyFrench : PrivacyPolicyEnglish;

  return (
    <LegalPage>
      <Logo />
      <article>
        <PrivacyPolicyContent />
      </article>
      <SiteFooter />
    </LegalPage>
  );
};
