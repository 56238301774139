import { IonContent, IonPage } from '@ionic/react';
import { useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { useStore } from 'effector-react';
import { $user, setUser } from 'stores/user';
import { useLogout, useUpdateProfile } from 'api/queries';

import '../pages.css';
import styled from 'styled-components';
import { LinkButton } from 'ui/atoms/link-button';

import { AuthGuard, GuardComponent, OnboardingRedirect } from 'guards';
import { getNextOnboardingStep } from 'utils/get-next-onboarding-step';
import { OnboardingStepRedirector } from 'guards/onboarding-step-redirector';
import type { User } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';

import Logo from '../../assets/icon/logo.svg';

import VerificationSentMessage from '../../components/VerificationSentMessage/VerificationSentMessage';
import VerifyPhoneNumber from '../../components/VerifyPhoneNumber/VerifyPhoneNumber';
import { VerifySecurityCode } from '../../components/VerifySecurityCode';
import VerifyIdentityForm from '../../components/VerifyIdentityForm/VerifyIdentityForm';
import { VerifyIdDocumentForm } from '../../components/VerifyIdDocumentForm/VerifyIdDocumentForm';
import RecoveryOptionsForm from '../../components/RecoveryOptionsForm/RecoveryOptionsForm';
import BusinessProfile from '../../components/BusinessProfile/BusinessProfile';
import SiteFooter from '../../components/SiteFooter/SiteFooter';

import { AuthApi } from '../../api/http';

import { VerificationActions, VerificationTypes } from '../../data';

import Notifications from '../../components/Notifications';

const Onboarding: React.FC<any> = ({ match }) => {
  const LL = useI18nObject();

  const history = useHistory();
  const { logout } = useLogout();

  const user = useStore($user);

  const onContinue = useCallback(
    (userProfile: User) => {
      let url = '';
      if (userProfile) {
        url = getNextOnboardingStep(userProfile);
      } else if (user) {
        url = getNextOnboardingStep(user);
      } else {
        return;
      }
      console.log(url);
      history.push(url);
    },
    [user],
  );

  const onRequestAnotherDestination = useCallback(() => {
    if (user) {
      setUser({
        ...user,
        phone_number: undefined,
        temp_phone_number: undefined,
      });
    }
    history.push('/dashboard/verify-phonenumber');
  }, [user]);

  const updateProfileMutation = useUpdateProfile();

  const onContinueBusinessForm = useCallback(async () => {
    const response = await updateProfileMutation.mutateAsync({
      onboarding_data: {
        ...user?.onboarding_data,
        skip_business_profile: true,
      },
    });
    onContinue(response);
  }, [user]);

  const onResendClick = useCallback(async () => {
    await AuthApi.sendVerificationLink({
      verification_type: VerificationTypes.email,
      verification_action: VerificationActions.changeEmail,
      email: user?.temp_email,
    });
    Notifications.alert(LL.VERIFICATION_EMAIL_HAS_BEEN_RESENT());
  }, [user?.temp_email]);

  return (
    <IonPage className="page1">
      <IonContent>
        <div className="ion-content-sub-container">
          <div className="logo-container">
            <img className="logo" src={Logo} />
          </div>
          <div className="content-container">
            <Switch>
              <Route path={`${match.url}/email-verification-sent`}>
                <Section>
                  <SectionTitle>{LL.VERIFY_YOUR_EMAIL()}</SectionTitle>
                  <div className="container">
                    <VerificationSentMessage
                      email={user?.temp_email || ''}
                      message={LL.CLICK_THE_VERIFICATION_BUTTON()}
                      onResendClick={onResendClick}
                    />
                  </div>
                </Section>
              </Route>
              <Route exact path={`${match.url}/verify-phonenumber`}>
                <div>
                  <SectionTitle>{LL.SET_UP_TWO_SET()}</SectionTitle>
                  <div className="container">
                    <VerifyPhoneNumber onContinue={onContinue} />
                  </div>
                </div>
              </Route>
              <Route exact path={`${match.url}/verify-code`}>
                <div>
                  <SectionTitle>
                    {LL.ENTER_AUTHENTIFICATION_CODE()}
                  </SectionTitle>
                  <div className="container">
                    <VerifySecurityCode
                      onRequestAnotherDestination={onRequestAnotherDestination}
                      onDone={onContinue}
                      verificationType={VerificationTypes.phoneNumber}
                      destination={user?.temp_phone_number}
                    />
                  </div>
                </div>
              </Route>
              <Route path={`${match.url}/verify-identity`}>
                <div>
                  <SectionTitle>{LL.VERIFY_YOUR_ID()}</SectionTitle>
                  <div className="container">
                    <VerifyIdentityForm
                      onDone={onContinue}
                      subTitle={LL.FINANCIAL_ACCOUNT_REQUIREMENT()}
                    />
                  </div>
                </div>
              </Route>
              <Route path={`${match.url}/recovery-options`}>
                <div>
                  <SectionTitle>{LL.SETUP_RECOVERY_OPTIONS()}</SectionTitle>
                  <div className="container">
                    <RecoveryOptionsForm onDone={onContinue} />
                  </div>
                </div>
              </Route>
              <Route path={`${match.url}/verify-id`}>
                <div>
                  <SectionTitle>{LL.VERIFY_YOUR_ID()}</SectionTitle>
                  <div className="container">
                    <VerifyIdDocumentForm onDone={onContinue} />
                  </div>
                </div>
              </Route>
              <Route path={`${match.url}/business-profile`}>
                <div>
                  <button
                    className="btn onboarding-skip-btn"
                    onClick={onContinueBusinessForm}
                  >
                    {LL.SKIP_FOR_NOW()}
                  </button>

                  <SectionTitle>{LL.VERIFY_YOUR_IDENTITY_()}</SectionTitle>
                  <div className="container">
                    <BusinessProfile
                      onDone={onContinue}
                      subTitle={LL.FINANCIAL_ACCOUNTS()}
                    />
                  </div>
                </div>
              </Route>

              <Route
                exact
                path={`${match.url}`}
                component={OnboardingStepRedirector}
              />
            </Switch>
          </div>
          <div className="sub-footer">
            <LinkButton onClick={logout}>{LL.LOGOUT()}</LinkButton>
          </div>

          <div className="footer onboarding-site-footer">
            <SiteFooter />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const Section = styled.div`
  padding: 16px;
`;

const SectionTitle = styled.div`
  padding: 16px;
  text-align: center;
  margin: 0.5rem 0 2rem 0;
  font-size: 1.75rem;
  color: var(--text-color-v2);
`;

export default GuardComponent(Onboarding, [AuthGuard, OnboardingRedirect]);
