import { DashboardPage } from 'pages/Dashboard';
import { useDevModeEnabled } from 'utils/use-dev-mode-enabled';
import { useHistory } from 'react-router-dom';
import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { $user } from 'stores/user';
import { resetDevMode } from 'stores/dev-mode';
import styled from 'styled-components';
import { DataLine, DataLineItem } from 'ui/organisms/summary';
import { Echo } from 'utils/echo';
import type { DeviceId, DeviceInfo } from '@capacitor/device';
import { Device } from '@capacitor/device';

import {
  $configName,
  configNames,
  resetConfig,
  setDevConfig,
  setProdConfig,
  setStagingConfig,
} from 'stores/config';
import { useStore } from 'effector-react';
import { REACT_APP_VERSION } from 'utils/react-app-version';
import { useLogout, useUpdateProfile } from 'api/queries';
import { Collapsible } from 'ui/atoms/collapsible';
import { Button } from 'ui/atoms/button';

import { LanguageSwitcher } from 'components/LanguageSwitcher';
import useDeferred from 'use-deferred';
import { Icon } from 'ui/atoms/icon';
import { MTNWarning } from 'pages/Transfer/MTNWarning';
import { NotificationPopup } from 'components/NotificationPopup';
import { Updater } from './Updater';

export const Dev = () => {
  const { logout } = useLogout();
  const devModeEnabled = useDevModeEnabled();
  const history = useHistory();
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>(null);
  const [deviceId, setDeviceId] = useState<DeviceId | null>(null);
  const configName = useStore($configName);
  const user = useStore($user);

  const [userExpanded, setUserExpanded] = useState(false);
  const toggleUserExpanded = () => {
    setUserExpanded((v) => !v);
  };

  const updateProfileMutation = useUpdateProfile();

  const forceToVerifyIdentity = useCallback(async () => {
    await updateProfileMutation.mutateAsync({
      profile: {
        date_of_birth: null,
      },
    });
  }, []);

  const forceToVerifyDocument = useCallback(async () => {
    await updateProfileMutation.mutateAsync({
      id_document: null,
      onboarding_data: {
        skip_welcome: true,
      },
    });
  }, []);

  const forceToVerifyBusiness = useCallback(async () => {
    await updateProfileMutation.mutateAsync({
      id_document: null,
      onboarding_data: {
        skip_welcome: true,
        skip_id_document: true,
      },
    });
  }, []);

  const forceToSetupRecovery = useCallback(async () => {
    await updateProfileMutation.mutateAsync({
      id_document: null,
      onboarding_data: {
        skip_welcome: true,
        skip_id_document: true,
        skip_business_profile: true,
      },
    });
  }, []);

  const {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    execute: showMTNWarning,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    resolve: resolveMTNWarning,
    isPending: isMTNWarningPending,
  } = useDeferred<void>();

  // This is a hack to prevent early redirect to '/'
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    if (!devModeEnabled) {
      const timeoutId = setTimeout(() => {
        history.push('/');
      }, 250);
      setTimerId(timeoutId);
    } else if (timerId) {
      clearTimeout(timerId);
    }
  }, [devModeEnabled]);

  const updateInfo = useCallback(async () => {
    const deviceInfo = await Device.getInfo();
    setDeviceInfo(deviceInfo);
    const deviceId = await Device.getId();
    setDeviceId(deviceId);
  }, []);

  useEffect(() => {
    updateInfo();
  }, []);

  const onEchoButtonClick = async () => {
    const { value } = await Echo.echo({ value: 'Hello World!' });
    console.log('Response from native:', value);
  };

  const onDisableDevModeClick = () => {
    resetDevMode();
  };

  const onConfigSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    const { value } = e.target;
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(
      'Config change will log you out and restart the application.\nDo you want to proceed?',
    );
    if (confirmed) {
      if (value === 'production') {
        setProdConfig();
      } else if (value === 'staging') {
        setStagingConfig();
      } else if (value === 'dev') {
        setDevConfig();
      } else {
        resetConfig();
      }
      logout();
    }
  };

  return (
    <DevStyled>
      {isMTNWarningPending && (
        <NotificationPopup
          onClose={() => resolveMTNWarning()}
          iconComponent={
            <Icon
              name="AlertOctogram"
              size={72}
              color="var(--primary-color-main)"
            />
          }
        >
          <MTNWarning />
        </NotificationPopup>
      )}
      <DataLine as="div">
        <DataLineItem isTitle>
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            ←
          </button>
        </DataLineItem>
        <DataLineItem>
          <p>AFi Developer tools</p>
        </DataLineItem>
      </DataLine>
      <button onClick={onDisableDevModeClick}>Disable dev mode</button>
      <DataLine>
        <DataLineItem isTitle>Config preset</DataLineItem>
        <DataLineItem>
          <select value={configName} onChange={onConfigSelect}>
            {configNames.map((c) => (
              <option value={c}>{c}</option>
            ))}
          </select>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>Language</DataLineItem>
        <DataLineItem>
          <LanguageSwitcher />
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>App version</DataLineItem>
        <DataLineItem>{REACT_APP_VERSION}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>name</DataLineItem>
        <DataLineItem>{deviceInfo?.name}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>device ID</DataLineItem>
        <DataLineItem>
          {String(deviceId?.identifier).toUpperCase()}
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>model</DataLineItem>
        <DataLineItem>{deviceInfo?.model}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>platform</DataLineItem>
        <DataLineItem>{deviceInfo?.platform}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>operatingSystem</DataLineItem>
        <DataLineItem>{deviceInfo?.operatingSystem}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>osVersion</DataLineItem>
        <DataLineItem>{deviceInfo?.osVersion}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>manufacturer</DataLineItem>
        <DataLineItem>{deviceInfo?.manufacturer}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>isVirtual</DataLineItem>
        <DataLineItem>{String(deviceInfo?.isVirtual)}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>memUsed</DataLineItem>
        <DataLineItem>{String(deviceInfo?.memUsed)}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem isTitle>webViewVersion</DataLineItem>
        <DataLineItem>{deviceInfo?.webViewVersion}</DataLineItem>
      </DataLine>
      <div>
        <Button onClick={showMTNWarning} shrink>
          MTN Warning
        </Button>
      </div>
      <div>
        <Button onClick={toggleUserExpanded} shrink>
          User: {user?.first_name ?? 'anonymous'}
        </Button>
      </div>
      <Collapsible expectedHeight={1000} isExpanded={userExpanded}>
        <DataLine>
          <DataLineItem isTitle>First name:</DataLineItem>
          <DataLineItem>{user?.first_name}</DataLineItem>
        </DataLine>
        <Button onClick={forceToVerifyIdentity} shrink>
          Force to verify identity
        </Button>
        <Button onClick={forceToVerifyDocument} shrink>
          Force to verify document
        </Button>
        <Button onClick={forceToVerifyBusiness} shrink>
          Force to verify business
        </Button>
        <Button onClick={forceToSetupRecovery} shrink>
          Force to setup recovery
        </Button>
      </Collapsible>
      <Updater />
      <DataLine>
        <DataLineItem>
          <button onClick={onEchoButtonClick}>Echo Plugin</button>
        </DataLineItem>
      </DataLine>
    </DevStyled>
  );
};

const DevStyled = styled(DashboardPage)`
  gap: 16px;
  padding: calc(16px + env(safe-area-inset-top)) 16px 16px;

  button {
    padding: 8px 16px;
    font-size: 1.2rem;
    background: darkslategray;
    border-radius: 4px;
    color: white;
  }
`;
