import { presentIonModal } from 'modals/present-ion-modal';

import type { ComponentProps } from 'react';
import type { User } from 'models';
import { i18nObject } from 'i18n/i18n-util';
import { $locale } from 'stores/locale';
import RecoveryOptionsForm from '../components/RecoveryOptionsForm/RecoveryOptionsForm';

export const updateSecurityQuestionModal = async (): Promise<
  User | undefined
> =>
  new Promise((res, rej) => {
    const LL = i18nObject($locale.getState());

    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (user: User) => {
      res(user);
      onClose();
    };

    const modalProps: ComponentProps<typeof RecoveryOptionsForm> = {
      onDone,
      onClose,
      isModal: true,
      showRecoveryOptions: false,
      showSecurityQuestion: true,
      title: LL.SECURITY_QUESTION(),
    };

    const [promptModal, dismissModal] = presentIonModal(
      RecoveryOptionsForm,
      modalProps,
      '',
    );

    promptModal();
  });
