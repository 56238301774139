import React, { useCallback, useState } from 'react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';

import styled from 'styled-components';
import { useUpdateProfile } from 'api/queries';
import type { User } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';

import { useCountryList } from 'data/countries';
import { Button } from 'ui/atoms/button';
import { Margins } from './Margins';
import CountrySelect from '../CountrySelect/CountrySelect';
import CloseIcon from '../../assets/icon/close.svg';

interface Props {
  onDone: (userProfile: User) => void;
  isModal?: any;
  onClose?: any;
}

const ALLOWED_COUNTRIES: string[] = ['gh', 'tg'];

const Empty = () => <></>;

const DevLand = ({ onSkip }: { onSkip: () => void }) => (
  <Button onClick={onSkip}>Skip</Button>
);

const getVerificationComponent = (country: string | null) => {
  switch (country) {
    case 'TG':
      // return SmileIdentity;
      return Margins;
    case 'GH':
      return Margins;
    case 'DV':
      return DevLand;
    case null:
      return Empty;
    default:
      return Empty;
  }
};

export const VerifyIdDocumentForm: React.VFC<Props> = ({
  onDone,
  isModal,
  onClose,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  const user = useStore($user);
  const LL = useI18nObject();

  const updateProfileMutation = useUpdateProfile();

  const onSkip = useCallback(async () => {
    const response = await updateProfileMutation.mutateAsync({
      onboarding_data: { ...user?.onboarding_data, skip_id_document: true },
    });
    onDone(response);
  }, [user, onDone]);

  const onCountryChange = useCallback((event) => {
    setSelectedCountry(event.detail.value);
  }, []);

  const countryList = useCountryList();

  const allowedCountries = countryList.filter(({ alpha2 }) =>
    ALLOWED_COUNTRIES.includes(alpha2),
  );

  const VerificationComponent = getVerificationComponent(selectedCountry);

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {LL.VERIFY_YOUR_ID()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <VerificationForm>
        <div className="title">{LL.FINANCIAL_ACCOUNT_REQUIREMENT()}</div>
        <CountrySelectContainer>
          <span>{LL.COUNTRY_THAT_ISSUED_ID()}</span>
          <CountrySelect
            countries={allowedCountries}
            onIonChange={onCountryChange}
            interface="popover"
          />
        </CountrySelectContainer>
        {!!selectedCountry && (
          <VerificationComponent
            onSkip={onSkip}
            onDone={onDone}
            country={selectedCountry}
          />
        )}
      </VerificationForm>
    </div>
  );
};

const CountrySelectContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;

  border: 1px solid hsl(0, 0%, 87%);
  border-radius: 4px;

  gap: 8px;
  margin-bottom: 16px;

  > span {
    font-size: 0.8rem;
  }
`;

const VerificationForm = styled.div`
  padding: 16px;
  max-width: 600px;
`;
