import { useCallback } from 'react';
import { IonItem, IonInput } from '@ionic/react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';

import { useI18nObject } from 'utils/use-i18n-object';

import { VerificationTypes } from '../../data';

import styles from './VerifyEmail.module.css';
import { AuthApi } from '../../api/http';
import { getFormData } from '../../utils';
import CloseIcon from '../../assets/icon/close.svg';

interface Props {
  onDone: any;
  title: any;
  message: any;
  onClose: any;
  isModal: any;
  verificationAction: any;
}

const VerifyEmail: React.FC<Props> = ({
  onDone,
  title,
  message,
  onClose,
  isModal,
  verificationAction,
}) => {
  const user = useStore($user);
  const LL = useI18nObject();

  const onSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const { nativeEvent } = event;

      const data = {
        ...getFormData(nativeEvent.target as HTMLFormElement),
        verification_type: VerificationTypes.email,
        verification_action: verificationAction,
      };
      await AuthApi.setNewEmail(data);

      onDone(data.email);
    },
    [onDone, user],
  );

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {title || 'Email address'}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <form className="form1" onSubmit={onSubmit}>
        <div className="form-container">
          <div className="input-group">
            <div className={`input-group-title ${styles.groupTitle}`}>
              {LL.YOUR_EMAIL()}
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  type="email"
                  name="email"
                  required
                  className="input"
                  placeholder=""
                />
              </IonItem>
            </div>
          </div>
        </div>
        <div className={styles.message}>
          {message || LL.SECURITY_IS_CRITICAL()}
        </div>
        <button type="submit" className="btn submit-btn">
          {LL.SEND_LINK()}
        </button>
      </form>
    </div>
  );
};

export default VerifyEmail;
