import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { useModal } from 'utils/use-modal';
import { useForm } from 'utils/use-form';

import { useCapabilities, usePullFinancialAccounts } from 'api/queries';
import { useInvoicePayMutation } from 'api/queries/mutations';
import { queryClient } from 'api/fetcher';
import { getAvailableAccounts } from 'data';

import { MaximizedModalTemplate } from 'ui/templates/modal-template';
import { FinancialAccountListItem } from 'components/FinancialAccountListItem/FinancialAccountListItem';
import type { FinancialAccount } from 'models';
import { AccountTypes } from 'models';
import { Button } from 'ui/atoms/button';
import { useRetrieveInvoice } from 'api/queries/queries';
import { useI18nObject } from 'utils/use-i18n-object';
import { DataLine, DataLineItem } from 'ui/organisms/summary';
import Notifications from 'components/Notifications';
import { enterCVVModal } from 'modals/enterCVVModal';
import { formatAmount } from 'utils/format-amount';

interface Props {}

interface Form {
  account: FinancialAccount | null;
  cvv: string;
}

export const Invoice: React.VFC<Props> = () => {
  const LL = useI18nObject();
  const { closeModal } = useModal();

  const { id } = useParams<{ id: string }>();
  const { data: invoice } = useRetrieveInvoice(id);
  const bulkPrId = invoice?.bulk_payment_request.id;
  const invoicePayMutation = useInvoicePayMutation(id);

  const { data: financialAccounts, refetch } = usePullFinancialAccounts();

  const { data: capabilities } = useCapabilities();

  const availableFinancialAccounts = useMemo(() => {
    if (financialAccounts && capabilities) {
      return getAvailableAccounts({
        direction: 'pull',
        financialAccounts,
        capabilities,
      });
    }
    return [];
  }, [financialAccounts, capabilities]);

  const form = useForm<Form>({
    initialValues: {
      account: null,
      cvv: '',
    },
    isInitialValid: false,
    validate: (values) => {
      const errors: { [k in keyof typeof values]?: string } = {};
      if (!values.account) {
        errors.account = 'Required';
      }
      return errors;
    },
    onSubmit: async (values) => {
      const accountID = values.account?.id;
      if (accountID) {
        const payload = {
          payer_account: accountID,
        };
        try {
          if (values.account?.account_type === AccountTypes.bank_card) {
            const response = await enterCVVModal({
              endpointUrl: `/api/invoices/${id}/pay`,
              payload,
              financialAccount: values.account,
            });
          } else {
            const response = await invoicePayMutation.mutateAsync({
              ...payload,
              cvv: '00000',
            });
          }
          queryClient.invalidateQueries(`bulk-pr/${bulkPrId}`);
          await Notifications.info(
            {
              message: LL.YOUR_PAYMENT_IS_BEING_PROCESSED(),
              big: true,
            },
            [{ text: 'OK', value: true }],
          );
          closeModal();
        } catch (e) {
          await Notifications.alert(
            {
              message: LL.SOMETHING_WENT_WRONG(),
              big: true,
            },
            [{ text: 'OK', value: true }],
          );
        }
      }
    },
  });

  const { account } = form.values;

  const onSelect = useCallback(
    (account: FinancialAccount) => () => {
      form.setFieldValue('account', account);
    },
    [],
  );

  if (!invoice) {
    return null;
  }

  return (
    <MaximizedModalTemplate>
      <h1>{LL.FEES_TITLE()}</h1>
      <Info>
        <DataLine>
          <DataLineItem>
            {LL.BULK_PR_WILL_BE_SENT_TO()}&nbsp;
            <strong>
              {invoice.bulk_payment_request.payment_requests.length}
            </strong>
            &nbsp;
            {LL.USERS()}
          </DataLineItem>
        </DataLine>
        <DataLine>
          <DataLineItem>{LL.EACH_ONE_COSTS_YOU()}</DataLineItem>
          <DataLineItem isTitle>
            {formatAmount({
              amount: '1.00',
              currency: invoice.currency,
            })}
          </DataLineItem>
        </DataLine>
        <DataLine>
          <DataLineItem isTitle>{LL.TOTAL_FEES()}</DataLineItem>
          <DataLineItem isTitle>
            {formatAmount({
              amount: invoice.amount,
              currency: invoice.currency,
            })}
          </DataLineItem>
        </DataLine>
      </Info>
      <h4>{LL.SELECT_ACCOUNT_TO_PAY_FEE_WITH()}</h4>
      <FinancialAccounts>
        {availableFinancialAccounts?.map((finAccount) => (
          <FinancialAccountListItem
            key={finAccount.id}
            onClick={onSelect(finAccount)}
            account={finAccount}
            disabled={!finAccount.available}
            isSelected={finAccount.id === account?.id}
          />
        ))}
      </FinancialAccounts>
      {account && (
        <ButtonHint>
          {LL.WE_WILL_CHARGE()}{' '}
          <strong>
            {formatAmount({
              amount: invoice.amount,
              currency: invoice.currency,
            })}
          </strong>{' '}
          {LL.YOUR_ACCOUNT({ account: account.account_title })}{' '}
          {LL.ENDING_WITH()} {account.last_four_digits}
        </ButtonHint>
      )}
      <Button onClick={form.onSubmitAttempt} disabled={!form.isValid}>
        {LL.PAY()}
      </Button>
    </MaximizedModalTemplate>
  );
};

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const FinancialAccounts = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  overflow-y: auto;
`;

const ButtonHint = styled.div`
  font-size: 0.8rem;
  margin: 8px 0;
  text-align: center;
`;
