import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { TitlePortal } from 'components/TitlePortal';
import { Icon } from 'ui/atoms/icon';
import { useI18nObject } from 'utils/use-i18n-object';
import { usePrefundAccounts } from 'api/queries/queries';
import { PrefundAccount } from 'models';
import { DashboardPage } from 'pages/Dashboard';
import { useModal } from 'utils/use-modal';
import { PrefundAccountCard } from 'pages/PrefundAccounts/PrefundAccountCard/PrefundAccountCard';
import { Spinner } from 'ui/atoms/spinner';
import { TransactionHistory } from 'pages/PrefundAccounts/TransactionHistory/TransactionHistory';
import { LinkButton } from 'ui/atoms/link-button/link-button';
import { AllowedToCreatePrefundAccountsGuard } from 'pages/PrefundAccounts/AllowedToCreatePrefundAccountsGuard';

export const PrefundAccounts = () => {
  const LL = useI18nObject();
  const { data: prefundAccounts, isLoading } = usePrefundAccounts();
  const [userSelectedAccount, setUserSelectedAccount] =
    useState<PrefundAccount | null>(null);
  const userHasPrefundAccount = prefundAccounts?.length !== 0;
  const { modalPush } = useModal();

  const promptNewPrefundBalance = useCallback(async () => {
    modalPush('/dashboard/prefund-accounts/add');
  }, []);

  const selectedAccount = userSelectedAccount ?? prefundAccounts?.[0] ?? null;

  return (
    <>
      <TitlePortal>{LL.PREFUND_BALANCE()}</TitlePortal>
      <AllowedToCreatePrefundAccountsGuard>
        <Container>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              <div>
                {userHasPrefundAccount && (
                  <NewAccountButton onClick={promptNewPrefundBalance}>
                    <NewAccountButtonLabel>
                      {LL.NEW_PREFUND_BALANCE()}
                    </NewAccountButtonLabel>
                    <Icon name="PlusIcon" size={24} color="white" />
                  </NewAccountButton>
                )}

                <PrefundAccountWrapper>
                  {userHasPrefundAccount ? (
                    prefundAccounts?.map((account) => (
                      <PrefundAccountCard
                        key={account.id}
                        account={account}
                        selected={selectedAccount?.id === account.id}
                        onAccountSelect={setUserSelectedAccount}
                      />
                    ))
                  ) : (
                    <LinkButtonStyled onClick={promptNewPrefundBalance}>
                      {LL.CREATE_FIRST_PREFUND_ACCOUNT()}
                    </LinkButtonStyled>
                  )}
                </PrefundAccountWrapper>
              </div>

              {userHasPrefundAccount && selectedAccount && (
                <TransactionsContainer>
                  <div>
                    <h2>{LL.TRANSACTIONS()}</h2>
                  </div>

                  <TransactionHistory prefundAccount={selectedAccount} />
                </TransactionsContainer>
              )}
            </>
          )}
        </Container>
      </AllowedToCreatePrefundAccountsGuard>
    </>
  );
};

const Container = styled(DashboardPage)`
  overflow-y: auto;
`;

const NewAccountButton = styled.button`
  background-color: var(--secondary-color-main);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 5px;
  height: 50px;
  font-size: 1.1rem;
`;

const NewAccountButtonLabel = styled.div`
  --background: var(--secondary-color-main);
  color: white;
  border-radius: 5px;
`;

const PrefundAccountWrapper = styled.div`
  height: 200px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding: 20px 0;
`;

const TransactionsContainer = styled.div`
  display: block;
  overflow-y: auto;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkButtonStyled = styled(LinkButton)`
  height: 60px;
  width: 100%;
  background: var(--secondary-color-v3);
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;
