import { combine, createEvent, createStore } from 'effector';

export const LOCAL_STORAGE_KEY = 'devMode';

export const resetDevMode = createEvent();
export const enableDevMode = createEvent();

export const $devModeEnabled = createStore(false)
  .on(enableDevMode, () => true)
  .reset(resetDevMode);

export const $devMode = combine({
  devModeEnabled: $devModeEnabled,
});
