import { ReactComponent as AccountLock } from './assets/account-lock.svg';
import { ReactComponent as AlertCircle } from './assets/alert-circle.svg';
import { ReactComponent as AlertOctogram } from './assets/alert-octogram.svg';
import { ReactComponent as ArrowUp } from './assets/arrow-up.svg';
import { ReactComponent as BankAccount } from './assets/bank-account.svg';
import { ReactComponent as BankCard } from './assets/bank-card.svg';
import { ReactComponent as Calendar } from './assets/calendar.svg';
import { ReactComponent as CameraPlus } from './assets/camera-plus.svg';
import { ReactComponent as Cash } from './assets/cash.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as Checkmark } from './assets/checkmark.svg';
import { ReactComponent as ClipboardCopy } from './assets/clipboard-copy.svg';
import { ReactComponent as ClockTimeFourOutline } from './assets/clock-time-four-outline.svg';
import { ReactComponent as Cross } from './assets/cross.svg';
import { ReactComponent as Delete } from './assets/delete.svg';
import { ReactComponent as Download } from './assets/download.svg';
import { ReactComponent as Edit } from './assets/edit.svg';
import { ReactComponent as Email } from './assets/email.svg';
import { ReactComponent as ExternalLink } from './assets/external-link.svg';
import { ReactComponent as FacebookIcon } from './assets/facebook.svg';
import { ReactComponent as FileDocumentPlus } from './assets/file-document-plus.svg';
import { ReactComponent as FileImagePlus } from './assets/file-image-plus.svg';
import { ReactComponent as FileSign } from './assets/file-sign.svg';
import { ReactComponent as FileSignMenuIcon } from './assets/file-sign-menu-icon.svg';
import { ReactComponent as FileSignMenuIconActive } from './assets/file-sign-menu-icon-active.svg';
import { ReactComponent as FlagGhana } from './assets/flag-gh.svg';
import { ReactComponent as FlagTogo } from './assets/flag-tg.svg';
import { ReactComponent as FlagUnitedStates } from './assets/flag-us.svg';
import { ReactComponent as HelpIcon } from './assets/help.svg';
import { ReactComponent as HelpIconActive } from './assets/help-active.svg';
import { ReactComponent as HomeIcon } from './assets/home.svg';
import { ReactComponent as HomeIconActive } from './assets/home-active.svg';
import { ReactComponent as InstagramIcon } from './assets/instagram.svg';
import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { ReactComponent as LogoutIconActive } from './assets/logout-active.svg';
import { ReactComponent as MinusCircle } from './assets/minus-circle.svg';
import { ReactComponent as MobileMoney } from './assets/mobile-money.svg';
import { ReactComponent as NetworkIcon } from './assets/network.svg';
import { ReactComponent as NetworkIconActive } from './assets/network-active.svg';
import { ReactComponent as PhoneNumber } from './assets/phone-number.svg';
import { ReactComponent as PlusCircle } from './assets/plus-circle.svg';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as PrefundAccount } from './assets/logo-black-and-white.svg';
import { ReactComponent as Refresh } from './assets/refresh.svg';
import { ReactComponent as ReversalIcon } from './assets/reversed.svg';
import { ReactComponent as Search } from './assets/search.svg';
import { ReactComponent as SettingsIcon } from './assets/settings.svg';
import { ReactComponent as SettingsIconActive } from './assets/settings-active.svg';
import { ReactComponent as TransactionHistoryIcon } from './assets/transaction-history.svg';
import { ReactComponent as TransactionHistoryIconActive } from './assets/transaction-history-active.svg';
import { ReactComponent as TransferIcon } from './assets/transfer.svg';
import { ReactComponent as TransferIconActive } from './assets/transfer-active.svg';
import { ReactComponent as TwitterIcon } from './assets/twitter.svg';
import { ReactComponent as Unverified } from './assets/unverified.svg';
import { ReactComponent as Verified } from './assets/verified.svg';
import { ReactComponent as ShareAndroid } from './assets/share-android.svg';
import { ReactComponent as ShareIOS } from './assets/share-ios.svg';
import { ReactComponent as WalletIcon } from './assets/wallet.svg';
import { ReactComponent as WalletIconActive } from './assets/wallet-active.svg';

export const iconsList: { [k: string]: SvgrComponent } = {
  AccountLock,
  AlertCircle,
  AlertOctogram,
  ArrowUp,
  BankAccount,
  BankCard,
  Calendar,
  CameraPlus,
  Cash,
  Check,
  Checkmark,
  ClipboardCopy,
  ClockTimeFourOutline,
  Cross,
  Delete,
  Download,
  Edit,
  Email,
  ExternalLink,
  FacebookIcon,
  FileDocumentPlus,
  FileImagePlus,
  FileSign,
  FileSignMenuIcon,
  FileSignMenuIconActive,
  FlagGhana,
  FlagTogo,
  FlagUnitedStates,
  HelpIcon,
  HelpIconActive,
  HomeIcon,
  HomeIconActive,
  InstagramIcon,
  LogoutIcon,
  LogoutIconActive,
  MinusCircle,
  MobileMoney,
  NetworkIcon,
  NetworkIconActive,
  PhoneNumber,
  PlusIcon,
  PlusCircle,
  PrefundAccount,
  Refresh,
  ReversalIcon,
  Search,
  SettingsIcon,
  SettingsIconActive,
  ShareAndroid,
  ShareIOS,
  TransactionHistoryIcon,
  TransactionHistoryIconActive,
  TransferIcon,
  TransferIconActive,
  TwitterIcon,
  Unverified,
  Verified,
  WalletIcon,
  WalletIconActive,
};
