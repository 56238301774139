import React, { useMemo } from 'react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';

import {
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
} from '@ionic/react';

import styled from 'styled-components';

import { useFormik } from 'formik';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import subYears from 'date-fns/subYears';
import isAfter from 'date-fns/isAfter';

import type { InputChangeEventDetail } from '@ionic/core';

import type { User } from 'models';
import { useUpdateProfile } from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

import CloseIcon from '../../assets/icon/close.svg';

import CountrySelect from '../CountrySelect/CountrySelect';
import Notifications from '../Notifications';

interface VerifyIdentityFormI {
  firstName: string;
  lastName: string;
  dobDay: string;
  dobMonth: string;
  dobYear: string;
  country: string;
  city: string;
  state: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  digitalAddress: string;
}

interface Props {
  onDone: (userProfile: User) => void;
  isModal?: any;
  onClose?: any;
  subTitle?: any;
}

const VerifyIdentityForm: React.VFC<Props> = ({
  onDone,
  isModal,
  onClose,
  subTitle,
}) => {
  const LL = useI18nObject();

  const user = useStore($user);
  const initialDob = useMemo(() => {
    const dob = user?.profile?.date_of_birth || '';
    const [year, month, day] = dob.split('-');
    return { day, month, year };
  }, [user]);

  const nowDate = useMemo(() => new Date(), []);

  const updateProfileMutation = useUpdateProfile();

  const formik = useFormik<VerifyIdentityFormI>({
    initialValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      dobDay: initialDob.day || '',
      dobMonth: initialDob.month || '',
      dobYear: initialDob.year || '',
      country: user?.profile?.address?.country || '',
      city: user?.profile?.address?.city || '',
      state: user?.profile?.address?.state || '',
      addressLine1: user?.profile?.address?.address_line_1 || '',
      addressLine2: user?.profile?.address?.address_line_2 || '',
      zip: user?.profile?.address?.zip || '',
      digitalAddress: user?.profile?.address?.digital_address || '',
    },
    validate: ({ dobDay, dobMonth, dobYear }) => {
      const errors: { [key: string]: string } = {};

      if (!firstName) {
        errors.firstName = LL.FIRST_NAME_REQUIRED();
      }
      if (!lastName) {
        errors.lastName = LL.LAST_NAME_REQUIRED();
      }
      if (!dobDay) {
        errors.dobDay = LL.DAY_REQUIRED();
      }
      if (!dobMonth) {
        errors.dobMonth = LL.MONTH_REQUIRED();
      }
      if (!dobYear) {
        errors.dobYear = LL.YEAR_REQUIRED();
      }
      const minDob = subYears(new Date(), 18);
      const dob = parse(
        `${dobYear}-${dobMonth}-${dobDay}`,
        'yyyy-M-d',
        new Date(),
      );
      if (isAfter(dob, minDob)) {
        errors.dobYear = LL.YOU_MUST_BE_AT_LEAST();
      }
      if (!country) {
        errors.country = LL.COUNTRY_REQUIRED();
      }
      if (!city) {
        errors.city = LL.CITY_REQUIRED();
      }
      if (country === 'GH' && !state) {
        errors.state = LL.STATE_REQUIRED();
      }
      if (!addressLine1) {
        errors.addressLine1 = LL.ADDRESS_REQUIRED();
      }
      if (country === 'GH' && !digitalAddress) {
        errors.digitalAddress = LL.DIGITAL_ADDRESS_REQUIRED();
      }

      return errors;
    },
    onSubmit: async ({
      firstName,
      lastName,
      dobDay,
      dobMonth,
      dobYear,
      country,
      city,
      state,
      addressLine1,
      addressLine2,
      zip,
      digitalAddress,
    }) => {
      const dobDate = parse(
        `${dobYear}-${dobMonth}-${dobDay}`,
        'yyyy-M-d',
        new Date(),
      );
      const dateOfBirth = format(dobDate, 'yyyy-MM-dd');

      const data = {
        first_name: firstName,
        last_name: lastName,
        profile: {
          date_of_birth: dateOfBirth,
          address: {
            country,
            city,
            state,
            address_line_1: addressLine1,
            address_line_2: addressLine2,
            zip,
            digital_address: digitalAddress,
          },
        },
        email: user?.email || '',
        onboarding_data: user?.onboarding_data,
      };

      console.log(data);

      const response = await updateProfileMutation.mutateAsync(data);
      onDone(response);
    },
  });

  const {
    firstName,
    lastName,
    dobDay,
    dobMonth,
    dobYear,
    country,
    city,
    state,
    addressLine1,
    addressLine2,
    zip,
    digitalAddress,
  } = formik.values;

  const isDigitalAddressInputVisible = country === 'GH';
  const isDigitalAddressRequired = country === 'GH';
  const isStateRequired = country === 'GH';

  const createIonChangeHandler =
    (fieldName: keyof VerifyIdentityFormI) =>
    (e: CustomEvent<InputChangeEventDetail>) => {
      formik.setFieldValue(fieldName, e.detail.value);
    };

  const onSubmitAttempt = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formik.isValid) {
      formik.submitForm();
    } else {
      Notifications.alert(Object.values(formik.errors).join('\n'));
    }
  };

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {LL.PERSONAL_INFO()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <Form onSubmit={onSubmitAttempt}>
        {subTitle && <div className="title">{subTitle}</div>}
        <div className="form">
          <div className="input-group">
            <div className="input-group-title">{LL.LEGAL_NAME()}</div>
            <IonGrid className="input-grid-group">
              <IonRow>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInputStyled
                      value={firstName}
                      onIonChange={createIonChangeHandler('firstName')}
                      name="firstName"
                      placeholder={LL.FIRST_NAME()}
                      required
                    />
                  </IonItem>
                </IonCol>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInputStyled
                      value={lastName}
                      onIonChange={createIonChangeHandler('lastName')}
                      name="lastName"
                      placeholder={LL.LAST_NAME()}
                      required
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="input-group">
            <div className="input-group-title">{LL.DATE_OF_BIRTH()}</div>
            <IonGrid className="input-grid-group">
              <IonRow>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonLabel position="floating">{LL.DAY()}</IonLabel>
                    <IonInputStyled
                      value={dobDay}
                      onIonChange={createIonChangeHandler('dobDay')}
                      name="dobDay"
                      min="1"
                      max="31"
                      type="number"
                      placeholder={LL.FORMAT_DAY()}
                      required
                    />
                  </IonItem>
                </IonCol>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonLabel position="floating">{LL.MONTH()}</IonLabel>
                    <IonInputStyled
                      value={dobMonth}
                      onIonChange={createIonChangeHandler('dobMonth')}
                      min="1"
                      max="12"
                      type="number"
                      name="dobMonth"
                      placeholder={LL.FORMAT_MONTH()}
                      required
                    />
                  </IonItem>
                </IonCol>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonLabel position="floating">{LL.YEAR()}</IonLabel>
                    <IonInputStyled
                      value={dobYear}
                      onIonChange={createIonChangeHandler('dobYear')}
                      name="dobYear"
                      type="number"
                      min="1800"
                      max={`${nowDate.getFullYear()}`}
                      placeholder={LL.FORMAT_YEAR()}
                      required
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="input-group">
            <div className="input-group-title">{LL.ADDRESS()}</div>
            <div className="input-field">
              <IonItemStyled className="input-item" lines="none">
                <CountrySelect
                  value={country}
                  onIonChange={createIonChangeHandler('country')}
                  name="country"
                  interface="popover"
                  placeholder={LL.COUNTRY()}
                />
              </IonItemStyled>
            </div>
            <IonGrid className="input-grid-group">
              <IonRow>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInputStyled
                      value={city}
                      onIonChange={createIonChangeHandler('city')}
                      name="city"
                      placeholder={LL.CITY()}
                      required
                    />
                  </IonItem>
                </IonCol>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInputStyled
                      value={state}
                      onIonChange={createIonChangeHandler('state')}
                      name="state"
                      placeholder={LL.STATE_REGION()}
                      required={isStateRequired}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInputStyled
                  value={addressLine1}
                  onIonChange={createIonChangeHandler('addressLine1')}
                  name="addressLine1"
                  placeholder={LL.ADDRESS_LINE_1()}
                  required
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInputStyled
                  value={addressLine2}
                  onIonChange={createIonChangeHandler('addressLine2')}
                  name="addressLine2"
                  placeholder={LL.ADDRESS_LINE_2()}
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInputStyled
                  value={zip}
                  onIonChange={createIonChangeHandler('zip')}
                  name="zip"
                  placeholder={LL.ZIP_CODE()}
                />
              </IonItem>
            </div>
            {isDigitalAddressInputVisible && (
              <div className="input-field">
                <IonItem className="input-item" lines="none">
                  <IonInputStyled
                    value={digitalAddress}
                    onIonChange={createIonChangeHandler('digitalAddress')}
                    name="digitalAddress"
                    placeholder={LL.DIGITAL_ADDRESS()}
                    required={isDigitalAddressRequired}
                  />
                </IonItem>
              </div>
            )}
          </div>
        </div>
        <button type="submit" className="btn submit-btn">
          {LL.SUBMIT()}
        </button>
      </Form>
    </div>
  );
};

const Form = styled.form`
  padding: 16px;
  max-width: 600px;
`;

const IonInputStyled = styled(IonInput)`
  :after {
    content: '${(p) => (p.required ? '*' : '')}';
    color: red;
  }
`;

const IonItemStyled = styled(IonItem)`
  position: relative;

  :after {
    position: absolute;
    top: 16px;
    right: 40px;
    z-index: 100;

    content: '*';
    color: red;
  }
`;

export default VerifyIdentityForm;
