import React from 'react';
import styled from 'styled-components';

import { useI18nObject } from 'utils/use-i18n-object';

import { LinkButton } from 'ui/atoms/link-button';
import EmailIcon from '../../assets/icon/email-big.svg';

import styles from './VerificationSentMessage.module.css';

interface Props {
  email: string;
  message: string;
  onResendClick: () => void;
}

const VerificationSentMessage: React.FC<Props> = ({
  email,
  message,
  onResendClick,
}) => {
  const LL = useI18nObject();

  return (
    <div>
      <div className={styles.iconContainer}>
        <img className={styles.icon} src={EmailIcon} />
      </div>
      <div className={styles.message}>
        {LL.WE_SENT_VERIFICATION_MAIL_TO()} <br />
        <Email>{email}</Email>
      </div>

      <div className={styles.footerMessage}>
        {message}
        <br />
        {LL.EMAIL_DID_NOT_ARRIVE()}{' '}
        <LinkButton onClick={onResendClick}>{LL.RESEND()}</LinkButton>
      </div>
    </div>
  );
};

const Email = styled.div`
  line-break: anywhere;
  margin-top: 1rem;
  font-size: 1.5rem;
`;

export default VerificationSentMessage;
