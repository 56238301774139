import React from 'react';
import styled from 'styled-components';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  shrink?: boolean;
  ghost?: boolean;
  disabled?: boolean;
}

export const Button = styled.button<Props>`
  padding: 1rem 1.5rem;
  background: ${(p) =>
    ({
      true: 'var(--secondary-color-main)',
      [String(p.ghost)]: 'transparent',
      [String(p.disabled)]: 'var(--secondary-color-v3)',
    }.true)};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  color: ${(p) =>
    ({
      true: 'white',
      [String(p.ghost)]: 'var(--secondary-color-v3)',
    }.true)};
  font-size: 1rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${(p) => !p.shrink && '100%'};
`;
