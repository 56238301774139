import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { IconButton } from 'ui/molecules/icon-button';
import { Input } from 'ui/atoms/input';
import { useForm } from 'utils/use-form';

interface Props {
  value: string;
  onSave: (newValue: string) => void;
  children?: React.ReactNode;
}

interface Form {
  value: string;
}

const DISPLAY_EDIT_BUTTON = true;

export const InstantEditField: React.VFC<Props> = ({
  value,
  onSave,
  children,
}) => {
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const onEditClick = useCallback(() => {
    setEditModeEnabled(true);
  }, []);
  const form = useForm<Form>({
    enableReinitialize: true,
    initialValues: {
      value,
    },
    onReset: () => {
      setEditModeEnabled(false);
    },
    onSubmit: async ({ value }) => {
      onSave(value);
      setEditModeEnabled(false);
    },
  });
  const onChange = useCallback(
    (value: string) => {
      form.setFieldValue('value', value);
    },
    [form],
  );
  return editModeEnabled ? (
    <Field as="form">
      <Input
        placeholder="Edit"
        value={form.values.value}
        onInputChange={onChange}
        right={
          <Buttons>
            <IconButton
              iconProps={{
                name: 'Check',
                size: 24,
                color: 'var(--text-color-v3)',
              }}
              type="submit"
              onButtonClick={form.onSubmitAttempt}
            />
            <IconButton
              iconProps={{
                name: 'Cross',
                size: 14,
                color: 'var(--text-color-v3)',
              }}
              type="reset"
              onButtonClick={form.resetForm}
            />
          </Buttons>
        }
      />
    </Field>
  ) : (
    <Field>
      {children || <Value>{value}</Value>}
      <Buttons>
        {DISPLAY_EDIT_BUTTON && (
          <IconButton
            iconProps={{
              name: 'Edit',
              size: 24,
              color: 'var(--text-color-v3)',
            }}
            type="button"
            onButtonClick={onEditClick}
          />
        )}
      </Buttons>
    </Field>
  );
};

const Field = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Value = styled.div``;
