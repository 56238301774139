import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

type Location = ReturnType<typeof useLocation>;

interface ModalPushParams {
  to: string;
  background: string;
}

export const useModal = () => {
  const history = useHistory();
  const location = useLocation<{ background?: Location }>();

  // This piece of state is set when one of the
  // gallery links is clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the gallery in the background, behind
  // the modal.
  const backgroundLocation = location.state && location.state.background;

  const modalPush = useCallback(
    (params: string | ModalPushParams) => {
      const pathname = typeof params === 'string' ? params : params.to;
      const backgroundLocation: Location =
        typeof params === 'string'
          ? location
          : {
              pathname: params.background,
              search: '',
              hash: '',
              state: {},
            };
      history.push({
        pathname,
        state: {
          background: backgroundLocation,
        },
      });
    },
    [location],
  );

  const closeModal = useCallback(() => {
    if (backgroundLocation) {
      history.push(backgroundLocation);
    }
  }, [backgroundLocation]);

  return {
    modalPush,
    backgroundLocation,
    closeModal,
  };
};
