import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TitlePortal } from 'components/TitlePortal';
import { useI18nObject } from 'utils/use-i18n-object';
import { AllowedToPerformTransactionsGuard } from 'pages/Transfer/AllowedToPerformTransactionsGuard';
import { DashboardPage } from 'pages/Dashboard';
import { useParams } from 'react-router';
import Notifications from 'components/Notifications';
import { LinkButton } from 'ui/atoms/link-button';

export const PrefundOptions = () => {
  const LL = useI18nObject();
  const { prefundAccountId } = useParams<{ prefundAccountId: string }>();

  const displayWarning = () => {
    Notifications.alert(LL.OPTION_NOT_AVAILABLE());
  };
  return (
    <>
      <TitlePortal>Pre-Fund</TitlePortal>
      <DashboardPageStyled>
        <AllowedToPerformTransactionsGuard>
          <Content>
            <PrefundNotice>{LL.PREFUND_OTHER_NOTICE()}</PrefundNotice>
            <SendButton
              to={`/dashboard/prefund-accounts/${prefundAccountId}/increase/afi`}
            >
              {LL.USE_AFI()}
            </SendButton>
            <LinkButtonStyled onClick={displayWarning}>
              {LL.USE_OTHER()}
            </LinkButtonStyled>
          </Content>
        </AllowedToPerformTransactionsGuard>
      </DashboardPageStyled>
    </>
  );
};

const DashboardPageStyled = styled(DashboardPage)`
  background-color: inherit;
  padding: 0;
`;

const Content = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const SendButton = styled(Link)`
  padding: 0.75rem 1rem;
  border: 1px solid var(--text-color-v4);
  font-size: 0.95rem;
  border-radius: 0.3rem;
  text-align: center;
  min-width: 200px;
  color: white;
  background-color: var(--primary-color-main);
  margin-top: 0;
`;

const PrefundNotice = styled.p`
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.2rem;
`;

const LinkButtonStyled = styled(LinkButton)`
  padding: 0.75rem 1rem;
  color: var(--text-color-v1);
  border: 1px solid var(--text-color-v4);
  background: white;
  font-size: 0.95rem;
  border-radius: 0.3rem;
  text-align: center;
  min-width: 200px;
  justify-content: center;
  align-items: center;
`;
