import React, { useState, useCallback } from 'react';
import { IonItem, IonInput } from '@ionic/react';
import { useI18nObject } from 'utils/use-i18n-object';

import { useRecoveryOptions } from 'api/queries';

import styled from 'styled-components';
import { RevealableInput } from 'ui/organisms/revealable-input';
import CloseIcon from '../../assets/icon/close.svg';

import { AuthApi } from '../../api/http';
import Notifications from '../Notifications';
import { getFormData } from '../../utils';

const ForgotPasswordForm: React.FC<any> = ({
  onDone,
  isModal,
  title,
  onClose,
}) => {
  const LL = useI18nObject();

  const [email, setEmail] = useState<string>('');
  const [locked, setLocked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const {
    data: recoveryOptions,
    refetch: fetchRecoveryOptions,
    isFetching: isRecoveryOptionsFetching,
  } = useRecoveryOptions(email);

  const checkEmail = useCallback(
    async (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (locked) {
        setLocked(false);
      } else {
        const reco = await fetchRecoveryOptions();
        if (!reco.isSuccess) {
          Notifications.alert(
            {
              backdropDismiss: true,
              message: LL.EMAIL_NOT_FOUND({ email }),
            },
            [{ text: 'OK', value: true }],
          );
        }
        if (reco.isSuccess) {
          setLocked(true);
        }
      }
    },
    [locked],
  );

  const secretQuestion = recoveryOptions?.secret_question;

  const onSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();
      try {
        setIsSubmitting(true);
        const { nativeEvent } = event;

        const data = getFormData(nativeEvent.target as HTMLFormElement);

        data.email = email;

        const r = await AuthApi.forgotPassword(data);
        Notifications.alert(
          {
            backdropDismiss: true,
            title: LL.EMAIL_SENT_SUCCESSFULLY(),
            message: LL.WE_SENT_A_PASSWORD_REST_LINK(),
          },
          [{ text: 'OK', value: true }],
        );
        setIsSubmitting(false);
        onDone(true);
      } catch (e) {
        setIsSubmitting(false);
      }
    },
    [email, onDone],
  );

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {title || LL.TITLE_FORGOT_PASSWORD()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <Form onSubmit={checkEmail}>
        <div className="input-group">
          <div className="input-group-title">
            {LL.WHAT_IS_YOUR_EMAIL_ADDRESS()}
          </div>
          <EmailInputContainer>
            <IonItem className="input-item" lines="none">
              <IonInput
                onInput={onEmailChange}
                required
                name="email"
                type="email"
                className="input"
                placeholder={LL.YOUR_MAIL()}
                disabled={locked}
              />
            </IonItem>
            <GoButton
              type="submit"
              disabled={isRecoveryOptionsFetching || !email}
            >
              {locked ? '×' : '→'}
            </GoButton>
          </EmailInputContainer>
        </div>
      </Form>
      {locked && (
        <Form onSubmit={onSubmit}>
          {secretQuestion && (
            <div className="input-group">
              <div className="input-group-title">{secretQuestion}</div>
              <div className="input-field">
                <RevealableInputStyled
                  name="security_answer"
                  type="password"
                  className="input"
                  placeholder={LL.ANSWER()}
                  required
                />
              </div>
            </div>
          )}
          <button
            type="submit"
            className="btn submit-btn"
            disabled={isSubmitting}
          >
            {LL.CONTINUE()}
          </button>
        </Form>
      )}
    </div>
  );
};

const Form = styled.form`
  padding: 18px 36px;
  border-radius: 0.5rem;
  background: white;
  color: #3c3c3c;
`;

const GoButton = styled.button`
  font-size: 20px;
  color: white;
  border-radius: 0.5rem;
  background-color: var(--secondary-color-main);
  height: 50px;
  width: 50px;
`;

const EmailInputContainer = styled.div`
  display: flex;
  gap: 8px;

  > *:first-child {
    flex: 1;
  }
`;

const RevealableInputStyled = styled(RevealableInput)`
  margin-bottom: 1rem;
  padding-left: 16px;
`;

export default ForgotPasswordForm;
