import {
  applyUpdateFx,
  checkForUpdatesFx,
  downloadUpdateFx,
  $latestVersion,
  $bundleInfo,
} from 'stores/updater';
import { $appActive, appStarted } from 'stores/app';
import { sample } from 'effector';
import type {
  LatestVersion as LatestVersionInfo,
  BundleInfo,
} from '@capgo/capacitor-updater';

sample({
  source: appStarted,
  target: checkForUpdatesFx,
});

sample({
  source: $latestVersion,
  clock: $appActive,
  filter: (
    latestVersion: LatestVersionInfo | null,
    active: boolean,
  ): latestVersion is LatestVersionInfo & { url: string } =>
    active && typeof latestVersion?.url === 'string',
  target: downloadUpdateFx,
});

sample({
  source: $bundleInfo,
  clock: $appActive,
  filter: (
    bundleInfo: BundleInfo | null,
    active: boolean,
  ): bundleInfo is BundleInfo => !!(!active && bundleInfo?.version),
  target: applyUpdateFx,
});
