// eslint-disable-next-line max-classes-per-file
import { setUser } from 'stores/user';
import { instance } from 'api/axios';
import type { FinancialAccount, SecurityQuestionAnswer, User } from 'models';

export class FinancialAccountApi {
  static async sendPlaidToken(public_token: string): Promise<FinancialAccount> {
    return instance.post<any, any>('/financial-accounts/public_token', {
      public_token,
    });
  }

  static getDwollaAccountVerificationToken(): Promise<any> {
    return instance.get<any, any>(`/financial-accounts/iav_token`);
  }

  static async sendDwollaFundingSource(
    source_token: string,
  ): Promise<FinancialAccount> {
    return instance.post<any, any>('/financial-accounts/funding_source_token', {
      source_token,
    });
  }
}

export class ProfileApi {
  static async getProfile(): Promise<User> {
    return instance
      .get<any, User>('/auth/profile/self')
      .then((m) => {
        setUser(m);
        return m;
      })
      .catch((err) => {
        setUser(null);
        throw err;
      });
  }
}

export class AuthApi {
  static async sendVerificationCode(params: any): Promise<any> {
    return instance.post<any, any>('/auth/verification-code', params);
  }

  static async sendVerificationLink(params: any): Promise<any> {
    return instance.post<any, any>('/auth/verification-link', params);
  }

  static async setNewEmail(params: any): Promise<any> {
    return instance.post<any, any>('/auth/set-new-email', params);
  }

  static async verifyPhoneNumber(params: any): Promise<any> {
    return instance.post<any, any>('/auth/account/change_phone_number', params);
  }

  static async createSecurityQuestion(
    params: any,
  ): Promise<SecurityQuestionAnswer> {
    return instance.post<any, SecurityQuestionAnswer>(
      '/auth/security-question-answer',
      params,
    );
  }

  static async updatePassword(params: any): Promise<any> {
    return instance.post<any, any>('/auth/account/update_password', params);
  }

  static async forgotPassword(params: any): Promise<any> {
    return instance.post<any, any>('/auth/account/forgot_password', params);
  }

  static async resetPassword(params: {
    token: string;
    password: string;
  }): Promise<any> {
    return instance.post<any, any>('/auth/account/reset_password', params);
  }
}
