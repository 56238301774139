import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useValidateTransaction } from 'api/queries/queries';

import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';
import Notifications from 'components/Notifications';

import { useI18nObject } from 'utils/use-i18n-object';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import styled from 'styled-components';

import { DataLine, DataLineItem } from 'ui/organisms/summary';

import { formatAmount } from 'utils/format-amount';
import type { CreateTransactionRequestBody, TransactionStatus } from 'models';
import { Button } from 'ui/atoms/button';

interface Props {
  onGoBack: () => void;
  onConfirm: () => void;
  data: CreateTransactionRequestBody;
}

export const MakePayment: React.VFC<Props> = ({
  onGoBack,
  onConfirm,
  data,
}) => {
  const history = useHistory();
  const { data: validationResult } = useValidateTransaction(data);

  const onCancel = useCallback(async () => {
    const decision = await Notifications.dialog(
      {
        message: LL.ARE_YOU_SURE_TO_CANCEL_PAYMENT(),
        backdropDismiss: true,
      },
      [
        {
          text: LL.KEEP_GOING_WITH_PAYMENT(),
          value: false,
          cssClass: 'main',
        },
        {
          text: LL.YES_CANCEL(),
          value: true,
          cssClass: 'secondary',
        },
      ],
    );
    if (decision) {
      history.push('/dashboard');
    }
  }, [history]);

  const user = useStore($user);
  const LL = useI18nObject();

  const STATUS_LABELS: { [key in TransactionStatus]: string } = {
    pending: LL.TRANSACTION_PENDING_STATUS(),
    succeeded: LL.TRANSACTION_SUCCEEDED_STATUS(),
    failed: LL.TRANSACTION_FAILED_STATUS(),
  };

  return (
    <PrevNextStepper
      onPrevClick={onGoBack}
      nextButtonLabel={LL.CONFIRM_AND_PAY()}
      onNextClick={onConfirm}
      nextStepActive
    >
      <Header>
        {LL.PLEASE_ENSURE_PAYMENT_DETAILS_CORRECT()}
        <br />
        {LL.NO_REFUNDS_TO_WRONG_ACCOUNT()}
      </Header>
      <PaymentSummaryStyled>
        <DataLine>
          <DataLineItem isTitle>{LL.FROM()}</DataLineItem>
          <span>
            {user?.first_name} {user?.last_name}
          </span>
          <DataLineItem secondary separateLine alignRight>
            {validationResult?.sender_account?.account_title}{' '}
            {validationResult?.sender_account?.account_type !==
              'prefund_account' && (
              <>
                {LL.ENDING_WITH()}
                {validationResult?.sender_account?.last_four_digits}
              </>
            )}
          </DataLineItem>
        </DataLine>
        <DataLine>
          <DataLineItem isTitle>{LL.TO()}</DataLineItem>
          <DataLineItem>
            {validationResult?.recipient_account?.recipient?.first_name}{' '}
            {validationResult?.recipient_account?.recipient?.last_name}
          </DataLineItem>
          <DataLineItem isTitle separateLine alignRight>
            {validationResult?.recipient_account?.account_title}
            {validationResult?.recipient_account.account_type !==
              'prefund_account' && (
              <>
                {` ${LL.ENDING_WITH()} ${
                  validationResult?.recipient_account?.last_four_digits
                }`}
              </>
            )}
          </DataLineItem>
        </DataLine>
        <br />
        <DataLine>
          <DataLineItem isTitle>{LL.PURPOSE_OF_PAYMENT()}</DataLineItem>
          <DataLineItem>{validationResult?.category?.name}</DataLineItem>
          <DataLineItem secondary separateLine alignRight>
            {validationResult?.description}
          </DataLineItem>
        </DataLine>
        <DataLine>
          <DataLineItem isTitle>{LL.AMOUNT()}</DataLineItem>
          <DataLineItem>
            {formatAmount({
              amount: validationResult?.send_amount,
              currency: validationResult?.currency,
            })}
          </DataLineItem>
        </DataLine>
        <DataLine>
          <DataLineItem isTitle>{LL.FEES()}</DataLineItem>
          <DataLineItem>
            {formatAmount({
              amount: validationResult?.fee,
              currency: validationResult?.currency,
            })}
          </DataLineItem>
        </DataLine>
        <DataLine>
          <DataLineItem isTitle>{LL.TOTAL()}</DataLineItem>
          <DataLineItem>
            {formatAmount({
              amount: validationResult?.total_amount,
              currency: validationResult?.currency,
            })}
          </DataLineItem>
        </DataLine>
        <DataLine>
          <DataLineItem isTitle>{LL.AMOUNT_RECEIVED()}</DataLineItem>
          <DataLineItem>
            {formatAmount({
              amount: validationResult?.recipient_amount,
              currency: validationResult?.recipient_currency,
            })}
          </DataLineItem>
        </DataLine>
      </PaymentSummaryStyled>
      <Footer>
        <CancelButton onClick={onCancel}>{LL.CANCEL_PAYMENT()}</CancelButton>
      </Footer>
    </PrevNextStepper>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.75rem 2rem;
  font-size: 1.3rem;
`;

const Footer = styled.div`
  padding: 1.75rem 2rem;
  display: flex;
`;

const PaymentSummaryStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
  //padding: 16px;

  padding: 1.75rem 2rem;
  border-bottom: 1px solid var(--text-color-v5);
  border-top: 1px solid var(--text-color-v5);
`;

const CancelButton = styled(Button)`
  display: flex;
  margin-left: auto;
  align-items: center;
  color: var(--text-color-v3);
  background: initial;
  font-size: 1.1rem;
  padding: 0.5rem;
  cursor: pointer;
`;
