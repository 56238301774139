import React, { useCallback, useEffect, useState } from 'react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';

import { Camera, PermissionStatus } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

import styled from 'styled-components';
import { ImageUploadInput } from 'ui/atoms/image-upload-input';
import { useFormik } from 'formik';
import { InputChangeEventDetail } from '@ionic/core';
import { useUpdateProfile, useUploadGhanaDocs } from 'api/queries';
import Notifications from 'components/Notifications';
import { useI18nObject } from 'utils/use-i18n-object';

import { Button } from 'ui/atoms/button';

import type { User } from 'models';
import { Input } from 'ui/atoms/input';
import { CameraType } from 'utils/photo-capture';
import { camera, crop } from 'ionicons/icons';

interface Props {
  onSkip: () => void;
  onDone: (userProfile: User) => void;
  country: string;
}

interface Form {
  idCardNumber: string;
  idCardPhoto: File | null;
  selfiePhoto: File | null;
  idCardPhotoCropped: File | null;
  selfiePhotoCropped: File | null;
}

export const Margins: React.VFC<Props> = ({ onSkip, onDone, country }) => {
  const [cameraPermission, setCameraPermission] =
    useState<PermissionStatus['camera']>();

  const uploadDocumentMutation = useUploadGhanaDocs();
  const user = useStore($user);
  const LL = useI18nObject();

  const updateProfileMutation = useUpdateProfile();

  const formik = useFormik<Form>({
    initialValues: {
      idCardNumber: '',
      idCardPhoto: null,
      selfiePhoto: null,
      idCardPhotoCropped: null,
      selfiePhotoCropped: null,
    },
    validate: ({ idCardNumber, idCardPhoto, selfiePhoto }) => {
      const errors: { [key: string]: string } = {};
      if (country === 'GH') {
        if (!idCardNumber) {
          errors.idCardNumber = 'Personal ID Number';
        }
        if (!/^\w{3}-\d{9}-\w$/i.test(idCardNumber)) {
          errors.idCardNumber =
            'Personal ID Number should follow AAA-000000000-0 format';
        }
        if (!idCardPhoto) {
          errors.idCardPhoto = 'ID Card photo is required';
        }
        if (!selfiePhoto) {
          errors.selfiePhoto = 'Selfie photo is required';
        }
      }
      return errors;
    },
    onSubmit: async ({ idCardNumber, idCardPhoto, selfiePhoto }) => {
      if (idCardPhoto && selfiePhoto) {
        try {
          const userProfile = await updateProfileMutation.mutateAsync({
            onboarding_data: {
              ...user?.onboarding_data,
              skip_id_document: true,
            },
          });
          if (userProfile) {
            onDone(userProfile);
          }
          await uploadDocumentMutation.mutateAsync({
            id_country: country,
            id_front_image: idCardPhoto,
            id_back_image: idCardPhoto,
            id_selfie_image: selfiePhoto,
            id_number: idCardNumber,
            id_type: 'NATIONAL_ID',
          });
        } catch (e) {
          if (typeof e === 'string') {
            await Notifications.alert(e);
          }
        }
      }
    },
  });

  const { idCardNumber, idCardPhotoCropped, selfiePhotoCropped } =
    formik.values;

  const updateCameraPermissions = async () => {
    const { camera } = await Camera.checkPermissions();
    setCameraPermission(camera);
    return camera;
  };
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      updateCameraPermissions();
    }
  }, []);

  const createIonChangeHandler =
    (fieldName: keyof Form) => (e: CustomEvent<InputChangeEventDetail>) => {
      formik.setFieldValue(fieldName, e.detail.value);
    };

  const cameraPermissionGranted = cameraPermission === 'granted';
  const canProceedToVerification =
    Capacitor.getPlatform() === 'web' || cameraPermissionGranted;

  const cameraPermissionDenied = cameraPermission === 'denied';

  const onRequestPermissionsClick = useCallback(async () => {
    const { camera } = await Camera.requestPermissions({
      permissions: ['camera'],
    });
    updateCameraPermissions();
  }, []);

  const onSubmitAttempt = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formik.isValid) {
      formik.submitForm();
    } else {
      Notifications.alert(Object.values(formik.errors).join('\n'));
    }
  };

  return (
    <div>
      {!canProceedToVerification && (
        <PermissionRequestForm>
          <h3>{LL.WE_NEED_CAMERA_PERMISSION()}</h3>
          {cameraPermissionDenied && (
            <h4>{LL.CAMERA_PERMISSION_COULD_NOT_BE_REQUESTED()}</h4>
          )}
          <Button onClick={onRequestPermissionsClick}>
            {cameraPermissionDenied ? LL.REFRESH() : LL.GRANT_PERMISSIONS()}
          </Button>
          <br />
        </PermissionRequestForm>
      )}
      <Input
        value={idCardNumber}
        onInputChange={(s: string) => {
          formik.setFieldValue('idCardNumber', s);
        }}
        name="idCardNumber"
        placeholder={LL.PERSONAL_ID_PLACEHOLDER()}
      />
      <ImageUploadInput
        value={idCardPhotoCropped}
        onChange={(originalPhoto: File, croppedPhoto: File) => {
          formik.setFieldValue('idCardPhoto', originalPhoto);
          formik.setFieldValue('idCardPhotoCropped', croppedPhoto);
        }}
        onDelete={() => {
          formik.setFieldValue('idCardPhoto', null);
          formik.setFieldValue('idCardPhotoCropped', null);
        }}
        label={LL.GHANA_CARD_PHOTO()}
        maxSizeMB={1}
        cameraType={CameraType.Rear}
        cameraPermissionGranted={cameraPermissionGranted}
      />
      <ImageUploadInput
        value={selfiePhotoCropped}
        onChange={(originalPhoto: File, croppedPhoto: File) => {
          formik.setFieldValue('selfiePhoto', originalPhoto);
          formik.setFieldValue('selfiePhotoCropped', croppedPhoto);
        }}
        onDelete={() => {
          formik.setFieldValue('selfiePhoto', null);
          formik.setFieldValue('selfiePhotoCropped', null);
        }}
        label="Selfie"
        maxSizeMB={1}
        cameraType={CameraType.Front}
        cameraPermissionGranted={cameraPermissionGranted}
      />
      <Button
        onClick={onSubmitAttempt}
        disabled={!country || !canProceedToVerification}
      >
        {LL.SUBMIT()}
      </Button>
    </div>
  );
};

const PermissionRequestForm = styled.div`
  padding-bottom: 4px;
`;
