import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import { Icon } from 'ui/atoms/icon';
import { useI18nObject } from 'utils/use-i18n-object';

import type {
  DocumentVerificationStatus,
  UploadDocumentResponse,
} from 'models';

interface Props {
  value: UploadDocumentResponse[];
  onDocumentAdd: () => Promise<void>;
  onDocumentDelete: (id: number) => void;
}

export const DocumentsField: React.VFC<Props> = ({
  value,
  onDocumentAdd,
  onDocumentDelete,
}) => {
  const LL = useI18nObject();

  const STATUS_LABELS: { [key in DocumentVerificationStatus]: string } = {
    approved: LL.APPROVED(),
    declined: LL.DECLINED(),
    pending: LL.ON_A_REVIEW(),
  };

  const createDeleteHandler = useCallback(
    (id: UploadDocumentResponse['id']) => () => {
      onDocumentDelete(id);
    },
    [],
  );

  const [addButtonActive, setAddButtonActive] = useState(true);

  const addButtonHandler = useCallback(async () => {
    setAddButtonActive(false);
    await onDocumentAdd();
    setAddButtonActive(true);
  }, []);

  return (
    <Documents>
      {value.map((doc) => (
        <Document key={doc.id}>
          <ImageContainer target="_blank" href={doc.document}>
            <VerificationStatusOverlay status={doc.verification_status} />
            <Image src={doc.document} />
          </ImageContainer>
          <InformationSection>
            <Label>{doc.title}</Label>
            <Status status={doc.verification_status}>
              {
                {
                  pending: (
                    <Icon
                      name="ClockTimeFourOutline"
                      color="hsl(0, 0%, 45%)"
                      size={16}
                    />
                  ),
                  declined: (
                    <Icon
                      name="MinusCircle"
                      color="hsl(0, 100%, 60%)"
                      size={16}
                    />
                  ),
                  approved: (
                    <Icon name="Check" color="hsl(115,100%,30%)" size={16} />
                  ),
                }[doc.verification_status]
              }{' '}
              {STATUS_LABELS[doc.verification_status]}
            </Status>
          </InformationSection>
          <DeleteButton onClick={createDeleteHandler(doc.id)}>
            <Icon name="Delete" color="hsl(0, 0%, 67%)" />
          </DeleteButton>
        </Document>
      ))}
      <Document>
        <ImageContainer as="div">
          <button
            disabled={!addButtonActive}
            onClick={addButtonHandler}
            type="button"
            className="btn"
            aria-label="add"
          >
            <Icon name="PlusCircle" size={48} />
          </button>
        </ImageContainer>
        <InformationSection>
          <Label>{LL.UPLOAD_A_DOCUMENT()}</Label>
        </InformationSection>
      </Document>
    </Documents>
  );
};

const Documents = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 16px;

  margin-bottom: 16px;
`;

const Document = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  :not(:first-child) {
    border-top: 1px solid hsl(0, 0%, 90%);
  }

  padding: 16px 0;
  gap: 24px;
`;

const ImageContainer = styled.a`
  position: relative;
  display: flex;
  flex: 0 1 160px;
  flex-direction: column;
  justify-content: center;
`;

const VerificationStatusOverlay = styled.div<{
  status: DocumentVerificationStatus;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${(p) =>
    ({
      true: 'hsla(0, 0%, 100%, 0.50)',
      [String(p.status === 'approved')]: 'none',
      [String(p.status === 'declined')]: 'hsla(0, 100%, 60%, 0.60)',
    }.true)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img``;

const DeleteButton = styled.button`
  background: none;
  padding: 0;
`;

const InformationSection = styled.div`
  flex: 1;
`;

const Label = styled.p``;

const Status = styled.p<{
  status: DocumentVerificationStatus;
}>`
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${(p) =>
    ({
      true: 'hsl(0, 0%, 45%)',
      [String(p.status === 'approved')]: 'hsl(115,100%,30%)',
      [String(p.status === 'declined')]: 'hsl(0, 100%, 60%)',
    }.true)};

  font-size: 14px;
`;
