import styled from 'styled-components';

const ANIMATION_DURATION_MS = 200;

export const Collapsible = styled.div<{
  isExpanded: boolean;
  expectedHeight?: number;
}>`
  max-height: ${(p) => (p.isExpanded ? p.expectedHeight || '10000' : '0')}px;
  transform: scaleY(${(p) => (p.isExpanded ? 1 : 0)});
  transform-origin: top center;
  opacity: ${(p) => (p.isExpanded ? 1 : 0)};
  visibility: ${(p) => (p.isExpanded ? 'visible' : 'hidden')};
  transition: transform ${ANIMATION_DURATION_MS}ms ease-in-out,
    opacity ${ANIMATION_DURATION_MS}ms ease-in-out,
    visibility ${ANIMATION_DURATION_MS}ms ease-in-out,
    max-height ${0}ms ease-in-out
      ${(p) => (p.isExpanded ? 0 : ANIMATION_DURATION_MS)}ms;
  display: flex;
  flex-direction: column;
`;
