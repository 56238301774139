import React, { ComponentProps, useCallback, useState } from 'react';
import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import styled from 'styled-components';
import type { CheckboxChangeEventDetail } from '@ionic/core';
import { useI18nObject } from 'utils/use-i18n-object';

import { useCreateRecipient } from 'api/queries/mutations';
import { Slider } from 'ui/organisms/Slider';
import { AccountNameResolver } from 'components/AccountNameResolver';
import { useForm } from 'utils/use-form';
import type { AccountTypeI, Operators } from 'models';
import { Button } from 'ui/atoms/button';
import { FinancialAccountPicker } from 'components/FinancialAccountPicker';
import CloseIcon from '../../assets/icon/close.svg';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';

interface Props {
  onRecipientCreated: any;
  onClose: any;
}

interface Form {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  customerName: string;
  confirmedDetails: boolean;
  accountType: AccountTypeI | '';
  country: string;
  bankCode: string;
  bankName: string;
  bankAccountNumber: string;
  mobileOperator: Operators | '';
  mobileNumber: string;
}

export const AddRecipientForm = ({ onRecipientCreated, onClose }: Props) => {
  const LL = useI18nObject();

  const [stageId, setStageId] = useState<0 | 1>(0);

  const createRecipientMutation = useCreateRecipient();

  const formik = useForm<Form>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      customerName: '',
      confirmedDetails: false,
      accountType: '',
      country: '',
      bankCode: '',
      bankName: '',
      bankAccountNumber: '',
      mobileOperator: '',
      mobileNumber: '',
    },
    validate: (values) => {
      const errors: { [P in keyof Form]?: string } = {};
      if (!values.customerName) {
        errors.customerName = LL.REQUIRED();
      }
      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);

      const payload = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
        confirmed_details: values.confirmedDetails,
        account_type: values.accountType,
        country: values.country,
        ...(values.accountType === 'bank_account' && {
          bank_account: {
            bank_code: values.bankCode,
            bank_name: values.bankName,
            account_number: values.bankAccountNumber,
          },
        }),
        ...(values.accountType === 'mobile_money' && {
          mobile_money: {
            operator: values.mobileOperator,
            mobile_number: values.mobileNumber,
          },
        }),
        customer_name: values.customerName,
        financial_account: {
          country: values.country,
          account_type: values.accountType,
          ...(values.accountType === 'bank_account' && {
            bank_account: {
              bank_code: values.bankCode,
              bank_name: values.bankName,
              account_number: values.bankAccountNumber,
            },
          }),
          ...(values.accountType === 'mobile_money' && {
            mobile_money: {
              operator: values.mobileOperator,
              mobile_number: values.mobileNumber,
            },
          }),
          customer_name: values.customerName,
          confirmed_details: values.confirmedDetails,
        },
        // phone_numberCountry: 'GH',
      };

      const recipient = await createRecipientMutation.mutateAsync(payload);

      onRecipientCreated(recipient);
    },
  });

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    customerName,
    confirmedDetails,
  } = formik.values;

  const accountData = {
    accountType: formik.values.accountType,
    country: formik.values.country,
    bankCode: formik.values.bankCode,
    bankName: formik.values.bankName,
    bankAccountNumber: formik.values.bankAccountNumber,
    mobileOperator: formik.values.mobileOperator,
    mobileNumber: formik.values.mobileNumber,
  };

  const onAccountDataChange = useCallback(
    (data: ComponentProps<typeof FinancialAccountPicker>['accountData']) => {
      formik.setValues({
        ...formik.values,
        ...data,
      });
    },
    [formik.values, formik.setFieldValue],
  );

  const isBankAccountFormVisible =
    accountData.country !== 'US' && accountData.accountType === 'bank_account';
  const isMobileMoneyFormVisible =
    accountData.country !== 'US' && accountData.accountType === 'mobile_money';

  const onSubmitAttempt = (e?: React.SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (stageId === 0) {
      setStageId(1);
    } else if (stageId === 1) {
      formik.onSubmitAttempt();
    }
  };

  return (
    <FormStyled onSubmit={onSubmitAttempt}>
      <div className="title formModalTitle">
        {LL.GROW_YOUR_NETWORK()}
        {onClose && (
          <img src={CloseIcon} className="title-close-icon" onClick={onClose} />
        )}
      </div>
      <SliderContainer>
        <Slider slideId={stageId}>
          <Stage hidden={stageId !== 0}>
            <StageContent>
              <div className="input-group">
                <div className="input-group-title">{LL.PERSONAL_DETAILS()}</div>

                <IonGrid className="input-grid-group">
                  <IonRow>
                    <IonCol className="input-field">
                      <IonItem className="input-item" lines="none">
                        <IonInput
                          value={firstName}
                          onIonChange={formik.createIonChangeHandler(
                            'firstName',
                          )}
                          name="firstName"
                          className="input"
                          placeholder={LL.PERSON_OR_BUSINESS_NAME()}
                          required
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <div className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInput
                      value={email}
                      onIonChange={formik.createIonChangeHandler('email')}
                      name="email"
                      className="input"
                      type="email"
                      placeholder={LL.EMAIL()}
                    />
                  </IonItem>
                </div>

                <div className="input-field">
                  <IonItem className="input-item" lines="none">
                    <PhoneNumberInput
                      value={phoneNumber}
                      onChange={(v: string) => {
                        formik.setFieldValue('phoneNumber', v);
                      }}
                      name="phoneNumber"
                      required
                      className="input"
                      type="text"
                      placeholder={LL.PHONE_NUMBER()}
                    />
                  </IonItem>
                </div>
              </div>

              <div className="input-group" style={{ paddingBottom: 0 }}>
                <div className="input-group-title">{LL.ACCOUNT_DETAILS()}</div>

                <FinancialAccountPicker
                  directionType="push"
                  accountData={accountData}
                  onAccountDataChange={onAccountDataChange}
                />
              </div>
            </StageContent>
            <Button>{LL.NEXT()}</Button>
          </Stage>
          <Stage hidden={stageId !== 1}>
            <StageContent>
              {stageId === 1 &&
                (isBankAccountFormVisible || isMobileMoneyFormVisible) && (
                  <AccountNameResolver
                    accountData={accountData}
                    customerName={customerName}
                    setCustomerName={(v: string) => {
                      formik.setFieldValue('customerName', v);
                    }}
                  />
                )}
              <div className="input-field">
                <IonItem className="input-item-checkbox" lines="none">
                  <IonCheckbox
                    checked={confirmedDetails}
                    onIonChange={(
                      e: CustomEvent<CheckboxChangeEventDetail>,
                    ) => {
                      formik.setFieldValue(
                        'confirmedDetails',
                        e.detail.checked,
                      );
                    }}
                    name="confirmedDetails"
                    className="input"
                  />
                  <IonLabel className="input-label">
                    {LL.CONFIRMED_DETAILS()}
                  </IonLabel>
                </IonItem>
              </div>
            </StageContent>
            <Button
              type="button"
              ghost
              onClick={() => {
                setStageId(0);
              }}
            >
              {LL.BACK()}
            </Button>
            <Button>{LL.SUBMIT()}</Button>
          </Stage>
        </Slider>
      </SliderContainer>
    </FormStyled>
  );
};

const FormStyled = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SliderContainer = styled.div`
  flex: 1;

  background: white;
  border-radius: 0;
`;

const Stage = styled.div`
  padding: 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StageContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
