import React from 'react';
import styled from 'styled-components';
import { CurrencyInput } from 'ui/molecules/currency-input';
import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';
import { TransactionLimit } from 'ui/atoms/transaction-limit';
import { formatAmount } from 'data/currencies';
import { useI18nObject } from 'utils/use-i18n-object';
import { SelectInput } from 'ui/atoms/select';
import { TextArea } from 'ui/molecules/textarea';

interface Props {
  onGoNext: () => void;
  onGoBack: () => void;
}
export const SelectAmount = ({ onGoNext, onGoBack }: Props) => {
  const LL = useI18nObject();

  return (
    <PrevNextStepper
      nextStepActive
      onPrevClick={onGoBack}
      onNextClick={onGoNext}
    >
      <Form
        onSubmit={() => {
          console.log('submit form');
        }}
      >
        <Section>
          <div className="transfer-stage-header">{LL.PREFUND_BALANCE()}</div>
          <div>
            <Row>
              <span> {LL.AMOUNT()} </span>
              <CurrencyInputContainer>
                <CurrencyInput name="amount" defaultValue={20} />
              </CurrencyInputContainer>
            </Row>
            <TransactionLimit isError={false}>
              {LL.MIN_AMOUNT_PER_TRANSACTION({
                currencyAmount: formatAmount({
                  amount: 20,
                  currency: 'GHS',
                }),
              })}
            </TransactionLimit>

            <TransactionLimit isError={false}>
              {LL.MAX_AMOUNT_PER_TRANSACTION({
                currencyAmount: formatAmount({
                  amount: 40,
                  currency: 'GHS',
                }),
              })}
            </TransactionLimit>
          </div>
        </Section>
        <Section>
          <div className="input-field">
            <div className="input-item">
              <SelectInput onValueChange={(v) => console.log(v)}>
                <option value="">{LL.PREFUND_USING_OTHER_WAYS()}</option>
              </SelectInput>
            </div>
          </div>
          <div className="input-field">
            <TextArea
              rows={10}
              onTextChange={(v) => console.log(v)}
              placeholder="description"
            />
          </div>
          <Row>
            <div>{LL.TOTAL()}</div>
            <div>
              {formatAmount({
                amount: 20,
                currency: 'GHS',
              })}
            </div>
          </Row>
          <Row>
            <div>{LL.REFERENCE_CODE()}</div>
            <div>SH283HSSJDJSJ78283</div>
          </Row>
        </Section>
      </Form>
    </PrevNextStepper>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
`;

const CurrencyInputContainer = styled.span`
  font-size: 0.8rem;
`;
