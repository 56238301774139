import { presentIonModal } from 'modals/present-ion-modal';
import type { ComponentProps } from 'react';
import type { FinancialAccount, DirectionI } from 'models';
import { AddFinancialAccount } from 'components/AddFinancialAccount';

import { ProfileApi } from '../api/http';

export const addFinancialAccountModal = async (
  directionType: DirectionI | 'both',
  recipientId?: number,
): Promise<FinancialAccount | undefined> =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismiss();
      res(undefined);
    };

    const onDone = async (account: FinancialAccount) => {
      await ProfileApi.getProfile();
      res(account);
      onClose();
    };

    const modalProps: ComponentProps<typeof AddFinancialAccount> = {
      onClose,
      onAccountCreated: onDone,
      directionType,
      recipientId,
    };
    const [promptModal, dismiss] = presentIonModal(
      AddFinancialAccount,
      modalProps,
      'addAccountModal',
    );

    promptModal();
  });
