import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import { Redirect } from 'react-router-dom';
import { getOnboardingState } from 'utils/get-onboarding-state';
import { getNextOnboardingStep } from 'utils/get-next-onboarding-step';
import { DevModeEnabledGuard } from 'utils/dev-mode-enabled-guard';
import { $isLoggedIn } from 'stores/derivatives/is-logged-in';

interface Props {
  children: React.ReactNode;
}

export const DashboardGuard = ({ children }: Props) => {
  const isLoggedIn = useStore($isLoggedIn);
  const user = useStore($user);
  if (typeof isLoggedIn !== 'boolean') {
    return <DevModeEnabledGuard>DashboardGuard</DevModeEnabledGuard>;
  }
  if (user) {
    const onboardingStep = getOnboardingState(user);
    if (onboardingStep !== 'complete') {
      const url = getNextOnboardingStep(user);
      console.log(`DashboardGuard: ${url}`, user);
      return <Redirect to={url} />;
    }
  }
  return children;
};
