import { presentIonModal } from 'modals/present-ion-modal';

import type { ComponentProps } from 'react';
import type { Recipient } from 'models';
import { AddNetworkMemberForm } from '../components/AddNetworkMemberForm';

export const addNetworkMemberModal = async (): Promise<Recipient | undefined> =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismiss();
      res(undefined);
    };

    const onDone = async (recipient: Recipient) => {
      res(recipient);
      onClose();
    };

    const modalProps: ComponentProps<typeof AddNetworkMemberForm> = {
      onClose,
      onRecipientCreated: onDone,
    };
    const [promptModal, dismiss] = presentIonModal(
      AddNetworkMemberForm,
      modalProps,
      'addRecipientModal',
    );

    promptModal();
  });
