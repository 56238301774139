import { Icon } from 'ui/atoms/icon';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Capacitor } from '@capacitor/core';

import { UploadButton } from 'ui/atoms/upload-button';
import { CameraType, PhotoCapturePlugin } from 'utils/photo-capture';
import Notifications from 'components/Notifications';

interface Props {
  value: File | null;
  onChange: (original: File, cropped: File) => void;
  maxSizeMB?: number;
  onDelete: () => void;
  label: string;
  required?: boolean;
  cameraType: CameraType;
  cameraPermissionGranted: boolean;
}

export const ImageUploadInput: React.VFC<Props> = ({
  value,
  onChange,
  onDelete,
  label,
  required = false,
  maxSizeMB,
  cameraType,
  cameraPermissionGranted,
}) => {
  const [preview, setPreview] = useState<string>();
  const isNativePlatform = Capacitor.isNativePlatform();
  useEffect(() => {
    let objectUrl: string;
    if (value) {
      objectUrl = URL.createObjectURL(value);
      setPreview(objectUrl);
    }
    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  const launchPhotoCapture = async () => {
    try {
      const r = await PhotoCapturePlugin.startCamera({
        camera_type: cameraType,
      });
      if (r.original_file_uri && r.cropped_file_uri) {
        const originalFile = await getFileObject(r.original_file_uri);
        const croppedFile = await getFileObject(r.cropped_file_uri);
        onChange(originalFile, croppedFile);
      }
    } catch (error) {
      Notifications.alert('Failed to Fetch file');
    }
  };

  const getFileObject = async (file_uri: string) => {
    const webUri = Capacitor.convertFileSrc(file_uri);

    const response = await fetch(webUri);
    const blob = await response.blob();

    const fileName = webUri.split('/').pop() || 'photo.jpg';
    return new File([blob], fileName, { type: blob.type });
  };
  return (
    <Document>
      {value ? (
        <ImageContainer target="_blank" href={preview}>
          <Image src={preview} />
        </ImageContainer>
      ) : (
        <Buttons>
          {!isNativePlatform && (
            <UploadButtonStyled
              onFilePick={(v: File) => {
                onChange(v, v);
              }}
              maxSizeMB={maxSizeMB}
            >
              <Icon name="FileImagePlus" size={48} />
            </UploadButtonStyled>
          )}

          {isNativePlatform && (
            <CameraButtonStyled
              disabled={!cameraPermissionGranted}
              onClick={launchPhotoCapture}
            >
              <Icon
                name="CameraPlus"
                size={48}
                color={cameraPermissionGranted ? 'black' : '--text-color-v3'}
              />
            </CameraButtonStyled>
          )}
        </Buttons>
      )}
      <InformationSection>
        <Label required={required}>{label}</Label>
      </InformationSection>
      {!!value && (
        <DeleteButton onClick={onDelete}>
          <Icon name="Delete" color="hsl(0, 0%, 67%)" />
        </DeleteButton>
      )}
    </Document>
  );
};

const Document = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px 0;
  gap: 24px;
`;

const Label = styled.p<{ required: boolean }>`
  font-weight: ${({ required }) => (required ? 600 : 'normal')};
`;

const Buttons = styled.div`
  flex: 0 1 160px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const UploadButtonStyled = styled(UploadButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;

  background-color: #efefef;
  border-radius: 4px;
`;

const CameraButtonStyled = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;

  background-color: #efefef;
  border-radius: 4px;
`;

const ImageContainer = styled.a`
  flex: 0 1 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: relative;
`;

const Image = styled.img``;

const InformationSection = styled.div`
  flex: 1;
`;

const DeleteButton = styled.button`
  background: none;
  padding: 0;
`;
