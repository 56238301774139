import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalStyle } from 'global-style';

import { ToastContainer } from 'components/ToastContainer';

import { useLogout } from 'api/queries';
import { AppUrlListener } from 'utils/app-url-listener';
import { Pay } from 'pages/Pay';
import { PaymentRequestTransaction } from 'pages/PaymentRequestTransaction';
import { Invoice } from 'pages/Invoice';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';
import { UserAgreement } from 'pages/UserAgreement';

import { Dev } from 'pages/Dev';
import Onboarding from 'pages/Onboarding/Onboarding';
import Dashboard from 'pages/Dashboard/Dashboard';
import { SignIn } from 'pages/SignIn/SignIn';
import { SignUp } from 'pages/SignUp/SignUp';
import NotFound from 'pages/NotFound/NotFound';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { UserHelper } from 'utils/user-helper';
import { IdleHelper } from 'utils/idle-helper';
import { ModalSwitch } from 'utils/modal-switch';
import { ConfigIndicator } from 'components/ConfigIndicator';
import { FetchingIndicator } from 'components/FetchingIndicator';

import { $locale } from 'stores/locale';
import { useStore } from 'effector-react';
import TypesafeI18n from 'i18n/i18n-react';
import { AddPrefundAccount } from 'pages/AddPrefundAccount';
import { loadAllLocalesAsync } from './i18n/i18n-util.async';

const App: React.VFC = () => {
  const { logout } = useLogout();
  const locale = useStore($locale);

  const [localesLoaded, setLocalesLoaded] = useState(false);
  useEffect(() => {
    loadAllLocalesAsync().then(() => setLocalesLoaded(true));
  });

  if (!localesLoaded) {
    return null;
  }

  return (
    <TypesafeI18n locale={locale}>
      <AppStyled>
        <GlobalStyle />
        <UserHelper />
        <IdleHelper />
        <ConfigIndicator />
        <FetchingIndicator />
        <Router>
          <AppUrlListener />
          <ModalSwitch>
            <Route path="/onboarding" component={Onboarding} />
            <Route
              path="/dashboard/prefund-accounts/add"
              component={AddPrefundAccount}
            />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/user-agreement" component={UserAgreement} />
            <Route path="/pay/:id" component={Pay} />
            <Route path="/result/:id" component={PaymentRequestTransaction} />
            <Route path="/invoice/:id" component={Invoice} />
            <Route path="/signin" component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route path="/dev" component={Dev} />
            <Route
              path="/logout"
              render={(props) => {
                logout();
                return <Redirect to="/" />;
              }}
            />
            <Route exact path="/" component={SignIn} />
            <Route component={NotFound} />
          </ModalSwitch>
        </Router>
        <ToastContainer />
      </AppStyled>
    </TypesafeI18n>
  );
};

const AppStyled = styled.div`
  overflow: auto;
  height: 100%;
`;

export default App;
