import { useStore } from 'effector-react';
import { Redirect, useLocation } from 'react-router-dom';
import { DevModeEnabledGuard } from 'utils/dev-mode-enabled-guard';
import { $isLoggedIn } from 'stores/derivatives/is-logged-in';

interface Props {
  children: React.ReactNode;
}

export const AuthGuard = ({ children }: Props) => {
  const location = useLocation();
  const redirect = `${location.pathname}${location.search}`;
  const isLoggedIn = useStore($isLoggedIn);
  if (typeof isLoggedIn !== 'boolean') {
    return <DevModeEnabledGuard>AuthGuard</DevModeEnabledGuard>;
  }
  console.log('AuthGuard: ', { isLoggedIn });
  return isLoggedIn ? (
    children
  ) : (
    <Redirect to={`/signin?redirect=${redirect}`} />
  );
};
