import React from 'react';
import styled from 'styled-components';
import { Modal } from 'components/Modal';

interface Props {
  children: React.ReactNode;
  iconComponent: React.ReactNode;
  onClose: () => void;
}

export const NotificationPopup: React.VFC<Props> = ({
  children,
  iconComponent,
  onClose,
}) => (
  <Modal>
    <Notification>
      {iconComponent && <IconContainer>{iconComponent}</IconContainer>}
      <NotificationBody>{children}</NotificationBody>
      <Actions>
        <CloseButton onClick={onClose}>OK</CloseButton>
      </Actions>
    </Notification>
  </Modal>
);

const Notification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  min-width: 320px;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 8px;
  background-color: #fff;
  padding: calc(0 + env(safe-area-inset-top)) 0 0;
`;

const NotificationBody = styled.div`
  padding: 40px 40px 20px;
`;

const IconContainer = styled.div`
  margin: 50px auto 0;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 0 28px;
`;

const CloseButton = styled.button`
  color: blue;

  line-height: 1.5;
  border: none;
  border-radius: 0;
  padding: 1rem;
  outline: none !important;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
`;
