import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

interface Props {
  children: React.ReactNode;
}

export const TitlePortal: React.VFC<Props> = ({ children }) => {
  const portalDomNode = document.getElementById('title-portal');
  const Component = (
    <TitleContainer>
      <Title>{children}</Title>
    </TitleContainer>
  );
  if (portalDomNode) {
    return createPortal(Component, portalDomNode);
  }
  return null;
};

const TitleContainer = styled.div``;

const Title = styled.h1`
  font-size: 1rem;
  color: var(--text-color-v1);
  font-weight: 500;
  margin: 0;

  @media (min-width: 560px) {
    font-size: 1.4rem;
  }
`;
