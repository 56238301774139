import { useStore } from 'effector-react';
import { $user } from 'stores/user';

import styled from 'styled-components';
import { DashboardPage } from 'pages/Dashboard';
import { useTransactionsList } from 'api/queries';
import { TitlePortal } from 'components/TitlePortal';

import { useI18nObject } from 'utils/use-i18n-object';
import TransactionListItem from '../../components/TransactionListItem/TransactionListItem';
import { Header } from './Header';

export const DashboardHome: React.FC = () => {
  const user = useStore($user);
  const LL = useI18nObject();
  const { data: transactionList } = useTransactionsList();

  return (
    <>
      <TitlePortal>{LL.HOME()}</TitlePortal>
      <DashboardPage>
        <Header />
        {!!user?.allowed_to_perform_transactions && (
          <DashboardContent>
            {transactionList?.map((transaction, index) => (
              <TransactionListItem
                key={transaction.id}
                transaction={transaction}
              />
            ))}
          </DashboardContent>
        )}
      </DashboardPage>
    </>
  );
};

const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background: white;
  border-radius: 0.5rem;
  padding: 0 0 4rem;
  flex-grow: 1;
`;
