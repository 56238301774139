import styled from 'styled-components';
import { useStore } from 'effector-react';
import { $locale } from 'stores/locale';

const MTNWarningEnglish = () => (
  <>
    <h3>
      Follow instructions below <br />
      to complete transaction!
    </h3>
    <p>
      Please authorize via the prompt on your mobile phone.
      <br />
      If no prompt appears, follow the approval steps below.
    </p>

    <p>
      <strong>For Merchant MTN Mobile Money Account:</strong>
    </p>
    <ul>
      <li>Dial *171#</li>
      <li>Select option 7 (MY WALLET)</li>
      <li>Select option 9 (MY APPROVALS)</li>
      <li>Enter PIN to see your pending approvals</li>
      <li>Choose your pending transaction/payment</li>
      <li>Select 1 to approve payment</li>
    </ul>

    <p>
      <strong>For Non-Merchant MTN Mobile Money Account:</strong>
    </p>
    <ul>
      <li>Dial *170#</li>
      <li>Select option 6 (MY WALLET)</li>
      <li>Select option 3 (MY APPROVALS)</li>
      <li>Enter PIN to see your pending approvals</li>
      <li>Choose your pending transaction/payment</li>
      <li>Select 1 to approve payment</li>
    </ul>
    <p>* A pending approval expires after 3 minutes</p>
  </>
);

const MTNWarningFrench = () => (
  <>
    <h3>
      Suivez les instructions ci-dessous <br />
      pour terminer la transaction!
    </h3>
    <p>
      Veuillez vous authentifier via la fenêtre contextuelle sur votre téléphone
      mobile.
      <br />
      Si aucune fenêtre contextuelle n'apparaît, suivez les étapes d'approbation
      ci-dessous.
    </p>

    <p>
      <strong>Pour le compte marchand MTN Mobile Money:</strong>
    </p>
    <ul>
      <li>Composez *171#</li>
      <li>Sélectionnez l’option 7 (MON PORTEFEUILLE)</li>
      <li>Sélectionnez l’option 9 (MES APPROBATIONS)</li>
      <li>Entrez votre code PIN pour voir vos approbations en attente</li>
      <li>Choisissez votre transaction/paiement en attente</li>
      <li>Sélectionnez 1 pour approuver le paiement</li>
    </ul>

    <p>
      <strong>Pour le compte MTN Mobile Money non marchand:</strong>
    </p>
    <ul>
      <li>Composez *170#</li>
      <li>Sélectionnez l’option 6 (MON PORTEFEUILLE)</li>
      <li>Sélectionnez l’option 3 (MES APPROBATIONS)</li>
      <li>Entrez votre code PIN pour voir vos approbations en attente</li>
      <li>Choisissez votre transaction/paiement en attente</li>
      <li>Sélectionnez 1 pour approuver le paiement</li>
    </ul>
    <p>* Une approbation en attente expire après 3 minutes.</p>
  </>
);

export const MTNWarning = () => {
  const locale = useStore($locale);
  const MTNWarningContent =
    locale === 'fr' ? MTNWarningFrench : MTNWarningEnglish;

  return (
    <MTNWarningStyled>
      <MTNWarningContent />
    </MTNWarningStyled>
  );
};

const MTNWarningStyled = styled.div`
  h3 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 48px;
  }
  ul {
    list-style: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  p {
    margin-bottom: 16px;
  }
`;
