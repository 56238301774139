import React from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

interface Params {
  message: string;
  timeout?: number;
  onUndo?: () => void;
}

export const inform = ({ message, timeout }: Params) => {
  const toastId = uuidv4();

  toast(
    () => (
      <>
        <span>{message}</span>
      </>
    ),
    {
      toastId,
      position: toast.POSITION.BOTTOM_CENTER,
      closeButton: true,
      closeOnClick: true,
      hideProgressBar: true,
      autoClose: timeout || 4 * 1000,
    },
  );
};
