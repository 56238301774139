import React from 'react';
import { createPortal } from 'react-dom';
import { useIsFetching, useIsMutating } from 'react-query';

import styled, { keyframes } from 'styled-components';
import { useStore } from 'effector-react';
import { $spinnerCounter } from 'stores/spinner';

export const FetchingIndicator = () => {
  const fetchingCount = useIsFetching();
  const mutatingCount = useIsMutating();
  const spinnerCounter = useStore($spinnerCounter);
  const showProgressBar = true;

  const Component = (
    <Overlay>
      {showProgressBar && (
        <ProgressBar>
          <Progress />
        </ProgressBar>
      )}
    </Overlay>
  );
  const portalDomNode = document.getElementById('spinner');
  if (portalDomNode && (fetchingCount || mutatingCount || spinnerCounter)) {
    return createPortal(Component, portalDomNode);
  }
  return null;
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
  content: '';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: hsla(0, 0%, 100%, 0.5);
`;

const load = keyframes`
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
`;

const Progress = styled.div`
  position: absolute;
  width: 40%;
  height: 100%;
  background-color: var(--primary-color-main);
  animation: ${load} 1.6s infinite ease;
`;
