import { forwardRef, PureComponent } from 'react';
import { VGSLoadedGuard } from 'guards/VGSLoadedGuard';

import { useStore } from 'effector-react';
import { $config } from 'stores/config';

import { spinnerDecrease, spinnerIncrease } from 'stores/spinner';
import { $authToken } from 'stores/auth';
import { $locale } from 'stores/locale';
import Notifications from 'components/Notifications';
import { getErrorMessage } from 'api/interceptor';

import '../vgs.css';

interface State {
  vgsForm: any;
}

interface CreditCardFormProps {
  onValid: (v: boolean) => void;
  vgsEnvironment: string;
  vgsVaultId: string;
  authToken: string;
  locale: string;
}

interface SubmitArgs {
  endpoint: string;
  body: { [key: string]: any };
}

export class CVVFormInner extends PureComponent<CreditCardFormProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      vgsForm: null,
    };
  }

  onVgsInit(inputField: any) {
    const { VGSCollect } = window as any;

    const { vgsEnvironment, vgsVaultId } = this.props;

    const vgsForm = VGSCollect.create(
      vgsVaultId,
      vgsEnvironment,
      (formState: any) => {
        const { onValid } = this.props;
        onValid(formState.cvv.isValid);
      },
    );

    // Create VGS Collect field for CVC
    vgsForm.field(inputField, {
      type: 'card-security-code',
      name: 'cvv',
      placeholder: 'cvv',
      validations: ['required', 'validCardSecurityCode'],
      showCardIcon: true,
      css: {
        fontSize: '1rem',
        lineHeight: 'normal',
      },
    });

    this.setState({ vgsForm });
  }

  setInputRef = (inputContainer: any) => {
    if (inputContainer) this.onVgsInit(inputContainer);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  async submit({
    endpoint,
    body,
  }: SubmitArgs): Promise<{ [key: string]: any }> {
    return new Promise((res, rej) => {
      const { vgsForm } = this.state;
      const { authToken, locale } = this.props;

      const headers = {
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
        ...(locale && { 'Accept-Language': locale }),
      };

      const data = {
        ...body,
      };

      spinnerIncrease();
      vgsForm.submit(
        endpoint,
        {
          mapDotToObject: 'merge',
          data,
          headers,
          method: 'post',
        },
        (status: any, data: any) => {
          spinnerDecrease();
          if (status >= 200 && status <= 299) res(data);
          else {
            rej(data);
            Notifications.alert(getErrorMessage(data));
          }
        },
        (errors: any) => {
          spinnerDecrease();
          console.log(errors);
          rej(errors);
          Notifications.alert(getErrorMessage(errors));
        },
      );
    });
  }

  render() {
    return (
      <VGSLoadedGuard>
        <div ref={this.setInputRef} className="vgs-input" />
      </VGSLoadedGuard>
    );
  }
}

interface Props {
  onValid: (v: boolean) => void;
}

export const CVVForm = forwardRef<CVVFormInner, Props>(
  ({ onValid }: Props, forwardedRef) => {
    const { vgsEnvironment, vgsVaultId } = useStore($config);
    const locale = useStore($locale);
    const authToken = useStore($authToken);
    return (
      <CVVFormInner
        ref={forwardedRef}
        onValid={onValid}
        vgsEnvironment={vgsEnvironment}
        vgsVaultId={vgsVaultId}
        authToken={authToken}
        locale={locale}
      />
    );
  },
);
