export const checkMinLengthValid = (v: string) => (v || '').length >= 8;
export const checkMinUpperCaseValid = (v: string) => !!/[A-Z]+/.exec(v || '');
export const checkMinLowerCaseValid = (v: string) => !!/[a-z]+/.exec(v || '');
export const checkMinNumberValid = (v: string) => !!/\d+/.exec(v || '');

export const checkPassword = (v: string) =>
  [
    checkMinLengthValid(v),
    checkMinUpperCaseValid(v),
    checkMinLowerCaseValid(v),
    checkMinNumberValid(v),
  ].every(Boolean);
