import styled from 'styled-components';

export const DashboardPage = styled.div`
  padding: 16px;
  margin: 8px;

  display: flex;
  flex-direction: column;

  background: white;
  border-radius: 0.5rem;
`;
