import React, { useMemo, useCallback } from 'react';
import { IonItem, IonInput, IonGrid, IonCol, IonRow } from '@ionic/react';
import { useI18nObject } from 'utils/use-i18n-object';

import styled from 'styled-components';
import type { User } from 'models';
import { LinkButton } from 'ui/atoms/link-button';
import { useVerifyAccountDeletion } from 'api/queries';

import styles from './VerifySecurityCode.module.css';
import TextChatIcon from '../../assets/icon/text-chat.svg';

import { VerificationTypes, VerificationActions } from '../../data';
import { AuthApi, ProfileApi } from '../../api/http';
import { getFormData } from '../../utils';

import Notifications from '../Notifications';

interface Props {
  onDone: (userProfile: User) => void;
  onRequestAnotherDestination?: React.MouseEventHandler<HTMLAnchorElement>;
  isRecoveryDestination?: boolean;
  verificationAction?: VerificationActions;
  verificationType?: VerificationTypes;
  destination?: string;
}

export const VerifySecurityCode: React.VFC<Props> = ({
  onRequestAnotherDestination,
  isRecoveryDestination = false,
  verificationAction = VerificationActions.changePhoneNumber,
  verificationType = VerificationTypes.phoneNumber,
  destination,
  onDone,
}) => {
  const LL = useI18nObject();

  const verifyAccountDeletion = useVerifyAccountDeletion();
  const destinationMask = useMemo(() => {
    if (
      verificationType === VerificationTypes.email ||
      verificationType === VerificationTypes.accountDeletionEmail
    )
      return `${destination ? destination[0] : ''}***@**${destination?.substr(
        destination.length - 2,
      )}`;
    return `+* *** *** **${destination?.substr(destination.length - 2)}`;
  }, [destination]);

  const onResendCode = useCallback(async () => {
    /*
  	if(verificationType === VerificationTypes.email) {
  		const params = {email: destination, verification_type: verificationType}
  		await AuthApi.sendVerificationCode(params)
  	}
    */

    if (verificationType === VerificationTypes.phoneNumber) {
      const params = {
        phone_number: destination,
        verification_type: verificationType,
        verification_action: verificationAction,
      };
      await AuthApi.sendVerificationCode(params);
    }

    Notifications.alert(LL.VERIFICATION_CODE_HAS_BENN_RESENT());
  }, [verificationType, destination]);

  const onSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      const { nativeEvent } = event;

      const { code } = getFormData(nativeEvent.target as HTMLFormElement);

      /*
  	if(verificationType === VerificationTypes.email) {
  		const params = {email: destination, code, verification_type: verificationType}
  		if(!isRecoveryDestination)
  			await AuthApi.verifyEmail(params)
  		else
  			await AuthApi.verifyRecoveryEmail(params)
  	}
    */

      if (verificationType === VerificationTypes.phoneNumber) {
        const params = {
          phone_number: destination,
          code,
          verification_type: verificationType,
          verification_action: verificationAction,
        };
        if (!isRecoveryDestination) await AuthApi.verifyPhoneNumber(params);
        // else
        //	await AuthApi.verifyRecoveryPhoneNumber(params)
      }

      if (verificationType === VerificationTypes.accountDeletionEmail) {
        const payload = {
          code,
        };
        console.log(payload);
        await verifyAccountDeletion.mutateAsync(payload);
      }

      const user = await ProfileApi.getProfile();

      onDone(user);
    },
    [verificationType, destination, onDone, isRecoveryDestination],
  );

  return (
    <Form onSubmit={onSubmit}>
      <div className={`title ${styles.title}`}>
        {LL.ENTER_THE_SECURITY_CODE_SENT_TO()}
        <br />
        {destinationMask}
      </div>
      <IonGrid className={`input-grid-group ${styles.formContainer}`}>
        <IonRow>
          <IonCol size="4">
            <img className="text-chat-icon" src={TextChatIcon} />
          </IonCol>
          <IonCol size="8">
            <IonItem className="input-item" lines="none">
              <IonInput name="code" required className="input" />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
      <button type="submit" className="btn submit-btn">
        {LL.SUBMIT()}
      </button>
      <IonGrid className={`input-grid-group ${styles.footer}`}>
        <IonRow
          className={`${styles.actionsFooter} ${
            !onRequestAnotherDestination && styles.actionsFooterSingleAction
          }`}
        >
          <IonCol>
            <a className="cursor" onClick={onResendCode}>
              {LL.RESEND_CODE()}
            </a>
          </IonCol>
          {onRequestAnotherDestination && (
            <IonCol>
              <LinkButton onClick={onRequestAnotherDestination}>
                {LL.USE_ANOTHER_PHONE()}
              </LinkButton>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </Form>
  );
};

const Form = styled.form`
  padding: 16px;
`;
