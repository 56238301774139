import React, { useState, useCallback } from 'react';
import { IonItem } from '@ionic/react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import styled from 'styled-components';
import type { User } from 'models';
import { useUpdateProfile } from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

import { VerificationTypes, VerificationActions } from '../../data';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';

import styles from './VerifyPhoneNumber.module.css';

import { AuthApi } from '../../api/http';
import { getFormData } from '../../utils';
import CloseIcon from '../../assets/icon/close.svg';
import CountrySelect from '../CountrySelect/CountrySelect';

interface Props {
  onDone?: (phoneNumber: string) => void;
  onContinue?: (userProfile: User) => void;
  title?: any;
  message?: any;
  isModal?: any;
  initialPhoneNumber?: any;
  onClose?: any;
}

const VerifyPhoneNumber: React.VFC<Props> = ({
  onDone,
  onContinue,
  title,
  message,
  isModal,
  initialPhoneNumber,
  onClose,
}) => {
  const LL = useI18nObject();

  const [country, setCountry] = useState<string>();
  const user = useStore($user);

  const onSelectCountryChange = useCallback((event) => {
    console.log(event);
    const { value } = event.detail;
    setCountry(value);
  }, []);

  const onPhoneInputCountryChange = (v: string) => {
    setCountry(v);
  };

  const updateProfileMutation = useUpdateProfile();

  const onSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const { nativeEvent } = event;

      const data = {
        ...getFormData(nativeEvent.target as HTMLFormElement),
        verification_type: VerificationTypes.phoneNumber,
        verification_action: VerificationActions.changePhoneNumber,
      };

      const response = await updateProfileMutation.mutateAsync({
        temp_phone_number: data.phone_number,
      });
      await AuthApi.sendVerificationCode(data);
      if (onDone) {
        onDone(data.phone_number);
      } else if (onContinue) {
        onContinue(response);
      }
    },
    [onDone, user],
  );

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {title || LL.TELEPHONE()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <Form onSubmit={onSubmit}>
        <div className="form-container">
          <div className="input-group">
            <div className={`input-group-title ${styles.groupTitle}`}>
              {LL.COUNTRY()}
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <CountrySelect
                  onIonChange={onSelectCountryChange}
                  value={country}
                  interface="popover"
                  className="input"
                  placeholder=""
                />
              </IonItem>
            </div>
          </div>
          <div className="input-group">
            <div className={`input-group-title ${styles.groupTitle}`}>
              {LL.YOUR_PHONE_NUMBER()}
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <PhoneNumberInput
                  onCountryChange={onPhoneInputCountryChange}
                  defaultCountry={country}
                  value={initialPhoneNumber}
                  name="phone_number"
                  required
                  className="input"
                  placeholder=""
                />
              </IonItem>
            </div>
          </div>
        </div>
        <div className={styles.message}>
          {message || LL.SECURITY_IS_CRITICAL()}
        </div>
        <button type="submit" className="btn submit-btn">
          {LL.SEND_CODE()}
        </button>
      </Form>
    </div>
  );
};

const Form = styled.form`
  padding: 16px;
`;

export default VerifyPhoneNumber;
