import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui/atoms/icon';

import type { Currency, Recipient } from 'models';

import { getDueDateMaximum } from 'pages/Request/utils';

import { DatePicker } from 'ui/molecules/date-picker';
import { CurrencyInput } from 'ui/molecules/currency-input';
import { Checkbox } from 'ui/atoms/checkbox';

interface Props {
  selected: boolean;
  recipient: Recipient;
  onAccountSelect: (v: Recipient) => void;
  requestAmount?: number;
  onRequestAmountChange?: (v: number) => void;
  dueDate?: Date;
  onDueDateChange?: (v?: Date) => void;
  currency?: Currency;
  amountError?: boolean;
  dueDateError?: boolean;
  hasCheckbox?: boolean;
}

export const NetworkItem: React.FC<Props> = ({
  selected,
  recipient,
  onAccountSelect,
  requestAmount,
  onRequestAmountChange,
  dueDate,
  onDueDateChange,
  currency,
  amountError,
  dueDateError,
  hasCheckbox = false,
}) => {
  const onSelectAccount = useCallback(() => {
    if (onAccountSelect) {
      onAccountSelect(recipient);
    }
  }, [onAccountSelect, recipient]);

  const nameAbbr = (
    recipient
      ? `${recipient.first_name && recipient.first_name[0]}${
          recipient.last_name && recipient.last_name[0]
        }`
      : ''
  ).toUpperCase();

  return (
    <GridStyled
      className="list-row"
      active={selected && !hasCheckbox}
      hasCheckbox={hasCheckbox}
      as={hasCheckbox ? 'div' : 'label'}
    >
      <input
        type="checkbox"
        checked={selected}
        onChange={onSelectAccount}
        hidden
      />
      <Avatar>
        {hasCheckbox ? (
          <CheckboxStyled value={selected} onCheck={onSelectAccount} />
        ) : (
          <>{nameAbbr}</>
        )}
      </Avatar>

      <Name>
        {recipient.first_name} {recipient.last_name}
      </Name>
      {recipient.email && (
        <>
          <DetailIcon size={16} name="Email" />
          <span>{recipient.email}</span>
        </>
      )}
      {recipient.phone_number && (
        <>
          <DetailIcon size={16} name="PhoneNumber" />
          <span>{recipient.phone_number}</span>
        </>
      )}
      {hasCheckbox && selected && (
        <>
          <DetailIcon size={16} name="Calendar" />
          <DatePickerStyled
            value={dueDate || undefined}
            onDayChange={onDueDateChange}
            disabled={{
              before: new Date(),
              after: getDueDateMaximum(),
            }}
            isError={dueDateError}
          />
          <DetailIcon size={16} name="Cash" />
          <CurrencyInputStyled
            name="amount"
            defaultValue={requestAmount || 0}
            onValueChange={onRequestAmountChange}
            currency={currency}
            isError={amountError}
          />
        </>
      )}
    </GridStyled>
  );
};

const GridStyled = styled.label<{ active: boolean; hasCheckbox: boolean }>`
  padding: 16px;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid
    ${(p) => (p.active ? 'var(--secondary-color-v3)' : 'transparent')};

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  grid-auto-flow: row;
  align-items: center;
  justify-items: start;
  cursor: ${(p) => (p.hasCheckbox ? 'default' : 'pointer')};
`;

const CheckboxStyled = styled(Checkbox)`
  font-size: 24px;
  cursor: pointer;
`;

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--secondary-color-v2);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color-v1);
  justify-self: end;
`;

const DatePickerStyled = styled(DatePicker)`
  max-width: 160px;
  height: 28px;
`;

const CurrencyInputStyled = styled(CurrencyInput)`
  max-width: 160px;
`;

const DetailIcon = styled(Icon)`
  justify-self: end;
`;

const Name = styled.div`
  flex: 1;
  text-align: left;

  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color-v1);
`;
