import React, { useCallback } from 'react';
import { IonItem, IonInput } from '@ionic/react';

import styled from 'styled-components';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import { useForm } from 'utils/use-form';
import { useSecurityQuestionChoices, useUpdateProfile } from 'api/queries';
import { useI18nObject } from 'utils/use-i18n-object';

import { RevealableInput } from 'ui/organisms/revealable-input';
import styles from './RecoveryOptionsForm.module.css';
import CloseIcon from '../../assets/icon/close.svg';

import type { User } from '../../models';

import { AuthApi, ProfileApi } from '../../api/http';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';

interface FormI {
  oldAnswer: string;
  question: number | null;
  answer: string;
  recoveryEmail: string;
  recoveryPhoneNumber: string;
}

interface Props {
  onDone: (user: User) => void;
  onClose?: () => void;
  showSecurityQuestion?: boolean;
  showRecoveryOptions?: boolean;
  isModal?: boolean;
  title?: string;
}

const RecoveryOptionsForm: React.VFC<Props> = ({
  onDone,
  showSecurityQuestion = true,
  showRecoveryOptions = true,
  isModal,
  title,
  onClose,
}) => {
  const LL = useI18nObject();

  const user = useStore($user);
  const currentSecurityQuestion = user?.security_question?.question;
  const updateProfileMutation = useUpdateProfile();

  const formik = useForm<FormI>({
    initialValues: {
      oldAnswer: '',
      question: null,
      answer: '',
      recoveryEmail: user?.recovery_email || '',
      recoveryPhoneNumber: user?.recovery_phone_number || '',
    },
    validate: ({ oldAnswer, question, answer }) => {
      const errors: { [key in keyof FormI]?: string } = {};
      if (currentSecurityQuestion && !oldAnswer) {
        errors.oldAnswer = LL.PLEASE_PROVIDE_THE_OLD_SECURITY_ANSWER();
      }
      if (!question) {
        errors.question = LL.PLEASE_SELECT_THE_QUESTION();
      }
      if (!answer) {
        errors.answer = LL.PLEASE_PROVIDE_NEW_SECURITY_ANSWER();
      }
      return errors;
    },
    onSubmit: async ({
      oldAnswer,
      question,
      answer,
      recoveryEmail,
      recoveryPhoneNumber,
    }) => {
      if (showSecurityQuestion) {
        await AuthApi.createSecurityQuestion({
          ...(oldAnswer && {
            old_answer: oldAnswer,
          }),
          question,
          answer,
        });
      }

      if (showRecoveryOptions) {
        const onboarding_data = {
          ...user!.onboarding_data,
          skip_recovery_options: true,
        };
        const profileData = {
          recovery_email: recoveryEmail,
          recovery_phone_number: recoveryPhoneNumber,
          onboarding_data,
        } as any;
        const response = await updateProfileMutation.mutateAsync(profileData);
        onDone(response);
      } else {
        const user = await ProfileApi.getProfile();
        onDone(user);
      }
    },
  });

  const { data: securityQuestions } = useSecurityQuestionChoices();

  const { oldAnswer, question, answer, recoveryEmail, recoveryPhoneNumber } =
    formik.values;

  const onQuestionChange = useCallback(
    (event) => {
      formik.setFieldValue('question', event.target.value);
    },
    [formik],
  );

  return (
    <div>
      {isModal && (
        <div className="title formModalTitle">
          {title || LL.RECOVERY_OPTIONS()}
          {onClose && (
            <img
              src={CloseIcon}
              className="title-close-icon"
              onClick={onClose}
            />
          )}
        </div>
      )}
      <Form onSubmit={formik.onSubmitAttempt}>
        <h1 className={styles.hint}>{LL.TO_RECOVER_YOUR_ACCOUNT_IF()}</h1>
        {Boolean(currentSecurityQuestion) && (
          <div className={styles.inputGroup}>
            <h2 className={styles.sectionHeader}>
              {LL.CURRENT_SECURITY_QUESTION()}
            </h2>
            <p className={styles.oldQuestion}>{currentSecurityQuestion}</p>
            <div className="input-field">
              <RevealableInputStyled
                value={oldAnswer}
                onInputChange={(v: string) => {
                  formik.setFieldValue('oldAnswer', v);
                }}
                name="oldAnswer"
                type="password"
                className="input"
                placeholder={LL.ANSWER()}
                required
              />
            </div>
          </div>
        )}
        <div className={styles.inputGroup}>
          {showSecurityQuestion && (
            <>
              <div className="input-field">
                <h2 className={styles.sectionHeader}>
                  {LL.SECURITY_QUESTION()}
                </h2>
                <IonItem className="input-item" lines="none">
                  <select
                    value={question || undefined}
                    name="question"
                    placeholder={LL.SECURITY_QUESTION()}
                    required
                    onChange={onQuestionChange}
                  >
                    <option value="">{LL.SELECT_SECURITY_QUESTION()}</option>
                    {(securityQuestions || [])?.map(({ id, question }) => (
                      <option key={id} value={String(id)}>
                        {question}
                      </option>
                    ))}
                  </select>
                </IonItem>
              </div>
              <div className="input-field">
                <RevealableInputStyled
                  value={answer}
                  onInputChange={(v: string) => {
                    formik.setFieldValue('answer', v);
                  }}
                  name="answer"
                  type="password"
                  className="input"
                  placeholder={LL.ANSWER()}
                  required
                />
              </div>
            </>
          )}
          {showRecoveryOptions && (
            <>
              <div className="input-field">
                <h2 className={styles.sectionHeader}>
                  {LL.RECOVERY_EMAIL_ADDRESS_OPTIONAL()}
                </h2>
                <IonItem className="input-item" lines="none">
                  <IonInput
                    value={recoveryEmail}
                    onIonChange={formik.createIonChangeHandler('recoveryEmail')}
                    name="recoveryEmail"
                    type="email"
                    className="input"
                    placeholder={LL.RECOVERY_EMAIL()}
                  />
                </IonItem>
              </div>
              <div className="input-field">
                <h2 className={styles.sectionHeader}>
                  {LL.RECOVERY_PHONE_NUMBER_OPTIONAL()}
                </h2>
                <IonItem className="input-item" lines="none">
                  <PhoneNumberInput
                    value={recoveryPhoneNumber}
                    onIonChange={formik.createIonChangeHandler(
                      'recoveryPhoneNumber',
                    )}
                    name="recoveryPhoneNumber"
                    className="input"
                    placeholder={LL.RECOVERY_PHONE_NUMBER()}
                  />
                </IonItem>
              </div>
            </>
          )}
        </div>
        <button
          type="submit"
          className={`btn submit-btn ${styles.submitButton}`}
          disabled={formik.isSubmitting}
        >
          {LL.SUBMIT()}
        </button>
      </Form>
    </div>
  );
};

const Form = styled.form`
  padding: 16px;
`;

const RevealableInputStyled = styled(RevealableInput)`
  margin-bottom: 1rem;
  padding-left: 16px;
`;

export default RecoveryOptionsForm;
