import React, { FocusEvent, useState } from 'react';
import styled from 'styled-components';
import ReactCurrencyInput from 'react-currency-input-field';

import {
  formatAmount,
  formatCurrency,
  getCurrencyDecimalScale,
  moneyNumberFormat,
} from 'data/currencies';
import type { Currency } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';
import { InputTitle } from 'ui/atoms/input-title';
import { Icon } from 'ui/atoms/icon';

interface Props {
  name: string;
  defaultValue: number;
  onValueChange?: (v: number) => void;
  currency?: Currency;
  title?: string;
  min?: number;
  max?: number;
  isError?: boolean;
  readOnly?: boolean;
}

const fieldValueToAmount = (
  v: string | null | undefined,
  currency?: Currency,
) => {
  if (typeof v !== 'string') {
    return 0;
  }
  const formattedAmount = moneyNumberFormat(v, currency);
  const floatAmount = parseFloat(formattedAmount);
  if (Number.isNaN(floatAmount)) {
    return 0;
  }
  return floatAmount;
};

export const CurrencyInput: React.VFC<Props> = ({
  name,
  defaultValue,
  onValueChange,
  currency,
  title,
  min,
  max,
  isError = false,
  readOnly = false,
}) => {
  // Hack: force uncontrolled component to update when defaultValue changes
  const hack = String(defaultValue);

  const LL = useI18nObject();

  const onInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const [dirtyValue, setDirtyValue] = useState<number>(defaultValue);
  const isLessThanMin = !!(min && dirtyValue && dirtyValue < min);
  const isMoreThanMax = !!(max && dirtyValue && dirtyValue > max);
  // const isError = isError || isLessThanMin || isMoreThanMax;

  const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDirtyValue(fieldValueToAmount(value, currency));
    if (onValueChange) {
      onValueChange(fieldValueToAmount(value, currency));
    }
  };

  const decimalScale = getCurrencyDecimalScale(currency);

  return (
    <CurrencyInputContainer>
      {title && <InputTitle>{title}</InputTitle>}
      <Label isError={isError} isReadOnly={readOnly}>
        {!!currency && (
          <CurrencyLabelContainer>
            <CurrencyFlag currency={currency} />
            <CurrencyLabel>{formatCurrency(currency)}</CurrencyLabel>
          </CurrencyLabelContainer>
        )}
        <CurrencyInputStyled
          key={hack}
          name={name}
          placeholder={moneyNumberFormat(0, currency)}
          defaultValue={defaultValue}
          decimalScale={decimalScale}
          groupSeparator=""
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          allowNegativeValue={false}
          disableAbbreviations
          disableGroupSeparators
          readOnly={readOnly}
        />
      </Label>

      {isLessThanMin && (
        <ErrorContainer>
          {LL.MIN_AMOUNT_PER_TRANSACTION({
            currencyAmount: formatAmount({
              amount: min,
              currency,
            }),
          })}
        </ErrorContainer>
      )}

      {isMoreThanMax && (
        <ErrorContainer>
          {LL.MAX_AMOUNT_PER_TRANSACTION({
            currencyAmount: formatAmount({
              amount: max,
              currency,
            }),
          })}
        </ErrorContainer>
      )}
    </CurrencyInputContainer>
  );
};

const CurrencyFlag = ({ currency }: { currency: Currency }) => {
  const icons = {
    GHS: 'FlagGhana',
    XOF: 'FlagTogo',
    USD: 'FlagUnitedStates',
  };
  const iconName = icons[currency] || 'FlagUnitedStates';
  return <Icon name={iconName} size={24} />;
};

const CurrencyInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.label<{ isError: boolean; isReadOnly: boolean }>`
  border-radius: 0.3rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border-color);
  border-color: ${(p) => p.isError && 'var(--error-color)'};
  opacity: ${(p) => p.isReadOnly && '0.6'};
  user-select: ${(p) => p.isReadOnly && 'none'};
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CurrencyInputStyled = styled(ReactCurrencyInput)`
  outline: none;
  border: none;
  text-align: right;
  flex: 1 1;
  min-width: 80px;
  font-size: 1.2rem;
`;

const CurrencyLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const CurrencyLabel = styled.span`
  font-size: 1rem;
`;

export const ErrorContainer = styled.div`
  color: var(--error-color);
  display: block;
  opacity: 1;
  font-size: 0.8rem;
`;
