import React from 'react';
import styled from 'styled-components';
import {
  IonContent,
  IonFooter,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';

import { DevModeButton } from 'components/DevModeButton';

import { useLogout, useSelf } from 'api/queries';

import { useI18nObject } from 'utils/use-i18n-object';

import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { Icon } from 'ui/atoms/icon';
import { Logo } from 'ui/atoms/logo';
import { MenuItem } from 'pages/Dashboard/MenuItem';
import { AllowedToCreatePrefundAccountsGuard } from 'pages/PrefundAccounts/AllowedToCreatePrefundAccountsGuard';

import { WEB_LINKS } from '../../data';

import './Menu.css';

interface Props {}

export const Menu: React.VFC<Props> = () => {
  const { logout } = useLogout();
  const LL = useI18nObject();

  const currentYear = new Date().getFullYear();
  return (
    <MenuStyled contentId="dashboard-main" type="overlay">
      <IonContent>
        <ListStyled id="inbox-list" lines="none">
          <Logo />
          <MenuItems>
            <IonMenuToggle autoHide={false}>
              <MenuItem
                to="/dashboard"
                highlightForStrict
                icon="HomeIcon"
                iconActive="HomeIconActive"
              >
                {LL.HOME()}
              </MenuItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <MenuItem
                to="/dashboard/go"
                highlightFor={[
                  '/dashboard/go',
                  '/dashboard/transfer',
                  '/dashboard/request',
                ]}
                icon="TransferIcon"
                iconActive="TransferIconActive"
              >
                {LL.SEND__REQUEST()}
              </MenuItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <MenuItem
                to="/dashboard/network"
                icon="NetworkIcon"
                iconActive="NetworkIconActive"
              >
                {LL.MY_NETWORK()}
              </MenuItem>
            </IonMenuToggle>
            <AllowedToCreatePrefundAccountsGuard hideWarning>
              <IonMenuToggle autoHide={false}>
                <MenuItem
                  to="/dashboard/prefund-accounts"
                  icon="WalletIcon"
                  iconActive="WalletIconActive"
                >
                  {LL.PREFUND_BALANCE()}
                </MenuItem>
              </IonMenuToggle>
            </AllowedToCreatePrefundAccountsGuard>
            <IonMenuToggle autoHide={false}>
              <MenuItem
                to="/dashboard/transactions"
                highlightFor={[
                  '/dashboard/transactions',
                  '/dashboard/payment-requests',
                  '/dashboard/bulk-pr',
                ]}
                icon="TransactionHistoryIcon"
                iconActive="TransactionHistoryIconActive"
              >
                {LL.TRANSACTIONS()}
              </MenuItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <MenuItem
                to="/dashboard/settings"
                highlightFor={[
                  '/dashboard/settings',
                  '/dashboard/settings/accounts',
                  '/dashboard/settings/security',
                ]}
                icon="SettingsIcon"
                iconActive="SettingsIconActive"
              >
                {LL.SETTINGS()}
              </MenuItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <MenuItem
                to="/dashboard/help"
                icon="HelpIcon"
                iconActive="HelpIconActive"
              >
                {LL.HELP()}
              </MenuItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <MenuItem
                to="/dashboard/legal"
                icon="FileSignMenuIcon"
                iconActive="FileSignMenuIconActive"
              >
                {LL.LEGAL()}
              </MenuItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <MenuItem to="/logout" icon="LogoutIcon" onClick={logout}>
                {LL.LOGOUT()}
              </MenuItem>
            </IonMenuToggle>
          </MenuItems>
        </ListStyled>
      </IonContent>
      <IonFooter className="dashboard-menu-footer">
        <div className="dashboard-menu-footer-icons">
          <a
            href={WEB_LINKS.facebook}
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <Icon name="FacebookIcon" size={16} />
          </a>
          <a
            href={WEB_LINKS.instagram}
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <Icon name="InstagramIcon" size={16} />
          </a>
          <a
            href={WEB_LINKS.twitter}
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter"
          >
            <Icon name="TwitterIcon" size={16} />
          </a>
          <DevModeButton />
        </div>
        <LanguageSwitcherContainer>
          {LL.PREFERED_LANGUAGE()}
          <LanguageSwitcher />
        </LanguageSwitcherContainer>

        <div className="dashboard-menu-footer-text">
          {LL.FINANCIALLY_CONNECTED()} ®<br />© 2017-{currentYear} TecHustle,
          Inc. and subsidiaries.
        </div>
      </IonFooter>
    </MenuStyled>
  );
};

const LanguageSwitcherContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-color-v3);
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  width: auto;
  gap: 5px;

  select {
    width: auto;
    border: 1px solid var(--text-color-v3);
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.2rem 1rem 0.2rem 0.5rem;
    font-weight: normal;
  }
`;

const MenuStyled = styled(IonMenu)`
  ${Logo} {
    justify-content: flex-start;
    margin: 0 0 48px;
  }
`;

const ListStyled = styled(IonList)`
  padding: 0 0 0 16px;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
