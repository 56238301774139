import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Input } from 'ui/atoms/input';
import format from 'date-fns/format';

import { DateRangePicker } from 'ui/molecules/date-picker';
import { Icon } from 'ui/atoms/icon';
import { throttle } from 'utils';
import { useI18nObject } from 'utils/use-i18n-object';

import '../Dashboard.css';

interface Props {
  onSearch: (
    params: { q?: string; start_date?: string; end_date?: string } | undefined,
  ) => void;
}

export const PaymentRequestsHeader: React.VFC<Props> = ({ onSearch }) => {
  const LL = useI18nObject();

  const [dateFrom, setDateFrom] = useState<Date | undefined>();
  const [dateTo, setDateTo] = useState<Date | undefined>();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const searchParams = {
    ...(searchQuery && {
      q: searchQuery,
    }),
    ...(dateFrom && { start_date: format(dateFrom, 'yyyy-MM-dd') }),
    ...(dateTo && { end_date: format(dateTo, 'yyyy-MM-dd') }),
  };

  const onInput = useCallback(
    throttle((value: string) => {
      setSearchQuery(value);
      const params = {
        ...searchParams,
        q: value,
      };
      onSearch(params);
    }, 1000),
    [searchParams, onSearch],
  );

  const onDateFromSet = useCallback(
    (from?: Date) => {
      setDateFrom(from);
      const params = {
        ...searchParams,
        start_date: from && format(from, 'yyyy-MM-dd'),
      };
      onSearch(params);
    },
    [searchParams, onSearch],
  );

  const onDateToSet = useCallback(
    (to?: Date) => {
      setDateTo(to);
      const params = {
        ...searchParams,
        end_date: to && format(to, 'yyyy-MM-dd'),
      };
      onSearch(params);
    },
    [searchParams, onSearch],
  );

  return (
    <HeaderStyled>
      <SearchBarContainer>
        <Input
          left={<Icon name="Search" size={16} />}
          onInputChange={onInput}
          placeholder={LL.SEARCH()}
        />
      </SearchBarContainer>

      <DateRangePickerStyled
        onDateFromSet={onDateFromSet}
        onDateToSet={onDateToSet}
      />
    </HeaderStyled>
  );
};

const HeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: white;
  padding: 1rem 2rem;
  gap: 16px;
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  flex: 1 0 50%;
`;

const DateRangePickerStyled = styled(DateRangePicker)`
  flex: 1 0 50%;
`;
