import { presentIonModal } from 'modals/present-ion-modal';

import type { ComponentProps } from 'react';
import type { User } from 'models';
import VerifyIdentityForm from '../components/VerifyIdentityForm/VerifyIdentityForm';

export const updatePersonalInfoModal = async (): Promise<User | undefined> =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (user: User) => {
      res(user);
      onClose();
    };

    const modalProps: ComponentProps<typeof VerifyIdentityForm> = {
      onClose,
      onDone,
      isModal: true,
    };
    const [promptModal, dismissModal] = presentIonModal(
      VerifyIdentityForm,
      modalProps,
      'personalInfoModal',
    );

    promptModal();
  });
