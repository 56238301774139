import styled from 'styled-components';
import { PrefundAccount } from 'models';
import { useI18nObject } from 'utils/use-i18n-object';
import LogoBlackAndWhite from 'assets/icon/logo-black-and-white.svg';

interface Props {
  account: PrefundAccount;
  onClick?: () => void;
  isSelected?: boolean;
}
const PrefundAccountListItem = ({
  account,
  isSelected = false,
  onClick,
}: Props) => {
  const LL = useI18nObject();
  return (
    <ItemWrapper selected={isSelected}>
      <Button onClick={onClick}>
        <AvatarIcon src={LogoBlackAndWhite} />
        <Content>
          <Title>
            {account.country} - {account.account_name}
          </Title>
          <BalanceRow>
            <SubTitle>
              {LL.BALANCE()}: {`${account.currency} ${account.balance}`}
            </SubTitle>
          </BalanceRow>
        </Content>
      </Button>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div<{
  selected: boolean;
}>`
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) =>
    p.selected ? 'var(--secondary-color-v3)' : 'transparent'};
`;

const AvatarIcon = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 10px;
  border: 1px solid var(--text-color-v4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-size: 1.1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 90%;
  row-gap: 4px;
`;

const Title = styled.p`
  color: var(--text-color-v0);
  line-height: 1.5rem;
  text-transform: uppercase;
  text-overflow: ellipsis'...';
  white-space: nowrap;
  overflow: hidden;
`;

const SubTitle = styled.span`
  color: var(--text-color-v4);
  line-height: 1.5rem;
  text-overflow: ellipsis'...';
  white-space: nowrap;
  overflow: hidden;
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;
export default PrefundAccountListItem;
