import { i18nObject } from 'i18n/i18n-util';
import { $locale } from 'stores/locale';

export { DocumentsField } from './documents-field';

export const ghanaBusinessDocChoices = (): [string, string][] => {
  const LL = i18nObject($locale.getState());

  return [
    ['metropolitanLicense', LL.METROPOLITAN_LICENSE()],
    ['municipalLicense', LL.MUNICIPAL_LICENSE()],
    ['districtAssemblyLicense', LL.DISTRICT_ASSEMBLY_LICENSE()],
    ['taxReceipt', LL.TAX_RECEIPT()],
    ['certificateOfIncorporation', LL.CERTIFICATE_OF_INCORPORATION()],
    ['certificateToCommerce', LL.CERTIFICATE_TO_COMMERCE()],
  ];
};
