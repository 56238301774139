import styled from 'styled-components';
import { iconsList } from './icons-list';

export type IconKeys = keyof typeof iconsList;

export interface IconI {
  name: IconKeys;
  rotate?: number;
  size?: number;
  lighten?: boolean;
  darken?: boolean;
  color?: string;
  hoverColor?: string;
  className?: string;
}

const TRANSIENT_PROPS: (keyof IconI | string)[] = ['darken', 'lighten'];

export const Icon = styled.div
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<React.PropsWithChildren<IconI>>(({ name }) => ({
    as: iconsList[name],
  }))<React.PropsWithChildren<IconI>>`
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  transform: rotate(${(p) => p.rotate}deg);
  display: inline-block;
  
  transition: transform 200ms ease-in-out;

  color: ${(p) => p.color};

  :hover {
    color: ${(p) => p.hoverColor};
  }
  filter: ${(p) =>
    ({
      true: 'none',
      [String(p.lighten)]: 'brightness(10) saturate(0)',
      [String(p.darken)]: 'brightness(0) saturate(1)',
    }.true)};
`;

Icon.defaultProps = {
  rotate: 0,
  size: 32,
  lighten: false,
  darken: false,
};
