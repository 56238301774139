import { presentIonModal } from 'modals/present-ion-modal';
import type { ComponentProps } from 'react';

import type { FinancialAccount } from 'models';
import EnterCVV from '../components/EnterCVV/EnterCVV';

interface Props {
  endpointUrl: string;
  payload: { [key: string]: any };
  financialAccount: FinancialAccount;
}

export const enterCVVModal = async ({
  endpointUrl,
  payload,
  financialAccount,
}: Props): Promise<{ [key: string]: any }> =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismiss();
      rej();
    };

    const onDone = async (response: { [key: string]: any }) => {
      res(response);
      onClose();
    };

    const modalProps: ComponentProps<typeof EnterCVV> = {
      onClose,
      onDone,
      isModal: true,
      endpointUrl,
      payload,
      financialAccount,
    };
    const [promptModal, dismiss] = presentIonModal(
      EnterCVV,
      modalProps,
      'cvvFormModal',
    );

    promptModal();
  });
