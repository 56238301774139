import { $devModeEnabled } from 'stores/dev-mode';
import { useStore } from 'effector-react';
import { REACT_APP_VERSION } from 'utils/react-app-version';

interface Props {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const DevModeEnabledGuard: React.FC<Props> = ({ children }) => {
  const devModeEnabled = useStore($devModeEnabled);
  if (REACT_APP_VERSION === 'dev') {
    return children;
  }
  return devModeEnabled ? children : null;
};
