import styled from 'styled-components';

export const BaseModalTemplate = styled.div`
  padding: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 0;
  border-radius: 0;
  box-shadow: none;

  @media (min-width: 768px) {
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  }

  h1 {
    font-weight: bold;
    margin: 1rem 0 1.5rem;
  }
`;

export const MaximizedModalTemplate = styled(BaseModalTemplate)`
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 60%;
    height: 90%;
  }
`;
