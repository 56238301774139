import React from 'react';
import type { ComponentProps } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui/atoms/icon';

interface Props {
  onButtonClick: () => void;
  iconProps: ComponentProps<typeof Icon>;
}

const TRANSIENT_PROPS: (keyof Props | string)[] = ['onButtonClick'];

export const IconButton = styled.button
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !TRANSIENT_PROPS.includes(prop) && defaultValidatorFn(prop),
  })
  .attrs<React.PropsWithChildren<Props>>(
    ({ onButtonClick, iconProps, ...props }) => ({
      ...props,
      onClick: onButtonClick,
      children: <Icon {...iconProps} />,
    }),
  )<React.PropsWithChildren<Props>>`
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  
  :active {
    transform: scale(0.9);
  }
`;
