import { useMutation } from 'react-query';

import { useStore } from 'effector-react';

import { $user, setUser } from 'stores/user';
import { resetAuthToken, resetRefreshToken } from 'stores/auth';

import {
  createFetcher,
  queryClient,
  useFetcher,
  useFetcherParams,
  useUrlPrefix,
} from 'api/fetcher';

import { IPQS } from 'api/ipqs';

import {
  CreateBulkPaymentRequestBody,
  CreatePaymentRequestBody,
  CreatePreFundAccountRequest,
  CreateTransactionRequestBody,
  InvoicePayRequestBody,
  PayPaymentRequestRequestBody,
  Recipient,
  RecipientsImportForm,
  UploadAttachmentForm,
  UploadDocumentForm,
  UploadGhanaDocsForm,
  UploadNetworkResponse,
  User,
} from 'models';

export const useDestroyFinancialAccount = () => {
  const { fetcherParams, urlPrefix } = useFetcherParams({
    method: 'DELETE',
  });
  const fetcher = (financialAccountId: number) => {
    return createFetcher({
      ...fetcherParams,
      url: `${urlPrefix}/financial-accounts/${financialAccountId}`,
    });
  };
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`financial-accounts`);
    },
  });
};

export const useCreateRecipient = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'recipients',
    method: 'POST',
  });
  const fetcher = (
    jsonBody: Pick<
      Recipient,
      'email' | 'first_name' | 'last_name' | 'phone_number'
    >,
  ) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });
  return useMutation(fetcher, {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(`recipients`);
    },
  });
};

export const useUpdateRecipient = (recipientId: number) => {
  const { fetcherParams } = useFetcher({
    method: 'PATCH',
    endpoint: `recipients/${recipientId}`,
  });

  const fetcher = (jsonBody: any) =>
    createFetcher({ ...fetcherParams, jsonBody });
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`recipients/${recipientId}`);
    },
  });
};

export const useDestroyRecipient = () => {
  const { fetcherParams, urlPrefix } = useFetcherParams({
    method: 'DELETE',
  });
  const fetcher = (id: number) => {
    return createFetcher({
      ...fetcherParams,
      url: `${urlPrefix}/recipients/${id}`,
    });
  };
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`recipients`);
    },
  });
};

export const useCreateTransaction = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'transactions',
    method: 'POST',
  });

  const fetcher = (jsonBody: CreateTransactionRequestBody) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`transactions`);
    },
  });
};

export const useCreatePaymentRequest = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'payment_requests',
    method: 'POST',
  });
  const fetcher = (jsonBody: CreatePaymentRequestBody) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`payment_requests`);
    },
  });
};

export const useCancelPaymentRequest = () => {
  const urlPrefix = useUrlPrefix();
  const { fetcherParams } = useFetcherParams({
    method: 'DELETE',
  });
  const fetcher = (id: string) => {
    return createFetcher({
      ...fetcherParams,
      url: `${urlPrefix}/payment_requests/${id}`,
    });
  };
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`payment_requests`);
    },
  });
};

export const usePayPaymentRequest = () => {
  const { fetcherParams, urlPrefix } = useFetcherParams({
    method: 'POST',
  });
  const fetcher = (args: {
    id: string;
    payer_account_data?: PayPaymentRequestRequestBody;
  }) => {
    const { id, ...jsonBody } = args;
    return createFetcher({
      ...fetcherParams,
      url: `${urlPrefix}/payment_requests/${id}/pay`,
      jsonBody,
    });
  };
  return useMutation(fetcher, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(`payment_requests/${id}`);
    },
  });
};

export const useCreateBulkPaymentRequest = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'bulk-pr',
    method: 'POST',
  });
  const fetcher = (jsonBody: CreateBulkPaymentRequestBody) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`bulk-pr`);
    },
  });
};
export const useInvoicePayMutation = (invoiceId: string) => {
  const { fetcherParams } = useFetcher({
    endpoint: `invoices/${invoiceId}/pay`,
    method: 'POST',
  });
  const fetcher = (jsonBody: InvoicePayRequestBody) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`invoices/${invoiceId}`);
    },
  });
};

export const useUploadDocument = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'auth/business-document',
    method: 'POST',
  });
  const fetcher = (formData: UploadDocumentForm) => {
    return createFetcher({
      ...fetcherParams,
      formData,
    });
  };
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`auth/business-document`);
    },
  });
};

export const useUploadGhanaDocs = () => {
  const { fetcherParams } = useFetcher({
    method: 'POST',
    endpoint: 'auth/identity-documents-upload',
  });
  const fetcher = (formData: UploadGhanaDocsForm) => {
    return createFetcher({
      ...fetcherParams,
      formData,
    });
  };
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`auth/business-document`);
    },
  });
};

export const useUploadAttachment = () => {
  const { fetcherParams } = useFetcher({
    method: 'POST',
    endpoint: 'attachments',
  });
  const fetcher = (formData: UploadAttachmentForm) => {
    return createFetcher({
      ...fetcherParams,
      formData,
    });
  };
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`auth/business-document`);
    },
  });
};

export const useDestroyBusinessDocument = () => {
  const { fetcherParams, urlPrefix } = useFetcherParams({
    method: 'DELETE',
    responseBodyType: 'text',
  });
  const fetcher = (id: number) => {
    return createFetcher({
      ...fetcherParams,
      url: `${urlPrefix}/auth/business-document/${id}`,
    });
  };
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`auth/business-document`);
    },
  });
};

export const useUpdateProfile = () => {
  const user = useStore($user);
  const endpoint = user ? `auth/profile/${user.id}` : '';
  const { fetcherParams } = useFetcher({
    method: 'PATCH',
    endpoint,
  });
  // const { invalidate } = useSelf();
  const fetcher = (jsonBody: any) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });
  return useMutation(fetcher, {
    onSettled: () => {
      queryClient.invalidateQueries(`auth/profile/self`);
    },
  });
};

export const useCreateFinancialAccount = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'financial-accounts',
    method: 'POST',
  });
  const urlPrefix = useUrlPrefix();
  const fetcher = (jsonBody: { recipient?: number; [key: string]: any }) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });
  return useMutation(fetcher, {
    onSuccess: (_, { recipient }) => {
      if (recipient) {
        queryClient.invalidateQueries(`recipients/${recipient}`);
      }
      queryClient.invalidateQueries(`recipients`);
    },
  });
};

export const useUpdateBusinessSettings = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'business-settings',
    method: 'PATCH',
  });
  const fetcher = (formData: any) =>
    createFetcher({
      ...fetcherParams,
      formData,
    });
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(`business-settings`);
    },
  });
};

export const useCreateAccount = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'auth/create-account',
    method: 'POST',
  });
  const fetcher = (jsonBody: User) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });

  return useMutation(fetcher, {
    onSuccess: (user: User) => {
      setUser(user);
      queryClient.invalidateQueries(`auth/profile/self`);
    },
    onError: (error) => {
      console.log('User creation error', error);
      setUser(null);
    },
  });
};

export const useLogin = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'auth/account/login',
    method: 'POST',
  });
  const fetcher = (jsonBody: { email: string; password: string }) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });

  return useMutation(fetcher, {
    onSuccess: (user: User) => {
      setUser(user);
      IPQS.pushData({
        billing_first_name: user?.first_name || '',
        billing_last_name: user?.last_name || '',
        billing_email: user?.email || '',
        billing_phone: user?.phone_number || '',
        userId: String(user?.id) || '',
      });
      queryClient.invalidateQueries(`auth/profile/self`);
    },
    onError: (error) => {
      console.log('Login error', error);
      setUser(null);
    },
  });
};

export const useLogout = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'auth/account/logout',
    method: 'POST',
  });
  const fetcher = () =>
    createFetcher({
      ...fetcherParams,
    });

  const mutation = useMutation(fetcher, {
    onSettled: () => {
      resetAuthToken();
      resetRefreshToken();
      setUser(null);
      queryClient.invalidateQueries();
      window.location.href = '/';
    },
  });

  const logout = async () => {
    mutation.mutateAsync();
  };

  return {
    ...mutation,
    logout,
  };
};

export const useUploadNetwork = () => {
  const { fetcherParams } = useFetcher({
    method: 'POST',
    endpoint: 'recipients-import',
  });
  const fetcher = (formData: RecipientsImportForm) => {
    return createFetcher({
      ...fetcherParams,
      formData,
    });
  };
  return useMutation<UploadNetworkResponse, unknown, RecipientsImportForm>(
    fetcher,
    {
      onSettled: () => {
        queryClient.invalidateQueries(`recipients`);
      },
    },
  );
};

export const useRequestAccountDeletion = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'auth/profile/request_deletion',
    method: 'POST',
  });

  const fetcher = () =>
    createFetcher({
      ...fetcherParams,
    });

  return useMutation(fetcher, {});
};

export const useVerifyAccountDeletion = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'auth/profile/verify_deletion_request',
    method: 'POST',
  });

  const fetcher = (jsonBody: { code: string }) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });

  return useMutation(fetcher, {});
};

export const useCreatePrefundAccount = () => {
  const { fetcherParams } = useFetcher({
    endpoint: 'prefund-accounts',
    method: 'POST',
  });

  const fetcher = (jsonBody: CreatePreFundAccountRequest) =>
    createFetcher({
      ...fetcherParams,
      jsonBody,
    });

  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries('prefund-accounts');
    },
  });
};
