import styled from 'styled-components';
import { HeaderLayout } from 'ui/atoms/header-layout';

export const DataLine = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px 24px;
`;

export const DataMultiline = styled(DataLine)``;

export const DataLineItem = styled.span<{
  isTitle?: boolean;
  secondary?: boolean;
  separateLine?: boolean;
  alignRight?: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;

  font-weight: ${(p) => p.isTitle && '500'};
  font-size: ${(p) => p.secondary && '0.8rem'};
  color: ${(p) => p.secondary && 'gray'};
  flex-basis: ${(p) => p.separateLine && '100%'};
  text-align: ${(p) => p.alignRight && 'right'};
`;

export const DataLineHighlight = styled(DataLine)`
  font-weight: 700;
`;

export const Heading = styled(HeaderLayout)<{ pending?: boolean }>`
  margin: 0;
  color: white;
  padding: calc(16px + env(safe-area-inset-top)) 16px 16px;
  border-radius: 4px 4px 0 0;
  text-align: center;
  background: var(--success-gradient);
  background: ${(p) => p.pending && `var(--pending-gradient)`};
`;
