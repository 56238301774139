import { presentIonModal } from 'modals/present-ion-modal';
import type { ComponentProps } from 'react';

import type { User } from 'models';
import { i18nObject } from 'i18n/i18n-util';
import VerifyPhoneNumber from '../components/VerifyPhoneNumber/VerifyPhoneNumber';
import { VerifySecurityCode } from '../components/VerifySecurityCode';
import { $locale } from '../stores/locale';

const verifyPhoneNumber = async function (): Promise<string | undefined> {
  return new Promise((res, rej) => {
    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (phoneNumber: string) => {
      res(phoneNumber);
      onClose();
    };
    const LL = i18nObject($locale.getState());

    const modalProps: ComponentProps<typeof VerifyPhoneNumber> = {
      onClose,
      onDone,
      title: LL.CHANGE_PHONE_NUMBER(),
      message: LL.ENTER_YOUR_NEW_PHONE_NUMBER(),
      isModal: true,
    };
    const [promptModal, dismissModal] = presentIonModal(
      VerifyPhoneNumber,
      modalProps,
      'addPhoneNumberModal',
    );

    promptModal();
  });
};

const verifySecurityCode = async function (
  phoneNumber: string,
  onRequestAnotherDestination: Function,
  isRecoveryDestination?: boolean,
): Promise<User | undefined> {
  return new Promise((res, rej) => {
    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (user: User) => {
      res(user);
      onClose();
    };

    const modalProps: ComponentProps<typeof VerifySecurityCode> = {
      onDone,
      destination: phoneNumber,
      isRecoveryDestination,
      onRequestAnotherDestination: (...args: any) => {
        onRequestAnotherDestination();
        onClose();
      },
    };
    const [promptModal, dismissModal] = presentIonModal(
      VerifySecurityCode,
      modalProps,
      'verifyCodeModal',
    );

    promptModal();
  });
};

export const changePhoneNumberModal = async (
  isRecoveryDestination?: boolean,
): Promise<User | undefined> =>
  new Promise(async (res, rej) => {
    const phoneNumber = await verifyPhoneNumber();
    if (phoneNumber) {
      const user = await verifySecurityCode(
        phoneNumber,
        () => res(changePhoneNumberModal(isRecoveryDestination)),
        isRecoveryDestination,
      );
      res(user);
    }
  });
