import React, { useCallback, useState } from 'react';

import { IonIcon, IonInput, IonItem } from '@ionic/react';
import { search } from 'ionicons/icons';
import styled from 'styled-components';

import isPast from 'date-fns/isPast';

import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';
import { useRecipients, useSelf } from 'api/queries';
import { NetworkItem } from 'components/NetworkItem';
import type { Currency, PaymentRequestData, Recipient } from 'models';

import { addNetworkMemberModal } from 'modals/addNetworkMemberModal';

import { useI18nObject } from 'utils/use-i18n-object';

import { Checkbox } from 'ui/atoms/checkbox';
import { LinkButton } from 'ui/atoms/link-button';
import { UploadButton } from 'ui/atoms/upload-button';
import { useExportNetwork } from 'api/queries/queries';
import { useUploadNetwork } from 'api/queries/mutations';
import { useForm } from 'utils/use-form';
import { Collapsible } from 'ui/atoms/collapsible';
import endOfDay from 'date-fns/endOfDay';
import Notifications from 'components/Notifications';
import styles from './SelectPayer.module.css';

interface Form {
  payerIds: PaymentRequestData['payerIds'];
  requestAmounts: PaymentRequestData['requestAmounts'];
  dueDates: PaymentRequestData['dueDates'];
}

type FormErrors = {
  [k in keyof Form]?: string;
};

interface Props {
  data: Form;
  onSubmit: (v: Form) => void;
  onGoBack: () => void;
  onGoNext: () => void;
  bulk: boolean;
  setBulk: (v: boolean) => void;
  currency: Currency;
}

export const SelectPayer: React.VFC<Props> = ({
  data,
  onSubmit,
  onGoBack,
  onGoNext,
  bulk,
  setBulk,
  currency,
}) => {
  const LL = useI18nObject();

  const { data: recipients } = useRecipients();
  const { data: user } = useSelf();

  const allowedToCreateBulkPr = user?.allowed_to_create_bulk_pr || false;

  const [recipientFilter, setRecipientFilter] = useState('');
  const [invalidRequestAmounts, setInvalidRequestAmounts] = useState<number[]>(
    [],
  );
  const [invalidDueDates, setInvalidDueDates] = useState<number[]>([]);

  const form = useForm<Form>({
    initialValues: {
      payerIds: data.payerIds,
      requestAmounts: data.requestAmounts,
      dueDates: data.dueDates,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: FormErrors = {};
      const requestAmountErrors = values.payerIds.filter(
        (payerId) => bulk && !values.requestAmounts[payerId],
      );
      setInvalidRequestAmounts(requestAmountErrors);
      const dueDateErrors = values.payerIds.filter(
        (payerId) =>
          bulk &&
          (!values.dueDates[payerId] ||
            isPast(endOfDay(values.dueDates[payerId]))),
      );
      setInvalidDueDates(dueDateErrors);
      if (bulk) {
        if (requestAmountErrors.length) {
          errors.requestAmounts = LL.PLEASE_PROVIDE_REQUEST_AMOUNTS();
        }
        if (dueDateErrors.length) {
          errors.dueDates = LL.PLEASE_PROVIDE_CORRECT_DUE_DATES();
        }
      }
      return errors;
    },
    onSubmit: (values) => {
      if (bulk) {
        onSubmit(values);
      } else {
        onSubmit({
          ...values,
          payerIds: [values.payerIds[0]],
        });
      }
      onGoNext();
    },
  });

  const onRecipientFilterChange = (e: CustomEvent) => {
    setRecipientFilter(e.detail.value);
  };

  const filteredNetwork = recipients?.filter((r) => {
    const searchQuery = recipientFilter.toLowerCase();
    const firstNameMatch = (r.first_name || '')
      .toLowerCase()
      .includes(searchQuery);
    const lastNameMatch = (r.last_name || '')
      .toLowerCase()
      .includes(searchQuery);
    const phoneNumberMatch = (r.phone_number || '')
      .toLowerCase()
      .includes(searchQuery);
    const emailMatch = (r.email || '').toLowerCase().includes(searchQuery);
    return [firstNameMatch, lastNameMatch, phoneNumberMatch, emailMatch].some(
      Boolean,
    );
  });

  const { payerIds, requestAmounts, dueDates } = form.values;

  const onSelectAccount = useCallback(
    (payer: Recipient) => {
      const { id } = payer;
      if (bulk) {
        if (payerIds.includes(id)) {
          form.setFieldValue(
            'payerIds',
            payerIds.filter((v) => v !== id),
          );
        } else {
          form.setFieldValue('payerIds', [...payerIds, id]);
        }
      } else {
        form.setFieldValue('payerIds', [id]);
      }
    },
    [payerIds, bulk],
  );

  const createOnRequestAmountChangeHandler = useCallback(
    (id: number) => (v: number) => {
      form.setFieldValue('requestAmounts', { ...requestAmounts, [id]: v });
    },
    [requestAmounts],
  );
  const createOnDueDateChangeHandler = useCallback(
    (id: number) => (v?: Date) => {
      form.setFieldValue('dueDates', { ...dueDates, [id]: v });
    },
    [dueDates],
  );

  const onAddSenderClick = useCallback(async () => {
    await addNetworkMemberModal();
  }, []);

  const onXlsxExportClick = useExportNetwork('xlsx');

  const uploadNetworkMutation = useUploadNetwork();

  const onXlsxUpload = useCallback(async (value) => {
    console.log('xlsx upload');
    const { payers, errors } = await uploadNetworkMutation.mutateAsync({
      file: value,
    });
    const payersWithAmount = Object.fromEntries(
      Object.entries(payers)
        .filter(([k, v]) => v.amount)
        .map(([k, v]) => [
          k,
          {
            ...v,
            id: parseInt(k, 10),
          },
        ]),
    );
    console.log({ payers });
    const payerIds = Object.values(payersWithAmount).map((v) => v.id);
    const requestAmounts = Object.fromEntries(
      Object.entries(payersWithAmount).map(([k, v]) => [k, v.amount]),
    );
    const dueDates = Object.fromEntries(
      Object.entries(payersWithAmount).map(([k, v]) => [
        k,
        new Date(v.due_date),
      ]),
    );
    form.setFieldValue('payerIds', payerIds);
    form.setFieldValue('requestAmounts', requestAmounts);
    form.setFieldValue('dueDates', dueDates);

    if (errors.length) {
      Notifications.alert(
        `The following rows could not be imported:\n${errors.join('\n')}`,
      );
    }
  }, []);

  return (
    <PrevNextStepper
      nextStepActive={form.isValid}
      onPrevClick={onGoBack}
      onNextClick={form.onSubmitAttempt}
    >
      <div className="transfer-stage">
        <div className="transfer-stage-header">
          {LL.WHO_ARE_REQUESTING_FROM()}
        </div>
        <div className="input-field">
          <IonItem className="input-item" lines="none">
            <SearchIcon icon={search} />
            <IonInput
              value={recipientFilter}
              onIonChange={onRecipientFilterChange}
              className="input"
              type="text"
              placeholder={LL.SEARCH_YOU_NETWORK()}
              required
            />
          </IonItem>
        </div>
        {allowedToCreateBulkPr && (
          <BulkControls>
            <Checkbox value={bulk} onCheck={setBulk}>
              {LL.BULK_MODE()}
            </Checkbox>
            <Collapsible isExpanded={bulk} expectedHeight={200}>
              <ImportExportButtons>
                <LinkButton onClick={onXlsxExportClick}>
                  {LL.DOWNLOAD_TEMPLATE()}
                </LinkButton>
                <UploadButtonStyled onFilePick={onXlsxUpload} accept=".xlsx">
                  {LL.UPLOAD_XLSX()}
                </UploadButtonStyled>
              </ImportExportButtons>
            </Collapsible>
          </BulkControls>
        )}
        <div
          onClick={onAddSenderClick}
          className={`${styles.addRecipientBtn} btn`}
        >
          + {LL.ADD_SENDER()}
        </div>
        <div className="transfer-stage-content">
          {filteredNetwork?.map((recipient) => (
            <NetworkItem
              key={recipient.id}
              selected={
                bulk
                  ? payerIds.includes(recipient.id)
                  : payerIds[0] === recipient.id
              }
              recipient={recipient}
              onAccountSelect={onSelectAccount}
              hasCheckbox={bulk}
              requestAmount={requestAmounts[recipient.id]}
              onRequestAmountChange={createOnRequestAmountChangeHandler(
                recipient.id,
              )}
              dueDate={dueDates[recipient.id]}
              onDueDateChange={createOnDueDateChangeHandler(recipient.id)}
              currency={currency}
              amountError={invalidRequestAmounts.includes(recipient.id)}
              dueDateError={invalidDueDates.includes(recipient.id)}
            />
          ))}
        </div>
      </div>
    </PrevNextStepper>
  );
};

const SearchIcon = styled(IonIcon)`
  margin-right: 16px;
`;

const BulkControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  gap: 8px;
`;

const ImportExportButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
`;

const UploadButtonStyled = styled(UploadButton)`
  //color: var(--secondary-color-v3);
  padding: 1rem 1.5rem;
  background: var(--secondary-color-main);
  color: white;
  font-size: 1rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;
