import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { getErrorMessage } from 'api/interceptor';
import Notifications from 'components/Notifications';
import { useStore } from 'effector-react';
import {
  $authToken,
  resetAuthToken,
  setAuthToken,
  setRefreshToken,
} from 'stores/auth';
import { $config } from 'stores/config';
import { $locale } from 'stores/locale';

interface QueryErrorContext {
  response: Response;
  responseData: any;

  [k: string]: any;
}

interface UseFetcherParamsI {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  queryParams?: { [key: string]: string };
  responseBodyType?: 'json' | 'blob' | 'text';
  urlPrefix?: string;
}

interface FetcherI extends UseFetcherParamsI {
  url: string;
  authToken: string;
  locale: string;
  jsonBody?: { [key: string]: any };
  formData?: { [key: string]: any };
}

class QueryError extends Error {
  public response: Response;

  public responseData: any;

  constructor(message: string, context: QueryErrorContext) {
    super(message);
    this.message = message;
    this.response = context.response;
    this.responseData = context.responseData;
  }
}

const errorHandler = (error: unknown) => {
  if (error instanceof QueryError) {
    const { response, responseData } = error;
    let errorMessage = '';
    if (response) {
      if (response.status === 401) {
        resetAuthToken();
        const currentURL = `${window.location.pathname}${window.location.search}`;
        if (currentURL !== '/signin')
          window.location.href = `/signin?redirect=${currentURL}`;
        // } else if (typeof responseData === 'string') {
        //   errorMessage = `${response.status}: ${response.statusText}`;
      } else {
        errorMessage = getErrorMessage(responseData);
      }
    } else {
      errorMessage = error.message;
    }

    if (String(errorMessage) === 'Network Error') {
      Notifications.alert('Cannot connect to the AFI network');
    } else if (errorMessage) {
      Notifications.alert(errorMessage);
    }
  } else {
    console.error('Not QueryError:', error);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        console.log({ failureCount });
        if (error instanceof QueryError && error.response.status === 401) {
          return false;
        }
        const RETRY_ATTEMPTS = 3;
        return failureCount <= RETRY_ATTEMPTS;
      },
    },
  },
  queryCache: new QueryCache({
    onError: errorHandler,
  }),
  mutationCache: new MutationCache({
    onError: errorHandler,
  }),
});

export const createFetcher = async (params: FetcherI) => {
  const {
    url,
    authToken,
    locale,
    method,
    queryParams,
    responseBodyType = 'json',
  } = params;
  let fetchUrl = url;
  if (queryParams) {
    const params = new URLSearchParams(queryParams).toString();
    fetchUrl += `?${params}`;
  }
  let body;
  let contentType = 'application/json';
  if ('jsonBody' in params && params.jsonBody) {
    body = JSON.stringify(params.jsonBody);
  }
  if ('formData' in params && params.formData) {
    const form = new FormData();
    contentType = '';
    Object.entries(params.formData).forEach((entry) => form.append(...entry));
    body = form;
  }
  const init = {
    method,
    headers: {
      ...(contentType && { 'Content-Type': contentType }),
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      ...(locale && { 'Accept-Language': locale }),
    },
    body,
  };
  const response = await fetch(fetchUrl, init);
  const setAuthTokenHeader = response.headers.get('set-auth-token');
  if (setAuthTokenHeader) {
    setAuthToken(setAuthTokenHeader);
  }
  const setRefreshTokenHeader = response.headers.get('set-refresh-token');
  if (setRefreshTokenHeader) {
    setRefreshToken(setRefreshTokenHeader);
  }
  // 402 is a special case for payment required
  if (!response.ok && response.status !== 402) {
    const responseData = await response.json();
    throw new QueryError('Network response was not ok', {
      response,
      responseData,
    });
  }
  if (response.status === 204) {
    return {};
  }
  return response[responseBodyType]();
};

export const useUrlPrefix = () => {
  const { apiEndpoint } = useStore($config);
  const urlPrefix = `${apiEndpoint}/api`;
  return urlPrefix;
};

export const useFetcherParams = (params: UseFetcherParamsI) => {
  const authToken = useStore($authToken);
  const locale = useStore($locale);
  const urlPrefix = useUrlPrefix();
  const fetcherParams: Omit<FetcherI, 'url'> = {
    ...params,
    authToken,
    locale,
  };

  return {
    fetcherParams,
    urlPrefix,
    authToken,
  };
};

export const useFetcher = ({
  ...params
}: UseFetcherParamsI & { endpoint: string }) => {
  const {
    fetcherParams: p,
    authToken,
    urlPrefix: u,
  } = useFetcherParams(params);
  const { endpoint } = params;
  const urlPrefix = params.urlPrefix || u;
  const url = `${urlPrefix}/${endpoint}`;
  const fetcherParams = {
    ...p,
    url,
  };
  const key = [endpoint, fetcherParams.queryParams];

  const fetcher = () =>
    createFetcher({
      ...fetcherParams,
      url,
    });

  return { fetcherParams, fetcher, key, authToken };
};
