import React from 'react';
import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';

import styled from 'styled-components';
import { useCreateRecipient } from 'api/queries/mutations';
import { useI18nObject } from 'utils/use-i18n-object';

import { useForm } from 'utils/use-form';
import type { CheckboxChangeEventDetail } from '@ionic/core';
import CloseIcon from '../../assets/icon/close.svg';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';

interface Props {
  onRecipientCreated: any;
  onClose: any;
}

interface Form {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  confirmedDetails: boolean;
}

export const AddNetworkMemberForm: React.FC<Props> = ({
  onRecipientCreated,
  onClose,
}) => {
  const LL = useI18nObject();

  const createRecipientMutation = useCreateRecipient();

  const formik = useForm<Form>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      confirmedDetails: false,
    },
    validate: (values) => {
      const errors: { [P in keyof Form]?: string } = {};
      if (!values.confirmedDetails) {
        errors.confirmedDetails = LL.PLEASE_CONFIRM_ACCOUNT_DETAILS();
      }
      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      const payload = {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: values.phoneNumber,
      };

      const recipient = await createRecipientMutation.mutateAsync(payload);

      onRecipientCreated(recipient);
    },
  });

  const { firstName, lastName, email, phoneNumber, confirmedDetails } =
    formik.values;

  return (
    <form onSubmit={formik.onSubmitAttempt}>
      <div className="title formModalTitle">
        {LL.GROW_YOUR_NETWORK()}
        {onClose && (
          <img src={CloseIcon} className="title-close-icon" onClick={onClose} />
        )}
      </div>
      <Form className="form1">
        <div>
          <div className="input-group">
            <div className="input-group-title">{LL.PERSONAL_DETAILS()}</div>
            <IonGrid className="input-grid-group">
              <IonRow>
                <IonCol className="input-field">
                  <IonItem className="input-item" lines="none">
                    <IonInput
                      value={firstName}
                      onIonChange={formik.createIonChangeHandler('firstName')}
                      name="firstName"
                      className="input"
                      placeholder={LL.PERSON_OR_BUSINESS_NAME()}
                      required
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <IonInput
                  value={email}
                  onIonChange={formik.createIonChangeHandler('email')}
                  name="email"
                  className="input"
                  type="email"
                  placeholder={LL.EMAIL()}
                />
              </IonItem>
            </div>
            <div className="input-field">
              <IonItem className="input-item" lines="none">
                <PhoneNumberInput
                  value={phoneNumber}
                  onChange={(v: string) => {
                    formik.setFieldValue('phoneNumber', v);
                  }}
                  name="phoneNumber"
                  required
                  className="input"
                  type="text"
                  placeholder={LL.PHONE_NUMBER()}
                />
              </IonItem>
            </div>
          </div>

          <div className="input-field">
            <IonItem className="input-item-checkbox" lines="none">
              <IonCheckbox
                checked={confirmedDetails}
                onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                  formik.setFieldValue('confirmedDetails', e.detail.checked);
                }}
                name="confirmedDetails"
                className="input"
              />
              <IonLabel className="input-label">
                {LL.CONFIRMED_DETAILS()}
              </IonLabel>
            </IonItem>
          </div>
        </div>
        <button className="btn submit-btn">{LL.SUBMIT()}</button>
      </Form>
    </form>
  );
};

const Form = styled.div`
  border-radius: 0;
`;
