import React, { useCallback, useMemo } from 'react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';

import { IonGrid, IonCol, IonRow } from '@ionic/react';
import { useHistory } from 'react-router';

import moment from 'moment';
import styled from 'styled-components';
import { changePhoneNumberModal } from 'modals/changePhoneNumberModal';
import { updateBusinessProfileModal } from 'modals/updateBusinessProfileModal';
import { accountDeletionPolicyModal } from 'modals/accountDeletionPolicyModal';
import { changeEmailModal } from 'modals/changeEmailModal';
import { updatePersonalInfoModal } from 'modals/updatePersonalInfoModal';
import { verifyIdModal } from 'modals/verifyIdModal';
import { $locale } from 'stores/locale';
import { useI18nObject } from 'utils/use-i18n-object';

import VerifiedIcon from '../../../assets/icon/verified.svg';
import UnVerifiedIcon from '../../../assets/icon/unverified.svg';

import styles from './Profile.module.css';
import 'moment/locale/fr';

export const Profile: React.FC = () => {
  const user = useStore($user);
  const history = useHistory();
  const LL = useI18nObject();
  const locale = useStore($locale);
  moment.locale(locale);

  const onModalActionClick = useCallback(
    (modal: any) => () => {
      modal();
    },
    [],
  );
  const onDeleteAccountModalActionClick = useCallback(
    (modal: any) => () => {
      modal().then(() => {
        history.push('/dashboard/verify-account-deletion-request');
      });
    },
    [],
  );

  const dobFormatted = useMemo(() => {
    return moment(user?.profile.date_of_birth).format('Do MMM YYYY');
  }, [user?.profile.date_of_birth]);

  return (
    <div className={`dashboard-page ${styles.profilePage}`}>
      {/* <div className={styles.profileHeader}> */}
      {/*  <img src={DefaultProfileIcon} /> */}
      {/*  <Button>Change profile picture</Button> */}
      {/* </div> */}

      <IonGrid className={styles.contentContainer}>
        <IonRow>
          <IonCol className={styles.sectionCol}>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionTitle}>
                {LL.PROFILE_LEGAL_NAME()}
              </div>
              <div className={styles.infoSectionText}>
                {user?.first_name} {user?.last_name}
              </div>
            </div>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionTitle}>
                {LL.DATE_OF_BIRTH()}
              </div>
              <div className={styles.infoSectionText}>{dobFormatted}</div>
            </div>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionTitle}>{LL.ADDRESS()}</div>
              <div className={styles.infoSectionText}>
                {user?.profile.address?.country && (
                  <>
                    <span>{user?.profile.address.country}</span>
                    <br />
                  </>
                )}
                {user?.profile.address?.address_line_1 && (
                  <>
                    <span>{user?.profile.address.address_line_1}</span>
                    <br />
                  </>
                )}
                {user?.profile.address?.address_line_2 && (
                  <>
                    <span>{user?.profile.address.address_line_2}</span>
                    <br />
                  </>
                )}
                {user?.profile.address?.state && (
                  <>
                    <span>{user?.profile.address.state}</span>
                    <br />
                  </>
                )}
                {user?.profile.address?.zip && (
                  <>
                    <span>{user?.profile.address.zip}</span>
                    <br />
                  </>
                )}
                {user?.profile.address?.city && (
                  <>
                    <span>{user?.profile.address.city}</span>
                    <br />
                  </>
                )}
              </div>
            </div>
            <Button onClick={onModalActionClick(updatePersonalInfoModal)}>
              {LL.CHANGE_PERSONAL_INFO()}
            </Button>
          </IonCol>
          <IonCol className={styles.sectionCol}>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionTitle}>{LL.EMAIL()}</div>
              <div className={styles.infoSectionText}>{user?.email}</div>
              <div className={styles.infoSectionStatus}>
                <img
                  src={user?.email_confirmed ? VerifiedIcon : UnVerifiedIcon}
                />
                {user?.email_confirmed ? LL.VERIFIED() : LL.UN_VERIFIED()}
              </div>
            </div>
            <Button onClick={onModalActionClick(changeEmailModal)}>
              {LL.CHANGE_EMAIL()}
            </Button>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionTitle}>{LL.TELEPHONE()}</div>
              <div className={styles.infoSectionText}>{user?.phone_number}</div>
              <div className={styles.infoSectionStatus}>
                <img
                  src={
                    user?.phone_number_confirmed ? VerifiedIcon : UnVerifiedIcon
                  }
                />
                {user?.phone_number_confirmed
                  ? LL.VERIFIED()
                  : LL.UN_VERIFIED()}
              </div>
            </div>
            <Button onClick={onModalActionClick(changePhoneNumberModal)}>
              {LL.CHANGE_PHONE_NUMBER()}
            </Button>
          </IonCol>
          <IonCol className={styles.sectionCol}>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionTitle}>
                {LL.GOVERNMENT_ISSUED_ID()}
              </div>
              {user?.id_document && (
                <>
                  <div className={styles.infoSectionText}>
                    {user?.id_document.country}
                    <br />
                    {user?.id_document.document_name}
                    <br />
                    {user?.id_document.document_number}
                    <br />
                  </div>
                  <div className={styles.infoSectionStatus}>
                    <img
                      src={
                        user?.id_document.verified
                          ? VerifiedIcon
                          : UnVerifiedIcon
                      }
                    />
                    {user?.id_document.verified
                      ? LL.VERIFIED()
                      : LL.UN_VERIFIED()}
                  </div>
                </>
              )}
            </div>
            <Button onClick={onModalActionClick(verifyIdModal)}>
              {user?.id_document ? LL.CHANGE_ID() : LL.ADD_ID()}
            </Button>
            <div className={styles.infoSection}>
              <div className={styles.infoSectionTitle}>
                {LL.BUSINESS_INFO()}
              </div>
              <div className={styles.infoSectionText}>
                {user?.business_profile?.business_name && (
                  <>
                    <span>{user?.business_profile.business_name}</span>
                    <br />
                  </>
                )}
                {user?.business_profile?.country && (
                  <>
                    <span>{user?.business_profile.country}</span>
                    <br />
                  </>
                )}
              </div>
            </div>
            <Button onClick={onModalActionClick(updateBusinessProfileModal)}>
              {LL.UPDATE_BUSINESS_INFO()}
            </Button>
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center">
          <IonCol size="4">
            <DeleteButton
              onClick={onDeleteAccountModalActionClick(
                accountDeletionPolicyModal,
              )}
            >
              {LL.DELETE_ACCOUNT()}
            </DeleteButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const Button = styled.button`
  margin-top: 16px;
  padding: 0.75rem 1rem;
  color: var(--text-color-v1);
  border: 1px solid var(--text-color-v4);
  background: var(--light-background-color);
  font-size: 0.95rem;
  border-radius: 0.3rem;
`;

const DeleteButton = styled(Button)`
  background-color: var(--light-background-color);
  color: var(--error-color);
  border-color: var(--error-color);
`;
export default Profile;
