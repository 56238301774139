import React, { useCallback, useMemo, useState } from 'react';
import { useI18nObject } from 'utils/use-i18n-object';
import styled from 'styled-components';

import { useCountryList } from 'data/countries';
import type { Country } from 'models';
import styles from './CountrySelect.module.css';

interface Props {
  onChange?: any;
  onIonChange?: any;
  value?: any;
  countries?: Country[];
  [k: string]: any;
}

const CountrySelect: React.FC<Props> = ({
  onChange,
  onIonChange,
  value,
  countries,
  ...props
}) => {
  const [localValue, setLocalValue] = useState();
  const LL = useI18nObject();

  const onSelectChange = useCallback(
    (event) => {
      const { value } = event.target;
      setLocalValue(value);
      if (onChange) onChange(event);
      if (onIonChange) onIonChange({ detail: { value } });
    },
    [onChange, onIonChange],
  );

  const setRef = useCallback(
    (current) => {
      if (current) {
        // eslint-disable-next-line no-param-reassign
        current.value = String(value || '').toUpperCase();
        setLocalValue(current.value);
      }
    },
    [value],
  );

  const defaultCountriesList = useCountryList();

  const countriesList = countries || defaultCountriesList;

  const className = useMemo(() => {
    return `${props.className} ${styles.disableHighlight} ${
      !localValue && styles.empty
    }`;
  }, [props.className, localValue]);

  return (
    <CountrySelectStyled
      ref={setRef}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      interface="popover"
      {...props}
      className={className}
      onChange={onSelectChange}
    >
      <option value="">{LL.SELECT_A_COUNTRY()}</option>
      {countriesList.map(({ alpha2, name }) => (
        <option key={alpha2} value={alpha2.toUpperCase()}>
          {name}
        </option>
      ))}
    </CountrySelectStyled>
  );
};

const CountrySelectStyled = styled.select`
  margin-left: -4px;
`;

export default CountrySelect;
