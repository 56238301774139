import { presentIonModal } from 'modals/present-ion-modal';
import type { ComponentProps } from 'react';
import { i18nObject } from 'i18n/i18n-util';
import { $locale } from 'stores/locale';
import ForgotPasswordForm from '../components/ForgotPasswordForm/ForgotPasswordForm';

export const forgotPasswordModal = async (): Promise<boolean | undefined> =>
  new Promise((res, rej) => {
    const LL = i18nObject($locale.getState());

    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (success: boolean) => {
      res(success);
      onClose();
    };

    const modalProps: ComponentProps<typeof ForgotPasswordForm> = {
      onClose,
      onDone,
      isModal: true,
      title: LL.TITLE_FORGOT_PASSWORD(),
    };
    const [promptModal, dismissModal] = presentIonModal(
      ForgotPasswordForm,
      modalProps,
      'forgotPasswordModal',
    );

    promptModal();
  });
