import { presentIonModal } from 'modals/present-ion-modal';
import type { ComponentProps } from 'react';
import { i18nObject } from 'i18n/i18n-util';
import { $locale } from 'stores/locale/index';
import { AuthApi } from '../api/http';

import { VerificationActions, VerificationTypes } from '../data';
import VerifyEmail from '../components/VerifyEmail/VerifyEmail';
import VerificationSentMessage from '../components/VerificationSentMessage/VerificationSentMessage';
import Notifications from '../components/Notifications';

const verifyEmail = async function (): Promise<string | undefined> {
  const LL = i18nObject($locale.getState());

  return new Promise((res, rej) => {
    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (email: string) => {
      res(email);
      onClose();
    };

    const modalProps: ComponentProps<typeof VerifyEmail> = {
      onClose,
      onDone,
      isModal: true,
      title: LL.CHANGE_EMAIL(),
      verificationAction: VerificationActions.changeEmail,
      message: LL.ENTER_YOUR_NEW_EMAIL_ADDRESS(),
    };
    const [promptModal, dismissModal] = presentIonModal(
      VerifyEmail,
      modalProps,
      'changeEmailModal',
    );

    promptModal();
  });
};

const emailVerificationSent = async function (email: string): Promise<any> {
  const LL = i18nObject($locale.getState());

  return new Promise<any>((res, rej) => {
    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async () => {
      res(undefined);
      onClose();
    };

    const onResendClick = async () => {
      const LL = i18nObject($locale.getState());

      await AuthApi.setNewEmail({
        verification_type: VerificationTypes.email,
        verification_action: VerificationActions.changeEmail,
        email,
      });
      Notifications.alert(LL.VERIFICATION_CODE_HAS_BENN_RESENT());
    };

    const modalProps: ComponentProps<typeof VerificationSentMessage> = {
      email,
      message: LL.CLICK_THE_LINK_SENT_TO_THIS_NEW_EMAIL_ADDRESS(),
      onResendClick,
    };
    const [promptModal, dismissModal] = presentIonModal(
      VerificationSentMessage,
      modalProps,
      'emailVerifiedMessage',
    );

    promptModal();
  });
};

export const changeEmailModal = async (): Promise<any> => {
  const email = await verifyEmail();
  if (email) {
    await emailVerificationSent(email);
  }
};
