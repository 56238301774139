import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { useI18nObject } from 'utils/use-i18n-object';
import { useAccountName } from 'api/queries/queries';

import { Input } from 'ui/atoms/input';
import { AccountForm } from 'components/FinancialAccountPicker/financial-account-picker';
import { Spinner } from 'ui/atoms/spinner';

interface Props {
  accountData: AccountForm;
  customerName: string;
  setCustomerName: (v: string) => void;
}
export const AccountNameResolver = ({
  accountData,
  customerName,
  setCustomerName,
}: Props) => {
  const LL = useI18nObject();

  const accountNameRequestParams = useMemo(() => {
    const {
      accountType,
      country,
      bankCode,
      bankName,
      bankAccountNumber,
      mobileOperator,
      mobileNumber,
    } = accountData;
    if (country) {
      if (accountType === 'bank_account') {
        return {
          type: 'bank_account' as const,
          country,
          bankName,
          bankCode,
          bankAccountNumber,
          bankAccountName: '',
        };
      }
      if (accountType === 'mobile_money') {
        return {
          type: 'mobile_money' as const,
          country,
          mobileMoneyNetwork: mobileOperator,
          mobileNumber,
        };
      }
    }
  }, [accountData]);

  const {
    data: accountNameData,
    refetch: fetchAccountName,
    isFetching,
  } = useAccountName(accountNameRequestParams);

  const registredName = accountNameData?.registeredName || '';

  const accountNumber =
    accountData.accountType === 'bank_account'
      ? `${LL.ACCOUNT_NUMBER()} ${accountData?.bankAccountNumber}`
      : `${LL.PHONE_NUMBER_X({ phoneNumber: accountData?.mobileNumber })}`;

  const refetchAccountName = useCallback(async () => {
    const accountNameData = await fetchAccountName();
    if (accountNameData?.data?.registeredName) {
      setCustomerName(accountNameData?.data?.registeredName);
    }
  }, [accountNumber]);

  useEffect(() => {
    refetchAccountName();
  }, []);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      {registredName ? (
        <Name>{registredName}</Name>
      ) : (
        <>
          <h3>{LL.UNABLE_TO_CONFIRM_ACCOUNT_HOLDER_NAME()}</h3>
          <p>{LL.CHECK_PROVIDED_ACCOUNT({ accountNumber })}</p>
          <Input
            value={customerName}
            onInputChange={setCustomerName}
            name="customerName"
            placeholder={LL.ACCOUNT_HOLDER_NAME()}
            // required
          />
        </>
      )}
    </>
  );
};

const Name = styled.h1`
  text-align: center;
`;
