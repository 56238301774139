import useScript from 'utils/use-script';
import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

export const VGSLoadedGuard = ({ children }: Props) => {
  const vgsLoadStatus = useScript(
    'https://js.verygoodvault.com/vgs-collect/2.4.0/vgs-collect.js',
  );

  if (vgsLoadStatus === 'loading' || vgsLoadStatus === 'idle') {
    return <div>Loading...</div>;
  }
  if (vgsLoadStatus === 'error') {
    return (
      <ErrorMessage>
        <p>Oops. It seems you have networking issues.</p>
        <p>Please wait a few minutes before you try again.</p>
      </ErrorMessage>
    );
  }

  return <>{children}</>;
};

const ErrorMessage = styled.div`
  color: var(--secondary-color-v3);
  font-size: 14px;
`;
