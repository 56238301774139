import { presentIonModal } from 'modals/present-ion-modal';
import type { ComponentProps } from 'react';
import type { User } from 'models';

import { i18nObject } from 'i18n/i18n-util';
import { $locale } from 'stores/locale';
import VerifyPhoneNumber from '../components/VerifyPhoneNumber/VerifyPhoneNumber';
import { VerifySecurityCode } from '../components/VerifySecurityCode';

const verifyPhoneNumber = async function (
  initialPhoneNumber?: string,
): Promise<string | undefined> {
  return new Promise((res, rej) => {
    const LL = i18nObject($locale.getState());

    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (phoneNumber: string) => {
      res(phoneNumber);
      onClose();
    };

    const modalProps: ComponentProps<typeof VerifyPhoneNumber> = {
      onClose,
      onDone,
      title: LL.TWO_FACTOR_AUTHENTICATION(),
      message: LL.ENTER_NEW_PHONE_NUMBER_ABOVE(),
      isModal: true,
      initialPhoneNumber,
    };

    const [promptModal, dismissModal] = presentIonModal(
      VerifyPhoneNumber,
      modalProps,
      'addPhoneNumberModal',
    );

    promptModal();
  });
};

const verifySecurityCode = async function (
  phoneNumber: string,
  onRequestAnotherDestination: Function,
): Promise<User | undefined> {
  return new Promise((res, rej) => {
    const onClose = () => {
      dismissModal();
      res(undefined);
    };

    const onDone = async (user: User) => {
      res(user);
      onClose();
    };

    const modalProps: ComponentProps<typeof VerifySecurityCode> = {
      onDone,
      destination: phoneNumber,
      isRecoveryDestination: false,
      onRequestAnotherDestination: (...args: any) => {
        onRequestAnotherDestination();
        onClose();
      },
    };
    const [promptModal, dismissModal] = presentIonModal(
      VerifySecurityCode,
      modalProps,
      'verifyCodeModal',
    );

    promptModal();
  });
};

export const twoFactorPhoneChangeModal = async (
  initialPhoneNumber?: string,
): Promise<User | undefined> =>
  new Promise(async (res, rej) => {
    const phoneNumber = await verifyPhoneNumber(initialPhoneNumber);
    if (phoneNumber) {
      const user = await verifySecurityCode(phoneNumber, () =>
        res(twoFactorPhoneChangeModal(phoneNumber)),
      );
      res(user);
    }
  });
