import React, { useCallback } from 'react';
import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';

import styled from 'styled-components';
import { DashboardPage } from 'pages/Dashboard';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { TitlePortal } from 'components/TitlePortal';
import { useI18nObject } from 'utils/use-i18n-object';

import ProfileTab from './Profile/Profile';
import AccountTab from './Accounts/Accounts';
import SecurityTab from './Security/Security';

export const Settings = ({ match }: RouteComponentProps) => {
  const profileTabActive = useRouteMatch({
    path: '/dashboard/settings',
    exact: true,
  });
  const accountsTabActive = useRouteMatch({
    path: '/dashboard/settings/accounts',
  });
  const securityTabActive = useRouteMatch({
    path: '/dashboard/settings/security',
  });

  const selectedTab = {
    true: '/dashboard/transactions',
    [String(!!profileTabActive)]: '/dashboard/settings',
    [String(!!accountsTabActive)]: '/dashboard/settings/accounts',
    [String(!!securityTabActive)]: '/dashboard/settings/security',
  }.true;

  const history = useHistory();

  const onTabChange = useCallback((event) => {
    history.push(event.detail.value);
  }, []);

  const LL = useI18nObject();

  return (
    <>
      <TitlePortal>{LL.SETTINGS()}</TitlePortal>
      <SettingsStyled>
        <IonSegment
          onIonChange={onTabChange}
          value={selectedTab}
          className="settings-tab-bar"
        >
          <IonSegmentButton value="/dashboard/settings">
            <IonLabel>{LL.PROFILE()}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="/dashboard/settings/accounts">
            <IonLabel>{LL.ACCOUNTS()}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="/dashboard/settings/security">
            <IonLabel>{LL.SECURITY()}</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <div className="settings-content">
          <Switch>
            <Route path={`${match.url}`} exact component={ProfileTab} />
            <Route path={`${match.url}/accounts`} component={AccountTab} />
            <Route path={`${match.url}/security`} component={SecurityTab} />
          </Switch>
          {/* {selectedTab === 'profile' && <ProfileTab />} */}
          {/* {selectedTab === 'accounts' && <AccountTab />} */}
          {/* {selectedTab === 'security' && <SecurityTab />} */}
        </div>
      </SettingsStyled>
    </>
  );
};

const SettingsStyled = styled(DashboardPage)`
  padding: 0;
`;
