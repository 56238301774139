import styled from 'styled-components';
import LogoSvg from 'assets/icon/logo.svg';

export const Logo = styled.div.attrs(() => ({
  children: <LogoImg src={LogoSvg} />,
}))`
  display: flex;
  justify-content: center;
  margin: 2.75rem 0 0.75rem;
`;

const LogoImg = styled.img`
  width: auto;
  height: 2.75rem;
`;
