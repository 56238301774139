import styled from 'styled-components';
import { LinkButton } from 'ui/atoms/link-button';
import { openExternalLink } from 'utils/open-external-link';

interface Props {
  to: string;
}

export const ExternalLink = styled(LinkButton).attrs<Props>(({ to }) => ({
  onClick: () => {
    openExternalLink(to, to);
  },
}))<Props>``;
