import type { Currency } from 'models';

export const getCurrencyDecimalScale = (currency?: Currency) => {
  switch (currency) {
    case 'GHS':
      return 2;
    case 'XOF':
      return 0;
    default:
      return 2;
  }
};

export const moneyNumberFormat = (
  rawInput: number | string | undefined | null,
  currency?: Currency,
) => {
  let number = Number(rawInput);
  if (!number || Number.isNaN(number)) {
    number = 0;
  }
  const decimalScale = getCurrencyDecimalScale(currency);
  return number.toFixed(decimalScale);
};

export const formatCurrency = (currency: Currency) => {
  if (currency === 'GHS') {
    return 'GHS';
  }
  if (currency === 'XOF') {
    return 'CFA';
  }
  return currency;
};

export const getCurrencyByCountry = (country?: string): Currency => {
  const countryUpper = (country || '').toUpperCase();
  if (typeof country === 'undefined' || country === null) {
    return 'GHS';
  }
  switch (countryUpper) {
    case 'GH':
      return 'GHS';
    // case 'US':
    //   return 'USD';
    case 'TG':
      return 'XOF';
    default:
      return 'GHS';
  }
};

export const formatAmount = ({
  currency,
  amount,
}: {
  amount?: number | string;
  currency?: Currency;
}) => {
  if (amount === 'undefined') {
    return '';
  }
  if (!currency) {
    return amount;
  }
  const currencyFormatted = formatCurrency(currency);
  const amountFormatted = moneyNumberFormat(amount, currency);
  return `${currencyFormatted} ${amountFormatted}`;
};

export const currencies: Currency[] = ['GHS', 'XOF', 'USD'];

export const getExchangeRateRepresentation = ({
  currency,
  recipientCurrency,
  exchangeRate,
}: {
  currency: Currency;
  recipientCurrency: Currency;
  exchangeRate: number;
}) => {
  if (exchangeRate < 1) {
    return `${formatCurrency(currency)} ${(1 / exchangeRate).toFixed(
      2,
    )} = ${recipientCurrency} 1`;
  }
  return `${currency} 1 = ${recipientCurrency} ${exchangeRate}`;
};
