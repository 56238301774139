import React from 'react';

import { DashboardPage } from 'pages/Dashboard';
import { openExternalLink } from 'utils/open-external-link';
import styled from 'styled-components';
import { Icon } from 'ui/atoms/icon';
import { TitlePortal } from 'components/TitlePortal';
import { useI18nObject } from 'utils/use-i18n-object';

export const Legal = () => {
  const LL = useI18nObject();

  return (
    <>
      <TitlePortal>{LL.LEGAL()}</TitlePortal>
      <DashboardPage>
        <CardsContainer>
          <Card
            onClick={() => {
              openExternalLink(
                '/user-agreement',
                'https://app.afipayments.com/user-agreement',
              );
            }}
          >
            <TitleContainer>
              <span>{LL.USER_AGREEMENT()}</span>
              <Icon name="ExternalLink" size={18} />
            </TitleContainer>
            <Description>{LL.KNOW_THE_RULES()}</Description>
          </Card>
          <Card
            onClick={() => {
              openExternalLink(
                '/privacy',
                'https://app.afipayments.com/privacy',
              );
            }}
          >
            <TitleContainer>
              <span>{LL.PRIVACY_POLICY()}</span>
              <Icon name="ExternalLink" size={18} />
            </TitleContainer>
            <Description>{LL.UNDERSTAND_HOW_WE_YOUR_DATA()}</Description>
          </Card>
        </CardsContainer>
      </DashboardPage>
    </>
  );
};

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Card = styled.button`
  margin: 10px;
  border: 1px solid #e8fafa;
  border-radius: 10px;
  padding: 20px;
  background-color: #e8fafa;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  flex: 1 1 100%;

  @media (min-width: 560px) {
    flex: 1 1 40%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Roboto, serif;
  color: #333333;
  font-size: 20px;
  font-weight: 500;
`;

const Description = styled.p`
  font-family: Roboto, serif;
  color: #767676;
  text-align: left;
`;
