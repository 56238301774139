import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { resetPasswordModal } from 'modals/resetPasswordModal';
import Notifications from 'components/Notifications';
import isAfter from 'date-fns/isAfter';
import { Capacitor } from '@capacitor/core';
import { resetConfig, setDevConfig, setStagingConfig } from 'stores/config';

export const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();

  const processSearchParams = async (searchParams: URLSearchParams) => {
    const action = searchParams.get('action');
    if (action === 'reset_password') {
      const token = searchParams.get('token')!;
      const token_expiry = new Date(searchParams.get('exp')!);
      const now = new Date().toUTCString();
      if (isAfter(new Date(now), token_expiry)) {
        Notifications.alert(
          'The password reset link has expired. Please contact AFi Support.',
        );
      } else {
        const success = await resetPasswordModal(token);
        if (success) {
          history.push('/signin');
        }
      }
    }
  };

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const url = new URL(event.url);
      const { pathname, searchParams, hostname } = url;

      if (Capacitor.isNativePlatform()) {
        if (hostname.startsWith('beta')) {
          setStagingConfig();
        } else if (
          hostname.startsWith('localhost') ||
          hostname.startsWith('dev')
        ) {
          setDevConfig();
        } else {
          resetConfig();
        }
      }
      setTimeout(() => {
        processSearchParams(searchParams);
      }, 100);
      if (pathname) {
        history.push(pathname);
      }
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setTimeout(() => {
      processSearchParams(searchParams);
    }, 100);
  }, []);

  return null;
};
