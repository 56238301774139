import React from 'react';
import { HelpOptions } from 'components/HelpOptions/HelpOptions';
import { DashboardPage } from 'pages/Dashboard';
import { TitlePortal } from 'components/TitlePortal';

import '../Dashboard.css';
import { useI18nObject } from 'utils/use-i18n-object';

export const Help: React.VFC = () => {
  const LL = useI18nObject();
  return (
    <>
      <TitlePortal>{LL.HELP()}</TitlePortal>
      <DashboardPage>
        <div className="dashboard-page-content">
          <HelpOptions />
        </div>
      </DashboardPage>
    </>
  );
};
