import { Capacitor } from '@capacitor/core';
import { $configName } from 'stores/config';

export const getHost = () => {
  if (!Capacitor.isNativePlatform()) {
    return window.location.origin;
  }
  const configName = $configName.getState();
  if (configName === 'production') {
    return 'https://app.afipayments.com';
  }
  if (configName === 'staging') {
    return 'https://beta.afipayments.com';
  }
  if (configName === 'dev') {
    return 'http://localhost:3000';
  }
  if (configName === 'default') {
    return 'https://app.afipayments.com';
  }
  return 'https://app.afipayments.com';
};
