import { presentIonModal } from 'modals/present-ion-modal';
import type { ComponentProps } from 'react';

import { UploadDocForm } from 'components/UploadDocForm';

type UploadDocFormProps = ComponentProps<typeof UploadDocForm>;

export const uploadDocModal = async (
  docsChoices: [string, string][],
): Promise<any | undefined> =>
  new Promise((res, rej) => {
    const onClose = () => {
      dismiss();
      res(undefined);
    };

    const onDone = async (
      document: Parameters<UploadDocFormProps['onFileUploaded']>[0],
    ) => {
      res(document);
      onClose();
    };

    const modalProps: ComponentProps<typeof UploadDocForm> = {
      docsChoices,
      onClose,
      onFileUploaded: onDone,
    };

    const [promptModal, dismiss] = presentIonModal(
      UploadDocForm,
      modalProps,
      '',
    );

    promptModal();
  });
