import { RouteComponentProps } from 'react-router';
import React from 'react';
import styled from 'styled-components';
import {
  DataLine,
  DataLineHighlight,
  DataLineItem,
  DataMultiline,
  Heading,
} from 'ui/organisms/summary';

import { formatDueDate } from 'utils';
import { useRetrievePaymentRequest } from 'api/queries';
import SiteFooter from 'components/SiteFooter/SiteFooter';
import { Link } from 'react-router-dom';
import { PoweredBy } from 'ui/atoms/powered-by';
import { AfiLogo } from 'ui/atoms/afi-logo';
import { useI18nObject } from 'utils/use-i18n-object';
import { formatAmount } from 'utils/format-amount';

interface TParams {
  id: string;
}

export const PaymentRequestTransaction = ({
  match,
}: RouteComponentProps<TParams>) => {
  const LL = useI18nObject();

  const { id } = match.params;
  const { data: paymentRequest } = useRetrievePaymentRequest(id);

  const {
    created,
    amount,
    currency,
    description,
    due_date: dueDate,
    logo,
    title,
    payer,
    payer_covers_fees,
    requester_name,
    requester_account,
    status,
  } = paymentRequest || {};

  const requesterName = title || requester_name;

  return (
    <Container>
      <Link to="/">
        {logo ? (
          <LogoContainer alt={requesterName} title={requesterName} src={logo} />
        ) : (
          <AfiLogo />
        )}
      </Link>
      <Content>
        <Heading
          pending={status && ['issued', 'processing_payment'].includes(status)}
        >
          {status === 'paid' && (
            <>
              <p>
                {LL.YOU_PAID()}
                {formatAmount({
                  amount,
                  currency,
                })}
              </p>
              <p>
                <To>{LL.TO()}</To> {requesterName}
              </p>
            </>
          )}
          {status === 'issued' && (
            <>
              <p>
                {LL.YOUR_PAYMENT()}{' '}
                {formatAmount({
                  amount,
                  currency,
                })}
              </p>
              <p>
                <To>{LL.TO()}</To> {requesterName}
              </p>
              <p>{LL.IS_PENDING()}</p>
            </>
          )}
          {status === 'processing_payment' && (
            <>
              <p>
                {LL.YOUR_PAYMENT()}
                {formatAmount({
                  amount,
                  currency,
                })}
              </p>
              <p>
                <To>{LL.TO()}</To> {requesterName}
              </p>
              <p>{LL.IS_BEING_PROCESSED()}</p>
            </>
          )}
        </Heading>
        <Information>
          <DataLine>
            <DataLineItem isTitle>{LL.REQUEST_ID()}</DataLineItem>
            <span>{id}</span>
          </DataLine>
          <DataMultiline>
            <DataLineItem isTitle>{LL.DESCRIPTION()}</DataLineItem>
            <span>{description}</span>
          </DataMultiline>
          <DataLine>
            <DataLineItem isTitle>{LL.DUE_DATE()}</DataLineItem>
            <span>{dueDate && formatDueDate(dueDate)}</span>
          </DataLine>
          <DataLine>
            <DataLineItem isTitle>{LL.INVOICE_AMOUNT()}</DataLineItem>
            <span>
              {formatAmount({
                amount,
                currency,
              })}
            </span>
          </DataLine>
          <DataLineHighlight>
            <span>{LL.TOTAL()}</span>
            <span>
              {formatAmount({
                amount,
                currency,
              })}
            </span>
          </DataLineHighlight>
        </Information>
      </Content>
      {!!logo && <PoweredBy />}
      <SiteFooter />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  padding: 16px;
`;

const LogoContainer = styled.img`
  max-width: 320px;
`;

const Content = styled.div`
  width: 100%;
  border: none;
  background-color: #ffffff;
  max-width: 460px;
  margin: auto;
  box-sizing: content-box;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  box-shadow: 0px 0px 6px 0px #aaa;
  border-radius: 4px;
`;

const Information = styled.div`
  flex-direction: column;
  display: flex;
  padding: 24px;
  gap: 16px;
`;

const To = styled.span`
  text-transform: lowercase;
`;
