import React from 'react';

import styled from 'styled-components';

import { useUploadDocument } from 'api/queries';
import { UploadButton } from 'ui/atoms/upload-button';
import { useI18nObject } from 'utils/use-i18n-object';

import CloseIcon from '../../assets/icon/close.svg';

interface Document {
  docType: string;
  docTypeLabel: string;
  file: File;
  url: string;
}

export interface Props {
  docsChoices: [string, string][];
  onFileUploaded: (doc: Document) => void;
  onClose: any;
}

export const UploadDocForm: React.FC<Props> = ({
  docsChoices,
  onFileUploaded,
  onClose,
}) => {
  const uploadDocumentMutation = useUploadDocument();

  const LL = useI18nObject();

  const createChangeHandler =
    ([docType, docTypeLabel]: Props['docsChoices'][number]) =>
    async (file: File) => {
      const url = URL.createObjectURL(file);
      const response = await uploadDocumentMutation.mutateAsync({
        title: docTypeLabel,
        document: file,
      });
      console.log({ response });
      onFileUploaded({ docType, docTypeLabel, file, url });
    };

  return (
    <div>
      <div className="title formModalTitle">
        {LL.UPLOAD_A_DOCUMENT()}
        {onClose && (
          <img src={CloseIcon} className="title-close-icon" onClick={onClose} />
        )}
      </div>
      <Form className="form1">
        {docsChoices.map((doc) => {
          const [value, label] = doc;
          return (
            <UploadButton
              className="btn submit-btn"
              key={value}
              onFilePick={createChangeHandler(doc)}
            >
              {label}
            </UploadButton>
          );
        })}
      </Form>
    </div>
  );
};

const Form = styled.div`
  border-radius: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
