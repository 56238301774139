import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import moment from 'moment';

import { DataLine, DataLineItem } from 'ui/organisms/summary';

import { useI18nObject } from 'utils/use-i18n-object';
import type { BulkPaymentRequest } from 'models';
import { InvoiceStatus } from 'models';
import { formatAmount } from 'utils/format-amount';

interface Props {
  data: BulkPaymentRequest;
}

export const BulkPRListItem: React.FC<Props> = ({ data }) => {
  const LL = useI18nObject();
  const STATUS_MAPPING: { [key in InvoiceStatus]: string } = {
    draft: LL.INVOICE_STATUS_SHORT_DRAFT(),
    paid: LL.INVOICE_STATUS_SHORT_PAID(),
    unpaid: LL.INVOICE_STATUS_SHORT_UNPAID(),
    pending: LL.INVOICE_STATUS_SHORT_PENDING(),
  };

  const { status } = data;
  const statusRepr = (status && STATUS_MAPPING[status]) ?? '';

  const createdDate = moment(data.created).format('DD/MM/YYYY');

  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(`/dashboard/bulk-pr/${data.id}`);
  }, []);

  return (
    <PaymentRequestListItemStyled onClick={onClick}>
      <DataLine>
        <DataLineItem>
          <Amount>
            {formatAmount({
              amount: data.total_requested_amount,
              currency: data.currency,
            })}
          </Amount>
        </DataLineItem>
        <DataLineItem secondary>{createdDate}</DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem>{LL.BULK_PAYMENT_REQUEST()}</DataLineItem>
        <DataLineItem secondary>
          <Status>{statusRepr}</Status>
        </DataLineItem>
      </DataLine>
      <DataLine>
        <DataLineItem>
          {LL.N_PAYMENT_REQUESTS({ amount: data.payment_requests.length })}
        </DataLineItem>
        <DataLineItem>{data.description}</DataLineItem>
      </DataLine>
    </PaymentRequestListItemStyled>
  );
};

const PaymentRequestListItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px solid var(--text-color-v5);
  gap: 8px;
  cursor: pointer;
`;

const Amount = styled.span<{
  isFailed?: boolean;
  isSucceeded?: boolean;
}>`
  color: ${(p) =>
    ({
      true: 'gray',
      [String(p.isSucceeded)]: 'var(--income-color)',
    }.true)};
  text-decoration: ${(p) => p.isFailed && 'line-through'};
`;

const Status = styled.div`
  text-transform: capitalize;
`;
