import React, { useCallback } from 'react';

import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import { useBusinessSettings, useUpdateBusinessSettings } from 'api/queries';

import styled from 'styled-components';
import {
  DataLine,
  DataLineHighlight,
  DataMultiline,
} from 'ui/organisms/summary';
import { formatDueDate } from 'utils';
import type { Currency, PaymentRequestData } from 'models';
import { InstantEditField } from 'ui/organisms/instant-edit-field';
import { UploadButton } from 'ui/atoms/upload-button';
import { Icon } from 'ui/atoms/icon';
import { useI18nObject } from 'utils/use-i18n-object';
import { formatAmount } from 'utils/format-amount';

interface Props {
  data: PaymentRequestData;
  currency: Currency;
  totalAmount: number;
}

export const PaymentRequestPreview: React.VFC<Props> = ({
  data,
  currency,
  totalAmount,
}) => {
  const LL = useI18nObject();

  const user = useStore($user);
  const { data: businessSettings } = useBusinessSettings();
  const businessSettingsMutation = useUpdateBusinessSettings();

  const requesterName =
    businessSettings?.payment_request_title ||
    `${user?.first_name} ${user?.last_name}`;
  const paymentRequestLogoSrc =
    businessSettings?.logo || 'assets/icon/logo.png';

  const dueDateFormatted = formatDueDate(
    Object.values(data.dueDates)[0].toISOString(),
  );

  const onTitleSave = useCallback(async (newValue) => {
    await businessSettingsMutation.mutateAsync({
      payment_request_title: newValue,
    });
  }, []);

  const onPhotoUploadClick = useCallback(async (value) => {
    await businessSettingsMutation.mutateAsync({
      logo: value,
    });
  }, []);

  return (
    <PreviewContainer>
      <Preview>
        <LogoContainer>
          <span />
          <Logo alt="logo" title="logo" src={paymentRequestLogoSrc} />
          <UploadButton onFilePick={onPhotoUploadClick}>
            <Icon name="Edit" size={24} color="var(--text-color-v3)" />
          </UploadButton>
        </LogoContainer>

        <Header>
          {LL.REQUESTER_REQUESTED_AMOUNT({
            requesterName,
            currencyAmount: formatAmount({
              currency,
              amount: totalAmount,
            }),
          })}
        </Header>
        <RequesterInformation>
          <DataLineHighlight>
            <InstantEditField value={requesterName} onSave={onTitleSave} />
            <span />
          </DataLineHighlight>
          <DataLine>
            <span>{LL.REQUEST_ID()}</span>
            <RequestId>abcdefgh</RequestId>
          </DataLine>
          <DataMultiline>
            <span>{LL.CATEGORY()}</span>
            <span>{data.category?.name}</span>
          </DataMultiline>
          <DataMultiline>
            <span>{LL.DESCRIPTION()}</span>
            <span>{data.description}</span>
          </DataMultiline>
          <DataLine>
            <span>{LL.DUE_DATE()}</span>
            <span>{dueDateFormatted}</span>
          </DataLine>
          <DataLineHighlight>
            <span>{LL.TOTAL()}</span>
            <span>
              {formatAmount({
                amount: totalAmount,
                currency,
              })}
            </span>
          </DataLineHighlight>
        </RequesterInformation>
      </Preview>
    </PreviewContainer>
  );
};

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

const Preview = styled.div`
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 16px;
`;

const RequestId = styled.span`
  filter: blur(3px);
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const Logo = styled.img`
  height: 60px;
  margin: 24px;
`;

const Header = styled.div``;

const RequesterInformation = styled.div`
  margin: 24px 0;
  min-width: 280px;
`;
