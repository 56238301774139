import type { ChangeEvent } from 'react';
import styled from 'styled-components';
import { useDevModeEnabled } from 'utils/use-dev-mode-enabled';
import { enableDevMode } from 'stores/dev-mode';
import { Link } from 'react-router-dom';

const DEV_MODE_PREFIX = '1337';

interface Props {}

export const DevModeButton: React.VFC<Props> = () => {
  const devModeEnabled = useDevModeEnabled();
  const currentDay = `0${new Date().getDate()}`.slice(-2);

  const handleFocus = (e: any) => {
    e.target.value = '';
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === `${DEV_MODE_PREFIX}${currentDay}`) {
      enableDevMode();
    }
  };

  return (
    <DevModeButtonStyled>
      {devModeEnabled ? (
        <Link to="/dev">🐞</Link>
      ) : (
        <input
          inputMode="numeric"
          pattern="[0-9]*"
          type="text"
          maxLength={6}
          tabIndex={-1}
          onFocus={handleFocus}
          onChange={handleChange}
        />
      )}
    </DevModeButtonStyled>
  );
};
const DevModeButtonStyled = styled.div`
  height: 20px;
  width: 20px;
  opacity: 0.5;

  display: flex;
  justify-content: center;
  align-items: center;

  > input {
    width: 20px;
    border: none;
  }
`;
