import React, { useCallback, useState } from 'react';

import { AttachmentRequirementField, Transaction } from 'models';
import 'components/forms.css';

import styled from 'styled-components';
import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';
import { useI18nObject } from 'utils/use-i18n-object';
import { useUploadAttachment } from 'api/queries/mutations';
import { CameraType } from 'utils/photo-capture';
import { ImageUploadInput } from 'ui/atoms/image-upload-input';
import { FileUploadInput } from 'ui/atoms/file-upload-input';
import Notifications from 'components/Notifications';

interface AttachmentField {
  file: File;
  fileId: string;
}

interface AttachmentFields {
  [k: string]: AttachmentField;
}

interface Props {
  initialValue: AttachmentFields;
  onSubmit: (v: AttachmentFields) => void;
  onGoBack: () => void;
  onGoNext: () => void;
  attachmentRequirements: Transaction['attachment_requirements'];
}

export const UploadAttachments = ({
  onSubmit,
  onGoBack,
  onGoNext,
  initialValue,
  attachmentRequirements,
}: Props) => {
  const LL = useI18nObject();

  const uploadAttachmentMutation = useUploadAttachment();

  const filteredFields = attachmentRequirements.fields.filter(
    (a) => a.stage === 'before',
  );

  const [attachmentFields, setAttachmentFields] =
    useState<AttachmentFields>(initialValue);

  const requiredAttachmentsCount =
    filteredFields?.filter((a) => !a.optional).length || 0;

  const requiredAttachmentsUploaded =
    Object.keys(attachmentFields).length >= requiredAttachmentsCount;

  const nextStepActive =
    requiredAttachmentsUploaded && !uploadAttachmentMutation.isLoading;

  const addAttachment = (filename: string) => (file: File) => {
    setAttachmentFields((v) => ({ ...v, [filename]: { file, fileId: '' } }));
  };

  const removeAttachment = (filename: string) => () => {
    setAttachmentFields((v) => {
      const { [filename]: _, ...newState } = {
        ...v,
      };
      return newState;
    });
  };

  const onSubmitAttempt = useCallback(async () => {
    if (!requiredAttachmentsUploaded) {
      Notifications.alert(LL.PLEASE_PROVIDE_THE_REQUIRED_ATTACHMENTS());
    }
    if (!nextStepActive) {
      return;
    }
    const promises = Object.entries(attachmentFields).map(
      ([filename, { file, fileId }]) => {
        if (fileId) {
          return Promise.resolve({
            id: fileId,
            file: null,
            image: null,
          });
        }
        const field: AttachmentRequirementField | undefined =
          filteredFields?.find((a) => a.name === filename);
        const type = field?.type || 'file';
        return uploadAttachmentMutation.mutateAsync({
          [type]: file,
        });
      },
    );

    Promise.all(promises).then(
      (
        values: {
          id: string;
          file: string | null;
          image: string | null;
        }[],
      ) => {
        const ids = values.map(({ id }) => id);
        const newAttachmentFields = Object.fromEntries(
          Object.entries(attachmentFields).map(([k, { file }], index) => [
            k,
            { file, fileId: ids[index] },
          ]),
        );
        setAttachmentFields(newAttachmentFields);
        onSubmit(newAttachmentFields);
        onGoNext();
      },
    );
  }, [attachmentFields, filteredFields]);

  return (
    <PrevNextStepper
      nextStepActive={nextStepActive}
      onPrevClick={onGoBack}
      onNextClick={onSubmitAttempt}
    >
      <Header>{LL.PLEASE_PROVIDE_THE_FOLLOWING_DETAILS()}</Header>
      <Form onSubmit={onSubmitAttempt}>
        {filteredFields?.map((a) => (
          <Section key={a.name}>
            {a.type === 'image' && (
              <ImageUploadInput
                value={attachmentFields[a.name]?.file}
                onChange={addAttachment(a.name)}
                onDelete={removeAttachment(a.name)}
                label={a.name}
                required={!a.optional}
                maxSizeMB={1}
                cameraType={CameraType.Rear}
                cameraPermissionGranted
              />
            )}
            {a.type === 'file' && (
              <FileUploadInput
                value={attachmentFields[a.name]?.file}
                onChange={addAttachment(a.name)}
                onDelete={removeAttachment(a.name)}
                label={a.name}
                required={!a.optional}
              />
            )}
          </Section>
        ))}
      </Form>
    </PrevNextStepper>
  );
};

const Form = styled.form`
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const Header = styled.h2`
  padding: 1.75rem 2rem;
  font-size: 1.3rem;
`;
