import React, { useCallback } from 'react';

import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';

import { useRecipients } from 'api/queries';
import Notifications from 'components/Notifications';
import {
  Currency,
  PaymentRequestCreationValidationBody,
  PaymentRequestData,
} from 'models';
import { $user } from 'stores/user';
import styled from 'styled-components';
import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';
import { DataLine, DataLineItem } from 'ui/organisms/summary';
import { formatDueDate } from 'utils';
import { formatAmount } from 'utils/format-amount';
import { useI18nObject } from 'utils/use-i18n-object';

import { useValidateCreatePaymentRequest } from 'api/queries/queries';
import styles from './MakePayment.module.css';

interface Props {
  paymentRequestData: PaymentRequestData;
  currency: Currency;
  totalAmount: number;
  children: React.ReactNode;
  onGoBack: () => void;
  onConfirm: () => void;
}

export const MakePayment: React.VFC<Props> = ({
  paymentRequestData,
  currency,
  totalAmount,
  children,
  onGoBack,
  onConfirm,
}) => {
  const LL = useI18nObject();
  const user = useStore($user);
  const history = useHistory();

  const { data: recipients } = useRecipients();
  const r = paymentRequestData.payerIds.map((id) => ({
    ...recipients?.find((r) => r.id === id),
    requestAmount: paymentRequestData.requestAmounts[id],
    dueDate: paymentRequestData.dueDates[id],
  }));
  const onCancel = useCallback(async () => {
    const decision = await Notifications.dialog(
      {
        message: LL.ARE_YOU_SURE_YOU_WANT(),
        backdropDismiss: true,
      },
      [
        {
          text: LL.KEEP_GOING_WITH_THE_REQUEST(),
          value: false,
          cssClass: 'main',
        },
        {
          text: LL.YES_CANCEL(),
          value: true,
          cssClass: 'secondary',
        },
      ],
    );
    if (decision) {
      history.push('/dashboard');
    }
  }, [history]);

  const transferData = paymentRequestData;
  const { bulk } = paymentRequestData;
  const payerId = bulk ? undefined : paymentRequestData.payerIds[0];
  const createTransactionBody: PaymentRequestCreationValidationBody = {
    category: transferData.category?.id,
    description: '',
    payer_covers_fees: false,
    amount: totalAmount,
    due_date: payerId ? transferData.dueDates[payerId].toISOString() : '',
    requester_account: transferData.requesterAccount?.id,
    payer: payerId,
    currency,
  };

  const { data: validationResult } = useValidateCreatePaymentRequest({
    ...createTransactionBody,
  });
  const restrict = validationResult?.restrict || false;
  const restrictionReason = validationResult?.restriction_reason || '';
  const nextStepActive = validationResult && !restrict;

  return (
    <PrevNextStepper
      onPrevClick={onGoBack}
      nextButtonLabel={LL.CONFIRM_AND_REQUEST()}
      onNextClick={onConfirm}
      nextStepActive={nextStepActive}
    >
      {restrict ? (
        <RestrictionWarning>{restrictionReason}</RestrictionWarning>
      ) : (
        <div className={`transfer-stage ${styles.transferStage}`}>
          <div className={styles.header}>
            {LL.PLEASE_ENSURE_PAYMENT_DETAILS_CORRECT()}
            <br />
            {LL.NO_REFUNDS_TO_WRONG_ACCOUNT()}
          </div>
          <div className={styles.summaryContainer}>
            <PaymentSummaryStyled>
              <DataLine>
                <DataLineItem isTitle>{LL.REQUEST_FROM()}</DataLineItem>
                <span>
                  {user?.first_name} {user?.last_name}
                </span>
                <DataLineItem secondary separateLine alignRight>
                  {paymentRequestData.requesterAccount?.account_title}
                </DataLineItem>
              </DataLine>
              <DataLine>
                <DataLineItem isTitle>{LL.PURPOSE_OF_PAYMENT()}</DataLineItem>
                <DataLineItem>{paymentRequestData.category?.name}</DataLineItem>
                <DataLineItem secondary separateLine alignRight>
                  {paymentRequestData.description}
                </DataLineItem>
              </DataLine>
              {r.map((payer) => (
                <React.Fragment key={payer.id}>
                  <DataLine>
                    <DataLineItem isTitle>
                      {payer?.first_name} {payer?.last_name}
                    </DataLineItem>
                    <DataLineItem>
                      {formatAmount({
                        amount: payer.requestAmount,
                        currency,
                      })}
                      <br />
                      {formatDueDate(payer?.dueDate?.toISOString())}
                    </DataLineItem>
                  </DataLine>
                  <hr />
                </React.Fragment>
              ))}
              <DataLine>
                <DataLineItem isTitle>{LL.FEES()}</DataLineItem>
                <DataLineItem>{LL.PROMOTIONAL()}</DataLineItem>
              </DataLine>
              <DataLine>
                <DataLineItem isTitle>{LL.TOTAL()}</DataLineItem>
                <DataLineItem>
                  {formatAmount({
                    amount: totalAmount,
                    currency,
                  })}
                </DataLineItem>
              </DataLine>
            </PaymentSummaryStyled>
            {children}
          </div>
          <div className={styles.footer}>
            <div className={styles.actionBtnContainer}>
              <span className={`${styles.cancelPaymentBtn}`} onClick={onCancel}>
                {LL.CANCEL_REQUEST()}
              </span>
            </div>
          </div>
        </div>
      )}
    </PrevNextStepper>
  );
};

const PaymentSummaryStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const RestrictionWarning = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
`;
