import { useStatus } from 'api/queries/queries';
import styled from 'styled-components';
import { LinkButton } from 'ui/atoms/link-button';
import { openExternalLink } from 'utils/open-external-link';
import { useStore } from 'effector-react';
import { $locale } from 'stores/locale';
import { useMemo } from 'react';

interface Props {
  className?: string;
}

export const StatusHelper = ({ className }: Props) => {
  const locale = useStore($locale);

  const { data: status } = useStatus();
  const onClick = () => {
    openExternalLink(
      'https://status.afipayments.com/',
      'https://status.afipayments.com/',
    );
  };

  const announcement = useMemo(() => {
    if (locale === 'fr') {
      return status?.announcementFr;
    }
    return status?.announcement;
  }, [status]);

  if (!status || !announcement) {
    return null;
  }
  return (
    <ButtonStyled className={className} onClick={onClick} level="high">
      {announcement}
    </ButtonStyled>
  );
};

const ButtonStyled = styled(LinkButton)<{ level: 'high' | 'medium' | 'low' }>`
  padding: 16px;
  color: black;
  background-color: ${({ level }) => {
    switch (level) {
      case 'low':
        return '#e9e91a';
      case 'medium':
        return '#ffa500';
      case 'high':
        return '#ff4b4b';
      default:
        break;
    }
  }};
`;
