import React, { useCallback } from 'react';
import { $locale, setLocale } from 'stores/locale/index';
import { useStore } from 'effector-react';

import styled from 'styled-components';
import { LANGUAGES } from 'data';

export interface LanguageSwitcherProps {
  onChange?: any;
  onIonChange?: any;
  value?: any;
  reload?: boolean;
  [k: string]: any;
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  onChange,
  onIonChange,
  value,
  countries,
  reload = true,
  ...props
}) => {
  const currentLocale = useStore($locale);

  const onSelectChange = useCallback(
    (event) => {
      const { value } = event.target;
      setLocale(value);
      if (onChange) {
        onChange(event);
      }
      if (onIonChange) {
        onIonChange({ detail: { value } });
      }
    },
    [onChange, onIonChange],
  );

  return (
    <LanguageSwitcherStyled
      {...props}
      onChange={onSelectChange}
      value={currentLocale}
    >
      {LANGUAGES.map(({ code, name }) => (
        <option key={code} value={code}>
          {name}
        </option>
      ))}
    </LanguageSwitcherStyled>
  );
};

export const LanguageSwitcherStyled = styled.select`
  user-select: none;
  border: none;
  background: inherit;
  width: 100%;
  cursor: pointer;
`;
